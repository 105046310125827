import React, { useEffect, useState } from "react";
import axios from "axios";
import "react-tabs/style/react-tabs.css";
import "../css/incident.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ReactECharts from "echarts-for-react";
import { Bars } from "react-loader-spinner";
import ApiClient from "./ApiClient";
function TableDashboard() {
  const [selectedTabRule, setSelectedTabRule] = useState(0);
  const [iframeData, setIframeData] = useState([]);
  const [source, setSource] = useState([]);
  const [selectedSource, setSelectedSource] = useState("");
  const [chartData, setChartData] = useState();
  const [reportName,setReportName] = useState();
  const [graphLoader, setGraphLoader] = useState(false);
  const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
    const [name, value] = cookie.split("=");
    acc[name] = decodeURIComponent(value);
    return acc;
  }, {});

  const headers = {
    "X-CSRFToken": cookies.csrftoken,
    "Content-Type": "application/json",
  };
  const handleTabRule = (index) => {
    setSelectedTabRule(index);
  };
  // const getDashboard = async () => {
  //   try {
  //     var data = await axios.get("/getPowerBIReport/dashboard");
  //     setIframeData(data?.data?.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const sendData = async () => {
    setChartData();
    setGraphLoader(true);
    try {
      const payload = {
        source: selectedSource,
      };
      const response = await axios.post("/table-charts-display/", payload, {
        headers: headers,
      });
      console.log(response, "response");
      setChartData(response?.data?.charts);
      setReportName(response?.data?.report_name)
      console.log(response?.data?.charts);
      setGraphLoader(false);
    } catch (error) {
      setGraphLoader(false);
      console.error("Error sending POST request:", error);
    }
  };

  useEffect(() => {
    sendData();
  }, [selectedSource]);

  const getSource = async () => {
    try {
      let data = await axios.get("/getSourceTable/");
      console.log(data?.data?.source);
      setSource(data?.data?.source);
      setSelectedSource(data?.data?.source?.[0]?.SOURCE);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      getSource();
    }, 3000);

    return () => clearTimeout(timer);
  }, []);
  console.log(chartData);
  return (
    <div className="p-4 mt-5 h-100">
      <div className="card adq-card p-3">
        {
          <Tabs selectedIndex={selectedTabRule} onSelect={handleTabRule}>
            <TabList className="horizontal-tabs pb-3">
              
              {reportName?.map((ele, index) => {
                return (
                  <Tab
                    className={`tab-item ${
                      selectedTabRule === index ? "active-tab" : ""
                    }`}
                  >
                    {ele}
                  </Tab>
                );
              })}
            </TabList>
            {/* {iframeData?.map((ele, index) => {
              return (
              );
            })} */}
            <TabPanel>
              <div className="row">
                <div className="col-2"></div>
                <div className="col-2">
                  <div className="text-center">
                    <h6 className="fw-bold">Data Source</h6>
                  </div>
                  <div style={{ overflowY: "auto", height: "600px" }}>
                    {source?.map((ele) => {
                      return (
                        <div
                          style={{
                            width: "95%",
                            border: "2px solid #176b87",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setSelectedSource(ele?.SOURCE);
                          }}
                          className={`text-center py-2 d-flex justify-content-center mainContainer align-items-center mb-1 dataSourceDashboard ${
                            selectedSource == ele?.SOURCE ? "active" : ""
                          }`}
                        >
                          <p
                            className="mb-0"
                            style={{ fontSize: "12px", color: "white" }}
                          >
                            {ele?.SOURCE}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-6">
                  {graphLoader && (
                    <div className="text-center d-flex justify-content-center h-100 align-items-center">
                      {" "}
                      <Bars
                        type="Puff"
                        color="#176b87"
                        height={70}
                        width={100}
                      />
                      <h6 className="mt-2 ms-3">Loading . . .</h6>
                    </div>
                  )}
                  <div className="mt-4 h-100">
                    {chartData && chartData?.[0]?.data?.xAxis.length !== 0 ? (
                      chartData.map((chart, index) => (
                        <div
                          // className="col-md-6"
                          className={
                            chartData?.length >= 1 ? "col-md-12" : "col-md-6"
                          }
                          key={index}
                        >
                          <ReactECharts
                            style={{
                              // width: "50%",
                              padding: "1%",
                              border: "1px solid #B0A676",
                              // marginTop: "0px",
                              // marginLeft: "-11px",
                              backgroundColor: "white",
                            }}
                            option={{
                              // backgroundColor:" #9284fc24",

                              color: [
                                "#0bb4ff",
                                "#FF5630",
                                "#e6d800",
                                "#5F6A6A",
                                "#00bfa0",
                                "#ffa300",
                                "#dc0ab4",
                                "#b3d4ff",
                              ],
                              title: {
                                text: chart?.heading,
                                textStyle: {
                                  fontSize: 15,
                                },
                              },
                              tooltip: {
                                trigger: "axis",
                                axisPointer: {
                                  type: "shadow",
                                },
                              },
                              toolbox: {
                                feature: {
                                  // restore: { show: true },
                                  // dataView: { show: true, readOnly: false },
                                  dataView: {
                                    show: true,
                                    readOnly: false,
                                    title: "Data View",
                                    lang: ["Data View", "Close", "Refresh"],
                                    optionToContent: function (opt) {
                                      let axisData = opt.xAxis[0].data;
                                      let series = opt.series;
                                      let table =
                                        '<table class="table" style="width:100%;";><thead><tr><th style="text-align: start;"></th>';

                                      for (
                                        let i = 0, l = series.length;
                                        i < l;
                                        i++
                                      ) {
                                        table += `<th style="text-align: start !important;">${
                                          series[i]?.name ? series[i].name : ""
                                        }</th>`;
                                      }

                                      table += "</tr></thead><tbody>";

                                      for (
                                        let i = 0, l = axisData.length;
                                        i < l;
                                        i++
                                      ) {
                                        table += "<tr>";
                                        table += `<td style="boder"><span style="margin-left:2%">${axisData[i]}</span></td>`;
                                        for (
                                          let j = 0, k = series.length;
                                          j < k;
                                          j++
                                        ) {
                                          table += `<td>${series[j].data[i]}</td>`;
                                        }
                                        table += "</tr>";
                                      }

                                      table += "</tbody></table>";
                                      return table;
                                    },
                                  },

                                  magicType: {
                                    show: true,
                                    type: ["line", "bar"],
                                  },
                                  saveAsImage: { show: true },
                                },
                              },
                              legend: {
                                // Try 'horizontal'
                                orient: "horizontal",
                                // right: 10,
                                padding: [26, 26, 26, 26],
                                data: chart?.xAxis,
                                // icon: "circle",
                              },

                              grid: {
                                // Set grid positioning to adjust legend margin
                                top: 80,
                                // Adjust this value to increase/decrease margin
                                bottom: 20,
                                left: 10,
                                right: 10,
                                containLabel: true,
                              },
                              xAxis: {
                                type: "category",
                                // name: "Table Name",
                                // nameGap: 30,
                                // nameLocation: "middle",
                                // boundaryGap:
                                //   chart?.chartType === "db" ? [0, 0.01] : false,
                                data: chart?.data?.xAxis,
                                axisLabel: {
                                  rotate: 20,
                                },
                              },
                              yAxis: {
                                type: "value",
                                // name: "Status count",
                                // nameGap: 30,
                                // nameLocation: "middle",
                              },
                              series:
                                chart?.chartType === "db"
                                  ? chart.data.yAxis.map(
                                      (seriesData, seriesIndex) => ({
                                        name:
                                          seriesData.name === "pass"
                                            ? "Pass"
                                            : seriesData.name === "fail"
                                            ? "Fail"
                                            : seriesData.name === "warn"
                                            ? "Warning"
                                            : seriesData.name === "invalid"
                                            ? "Invalid"
                                            : seriesData.name,
                                        type: seriesData.type,
                                        data: seriesData.data,
                                        barGap: "0%",
                                      })
                                    )
                                  : [
                                      {
                                        data: chart?.data?.yAxis,
                                        type: chart?.chartType,
                                        areaStyle: {},
                                      },
                                    ],
                            }}
                          />
                        </div>
                      ))
                    ) : (
                      <>
                        <div
                          className="h-100"
                          style={{
                            color: "#a3a2a2",
                          }}
                        >
                          <h3 className="text-center d-flex justify-content-center h-100 align-items-center">
                            No Data Found
                          </h3>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        }
      </div>
    </div>
  );
}

export default TableDashboard;

import axios from "axios";
import { Doughnut } from "react-chartjs-2";
import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net";
import { Bars } from "react-loader-spinner";
import IncidentTable from "./IncidentTable";
import { Line } from "react-chartjs-2";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { SwipeableDrawer } from "@mui/material";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IncidentErrorTable from "./IncidentErrorTable";
import PreviousIncidentError from "./PreviousIncidentError";
import OtherCheckIncident from "./OtherCheckIncident";
import "react-tabs/style/react-tabs.css";
import "../css/incident.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Cytoscape from "./Cytoscape";
import Swal from "sweetalert2";
import ApiClient from "./ApiClient";
import { color } from "echarts";
const initState = {
  category: "All",
  Type: "All",
  Status: "All",
};
let initialChartState = {
  type: "bar",
  data: {
    labels: [],
    datasets: [
      {
        label: "Row Count",
        data: [],
        backgroundColor: "#5470c6",
        fill: false,
        borderColor: "#5470c6",
        lineWithLine: true,
        tension: 0.4,
        pointStyle: "circle",
      },
    ],
  },
  options: {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  },
};
const typesDisplayName = [
  "All",
  "Composite Key",
  "Duplicate",
  "Freshness",
  "Maximum Length",
  "Minimum Length",
  "Null",
  "Mandatory Field(s)",
  "Relationship",
  "Row Count",
  "Validity",
  "Multi-Column Unique Index",
  "Current Vs Previous",
  "L1 Vs L2",
  "L1 Vs L3",
  "L2 Vs L3",
  "L1 Vs L2 Vs L3",
  "Standalone",
];
const typesValueName = [
  "All",
  "Composite Key Validation",
  "Duplicate Validation",
  "Freshness Validation",
  "Max Length Check",
  "Min Length Check",
  "Null Validation",
  "Mandatory Fields Check",
  "Relationship Validation",
  "Row Count Validation",
  "Validity Check",
  "Multi-Column Unique Index",
  "Current Vs Previous",
  "L1 Vs L2",
  "L1 Vs L3",
  "L2 Vs L3",
  "L1 Vs L2 Vs L3",
  "Standalone",
];

const RuleTypeDisplay = [
  "All",
  "Composite Key",
  "Duplicate",
  "Freshness",
  "Maximum Length",
  "Minimum Length",
  "Null",
  "Mandatory Field(s)",
  "Relationship",
  "Row Count",
  "Validity",
];
const RuleType = [
  "All",
  "Composite Key Validation",
  "Duplicate Validation",
  "Freshness Validation",
  "Max Length Check",
  "Min Length Check",
  "Null Validation",
  "Mandatory Fields Check",
  "Relationship Validation",
  "Row Count Validation",
  "Validity Check",
];

const MetricTypeDisplay = [
  "All",
  "Current Vs Previous",
  "L1 Vs L2",
  "L1 Vs L3",
  "L2 Vs L3",
  "L1 Vs L2 Vs L3",
  "Standalone",
];

var modelStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: 2,
  width: "40%",
  padding: 0,
};

const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
  const [name, value] = cookie.split("=");
  acc[name] = decodeURIComponent(value);
  return acc;
}, {});
const headers = {
  "X-CSRFToken": cookies.csrftoken,
  "Content-Type": "application/json",
};

const initialDonutChartDataCall = {
  labels: ["New", "In Progress", "Resolved"],
  datasets: [
    {
      data: [],
      backgroundColor: ["#EC0041", "orange", "green"],
    },
  ],
};
const chartoption = {
  legend: {
    display: true,
  },
  plugins: {
    legend: {
      display: true,
      position: "right",
      labels: {
        boxWidth: 15,
        font: {
          size: 10,
        },
        padding: 1,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const value = context.raw;
            return `${value}`;
          },
        },
      },
    },
  },
  maintainAspectRatio: false, // Allow chart to maintain aspect ratio
  responsive: true, // Make the chart responsive
  height: 100,
};

const getQCResultColumns = (qcType) => {
  const normalizedQCType = qcType?.toUpperCase();
  console.log(normalizedQCType, "normalizedQCType");
  switch (normalizedQCType) {
    case "L1 VS L2":
      return ["L1_VALUE", "L2_VALUE"];
    case "L1 VS L3":
      return ["L1_VALUE", "L3_VALUE"];
    case "L2 VS L3":
      return ["L2_VALUE", "L3_VALUE"];
    case "L1 VS L2 VS L3":
      return ["L1_VALUE", "L2_VALUE", "L3_VALUE"];
    case "STANDALONE":
      return ["EXPECTED_VALUE", "ACTUAL_VALUE"];
    case "CURRENT VS PREVIOUS":
      return ["CURRENT_VALUE", "PREVIOUS_VALUE"];
    default:
      return [];
  }
};

function Incident() {
  const tableRef = useRef(null);
  const dataTableRef = useRef(null);
  const [state, setState] = useState(initState);
  const [isApply, setIsApply] = useState(false);
  const [lookBackInput, setLookBackInput] = useState(21);
  const [status, setStatus] = useState({});
  const [incidentData, setIncidentData] = useState([]);
  const [tableLoader, setTableLoader] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [chartData, setChartData] = useState(initialChartState);
  const [isOpen, setIsOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [metricTitle, setMetricTitle] = useState({});
  const [ModelOpen, setModelOpen] = useState(false);
  const [rulesPopup, setRulesPopup] = useState({});
  const [tableErrorHistory, setTableErrorHistory] = useState([]);
  const [previousError, setPreviousError] = useState([]);
  const [otherCheck, setOtherCheck] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedTabRule, setSelectedTabRule] = useState(0);
  const [RuleInfo, setRuleInfo] = useState({});
  const [lineageData, setLineageData] = useState({ nodes: [] });
  const [lineageLoader, setLineageLoader] = useState(false);
  const [crtCategory, setCrtCategory] = useState("All");
  const [defaultSelect, setDefaultSelect] = useState(null);
  const [initialDonutChartData, setInitialDonutChartData] = useState(
    initialDonutChartDataCall
  );

  console.log(selectedData,"selectedData");

  var param = useParams();
  useEffect(() => {
    console.log("param.component", param.component);
    if (param.component != undefined) {
      var incident_id = parseInt(param.component);
      if (!isNaN(incident_id)) {
        let selectedTempData = incidentData?.filter(
          (ele) => ele.incdId == incident_id
        );
        if (selectedTempData.length) {
          setDefaultSelect(incident_id);
          selectedIncident({ ...selectedTempData[0] });
        }
      }
    }
  }, [param, incidentData]);

  function handleChange(evt) {
    const value = evt.target.value;
    const newState = { ...state };
    newState[evt.target.name] = value;
    if (evt.target.name == "category") {
      newState["Type"] = "All";
      setCrtCategory(value);
    }
    setState(newState);
    setIsApply(true);
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
    }
  }

  const handleTabSelect = (index) => {
    setSelectedTabIndex(index);
  };

  const handleTabRule = (index) => {
    setSelectedTabRule(index);
  };
  function handleChangeLook(event) {
    if (!isNaN(event.target.value)) {
      setIsApply(true);
      const firstValue = event.target.value.trim();
      const trimmedValue = firstValue.replace(/\./g, "");
      if (trimmedValue <= 60) {
        if (trimmedValue == "0") {
          setLookBackInput("");
        } else {
          setLookBackInput(trimmedValue);
        }
      } else {
        setLookBackInput(60);
      }
    }
  }

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };

  const incidentDashboard = async () => {
    setIsApply(false);
    setTableLoader(true);
    if (lookBackInput != "") {
      try {
        var data = await axios.get(`/incidents/incidentDashboardData/`, {
          params: {
            daysToFilter: lookBackInput,
            category: state?.category,
            type: state?.Type,
            status: state?.Status,
          },
        });
        setIncidentData(data?.data?.ResponseData);
        setStatus(data?.data?.chartData);
        let tempArr = [];
        tempArr.push(data?.data?.chartData?.newCnt);
        tempArr.push(data?.data?.chartData?.inPrgCnt);
        tempArr.push(data?.data?.chartData?.resolvedCnt);

        setInitialDonutChartData({
          ...initialDonutChartData,

          labels: ["New", "In Progress", "Resolved"],
          datasets: [
            {
              data: tempArr,
              backgroundColor: ["#FF0000", "#F29C00", "#98AA11"],
            },
          ],
        });
        setSelectedData([]);
      } catch (error) {
        console.log(error);
      }
    }
    setTableLoader(false);
  };

  const otherMetricDetails = async (id) => {
    try {
      var data = await axios.get(`/incidents/qcIdDetails/`, {
        params: { incdId: id },
      });
      modelStyle["width"] = "80%";
      setRulesPopup(data?.data?.Result[0]);
      setModelOpen(true);
    } catch (error) {
      console.log(error);
    }
  };
  const RulesDetails = async (childData = false) => {
    if (childData) {
      try {
        var data = await axios.get(`/incidents/ruleIdDetails/`, {
          params: { incdId: childData },
        });
        if (selectedData.incidentTbl.category == "Metric Rule") {
          modelStyle["width"] = "80%";
          // const validDataString = (data?.data?.Result).replace(/Decimal\('([\d.]+)'\)/g, '$1');
          // const sqlQueryPattern = /"([^"]*?)(?:"|$)/g;
          // const placeholders = [];
          // const textWithPlaceholders = validDataString.replace(sqlQueryPattern, (match, query) => {
          //     placeholders.push(query);
          //     return `"SQL_QUERY_${placeholders.length - 1}"`;
          // });
          // const textWithDoubleQuotes = textWithPlaceholders.replace(/'/g, '"');
          // const finalText = textWithDoubleQuotes.replace(/"SQL_QUERY_(\d+)"/g, (match, index) => `"${placeholders[index]}"`);
          // var temp = finalText.replace(/None/g, 'null').replace(/False/g, 'false').replace(/True/g, 'true');
          // setRulesPopup(JSON.parse(temp));
          setRulesPopup(data?.data?.Result);
        } else {
          modelStyle["width"] = "40%";
          // setRulesPopup(JSON.parse((data?.data?.Result).replace(/'/g, '"').replace(/\bNone\b/g, 'null').replace(/\bTrue\b/g, 'true').replace(/\bFalse\b/g, 'false')))
          setRulesPopup(data?.data?.Result);
        }
        setModelOpen(true);
      } catch (error) {
        console.log(error);
      }
    } else if (selectedData?.incdId) {
      try {
        var data = await axios.get(`/incidents/ruleIdDetails/`, {
          params: { incdId: selectedData?.incdId },
        });
        if (selectedData.incidentTbl.category == "Metric Rule") {
          modelStyle["width"] = "80%";
          // const validDataString = (data?.data?.Result).replace(/Decimal\('([\d.]+)'\)/g, '$1');
          // const sqlQueryPattern = /"([^"]*?)(?:"|$)/g;
          // const placeholders = [];
          // const textWithPlaceholders = validDataString.replace(sqlQueryPattern, (match, query) => {
          //     placeholders.push(query);
          //     return `"SQL_QUERY_${placeholders.length - 1}"`;
          // });
          // const textWithDoubleQuotes = textWithPlaceholders.replace(/'/g, '"');
          // const finalText = textWithDoubleQuotes.replace(/"SQL_QUERY_(\d+)"/g, (match, index) => `"${placeholders[index]}"`);
          // var temp = finalText.replace(/None/g, 'null').replace(/False/g, 'false').replace(/True/g, 'true');
          // setRulesPopup(JSON.parse(temp));
          setRulesPopup(data?.data?.Result);
        } else {
          modelStyle["width"] = "40%";
          // setRulesPopup(JSON.parse((data?.data?.Result).replace(/'/g, '"').replace(/\bNone\b/g, 'null').replace(/\bTrue\b/g, 'true').replace(/\bFalse\b/g, 'false')))
          setRulesPopup(data?.data?.Result);
        }
        setModelOpen(true);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (dataTableRef.current) {
      dataTableRef.current.destroy();
      if (!dataTableRef.current) {
        // Only initialize the DataTable if it hasn't been initialized before
        const dataTableInstance = $(tableRef.current).DataTable({
          language: {
            searchPlaceholder: "Search records",
          },
          lengthChange: false,
        });

        dataTableRef.current = dataTableInstance; // Store the DataTable instance
        if (dataTableRef.current) {
          dataTableRef.current.order([2, "asc"]).draw();
        }
        //}
      }
    }
    incidentDashboard();
  }, []);


  useEffect(() => {
    const timer = setTimeout(() => {
      incidentDashboard();
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const selectedIncident = (row) => {
    console.log(row);
    setSelectedData(row);
    if (row.incidentTbl.category == "Raw Data Rule") {
      if (row?.Rules?.CHECK == "Row Count Validation") {
        var label = row?.rowCheckData?.map((ele) =>
          convertDateWithoutHrs(ele?.time)
        );
        var data = row?.rowCheckData?.map((ele) => ele?.rowCount);
        var ruleData = JSON.parse(
          (row?.incidentTbl?.rules_details)
            .replace(/'/g, '"')
            .replace(/\bNone\b/g, "-")
            .replace(/\bTrue\b/g, "true")
            .replace(/\bFalse\b/g, "false")
            .replace(/:\s*-(\s*[,\}])/g, ": null$1")
        );
        var minRange = ruleData.min_value;
        var maxRange = ruleData.max_value;
        setChartData({
          ...chartData,
          data: {
            labels: label,
            datasets: [
              {
                ...chartData.data.datasets[0],
                data: data,
                // pointStyle: function(context) {
                //     var value = context.dataset.data[context.dataIndex];
                //     if (value > maxRange || value < minRange) {
                //         return 'rectRounded'; // Change this to the desired point style
                //     } else {
                //         return 'circle'; // Change this to the desired point style
                //     }
                // },
                backgroundColor: function (context) {
                  var value = context.dataset.data[context.dataIndex];
                  if (value > maxRange || value < minRange) {
                    return "#EE6666";
                  } else {
                    return "#5470c6";
                  }
                },
                borderColor: function (context) {
                  var value = context.dataset.data[context.dataIndex];
                  if (value > maxRange || value < minRange) {
                    return "#EE6666";
                  } else {
                    return "#5470c6";
                  }
                },
                pointRadius: function (context) {
                  var value = context.dataset.data[context.dataIndex];
                  if (value > maxRange || value < minRange) {
                    return 4;
                  } else {
                    return 3;
                  }
                },
              },
            ],
          },
        });
      } else {
        try {
          setTableData(
            JSON.parse(
              (row?.incidentTbl?.sample_failed_rows)
                 .replace(/'/g, '`')
                // .replace(/{'/g, '{"')
                // .replace(/'}/g, '"}')
                // .replace(/':'}/g, '":"')
                // .replace(/','}/g, '","')
                .replace(/\bNone\b/g, "-")
                .replace(/\bTrue\b/g, "true")
                .replace(/\bFalse\b/g, "false")
                .replace(/:\s*-(\s*[,\}])/g, ": null$1")
            )
          );
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      try {
        setTableData(
          JSON.parse(
            (row?.incidentTbl?.metric_rule_results)
              .replace(/'/g, '"')
              .replace(/\bNone\b/g, "-")
              .replace(/\bTrue\b/g, "true")
              .replace(/\bFalse\b/g, "false")
              .replace(/:\s*-(\s*[,\}])/g, ": null$1")
          )
        );
      } catch (error) {
        console.log(error);
      }
      try {
        const validDataString = (row?.incidentTbl?.rules_details).replace(
          /Decimal\('([\d.]+)'\)/g,
          "$1"
        );
        const sqlQueryPattern = /"([^"]*?)(?:"|$)/g;
        const placeholders = [];
        const textWithPlaceholders = validDataString.replace(
          sqlQueryPattern,
          (match, query) => {
            placeholders.push(query);
            return `"SQL_QUERY_${placeholders.length - 1}"`;
          }
        );
        const textWithDoubleQuotes = textWithPlaceholders.replace(/'/g, '"');
        const finalText = textWithDoubleQuotes.replace(
          /"SQL_QUERY_(\d+)"/g,
          (match, index) => `"${placeholders[index]}"`
        );
        var temp = finalText
          .replace(/None/g, "null")
          .replace(/False/g, "false")
          .replace(/True/g, "true");
        setMetricTitle(JSON.parse(temp));
      } catch (error) {
        console.log(error);
      }
    }
    setTimeout(() => {
      document
        .getElementById("expandOfIncident")
        .scrollIntoView({ block: "center", behavior: "smooth" });
      document.getElementById("expandOfIncident").style.scrollMarginTop =
        "100px";
      document.getElementById("expandOfIncident").style.boxShadow =
        "rgb(170, 175, 179) 0px 0px 7px 7px";
      setTimeout(() => {
        document.getElementById("expandOfIncident").style = "";
      }, 1000);
    }, 100);
  };

  const diffDate = (inputDate) => {
    const currentDate = new Date();
    const differenceInMilliseconds = currentDate - new Date(inputDate);

    const minutes = Math.floor((differenceInMilliseconds / 1000 / 60) % 60);
    const hours = Math.floor((differenceInMilliseconds / 1000 / 60 / 60) % 24);
    const days = Math.floor(differenceInMilliseconds / 1000 / 60 / 60 / 24);

    const dayStr = days ? `${days} d ` : "";
    const hoursStr = hours ? `${hours} h ` : "";
    const minutesstr = minutes ? `${minutes} min ` : "";
    return dayStr + hoursStr + minutesstr + "ago";
  };

  const getLineageData = async (source, table) => {
    setLineageLoader(true);
    try {
      var data = await axios.post(
        "/lineage/metric_lineage_tableData_validation/",
        { source_name: source, file_name: table },
        { headers }
      );
      if (data.data.status != "error") {
        setLineageData(data?.data?.data);
      } else setLineageData({ nodes: [] });
    } catch (error) {
      setLineageData({ nodes: [] });
      console.log(error);
    }
    setLineageLoader(false);
  };

  const moreDetails = async () => {
    setTableErrorHistory([]);
    setPreviousError([]);
    setOtherCheck([]);
    try {
      var data = await axios.get("/incidents/incidentsErrorDetails/", {
        params: { inc_id: selectedData?.incdId },
      });
      setSelectedTabIndex(0);
      setSelectedTabRule(0);
      setTableErrorHistory(data?.data?.table_error_list);
      if (data.data.incdData[0].category != "Metric Rule") {
        setPreviousError(data?.data?.previous_error_list);
        setOtherCheck(data?.data?.table_other_checks_list);
        var temp = (data?.data?.incdData[0]?.rules_details)
          .replace(/'/g, '"')
          .replace(/\bNone\b/g, "null")
          .replace(/\bTrue\b/g, "true")
          .replace(/\bFalse\b/g, "false");
        setRuleInfo(JSON.parse(temp));
      } else {
        setPreviousError(data?.data?.previous_error_list_metric);
        setOtherCheck(data?.data?.metricOtherChecks);
        const validDataString =
          (data?.data?.incdData[0]?.rules_details).replace(
            /Decimal\('([\d.]+)'\)/g,
            "$1"
          );
        const sqlQueryPattern = /"([^"]*?)(?:"|$)/g;
        const placeholders = [];
        const textWithPlaceholders = validDataString.replace(
          sqlQueryPattern,
          (match, query) => {
            placeholders.push(query);
            return `"SQL_QUERY_${placeholders.length - 1}"`;
          }
        );
        const textWithDoubleQuotes = textWithPlaceholders.replace(/'/g, '"');
        const finalText = textWithDoubleQuotes.replace(
          /"SQL_QUERY_(\d+)"/g,
          (match, index) => `"${placeholders[index]}"`
        );
        var temp = finalText
          .replace(/None/g, "null")
          .replace(/False/g, "false")
          .replace(/True/g, "true");
        setRuleInfo(JSON.parse(temp));
      }
      setIsOpen(true);
      getLineageData(
        selectedData?.incidentTbl?.source,
        selectedData?.incidentTbl?.table_name
      );
    } catch (error) {
      console.log(error);
    }
  };
  const incidentErrDesc = (chkData) => {
    const ruledetails = JSON.parse(
      chkData
        .replace(/'/g, '"')
        .replace(/\bNone\b/g, "null")
        .replace(/\bTrue\b/g, "true")
        .replace(/\bFalse\b/g, "false")
    );
    const errorDescriptions = {
      "Row Count Validation": () => {
        if (ruledetails.max_value && !ruledetails.min_value) {
          return `Rows are above the provided threshold of ${ruledetails.max_value}`;
        } else if (ruledetails.min_value && !ruledetails.max_value) {
          return `Rows are below the provided threshold of ${ruledetails.min_value}`;
        } else if (
          ruledetails.min_value !== "" &&
          ruledetails.max_value !== ""
        ) {
          return `Rows are not between the provided threshold of ${ruledetails.min_value} and ${ruledetails.max_value}`;
        } else {
          return "Provided threshold dosen't match the Rows";
        }
      },
      "Freshness Validation": () =>
        `Values older than expected date in ${ruledetails.table_name} ${ruledetails.column_name}`,
      "Relationship Validation": () => `Data Relationship failure`,
      "Null Validation": () => `${ruledetails.column_name} is found Null`,
      "Mandatory Fields Check": () =>
        `${ruledetails.QUERY_TEXT} column(s) is missing in incoming file`,
      "Max Length Check": () =>
        `${ruledetails.column_name} has exceeded the maximum allowed length of ${ruledetails.max_value}`,
      "Validity Check": () => {
        switch (ruledetails.column_format.toUpperCase()) {
          case "INVALID VALUES":
            return `${ruledetails.column_name} contains invalid value(s) ${ruledetails.format_value}`;
          case "VALID VALUES":
            return `${ruledetails.column_name} contains value(s) other than ${ruledetails.format_value}`;
          case "VALID FORMAT":
            return `${ruledetails.column_name} is not in the valid format ${ruledetails.format_value}`;
          case "VALID REGEX":
            return `${ruledetails.column_name} is not in the valid format ${ruledetails.query_text}`;
          default:
            return "";
        }
      },
      "Duplicate Validation": () =>
        `Duplicate Values found in column ${ruledetails.column_name}`,
      "Metadata Check": () =>
        `Schema error in table ${ruledetails.table_name} columns ${ruledetails.query_text} are missing`,
      "Min Length Check": () =>
        `${ruledetails.column_name} does not meet the minimum allowed length of ${ruledetails.min_value}`,
      "Multi-Column Unique Index": () =>
        `Unique Constraint Violation ${ruledetails.query_text}`,
      "L1 vs L2": () =>
        `Metric ${ruledetails.metrics_name} for the field ${ruledetails.field_name} returned inconsistent values`,
      "L1 vs L3": () =>
        `Metric ${ruledetails.metrics_name} for the field ${ruledetails.field_name} returned inconsistent values`,
      "L2 vs L3": () =>
        `Metric ${ruledetails.metrics_name} for the field ${ruledetails.field_name} returned inconsistent values`,
      "L1 vs L2 vs L3": () =>
        `Metric ${ruledetails.metrics_name} for the field ${ruledetails.field_name} returned inconsistent values`,
      "Current vs Previous": () =>
        `Metric ${ruledetails.metrics_name} for the field ${ruledetails.field_name} returned inconsistent values`,
      Standalone: () =>
        `Metric ${ruledetails.metrics_name} for the field ${ruledetails.field_name} returned inconsistent values`,
    };
    const getDescription = errorDescriptions[ruledetails.check];
    return getDescription ? getDescription() : "";
  };

  const changeStatus = async (e) => {
    try {
      let datas = await axios.get("/incidents/IncidentStatusChange/", {
        params: { incID: selectedData?.incdId, sts: e.target.value },
      });
      setIsOpen(false);
      Swal.fire({
        title: "Status Changed successfully",
        text: "",
        icon: "success",
        confirmButtonColor: "#176b87",
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  const convertDate = (input) => {
    const usEasternTimezone = "America/New_York";
    const date_format = "YYYY-MM-DD HH:mm:ss"; // Adjust this format as needed

    if (input && input !== "NO-DATE") {
      const newone = moment.utc(input, date_format);
      // const localTime = newone.tz(usEasternTimezone);
      // const formattedLocalTime = localTime.format("MM-DD-YYYY HH:mm");
      const formattedLocalTime = newone.format("MM-DD-YYYY HH:mm");
      
      return formattedLocalTime;
    }
  };

  const convertDateWithoutHrs = (input) => {
    const usEasternTimezone = "America/New_York";
    const date_format = "YYYY-MM-DD HH:mm:ss"; // Adjust this format as needed

    if (input && input !== "NO-DATE") {
      const newone = moment.utc(input, date_format);
      // const localTime = newone.tz(usEasternTimezone);
      // const formattedLocalTime = localTime.format("MM-DD-YYYY");
      const formattedLocalTime = newone.format("MM-DD-YYYY");
      return formattedLocalTime;
    }
  };
  const DynamicMetricResultTable = ({ tableData }) => {
    console.log(tableData, "tableData");
    console.log(tableData?.QC_TYPE, "tableData?.QC_TYPE");
    const columnsToShow = getQCResultColumns(tableData?.QC_TYPE);
    console.log(columnsToShow, "columnsToShow");
    let valueColor = "##0000FF";
    let L2ValueColor = "##0000FF";
    const {
      L1_VALUE,
      L2_VALUE,
      L3_VALUE,
      EXPECTED_VALUE,
      ACTUAL_VALUE,
      CURRENT_VALUE,
      PREVIOUS_VALUE,
      QC_TYPE,
    } = tableData;
  
    switch (QC_TYPE?.toUpperCase()) {
      case "L1 VS L2":
        if (L2_VALUE !== L1_VALUE) L2ValueColor = "#FF0000";
        break;
  
      case "L1 VS L3":
        if (L3_VALUE !== L1_VALUE) valueColor = "#FF0000";
        break;
  
      case "L2 VS L3":
        if (L2_VALUE !== L3_VALUE) valueColor = "#FF0000";
        break;
  
      case "L1 VS L2 VS L3":
        if (L2_VALUE !== L1_VALUE) {
          L2ValueColor = "#FF0000";
        }
        if (L3_VALUE !== L1_VALUE){
          valueColor = "#FF0000";
        }
        break;
  
      case "STANDALONE":
        if (EXPECTED_VALUE !== ACTUAL_VALUE) valueColor = "#FF0000";
        break;
  
      case "CURRENT VS PREVIOUS":
        if (CURRENT_VALUE !== PREVIOUS_VALUE) valueColor = "#FF0000";
        break;
  
      default:
        break;
    }
  
    return (
      <div className="table-responsive">
        {columnsToShow.length > 0 ? (
          <table className="table table-striped">
            <thead>
              <tr>
                {columnsToShow.includes("L1_VALUE") && <th>L1 QUERY RESULT</th>}
                {columnsToShow.includes("L2_VALUE") && <th>L2 QUERY RESULT</th>}
                {columnsToShow.includes("L3_VALUE") && <th>L3 QUERY RESULT</th>}
                {columnsToShow.includes("EXPECTED_VALUE") && (
                  <th>EXPECTED VALUE</th>
                )}
                {columnsToShow.includes("ACTUAL_VALUE") && (
                  <th>ACTUAL VALUE</th>
                )}
                {columnsToShow.includes("CURRENT_VALUE") && (
                  <th>CURRENT VALUE</th>
                )}
                {columnsToShow.includes("PREVIOUS_VALUE") && (
                  <th>PREVIOUS VALUE</th>
                )}
              </tr>
            </thead>
            <tbody>
              <tr>
                {columnsToShow.includes("L1_VALUE") && (
                  <td className="int-cell">{tableData?.L1_VALUE || "-"}</td>
                )}
                {columnsToShow.includes("L2_VALUE") && (
                  <td className="int-cell" style={{ color: L2ValueColor }}>
                    {tableData?.L2_VALUE || "-"}
                  </td>
                )}
                {columnsToShow.includes("L3_VALUE") && (
                  <td className="int-cell" style={{ color: valueColor }}>
                    {tableData?.L3_VALUE || "-"}
                  </td>
                )}
                {columnsToShow.includes("EXPECTED_VALUE") && (
                  <td className="int-cell">
                    {tableData?.EXPECTED_VALUE || "-"}
                  </td>
                )}
                {columnsToShow.includes("ACTUAL_VALUE") && (
                  <td className="int-cell" style={{ color: valueColor }}>
                    {tableData?.ACTUAL_VALUE || "-"}
                  </td>
                )}
                {columnsToShow.includes("CURRENT_VALUE") && (
                  <td className="int-cell">
                    {tableData?.CURRENT_VALUE || "-"}
                  </td>
                )}
                {columnsToShow.includes("PREVIOUS_VALUE") && (
                  <td className="int-cell" style={{ color: valueColor }}>
                    {tableData?.PREVIOUS_VALUE || "-"}
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        ) : (
          <p>No data available</p>
        )}
      </div>
    );
  };

  return (
    <div className="p-4 mt-5">
      <div className="card adq-card p-3">
        <div className="row me-2">
          <div className="col-10">
            <h2 className="adq-source">Incidents</h2>
            <div className="row">
              <div className="col-10">
                <div className="row">
                  <div className="col-3">
                    <label className="fw-bold" style={{ fontSize: "15px" }}>
                      Category
                    </label>
                    <select
                      className="form-control"
                      name="category"
                      value={state?.category}
                      onChange={handleChange}
                    >
                      <option value="All">All</option>
                      <option value="Raw Data Rule">Raw Data Rule</option>
                      <option value="Metric Rule">Metric Rule</option>
                    </select>
                  </div>
                  <div className="col-3">
                    <label className="fw-bold" style={{ fontSize: "15px" }}>
                      Type
                    </label>
                    <select
                      className="form-control"
                      name="Type"
                      value={state?.Type}
                      onChange={handleChange}
                    >
                      {crtCategory == "All"
                        ? typesValueName?.map((ele, index) => {
                            return (
                              <option value={ele}>
                                {typesDisplayName?.[index]}
                              </option>
                            );
                          })
                        : crtCategory == "Raw Data Rule"
                        ? RuleType?.map((ele, index) => {
                            return (
                              <option value={ele}>
                                {RuleTypeDisplay?.[index]}
                              </option>
                            );
                          })
                        : crtCategory == "Metric Rule"
                        ? MetricTypeDisplay?.map((ele, index) => {
                            return <option value={ele}>{ele}</option>;
                          })
                        : null}
                    </select>
                  </div>
                  <div className="col-3">
                    <label className="fw-bold" style={{ fontSize: "15px" }}>
                      Status
                    </label>
                    <select
                      className="form-control"
                      name="Status"
                      value={state?.Status}
                      onChange={handleChange}
                    >
                      <option value="All">All</option>
                      <option value="New">New </option>
                      <option value="Assigned">Assigned</option>
                      <option value="In Progress">In Progress</option>
                      <option value="Resolved">Resolved</option>
                      <option value="False Positive">False Positive</option>
                      <option value="Deferred">Deferred</option>
                    </select>
                  </div>
                  <div className="col-2">
                    {/* <div className='d-flex justify-content-between'> */}
                    <label className="fw-bold" style={{ fontSize: "15px" }}>
                      Lookback Period{" "}
                    </label>
                    <input
                      type="text"
                      min="0"
                      max="100"
                      className="form-control ms-1"
                      value={lookBackInput}
                      onInput={handleChangeLook}
                      onKeyPress={handleKeyPress}
                    />
                    {/* </div> */}
                  </div>
                  <div className="col-1">
                    <button
                      className="btn mt-4"
                      disabled={!isApply}
                      style={{
                        backgroundColor: "#176b87",
                        color: "white",
                      }}
                      onClick={() => {
                        incidentDashboard();
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="me-2 ms-2">
                  <p className="mb-2">Till Date</p>
                  <span className=" fw-bold">
                    {status?.date || "DD-MM-YYYY"}
                  </span>
                </div>
              </div>
              {/* <div className='row'>
                            <div className='col-4'>
                                <div className='me-2 ms-2'>
                                    <p className='mb-2'>Till Date</p>
                                    <span className=' fw-bold'>{status?.date || 'DD-MM-YYYY'}</span>
                                </div>
                            </div>
                            <div className='col-8'>
                                <div className='card adq-card pt-2 pb-2 ps-3 pe-3'>
                                    <div className='d-flex justify-content-between'>
                                        <div className='me-2'>
                                            <p className='mb-2 fw-bold'>New</p>
                                            <span >{status?.newCnt || 0}</span>
                                        </div>
                                        <div className='me-2'>
                                            <p className='mb-2 fw-bold'>In-Progress</p>
                                            <span >{status?.inPrgCnt || 0}</span>
                                        </div>
                                        <div className='me-2'>
                                            <p className='mb-2 fw-bold'>Resolved</p>
                                            <span >{status?.resolvedCnt || 0}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
            </div>
          </div>
          <div className="col-2 donut-card">
            <div style={{ height: "100px", marginLeft: "-32%" }}>
              <Doughnut
                data={initialDonutChartData}
                options={chartoption}
                className=""
              />
            </div>
          </div>
        </div>
        {tableLoader ? (
          <div className="d-flex justify-content-center align-items-center text-center h-100">
            <Bars type="Puff" color="#176b87" height={100} width={100} />
            <h6>Loading . . .</h6>
          </div>
        ) : (
          <IncidentTable
            incidentData={incidentData}
            sourceList={typesValueName}
            defaultSelect={defaultSelect}
            handleIncident={(ele) => {
              selectedIncident(ele);
            }}
          ></IncidentTable>
        )}
      </div>
      {selectedData?.incdId ? (
        selectedData.incidentTbl.category == "Raw Data Rule" ? (
          <div className="card adq-card mt-4 p-3" id="expandOfIncident">
            <div className="row mb-3">
              <div className="col-4">
                <h6>
                  {incidentErrDesc(selectedData?.incidentTbl?.rules_details)}
                </h6>
                <div className="d-flex">
                  <span className="spann">
                    {convertDate(selectedData?.incidentTbl?.time)}
                  </span>
                  <span className="ms-3 spann">
                    {diffDate(selectedData?.incidentTbl?.time)}
                  </span>
                </div>
              </div>
              <div className="col-4 ps-3 demo-card">
                <div className="d-flex justify-content-around  align-items-center h-100 w-100">
                  <div className="row ">
                    <p className=" mb-0 text-center">Total Records </p>
                    <span className="fw-bold text-center">
                      {selectedData?.incidentTbl?.total_record_count || 0}
                    </span>
                  </div>

                  <div className="row ">
                    <p className=" mb-0 text-center">Mismatches </p>
                    <span className="fw-bold text-center">
                      {selectedData?.incidentTbl?.error_record_count || 0}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="row">
                  <div className="col-6">
                    <p className="mb-0">
                      Incident ID :{" "}
                      <span className="fw-bold">
                        {selectedData?.incdId || 0}
                      </span>
                    </p>
                  </div>
                  <div className="col-6 d-flex ">
                    <label className="mt-2">Status</label>
                    <select
                      className="form-control ms-3"
                      value={selectedData?.incidentTbl?.status}
                      onChange={(event) => {
                        changeStatus(event);
                      }}
                    >
                      <option value="NEW">New</option>
                      <option value="IN PROGRESS">In Progress</option>
                      <option value="RESOLVED">Resolved</option>
                    </select>
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-4">
                  <button
                    className="btn mx-2"
                    style={{
                      backgroundColor: "#176b87",
                      color: "white",
                    }}
                    onClick={() => {
                      RulesDetails();
                    }}
                  >
                    Rule Details
                  </button>
                  <button
                    className="btn mx-2"
                    style={{
                      backgroundColor: "#176b87",
                      color: "white",
                    }}
                    onClick={() => {
                      moreDetails();
                    }}
                  >
                    More Details
                  </button>
                </div>
              </div>
              <div className="row">
                <p className=" mb-0">
                  Source&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{" "}
                  <span className="fw-bold">
                    {selectedData?.incidentTbl?.source || "-"}
                  </span>
                </p>
              </div>
              {selectedData?.incidentTbl?.data_area && (
                <div className="row">
                  <p className=" mb-0">
                    DataArea :{" "}
                    <span className="fw-bold">
                      {selectedData?.incidentTbl?.data_area || "-"}
                    </span>
                  </p>
                </div>
              )}
              <div className="row">
                <p className=" mb-0">
                  Table &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{" "}
                  <span className="fw-bold">
                    {selectedData?.incidentTbl?.table_name || "-"}
                  </span>
                </p>
              </div>
              <div className="row">
                <p className=" mb-0">
                  Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{" "}
                  <span className="fw-bold">
                    {selectedData?.incidentTbl?.type || "-"}
                  </span>
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              {selectedData?.Rules?.CHECK == "Row Count Validation" ? (
                <div className="w-50 text-center">
                  <Line options={chartData?.options} data={chartData?.data} />
                </div>
              ) : (
                <div className="table-responsive">
                  {tableData.length ? (
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          {Object.keys(tableData[0])?.map((ele) => {
                            return <th>{ele}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {tableData?.map((item, index) => (
                          <tr key={index}>
                            {Object.values(item)?.map((value, index) => (
                              <td key={index}>{value}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="card adq-card mt-4 p-3" id="expandOfIncident">
            <div className="row mb-3">
              <div className="col-4">
                <h6>{`Metric ${metricTitle.metrics_name} for the field ${metricTitle.field_name} returned inconsistent values`}</h6>
                <div className="d-flex">
                  <span className="spann">
                    {convertDate(selectedData?.incidentTbl?.time)}
                  </span>
                  <span className="ms-3 spann">
                    {diffDate(selectedData?.incidentTbl?.time)}
                  </span>
                </div>
              </div>
              <div className="col-4 ps-3"></div>
              <div className="col-4">
                <div className="row">
                  <div className="col-6">
                    <p className="mb-0">
                      Incident ID :{" "}
                      <span className="fw-bold">
                        {selectedData?.incdId || 0}
                      </span>
                    </p>
                  </div>
                  <div className="col-6 d-flex ">
                    <label className="mt-2">Status</label>
                    <select
                      className="form-control ms-3"
                      value={selectedData?.incidentTbl?.status}
                      onChange={(event) => {
                        changeStatus(event);
                      }}
                    >
                      <option value="NEW">New</option>
                      <option value="IN PROGRESS">In Progress</option>
                      <option value="RESOLVED">Resolved</option>
                    </select>
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-4">
                  <button
                    className="btn mx-2"
                    style={{
                      backgroundColor: "#176b87",
                      color: "white",
                    }}
                    onClick={() => {
                      RulesDetails();
                    }}
                  >
                    Rule Details
                  </button>
                  <button
                    className="btn mx-2"
                    style={{
                      backgroundColor: "#176b87",
                      color: "white",
                    }}
                    onClick={() => {
                      moreDetails();
                    }}
                  >
                    More Details
                  </button>
                </div>
              </div>
              <div className="row">
                <p className=" mb-0">
                  Source&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{" "}
                  <span className="fw-bold">
                    {selectedData?.incidentTbl?.source || 0}
                  </span>
                </p>
              </div>
              {selectedData?.incidentTbl?.data_area && (
                <div className="row">
                  <p className=" mb-0">
                    DataArea :{" "}
                    <span className="fw-bold">
                      {selectedData?.incidentTbl?.data_area || "-"}
                    </span>
                  </p>
                </div>
              )}
              <div className="row">
                <p className=" mb-0">
                  Table &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{" "}
                  <span className="fw-bold">
                    {selectedData?.incidentTbl?.table_name || 0}
                  </span>
                </p>
              </div>
              <div className="row">
                <p className=" mb-0">
                  Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{" "}
                  <span className="fw-bold">
                    {selectedData?.incidentTbl?.type || "-"}
                  </span>
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              {selectedData?.Rules?.CHECK == "Row Count Validation" ? (
                <div className="w-50 text-center">
                  <Line options={chartData?.options} data={chartData?.data} />
                </div>
              ) : (
                // <div className="table-responsive">
                //   {tableData != {} ? (
                //     <table className="table table-striped">
                //       <thead>
                //         <tr>
                //           <th>L1 QUERY RESULT</th>
                //           <th>L2 QUERY RESULT</th>
                //           <th>L3 QUERY RESULT</th>
                //         </tr>
                //       </thead>
                //       <tbody>
                //         <tr>
                //           <td class="int-cell">{tableData?.L1_VALUE || "-"}</td>
                //           <td class="int-cell">{tableData?.L2_VALUE || "-"}</td>
                //           <td class="int-cell">{tableData?.L3_VALUE || "-"}</td>
                //         </tr>
                //       </tbody>
                //     </table>
                //   ) : null}
                // </div>
                <div>
                  {tableData != {} ? (
                    <DynamicMetricResultTable tableData={tableData} />
                  ) : null}
                </div>
              )}
            </div>
          </div>
        )
      ) : null}

      <SwipeableDrawer
        anchor="right"
        open={isOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        PaperProps={{ style: { width: "75%" } }}
        transitionDuration={1000}
      >
        <div role="presentation">
          <div className="p-2 pt-3">
            {selectedData?.incdId ? (
              selectedData.incidentTbl.category == "Raw Data Rule" ? (
                <div className="card adq-card p-3">
                  <div className="row mb-3">
                    <div className="col-4">
                      <h6>
                        {incidentErrDesc(
                          selectedData?.incidentTbl?.rules_details
                        )}
                      </h6>
                      <div className="d-flex">
                        <span className="spann">
                          {convertDate(selectedData?.incidentTbl?.time)}
                        </span>
                        <span className="ms-3 spann">
                          {diffDate(selectedData?.incidentTbl?.time)}
                        </span>
                      </div>
                    </div>
                    <div className="col-4 ps-3 demo-card">
                      <div className="d-flex justify-content-around align-items-center h-100 w-100">
                        <div className="row ">
                          <p className=" mb-0 text-center">Total Records </p>
                          <span className="fw-bold text-center">
                            {selectedData?.incidentTbl?.total_record_count || 0}
                          </span>
                        </div>
                        <div className="row ">
                          <p className=" mb-0 text-center">Mismatches </p>
                          <span className="fw-bold text-center">
                            {selectedData?.incidentTbl?.error_record_count || 0}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="row">
                        <div className="col-6">
                          <p className="mb-0">
                            Incident ID :{" "}
                            <span className="fw-bold">
                              {selectedData?.incdId || 0}
                            </span>
                          </p>
                        </div>
                        <div className="col-6 d-flex">
                          <label className="mt-2">Status</label>
                          <select
                            className="form-control ms-3"
                            value={selectedData?.incidentTbl?.status}
                            onChange={(event) => {
                              changeStatus(event);
                            }}
                          >
                            <option value="NEW">New</option>
                            <option value="IN PROGRESS">In Progress</option>
                            <option value="RESOLVED">Resolved</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <p className=" mb-0">
                      Source &nbsp;&nbsp;&nbsp;&nbsp;:{" "}
                      <span className="fw-bold">
                        {selectedData?.incidentTbl?.source || "-"}
                      </span>
                    </p>
                  </div>
                  {selectedData?.incidentTbl?.data_area && (
                    <div className="row">
                      <p className=" mb-0">
                        DataArea :{" "}
                        <span className="fw-bold">
                          {selectedData?.incidentTbl?.data_area || "-"}
                        </span>
                      </p>
                    </div>
                  )}
                  <div className="row">
                    <p className=" mb-0">
                      Table &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{" "}
                      <span className="fw-bold">
                        {selectedData?.incidentTbl?.table_name || "-"}
                      </span>
                    </p>
                  </div>
                  <div className="row">
                    <p className=" mb-0">
                      Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{" "}
                      <span className="fw-bold">
                        {selectedData?.incidentTbl?.type || "-"}
                      </span>
                    </p>
                  </div>
                </div>
              ) : (
                <div className="card adq-card mt-4 p-3" id="expandOfIncident">
                  <div className="row mb-3">
                    <div className="col-8">
                      <h6>{`Metric ${metricTitle.metrics_name} for the field ${metricTitle.field_name} returned inconsistent values`}</h6>
                      <div className="d-flex">
                        <span className="spann">
                          {convertDate(selectedData?.incidentTbl?.time)}
                        </span>
                        <span className="ms-3 spann">
                          {diffDate(selectedData?.incidentTbl?.time)}
                        </span>
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="row">
                        <div className="col-6">
                          <p className="mb-0">
                            Incident ID :{" "}
                            <span className="fw-bold">
                              {selectedData?.incdId || 0}
                            </span>
                          </p>
                        </div>
                        <div className="col-6 d-flex ">
                          <label className="mt-2">Status</label>
                          <select
                            className="form-control ms-3"
                            value={selectedData?.incidentTbl?.status}
                            onChange={(event) => {
                              changeStatus(event);
                            }}
                          >
                            <option value="NEW">New</option>
                            <option value="IN PROGRESS">In Progress</option>
                            <option value="RESOLVED">Resolved</option>
                          </select>
                        </div>
                      </div>
                      {/* <div className='d-flex justify-content-end mt-4'>
                                                   
                                                </div> */}
                    </div>
                    <div className="row">
                      <p className=" mb-0">
                        Source &nbsp;&nbsp;&nbsp;&nbsp;:{" "}
                        <span className="fw-bold">
                          {selectedData?.incidentTbl?.source || "-"}
                        </span>
                      </p>
                    </div>
                    {selectedData?.incidentTbl?.data_area && (
                      <div className="row">
                        <p className=" mb-0">
                          DataArea :{" "}
                          <span className="fw-bold">
                            {selectedData?.incidentTbl?.data_area || "-"}
                          </span>
                        </p>
                      </div>
                    )}
                    <div className="row">
                      <p className=" mb-0">
                        Table &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{" "}
                        <span className="fw-bold">
                          {selectedData?.incidentTbl?.table_name || "-"}
                        </span>
                      </p>
                    </div>
                    <div className="row">
                      <p className=" mb-0">
                        Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{" "}
                        <span className="fw-bold">
                          {selectedData?.incidentTbl?.type || "-"}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    {selectedData?.Rules?.CHECK == "Row Count Validation" ? (
                      <div className="w-50 text-center">
                        <Line
                          options={chartData?.options}
                          data={chartData?.data}
                        />
                      </div>
                    ) : (
                      // <div className="table-responsive">
                      //   {tableData != {} ? (
                      //     <table className="table table-striped">
                      //       <thead>
                      //         <tr>
                      //           <th>L1 QUERY RESULT</th>
                      //           <th>L2 QUERY RESULT</th>
                      //           <th>L3 QUERY RESULT</th>
                      //         </tr>
                      //       </thead>
                      //       <tbody>
                      //         <tr>
                      //           <td class="int-cell">
                      //             {tableData?.L1_VALUE || "-"}
                      //           </td>
                      //           <td class="int-cell">
                      //             {tableData?.L2_VALUE || "-"}
                      //           </td>
                      //           <td class="int-cell">
                      //             {tableData?.L3_VALUE || "-"}
                      //           </td>
                      //         </tr>
                      //       </tbody>
                      //     </table>
                      //   ) : null}
                      // </div>

                      <div>
                        {tableData != {} ? (
                          <DynamicMetricResultTable tableData={tableData} />
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
              )
            ) : null}

            <div className="card adq-card p-3 mt-4">
              {isOpen ? (
                <Tabs selectedIndex={selectedTabRule} onSelect={handleTabRule}>
                  <TabList className="horizontal-tabs">
                    <Tab
                      className={`tab-item ${
                        selectedTabRule === 0 ? "active-tab" : ""
                      }`}
                    >
                      Rule Details
                    </Tab>
                    {/* <Tab
                      className={`tab-item ${
                        selectedTabRule === 1 ? "active-tab" : ""
                      }`}
                    >
                      Lineage Graph
                    </Tab> */}
                  </TabList>
                  <TabPanel>
                    {selectedData?.incidentTbl?.category == "Raw Data Rule" ? (
                      <div className="table-responsive">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Check</th>
                              <th>Test Condition</th>
                              <th>Result Type</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{RuleInfo?.check}</td>
                              <td>{RuleInfo?.test_condition}</td>
                              <td>{RuleInfo?.result_type}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="table-responsive">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th></th>
                              <th>L1</th>
                              <th>L2</th>
                              <th>L3</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="fw-bold">Query</td>
                              <td>{RuleInfo?.l1_query}</td>
                              <td>{RuleInfo?.l2_query}</td>
                              <td>{RuleInfo?.l3_query}</td>
                            </tr>
                            <tr>
                              <td className="fw-bold">Value</td>
                              <td>{tableData?.L1_VALUE || "-"}</td>
                              <td>{tableData?.L2_VALUE || "-"}</td>
                              <td>{tableData?.L3_VALUE || "-"}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </TabPanel>
                  {/* <TabPanel>
                    {lineageLoader ? (
                      <div className="d-flex justify-content-center align-items-center text-center h-100">
                        <Bars
                          type="Puff"
                          color="#176b87"
                          height={100}
                          width={100}
                        />
                        <h6>Loading . . .</h6>
                      </div>
                    ) : lineageData["nodes"].length ? (
                      <Cytoscape lineageData={lineageData} />
                    ) : (
                      <p className="text-center">No Data Found</p>
                    )}
                  </TabPanel> */}
                </Tabs>
              ) : null}
            </div>
            <div className="card adq-card p-3 mt-4">
              {isOpen ? (
                <Tabs
                  selectedIndex={selectedTabIndex}
                  onSelect={handleTabSelect}
                >
                  <TabList className="horizontal-tabs">
                    <Tab
                      className={`tab-item ${
                        selectedTabIndex === 0 ? "active-tab" : ""
                      }`}
                    >
                      Error history for this table
                    </Tab>
                    <Tab
                      className={`tab-item ${
                        selectedTabIndex === 1 ? "active-tab" : ""
                      }`}
                    >
                      Previous instances of this error
                    </Tab>
                    <Tab
                      className={`tab-item ${
                        selectedTabIndex === 2 ? "active-tab" : ""
                      }`}
                    >
                      Other checks on this table
                    </Tab>
                  </TabList>
                  <TabPanel>
                    {tableErrorHistory.length ? (
                      <IncidentErrorTable
                        tableData={tableErrorHistory}
                        handleRowDetails={(ele) => {
                          RulesDetails(ele);
                        }}
                        type={selectedData?.incidentTbl?.category}
                        source={selectedData?.incidentTbl?.source}
                        table={selectedData?.incidentTbl?.table_name}
                      ></IncidentErrorTable>
                    ) : (
                      <div className="text-center">No data Found</div>
                    )}
                  </TabPanel>
                  <TabPanel>
                    {previousError.length ? (
                      <PreviousIncidentError
                        type={selectedData?.incidentTbl?.category}
                        handleRowDetails={(ele) => {
                          RulesDetails(ele);
                        }}
                        tableData={previousError}
                      ></PreviousIncidentError>
                    ) : (
                      <div className="text-center">No data Found</div>
                    )}
                  </TabPanel>
                  <TabPanel>
                    {otherCheck.length ? (
                      <OtherCheckIncident
                        type={selectedData?.incidentTbl?.category}
                        handleRowDetails={(ele) => {
                          otherMetricDetails(ele);
                        }}
                        tableData={otherCheck}
                      ></OtherCheckIncident>
                    ) : (
                      <div className="text-center">No data Found</div>
                    )}
                  </TabPanel>
                </Tabs>
              ) : null}
            </div>
          </div>
        </div>
      </SwipeableDrawer>
      <Modal
        open={ModelOpen}
        onClose={() => setModelOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={modelStyle}
          //className='animated zoomIn'
        >
          <table className="table table-striped">
            <thead>
              <tr>
                <th
                  style={{
                    backgroundColor: "#176b87",
                    color: "white",
                    borderTopRightRadius: "5px",
                    borderTopLeftRadius: "5px",
                  }}
                >
                  <div className="d-flex justify-content-between  align-items-center mb-2">
                    <div className="text-center text-uppercase ">
                      <h6 className=" ">Rule Details</h6>
                    </div>
                    <i
                      class="fa-solid fa-x"
                      onClick={() => setModelOpen(false)}
                      style={{ cursor: "pointer" }}
                    ></i>
                  </div>
                </th>
              </tr>
            </thead>
          </table>

          <div className="p-3">
            <table class="table table-striped">
              <thead></thead>
              <tbody>
                {rulesPopup?.source != ""
                  ? Object.keys(rulesPopup)?.map((ele) => {
                      if (rulesPopup[ele] != null && rulesPopup[ele] != "") {
                        return (
                          <tr>
                            <td className="fw-bold adq-w-150">
                              {ele?.replace("_", " ").toUpperCase()}
                            </td>
                            <td>{rulesPopup[ele]}</td>
                          </tr>
                        );
                      }
                    })
                  : null}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-around">
            <button
              className="btn btn-sm h-100  py-0 mb-2"
              onClick={() => setModelOpen(false)}
              style={{
                backgroundColor: "#176b87",
                color: "white",
                width: "10%",
              }}
            >
              OK
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
export default Incident;
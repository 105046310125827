import React, { useEffect, useRef, useState } from 'react'
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net';
import '../css/Home.css';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import MetricDataTable from './MetricDataTable';
import { Bars } from 'react-loader-spinner';
import axios from 'axios';
import ApiClient from "./ApiClient"
function MetricRules() {
    const [rulesTblLoader, setRulesTblLoader] = useState(false);
    const [metricsData, setMetricsData] = useState([])
    //  const handleTestClose = () => setTestOpen(false);

    const getMetricTable = async () => {
        setRulesTblLoader(true);
        try {
            var data = await axios.get(`/metric_rules/`, {
                withCredentials: true
            });
            setMetricsData(data?.data?.context?.data);
        } catch (error) {
            console.log(error);
        }
        setRulesTblLoader(false)
    }
    useEffect(() => {
        const timer = setTimeout(() => {
            getMetricTable()
        }, 3000);
  
        return () => clearTimeout(timer);
      }, []);


    const removeData = async (id) => {
        //  setRemoveOpen(false)
        // setRulesTblLoader(true)
        try {
            var data = await axios.get(`/removeMetricRule/${id}`, {
                withCredentials: true
            });
            // setRulesTblLoader(false)
            Swal.fire({title:'Metric Rule Deleted Successfully', text:'', icon:'success',
            confirmButtonColor: "#176b87",});
            getMetricTable();
        } catch (error) {
            console.log(error);
        }
        // Swal.fire('Metric Rules Deleted Successfully', '', 'success');
    }
    return (
        <div>
            {rulesTblLoader ? <div className='mt-5 pt-5 d-flex justify-content-center align-items-center text-center'>
                <Bars type="Puff" color="#176b87" height={100} width={100} />
                <h6 >Loading . . .</h6>
            </div> :
                <MetricDataTable tableData={metricsData} removeData={(ele) => { removeData(ele) }} reRenderTable={()=>{getMetricTable()}} />
            }
        </div>
    )
}

export default MetricRules
import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "../css/Home.css";
import { SwipeableDrawer } from "@mui/material";
import { Bar } from "react-chartjs-2";
import SourceTableView from "./SourceTableView";
import axios from "axios";
import moment from "moment-timezone";
var initcolumn = {
  Source: ["", 1],
  RunDay: ["", 2],
  RunAt: ["", 3],
  Status: ["", 4],
  Frequency: ["", 5],
  Fail: ["", 6],
  FailedCount: ["", 7],
};
var metricColumn = {
  Source: ["", 1],
  RunAt: ["", 2],
  Status: ["", 4],
  Frequency: ["", 5],
  Fail: ["", 6],
  FailedCount: ["", 7],
};

function SourceSummary(props) {
  console.log(props);
  const tableRef = useRef(null);
  const [lookback, setLookback] = useState("");
  const dataTableRef = useRef(null); // Store a reference to the DataTable instance
  const [isFilter, setIsFilter] = useState(true);
  const [columnText, setColumnText] = useState(initcolumn);
  const [columnmetric, setColumnMetric] = useState(metricColumn);
  const [source, setSource] = useState([]);
  const [tableData, setTableDate] = useState([]);
  const [sources, setSources] = useState([]);
  const [filterData, setFilterData] = useState({ target: { value: "" } });
  var popoverStyle = {
    border: " 0px solid rgba(0, 0, 0, .2)",
    borderRadius: "2px",
    fontFamily: "Roboto,sans-serif",
    boxShadow: " 0 2px 10px rgba(0, 0, 0, .2)",
    padding: "5px 15px 10px 15px",
    lineHeight: " 24px",
    fontSize: "14px",
    width: "190px",
    height: "190px",
  };

  useEffect(() => {
    if (source.length) {
      if (tableRef.current && !dataTableRef.current) {
        // Only initialize the DataTable if it hasn't been initialized before
        $.extend($.fn.dataTableExt.oSort, {
          "datetime-custom-pre": function (a) {
            var dateParts = a.split(/\/|, |\s|\:/);

            var month = parseInt(dateParts[0], 10);
            var day = parseInt(dateParts[1], 10);
            var year = parseInt(dateParts[2], 10);
            var hours = parseInt(dateParts[3], 10);
            var minutes = parseInt(dateParts[4], 10);

            var date = new Date(year, month - 1, day, hours, minutes);
            return date.getTime();
          },

          "datetime-custom-asc": function (a, b) {
            return a - b;
          },

          "datetime-custom-desc": function (a, b) {
            return b - a;
          },
        });
        const dataTableInstance = $(tableRef.current).DataTable({
          language: {
            searchPlaceholder: "Search records",
          },
          columnDefs: [
            { type: "date-custom", targets: [2] }, // Apply time-based sorting to column 0
          ],
          lengthChange: false,
          order: [[3, "desc"]],
        });

        dataTableRef.current = dataTableInstance; // Store the DataTable instance
        if (dataTableRef.current) {
          dataTableRef.current.order([2, "desc"]).draw();
        }
      }
    }
  }, [source]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverData, setPopoverData] = useState({
    totalPass: 0,
    totalFail: 0,
    totalWarnings: 0,
    totalTable: 0,
    affectedTable: 0,
    totalInvalid: 0,
  });
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: [
      {
        label: "Pass",
        backgroundColor: "#5470c6",
        borderColor: "transparent",
        borderWidth: 2,
        data: [],
        barThickness: 10,
      },
      {
        label: "Fail",
        backgroundColor: "#ee6666",
        borderColor: "transparent",
        borderWidth: 2,
        data: [],
        barThickness: 10,
      },
      {
        label: "Warning",
        backgroundColor: "#fac858",
        borderColor: "transparent",
        borderWidth: 2,
        data: [],
        barThickness: 10,
      },
    ],
  });

  const findLookback = (tab) => {
    if (tab == "1") {
      return 7;
    } else if (tab == "2") {
      return 30;
    } else if (tab == "3") {
      return 90;
    } else {
      return props?.numberOfDays;
      //TODO
    }
  };

  const handleClick = async (event, data) => {
    setPopoverData(data);
    try {
      if (lookback == "4") {
        var data = await axios.get(
          `/getSourceSummaryData/`,
          {
            params: {
              sourceName: data?.SOURCE_NAME,
              dateTime: data?.RUN_AT,
              ruleType: data?.CATEGORY,
              startDate: props?.lookbackDate?.startDate,
              endDate: props?.lookbackDate?.endDate,
            },
          },
          {
            withCredentials: true,
          }
        );
      } else {
        var data = await axios.get(
          `/getSourceSummaryData/`,
          {
            params: {
              sourceName: data?.SOURCE_NAME,
              dateTime: data?.RUN_AT,
              ruleType: data?.CATEGORY,
              daysToFilter: findLookback(lookback),
            },
          },
          {
            withCredentials: true,
          }
        );
      }

      var newPopover = {
        totalPass: 0,
        totalFail: 0,
        totalWarnings: 0,
        totalTable: 0,
        affectedTable: 0,
        totalInvalid: 0,
      };
      var graphData = data?.data?.graphData;
      var labelChart = [];
      var passChart = [];
      var failChart = [];
      var warnChart = [];
      graphData?.map((ele) => {
        newPopover.totalPass = newPopover.totalPass + ele.pass;
        newPopover.totalFail = newPopover.totalFail + ele.fail;
        newPopover.totalWarnings = newPopover.totalWarnings + ele.warn;
        newPopover.totalInvalid = newPopover.totalInvalid + ele.invalid;
        newPopover.totalTable = newPopover.totalTable + 1;
        if (ele.fail > 0 || ele.warn > 0) {
          newPopover.affectedTable = newPopover.affectedTable + 1;
        }
        labelChart.push(ele.table_name);
        passChart.push(ele.pass);
        failChart.push(ele.fail);
        warnChart.push(ele.warn);
      });
      setPopoverData(newPopover);
      setGraphData({
        labels: labelChart,
        datasets: [
          {
            label: "Pass",
            backgroundColor: "#5470c6",
            borderColor: "transparent",
            borderWidth: 2,
            data: passChart,
            barThickness: 10,
          },
          {
            label: "Fail",
            backgroundColor: "#ee6666",
            borderColor: "transparent",
            borderWidth: 2,
            data: failChart,
            barThickness: 10,
          },
          {
            label: "Warning",
            backgroundColor: "#fac858",
            borderColor: "transparent",
            borderWidth: 2,
            data: warnChart,
            barThickness: 10,
          },
        ],
      });
      setTableDate(data?.data?.tableData);
      setAnchorEl(event.target);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isFilter) {
      setColumnText(initcolumn);
    }
  }, [isFilter]);

  useEffect(() => {
    if (dataTableRef.current) {
      Object.keys(columnText).map((ele) => {
        var temp = columnText[ele];
        dataTableRef.current
          .column(temp[1] - 1)
          .search(temp[0])
          .draw();
      });
    }
  }, [columnText]);

  const handleText = (e, position) => {
    setColumnText({
      ...columnText,
      [e.target.name]: [e.target.value, position],
    });
  };

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const dataParam = params.get("data");

    if (dataParam) {
      try {
        const decodedData = decodeURIComponent(dataParam);
        const jsonData = atob(decodedData);
        const dataSelect = JSON.parse(jsonData);
        const dat = dataSelect
          ? `${dataSelect.source_type}_${dataSelect.entity_name}`
          : "";
        setFilterData({ target: { value: dat } });
      } catch (error) {
        console.error("Error decoding data:", error);
      }
    }
  }, [props]);

  useEffect(() => {
    if (filterData && filterData.target.value) {
      handleText(filterData, 1);
    }
  }, [filterData.target.value]);

  useEffect(() => {
    setSource(props.sourceTableData);
    setLookback(props?.lookback);
    setSources(props?.sourceList);
  }, [props]);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setAnchorEl(null);
    setIsOpen(open);
  };

  // Chart options
  const options = {
    type: "bar",
    indexAxis: "y", // Set the index axis to 'y' for vertical bars
    // scales: {
    //   x: {
    //     beginAtZero: true,
    //   },
    //   y: {
    //     beginAtZero: true,
    //   },
    // },
    scales: {
      xAxes: [
        {
          barThickness: 10,
        },
      ],
    },
  };
  const statusBackgroundColor = (ele) => {
    if (ele.SOURCE_FAIL_COUNT > 0) {
      return "#f9bebe";
    } else if (ele.SOURCE_WARN_COUNT > 0) {
      return "#fcf0b7";
    } else {
      return "#b4efd3";
    }
  };

  const getDayLabel = (inputDate) => {
    const date = new Date(inputDate);

    const dayOfWeekIndex = date.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
    const dayNumber = dayOfWeekIndex === 0 ? 7 : dayOfWeekIndex; // Convert Sunday (0) to 7
    return `DAY-${dayNumber}`;
  };

  const convertDate = (input) => {
    const usEasternTimezone = "America/New_York";
    const date_format = "YYYY-MM-DD HH:mm:ss"; // Adjust this format as needed

    if (input && input !== "NO-DATE") {
      const newone = moment.utc(input, date_format);
      // const localTime = newone.tz(usEasternTimezone);
      // const formattedLocalTime = localTime.format("MM-DD-YYYY HH:mm");
      const formattedLocalTime = newone.format("MM-DD-YYYY HH:mm");
      return formattedLocalTime;
 
    }
  };
  return (
    <div className="px-3 ">
      <div className="d-flex justify-content-between mb-1">
        {/* <h2 className='adq-source ms-2 '>Source Summary </h2> */}
        <div></div>
        <button
          className="btn ms-1"
          style={{ backgroundColor: "#176b87", color: "white" }}
          onClick={() => {
            setIsFilter(!isFilter);
          }}
        >
          Filter
        </button>
      </div>

      {props?.type == "RawData" ? (
        <div className="table-responsive">
          <table
            id="data-table-basic"
            class="table table-striped"
            ref={tableRef}
          >
            <thead>
              <tr className={isFilter ? "d-none" : ""}>
                <th>
                  <select
                    onChange={(e) => handleText(e, 1)}
                    className="form-control"
                    value={columnText.Source[0]}
                    style={{ minWidth: "85px" }}
                    name="Source"
                    type="text"
                  >
                    <option value="">Select</option>
                    {sources?.map((ele) => {
                      return <option value={ele}>{ele}</option>;
                    })}
                  </select>
                </th>
                <th>
                  <input
                    className="form-control"
                    value={columnText.RunDay[0]}
                    style={{ minWidth: "85px" }}
                    name="RunDay"
                    onInput={(e) => handleText(e, 2)}
                    placeholder="Search"
                    type="text"
                  ></input>
                </th>
                <th>
                  <input
                    className="form-control"
                    value={columnText.RunAt[0]}
                    style={{ minWidth: "85px" }}
                    name="RunAt"
                    onInput={(e) => handleText(e, 3)}
                    placeholder="Search"
                    type="text"
                  ></input>
                </th>
                <th>
                  <input
                    className="form-control"
                    value={columnText.Status[0]}
                    style={{ minWidth: "85px" }}
                    name="Status"
                    onInput={(e) => handleText(e, 4)}
                    placeholder="Search"
                    type="text"
                  ></input>
                </th>
                <th>
                  <input
                    className="form-control"
                    value={columnText.Frequency[0]}
                    style={{ minWidth: "85px" }}
                    name="Frequency"
                    onInput={(e) => handleText(e, 5)}
                    placeholder="Search"
                    type="text"
                  ></input>
                </th>
                <th>
                  <input
                    className="form-control"
                    value={columnText.Fail[0]}
                    style={{ minWidth: "85px" }}
                    name="Fail"
                    onInput={(e) => handleText(e, 6)}
                    placeholder="Search"
                    type="text"
                  ></input>
                </th>
                <th>
                  <input
                    className="form-control"
                    value={columnText.FailedCount[0]}
                    style={{ minWidth: "85px" }}
                    name="FailedCount"
                    onInput={(e) => handleText(e, 7)}
                    placeholder="Search"
                    type="text"
                  ></input>
                </th>
              </tr>
              <tr>
                <th>Source</th>
                <th>Run Day</th>
                <th>Run At</th>
                <th>Status</th>
                <th>Frequency</th>
                <th>Source Fail</th>
                <th>Source Failed Count</th>
              </tr>
            </thead>
            <tbody>
              {source?.map((ele) => {
                return (
                  <tr style={{ cursor: "pointer" }}>
                    <td
                      onClick={(e) => handleClick(e, ele)}
                      variant="contained"
                    >
                      {ele?.SOURCE_NAME}
                    </td>
                    <td
                      class="int-cell"
                      onClick={(e) => handleClick(e, ele)}
                      variant="contained"
                    >
                      {getDayLabel(convertDate(ele?.RUN_AT))}
                    </td>
                    <td
                      class="int-cell"
                      onClick={(e) => handleClick(e, ele)}
                      variant="contained"
                    >
                      {convertDate(ele?.RUN_AT)}
                    </td>
                    <td
                      class="int-cell"
                      style={{ backgroundColor: statusBackgroundColor(ele) }}
                      onClick={(e) => handleClick(e, ele)}
                      variant="contained"
                    >
                      {ele?.STATUS}
                    </td>
                    <td
                      class="int-cell"
                      onClick={(e) => handleClick(e, ele)}
                      variant="contained"
                    >
                      {ele?.FREQUENCY}
                    </td>
                    <td
                      class="int-cell"
                      onClick={(e) => handleClick(e, ele)}
                      variant="contained"
                    >
                      {ele?.SOURCE_FAIL ? "True" : "False"}
                    </td>
                    <td
                      class="int-cell"
                      onClick={(e) => handleClick(e, ele)}
                      variant="contained"
                    >
                      {ele?.SOURCE_FAIL_COUNT}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="table-responsive">
          <table
            id="data-table-basic"
            class="table table-striped"
            ref={tableRef}
          >
            <thead>
              <tr className={isFilter ? "d-none" : ""}>
                <th>
                  <select
                    onChange={(e) => handleText(e, 1)}
                    className="form-control"
                    value={columnmetric.Source[0]}
                    style={{ minWidth: "85px" }}
                    name="Source"
                    type="text"
                  >
                    <option value="">Select</option>
                    {sources?.map((ele) => {
                      return <option value={ele}>{ele}</option>;
                    })}
                  </select>
                </th>
                {/* <th><input className='form-control' value={columnText.RunDay[0]} style={{ minWidth: "85px" }} name="RunDay" onInput={(e) => handleText(e, 2)} placeholder='Search' type="text"></input></th> */}
                <th>
                  <input
                    className="form-control"
                    value={columnText.RunAt[0]}
                    style={{ minWidth: "85px" }}
                    name="RunAt"
                    onInput={(e) => handleText(e, 3)}
                    placeholder="Search"
                    type="text"
                  ></input>
                </th>
                <th>
                  <input
                    className="form-control"
                    value={columnText.Status[0]}
                    style={{ minWidth: "85px" }}
                    name="Status"
                    onInput={(e) => handleText(e, 4)}
                    placeholder="Search"
                    type="text"
                  ></input>
                </th>
                <th>
                  <input
                    className="form-control"
                    value={columnText.Frequency[0]}
                    style={{ minWidth: "85px" }}
                    name="Frequency"
                    onInput={(e) => handleText(e, 5)}
                    placeholder="Search"
                    type="text"
                  ></input>
                </th>
                <th>
                  <input
                    className="form-control"
                    value={columnText.Fail[0]}
                    style={{ minWidth: "85px" }}
                    name="Fail"
                    onInput={(e) => handleText(e, 6)}
                    placeholder="Search"
                    type="text"
                  ></input>
                </th>
                <th>
                  <input
                    className="form-control"
                    value={columnText.FailedCount[0]}
                    style={{ minWidth: "85px" }}
                    name="FailedCount"
                    onInput={(e) => handleText(e, 7)}
                    placeholder="Search"
                    type="text"
                  ></input>
                </th>
              </tr>
              <tr>
                <th>Source</th>
                {/* <th>Run Day</th> */}
                <th>Run At</th>
                <th>Status</th>
                <th>Frequency</th>
                <th>Source Fail</th>
                <th>Source Failed Count</th>
              </tr>
            </thead>
            <tbody>
              {source?.map((ele) => {
                return (
                  <tr style={{ cursor: "pointer" }}>
                    <td variant="contained">{ele?.SOURCE_NAME}</td>
                    {/* <td onClick={(e) => handleClick(e, ele)} variant="contained">{getDayLabel(convertDate(ele?.RUN_AT))}</td> */}
                    <td
                      class="int-cell"
                      onClick={(e) => handleClick(e, ele)}
                      variant="contained"
                    >
                      {convertDate(ele?.RUN_AT)}
                    </td>
                    <td
                      class="int-cell"
                      style={{ backgroundColor: statusBackgroundColor(ele) }}
                      variant="contained"
                    >
                      {ele?.STATUS}
                    </td>
                    <td class="int-cell" variant="contained">
                      {ele?.FREQUENCY}
                    </td>
                    <td class="int-cell" variant="contained">
                      {ele?.SOURCE_FAIL ? "True" : "False"}
                    </td>
                    <td class="int-cell" variant="contained">
                      {ele?.SOURCE_FAIL_COUNT}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center", // Center vertically
          horizontal: "right", // Align to the right side
        }}
        transformOrigin={{
          vertical: "center", // Center vertically
          horizontal: "left", // Align to the left side
        }}
        style={{ borderRadius: "5px" }}
      >
        <Typography style={popoverStyle}>
          <div>
            <p className="popoverP">
              {" "}
              Total Pass : <b>{popoverData.totalPass}</b>{" "}
            </p>
            <p className="popoverP">
              {" "}
              Total Fail : <b>{popoverData.totalFail}</b>{" "}
            </p>
            <p className="popoverP">
              {" "}
              Total Warnings : <b>{popoverData.totalWarnings}</b>{" "}
            </p>
            <p className="popoverP">
              {" "}
              Total Invalid : <b>{popoverData.totalInvalid}</b>{" "}
            </p>
            <p className="popoverP">
              {" "}
              Total Tables : <b>{popoverData.totalTable}</b>{" "}
            </p>
            <p className="popoverP">
              {" "}
              Affected Tables : <b>{popoverData.affectedTable}</b>{" "}
            </p>
            <p className="popoverMore" onClick={toggleDrawer(true)}>
              More Details
            </p>
          </div>
        </Typography>
      </Popover>
      <SwipeableDrawer
        anchor="right"
        open={isOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        PaperProps={{ style: { width: "55%" } }}
        transitionDuration={1000}
      >
        <div role="presentation">
          <div className="p-5">
            <p className="adq-source">Source Summary Dashboard</p>
            <Bar data={graphData} options={options} width={100} height={40} />
            <div className="mt-3">
              <SourceTableView
                tableData={tableData}
                ruleType={props?.type}
              ></SourceTableView>
            </div>
          </div>
        </div>
      </SwipeableDrawer>
    </div>
  );
}

export default SourceSummary;

import React, { useEffect, useRef, useState } from 'react'
import * as echarts from 'echarts';
import moment from 'moment';
function DataReliabilityChart(props) {
    const [endChart, setEndChart] = useState(25);
    const chartRef = useRef(null);
    //const [option, setOption] = useState({});
    const [data, setData] = useState([]);
    const convertDate = (input) => {
        const usEasternTimezone = 'America/New_York';
        const date_format = 'YYYY-MM-DD HH:mm:ss'; // Adjust this format as needed

        if (input && input !== 'NO-DATE') {
            const newone = moment.utc(input, date_format);
            const localTime = newone.tz(usEasternTimezone);
            const formattedLocalTime = localTime.format('MM-DD-YYYY');
            return formattedLocalTime;
        }
    }
    useEffect(() => {
        if (data && data.length > 0) {
            const chart = echarts.init(chartRef.current);
            var option = {
                title: {
                    text: props.title,
                    left: 'center',
                    textStyle: {
                        fontSize: 12,
                        fontWeight: "normal"
                    },
                },
                tooltip: {
                    trigger: 'axis'
                },
                grid: {
                    bottom: '25%',
                    right: '1%'
                },
                xAxis: {
                    data: data.map(function (item) {
                        return convertDate(item[0]);
                    }),
                },
                yAxis: {

                },
                color: ["#5470c6", "#ee6666", "#fac858"],
                // dataZoom: [
                //     {
                //         type: 'inside',
                //         start: 0,
                //         end: endChart,
                //         zoomLock: true,
                //         moveOnMouseMove: false,
                //         disableDrag: true,
                //         className: 'custom-data-zoom',
                //     },
                //     {
                //         type: 'slider',
                //         start: 0,
                //         end: endChart,
                //         zoomLock: false,
                //         moveOnMouseMove: false,
                //         disableDrag: true,
                //         minSpan: 21,
                //         className: 'custom-data-zoom',
                //     },
                // ],
                series: [
                    {
                        name: 'Pass',
                        type: 'line',
                        smooth: true,
                        emphasis: {
                            focus: 'series'
                        },
                        data: data.map(function (item) {
                            return item[1];
                        }),
                    },
                    {
                        name: 'Fail',
                        type: 'line',
                        smooth: true,
                        emphasis: {
                            focus: 'series'
                        },
                        data: data.map(function (item) {
                            return item[2];
                        }),
                    },
                    {
                        name: 'Warning',
                        type: 'line',
                        smooth: true,
                        emphasis: {
                            focus: 'series'
                        },
                        data: data.map(function (item) {
                            return item[3];
                        }),
                    }
                ]
            }
            window.addEventListener('resize', () => {
                chart.resize();
            });
            chart.setOption(option);
            chart.on('datazoom', (params) => {
                // Get the new start and end values from params
                const newStartValue = params.start;
                const newEndValue = params.end;

                if ((newEndValue - newStartValue) <= 25) {
                    var adjEndValue = newStartValue + 26;
                    // setEndValue(adjEndValue);
                    // setStartValue(newStartValue)
                    setTimeout(() => {
                        chart.dispatchAction({
                            type: 'dataZoom',
                            start: newStartValue,
                            end: adjEndValue,
                        });
                    }, 50);


                }

            });
        }
    }, [data])


    useEffect(() => {
        if (props.data) {
            setData(props.data)
            if (props.data.length <= 7) {
                setEndChart(100)
            } else {
                setEndChart(25)
            }
        }
    }, [props])



    return (
        <div className='h-100' >
            <div ref={chartRef} style={{ width: '100%', height: '100%' }} />
        </div>
    )
}

export default DataReliabilityChart
import React, { useEffect, useState } from "react";
import "../css/Home.css";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Select from "react-select";
import axios from "axios";
import Spinner from "../widgets/Spinner";



var initialRule = {
  source: "",
  level: "Table Level",
  table: "",
  column: "",
  check: "",
  result_type: "Warning",
  description_comment: "",
  max_value: null,
  min_value: null,
  freshness_value: null,
  rowcount_minlength: null,
  rowcount_maxlength: null,
  validity_type: "",
  validation_value: "",
  query_text: "",
  Area: "",
};
function Rules(props) {
  const [state, setState] = useState(initialRule);
  const [title, setTitle] = useState("Add Rule");
  const [sourceName, setSourceName] = useState([]);
  const [tableName, setTableName] = useState([]);
  const [columnNames, setColumnNames] = useState([]);
  const [FullPageLoader, setFullPageLoader] = useState(false);
  const [optionList, setOptionList] = useState([]);
  const [errMsg, setErrMsg] = useState("none");
  const [minMaxErr, setMinMaxErr] = useState("none");
  const [dataArea, setDataArea] = useState([]);
  const [cookies, setCookies] = useState({});
  const [newData,setNewData] = useState({});
  const getSourceName = async () => {
    try {
      var data = await axios.get(`/getSourceTable/`, {
        withCredentials: true,
      });
      setSourceName(data.data.source);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      getSourceName();
      const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
        const [name, value] = cookie.split("=");
        acc[name] = decodeURIComponent(value);
        return acc;
      }, {});

      setCookies(cookies);
      setNewData(JSON.parse(JSON.stringify(cookies)));
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const columnData = async (source, value) => {
    if (value != "") {
      let data = await axios.get(`/getColumnNames/${source}/${value}/`);
      setColumnNames(data.data.columns);
    }
  };

  useEffect(() => {
    setOptionList(columnNames?.map((e) => ({ value: e, label: e })));
  }, [columnNames]);

  async function handleChange(evt) {
    setErrMsg("none");
    setMinMaxErr("none");
    const value = evt.target.value;

    const newState = { ...state };
    newState[evt.target.name] = value;

    if (evt.target.name == "level") {
      newState["check"] = "";
      if (value == "Table Level") {
        newState["column"] = "";
      }
    }

    if (evt.target.name == "source") {
      newState["Area"] = "";
      newState["table"] = "";
    }
    if (evt.target.name == "table") {
      newState["column"] = "";
    }
    if (
      [
        "max_value",
        "min_value",
        "rowcount_minlength",
        "rowcount_maxlength",
        "freshness_value",
      ].includes(evt.target.name)
    ) {
      if (evt.target.value === "0") {
        evt.target.value = "";
      }
      const trgtVal = evt.target.value;
      var filteredValue = trgtVal.replace(/[-+eE]/g, "");
      filteredValue = filteredValue.replace(/\./g, "");
      evt.target.value = filteredValue;
      if (filteredValue == "00") {
        evt.target.value = "";
      }
      newState[evt.target.name] = evt.target.value;
    }
    if (evt.target.name == "table") {
      await columnData(state.source, value);
    }
    setSelectedOptions([]);

    if (evt.target.name === "check" && state?.source !== "") {
      setState((newState) => ({
        ...initialRule,
        level: newState.level,
        check: evt.target.value,
        max_value: "",
        min_value: "",
      }));
      setColumnNames([]);
    } else {
      setState(newState);
      if (evt?.target?.name == "source" && evt?.target?.value) {
        getTableName(evt?.target?.value);
        if (newState.check == "Freshness Validation") {
          await getDataArea(evt.target.value);
        }
      }
    }
  }

  const getDataArea = async (source) => {
    if (source) {
      try {
        var data = await axios.get(`/FreshnessCheckSourcesDataArea/`, {
          params: { sourceName: source },
        });
        setDataArea(data.data.dataArea);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getTableName = async (source) => {
    try {
      var data = await axios.get(`/getSourceTable/${source}/`, {
        withCredentials: true,
      });
      setTableName(data.data.tables);
    } catch (error) {
      console.log(error);
    }
  };
  

  // const getEditData = async (id) => {

  //   // let data = {
  //   //   id: id,
  //   // };
  //   // const headers = {
  //   //   "X-CSRFToken": cookies.csrftoken,
  //   //   "Content-Type": "application/json",
  //   // };

   
  //   try {
   
  //     // let result = await axios.post(`/editrule/`, { data }, { headers });
  //     let result = await axios.get(`/editrule/${id}`);
 
  //     var editdata = { ...result?.data };
  //     if (editdata.check == "Freshness Validation") {
  //       editdata["Area"] = editdata.table;
  //       getDataArea(editdata.source);
  //     }
  //     setState(editdata);
  //     getSourceName();
  //     getTableName(result.data.source);
  //     columnData(result.data.source, result.data.table);
  //     let selectedData = result.data.query_text
  //       .split(",")
  //       ?.map((e) => ({ value: e, label: e }));
  //     setSelectedOptions(selectedData);
  //   } catch (error) {}
  // };

  const getEditData = async (id) => {
    try {
      let result = await axios.get(`/editrule/`, { params: { id: id } });
 
      var editdata = { ...result?.data };
      if (editdata.check == "Freshness Validation") {
        editdata["Area"] = editdata.table;
        getDataArea(editdata.source);
      }
      setState(editdata);
      getSourceName();
      getTableName(result.data.source);
      columnData(result.data.source, result.data.table);
      let selectedData = result.data.query_text
        .split(",")
        ?.map((e) => ({ value: e, label: e }));
      setSelectedOptions(selectedData);
    } catch (error) {}
  };

  useEffect(() => {
    if (props.data) {
      if (Object.keys(props.data).length > 0) {
        setTitle("Edit Rule");
        if (props.data.RULE_ID) {

          getEditData(props.data.RULE_ID);
        }
      }
    } else {
      setTitle("Add Rule");
    }
  }, [props]);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#176b87",
      color: "white",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const findTestCondition = (type) => {
    switch (type) {
      case "Relationship Validation":
        return `realtionship should be as per Query`;
        break;
      case "Duplicate Validation":
        return `${state?.column} should not have duplicate values`;
        break;
      case "Null Validation":
        return `${state?.column} should not be null`;
        break;
      case "Metadata Check":
        return `All columns of the table ${state?.table} should be available`;
        break;
      case "Mandatory Fields Check":
        return `All mandatory fields of the table ${state?.table} should be available`;
        break;
      case "Multi-Column Unique Index":
        return `Combination of the columns ${state?.table} should be unique`;
        break;
      case "Row Count Validation":
        if (state.rowcount_maxlength && state?.rowcount_minlength) {
          return `Row Count Validation - Rowcount should be between ${state?.rowcount_minlength} and ${state?.rowcount_maxlength}`;
        } else if (state.rowcount_maxlength) {
          return `Row Count Validation - Rowcount should be greater than ${state?.rowcount_maxlength}`;
        } else {
          return `Rowcount should not be lesser than ${state?.rowcount_minlength}`;
        }
        break;
      case "Freshness Validation":
        return `${state?.column} value should be less than or equal to ${state?.freshness_value}`;
        break;
      case "Max Length Check":
        return `The length of ${state?.column} should not be more than ${state?.max_value}`;
        break;
      case "Validity Check":
        if (state?.validity_type == "valid format") {
          return `valid format of ${state?.column} should be - ${state?.validation_value}`;
        } else if (state?.validity_type == "valid values") {
          return `valid values of ${state?.column} should be - ${state?.validation_value}`;
        } else if (state?.validity_type == "valid length") {
          return `invalid_count(${state?.column}) = ${state?.validation_value}`;
        } else if (state?.validity_type == "invalid values") {
          return `${state?.column} should not contain the value(s) - ${state?.validation_value}`;
        }
        break;
      case "Min Length Check":
        return `The length of ${state?.column} should not be less than ${state?.min_value}`;
        break;
      default:
        return null;
        break;
    }
  };

  const handleSave = async () => {
    try {
      const headers = {
        // "X-CSRFToken": cookies.csrftoken,
        "Content-Type": "application/json",
      };
      let data = { ...state };

      data["test_condition"] = findTestCondition(state?.check);

      if (data.check == "Mandatory Fields Check") {
        data["query_text"] = selectedOptions.map((e) => e.value).join(",");
      }
      if (data.check == "Metadata Check") {
        data["query_text"] = columnNames?.join(",");
      }
      if (data.check == "Multi-Column Unique Index") {
        data["query_text"] = selectedOptions.map((e) => e.value).join(",");
      }
      data["rowcount_minlength"] = parseInt(data["rowcount_minlength"]);
      data["rowcount_maxlength"] = parseInt(data["rowcount_maxlength"]);
      data["min_value"] = parseInt(data["min_value"]);
      data["max_value"] = parseInt(data["max_value"]);
      data["freshness_value"] = parseInt(data["freshness_value"]);
      if (isNaN(data["rowcount_maxlength"])) {
        data["rowcount_maxlength"] = null;
      }
      if (isNaN(data["rowcount_minlength"])) {
        data["rowcount_minlength"] = null;
      }
      if (isNaN(data["min_value"])) {
        data["min_value"] = null;
      }
      if (isNaN(data["max_value"])) {
        data["max_value"] = null;
      }
      if (data.check === "Freshness Validation") {
        data["table"] = data["Area"];
      }
      var minMaxCheck = true;
      try {
        if (
          data.source &&
          data.table &&
          data.result_type &&
          data.level &&
          data.check
        ) {
          if (
            (data.check === "Mandatory Fields Check" && data.query_text) ||
            (data.check === "Metadata Check" && data.query_text) ||
            (data.check === "Relationship Validation" && data.query_text) ||
            (data.check === "Multi-Column Unique Index" && data.query_text) ||
            (data.check === "Duplicate Validation" && data.column) ||
            (data.check === "Freshness Validation" && data.freshness_value) ||
            (data.check === "Max Length Check" &&
              data.column &&
              data.max_value) ||
            (data.check === "Min Length Check" &&
              data.column &&
              data.min_value) ||
            (data.check === "Null Validation" && data.column) ||
            (data.check === "Validity Check" &&
              data.validity_type &&
              data.column &&
              data.validation_value) ||
            data.check === "Row Count Validation"
          ) {
            if (
              (data["rowcount_minlength"] || data["rowcount_minlength"] == 0) &&
              (data["rowcount_maxlength"] || data["rowcount_maxlength"] == 0)
            ) {
              if (data["rowcount_maxlength"] <= data["rowcount_minlength"]) {
                setMinMaxErr("block");
                minMaxCheck = false;
              }
            }
            setErrMsg("none");
            if (minMaxCheck) {
              setFullPageLoader(true);
              delete data["Area"];
              // var result = await axios.post(`/addData/`, { data }, { headers });
              var result = await axios.post(`/addDataBB/`, { data },{ headers });
              
              let propsData = result.data;
              propsData.type = "reload";
              if (title == "Add Rule") {
                propsData.action = "Add Rule";
              } else if (title == "Edit Rule") {
                propsData.action = "Edit Rule";
              }
              props.handleSavePop(propsData);
              setFullPageLoader(false);
            }
          } else {
            setErrMsg("block");
          }
        } else {
          setErrMsg("block");
        }
        // setErrMsg('none');
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      window.location.reload();
      setFullPageLoader(false);
    }
  };

  const [selectedOptions, setSelectedOptions] = useState();

  // Array of all options

  // Function triggered on selection
  function handleSelect(data) {
    setErrMsg("none");
    if (state.check == "Multi-Column Unique Index") {
      if (selectedOptions.length <= 4) {
        setSelectedOptions(data);
      }
    } else {
      setSelectedOptions(data);
    }
  }

  // const InsideTestRule = async () => {
  //   // props.handleTestOpen('Yes')
  //   const headers = {
  //     "X-CSRFToken": cookies.csrftoken,
  //     "Content-Type": "application/json",
  //   };
  //   let data = { ...state };

  //   data["test_condition"] = findTestCondition(state?.check);

  //   if (data.check == "Mandatory Fields Check") {
  //     data["query_text"] = selectedOptions.map((e) => e.value).join(",");
  //   }
  //   if (data.check == "Metadata Check") {
  //     data["query_text"] = columnNames?.join(",");
  //   }
  //   if (data.check == "Multi-Column Unique Index") {
  //     data["query_text"] = selectedOptions.map((e) => e.value).join(",");
  //   }
  //   data["rowcount_minlength"] = parseInt(data["rowcount_minlength"]);
  //   data["rowcount_maxlength"] = parseInt(data["rowcount_maxlength"]);
  //   data["min_value"] = parseInt(data["min_value"]);
  //   data["max_value"] = parseInt(data["max_value"]);
  //   data["freshness_value"] = parseInt(data["freshness_value"]);
  //   if (isNaN(data["rowcount_maxlength"])) {
  //     data["rowcount_maxlength"] = null;
  //   }
  //   if (isNaN(data["rowcount_minlength"])) {
  //     data["rowcount_minlength"] = null;
  //   }
  //   if (isNaN(data["min_value"])) {
  //     data["min_value"] = null;
  //   }
  //   if (isNaN(data["max_value"])) {
  //     data["max_value"] = null;
  //   }
  //   if (isNaN(data["freshness_value"])) {
  //     data["freshness_value"] = null;
  //   }
  //   if (data.check === "Freshness Validation") {
  //     data["table"] = data["Area"];
  //   }
  //   setFullPageLoader(true);
  //   var minMaxCheck = true;
  //   try {
  //     if (
  //       data.source &&
  //       data.table &&
  //       data.result_type &&
  //       data.level &&
  //       data.check
  //     ) {
  //       if (
  //         (data.check === "Mandatory Fields Check" && data.query_text) ||
  //         (data.check === "Metadata Check" && data.query_text) ||
  //         (data.check === "Relationship Validation" && data.query_text) ||
  //         (data.check === "Multi-Column Unique Index" && data.query_text) ||
  //         (data.check === "Duplicate Validation" && data.column) ||
  //         (data.check === "Freshness Validation" && data.freshness_value) ||
  //         (data.check === "Max Length Check" && data.max_value) ||
  //         (data.check === "Min Length Check" && data.min_value) ||
  //         (data.check === "Null Validation" && data.column) ||
  //         (data.check === "Validity Check" &&
  //           data.validity_type &&
  //           data.validation_value) ||
  //         data.check === "Row Count Validation"
  //       ) {
  //         if (
  //           (data["rowcount_minlength"] || data["rowcount_minlength"] == 0) &&
  //           (data["rowcount_maxlength"] || data["rowcount_maxlength"] == 0)
  //         ) {
  //           if (data["rowcount_maxlength"] <= data["rowcount_minlength"]) {
  //             setMinMaxErr("block");
  //             minMaxCheck = false;
  //           }
  //         }
  //         setErrMsg("none");
  //         if (minMaxCheck) {
  //           delete data["Area"];
  //           var result = await axios.post(
  //             `/testRuleBeforeSave/`,
  //             { data },
  //             { headers }
  //           );
  //           //  props.reRenderTable()
  //           let propsData = result.data;
  //           propsData.action = "Test Rule";
  //           props.handleSavePop(propsData);
  //         }
  //       } else {
  //         setErrMsg("block");
  //       }
  //     } else {
  //       setErrMsg("block");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     // setErrMsg('block');
  //   }
  //   setFullPageLoader(false);
  //   // props.handleTestOpen('Yes')
  // };

  const InsideTestRule = async () => {
    // props.handleTestOpen('Yes')
    const headers = {
      // "X-CSRFToken": cookies.csrftoken,
      "Content-Type": "application/json",
    };
    let data = { ...state };
    
    data["test_condition"] = findTestCondition(state?.check);

    if (data.check == "Mandatory Fields Check") {
      data["query_text"] = selectedOptions.map((e) => e.value).join(",");
    }
    if (data.check == "Metadata Check") {
      data["query_text"] = columnNames?.join(",");
    }
    if (data.check == "Multi-Column Unique Index") {
      data["query_text"] = selectedOptions.map((e) => e.value).join(",");
    }
    
    
    data["rowcount_minlength"] = parseInt(data["rowcount_minlength"]);
    data["rowcount_maxlength"] = parseInt(data["rowcount_maxlength"]);
    data["min_value"] = parseInt(data["min_value"]);
    data["max_value"] = parseInt(data["max_value"]);
    data["freshness_value"] = parseInt(data["freshness_value"]);
    if (isNaN(data["rowcount_maxlength"])) {
      data["rowcount_maxlength"] = null;
    }
    if (isNaN(data["rowcount_minlength"])) {
      data["rowcount_minlength"] = null; 
    }
    if (isNaN(data["min_value"])) {
      data["min_value"] = null;
    }
    if (isNaN(data["max_value"])) {
      data["max_value"] = null;
    }
    if (isNaN(data["freshness_value"])) {
      data["freshness_value"] = null;
    }
    if (data.check === "Freshness Validation") {
      data["table"] = data["Area"];
    }
   
    
    setFullPageLoader(true);
    var minMaxCheck = true;
    try {
      if (
        data.source &&
        data.table &&
        data.result_type &&
        data.level &&
        data.check
      ) {
        if (
          (data.check === "Mandatory Fields Check" && data.query_text) ||
          (data.check === "Metadata Check" && data.query_text) ||
          (data.check === "Relationship Validation" && data.query_text) ||
          (data.check === "Multi-Column Unique Index" && data.query_text) ||
          (data.check === "Duplicate Validation" && data.column) ||
          (data.check === "Freshness Validation" && data.freshness_value) ||
          (data.check === "Max Length Check" && data.max_value) ||
          (data.check === "Min Length Check" && data.min_value) ||
          (data.check === "Null Validation" && data.column) ||
          (data.check === "Validity Check" &&
            data.validity_type &&
            data.validation_value) ||
          data.check === "Row Count Validation"
        ) {
          if (
            (data["rowcount_minlength"] || data["rowcount_minlength"] == 0) &&
            (data["rowcount_maxlength"] || data["rowcount_maxlength"] == 0)
          ) {
            if (data["rowcount_maxlength"] <= data["rowcount_minlength"]) {
              setMinMaxErr("block");
              minMaxCheck = false;
            }
          }
          setErrMsg("none");
          if (minMaxCheck) {
            delete data["Area"];
            // let payload={...data}
            
            var result = await axios.get(`/testRuleBeforeSave/`,{params:{...data}}); 
            //  props.reRenderTable()
            let propsData = result.data;
            propsData.action = "Test Rule";
            props.handleSavePop(propsData);
          }
        } else {
          setErrMsg("block");
        }
      } else {
        setErrMsg("block");
      }
    } catch (error) {
      console.log(error);
      // setErrMsg('block');
    }
    setFullPageLoader(false);
    // props.handleTestOpen('Yes')
  };
  return (
    <div className="">
      {FullPageLoader ? (
        <div className="position-relative">
          <div className="backdrop"></div>
          <div className="spinner-container">
            <Spinner />
          </div>
        </div>
      ) : null}
      <h2 className="adq-source">{title}</h2>

      <div className="col-12 mt-2">
        <label className="fw-bold">
          Select <span className="spann">*</span>
        </label>
        <div className="d-flex justify-content-start">
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              id="flexRadioDefault1"
              name="level"
              value="Table Level"
              onClick={handleChange}
              checked={state?.level == "Table Level" ? true : false}
            />
            <label class="form-check-label" for="flexRadioDefault1">
              Table Level
            </label>
          </div>
          <div class="form-check ms-3">
            <input
              class="form-check-input"
              type="radio"
              name="level"
              value="Column Level"
              onClick={handleChange}
              id="flexRadioDefault2"
              checked={state?.level == "Column Level" ? true : false}
            />
            <label class="form-check-label" for="flexRadioDefault2">
              Column Level
            </label>
          </div>
        </div>
      </div>
      {state?.level == "Column Level" ? (
        <div className="col-12 mt-2">
          <label className="fw-bold">
            Check <span className="spann">*</span>
          </label>
          <select
            className="form-control"
            name="check"
            value={state?.check}
            onChange={handleChange}
          >
            <option value="" selected disabled>
              Select Check
            </option>
            <option value="Duplicate Validation">Duplicate Validation</option>
            <option value="Max Length Check">Max Length Check</option>
            <option value="Min Length Check">Min Length Check</option>
            <option value="Null Validation">Null Validation</option>
            <option value="Validity Check">Validity Check</option>
          </select>
        </div>
      ) : state?.level == "Table Level" ? (
        <div className="col-12 mt-2">
          <label className="fw-bold">
            Check <span className="spann">*</span>
          </label>
          <select
            className="form-control"
            name="check"
            value={state?.check}
            onChange={handleChange}
          >
            <option value="" selected disabled>
              Select Check
            </option>
            <option value="Mandatory Fields Check">
              Mandatory Fields Check
            </option>
            <option value="Metadata Check">Metadata Check</option>
            <option value="Row Count Validation">Row Count Validation</option>
            <option value="Relationship Validation">
              Relationship Validation
            </option>
            <option value="Multi-Column Unique Index">
              Multi-Column Unique Index
            </option>
            <option value="Freshness Validation">Freshness Validation</option>
          </select>
        </div>
      ) : null}
      {state?.check && state?.level ? (
        <>
          <div className="col-12 mt-2">
            <label className="fw-bold">
              Source <span className="spann">*</span>
            </label>
            <select
              className="form-control"
              name="source"
              value={state?.source}
              onChange={handleChange}
            >
              <option value="" selected disabled>
                Select Source
              </option>
              {sourceName?.map((ele) => {
                return <option value={ele.SOURCE}>{ele.SOURCE}</option>;
              })}
            </select>
          </div>
          {state?.check != "Freshness Validation" ? (
            <div className="col-12 mt-2">
              <label className="fw-bold">
                Table <span className="spann">*</span>
              </label>
              <select
                className="form-control"
                name="table"
                value={state?.table}
                onChange={handleChange}
              >
                <option value="" selected>
                  Select Table
                </option>
                {tableName?.map((ele) => {
                  return <option value={ele}>{ele}</option>;
                })}
              </select>
            </div>
          ) : null}

          {state?.level == "Column Level" ? (
            <div className="col-12 mt-2">
              <label className="fw-bold">
                Column <span className="spann">*</span>
              </label>
              <div>
                <select
                  className="form-control "
                  name="column"
                  value={state?.column}
                  onChange={handleChange}
                >
                  <option value="" selected disabled>
                    Select Column
                  </option>
                  {columnNames?.map((e) => {
                    return <option style={{ overflowX: "auto" }}>{e}</option>;
                  })}
                </select>
              </div>
            </div>
          ) : null}

          {state?.check == "Freshness Validation" ? (
            <div className="col-12 mt-2">
              <label className="fw-bold">
                Data Area <span className="spann">*</span>
              </label>
              <select
                className="form-control"
                name="Area"
                value={state?.Area}
                onChange={handleChange}
              >
                <option value="" selected disabled>
                  Select Data Area
                </option>
                {dataArea?.map((ele) => (
                  <option value={ele}>{ele}</option>
                ))}
              </select>
            </div>
          ) : null}

          {state?.check == "Freshness Validation" ? (
            <div className="row mt-2">
              <div className="col-10">
                <label className="fw-bold">
                  Freshness Age on business days{" "}
                  <span className="spann">*</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  value={state?.freshness_value}
                  name="freshness_value"
                  onInput={handleChange}
                ></input>
              </div>
              <div className="col-2 text-center mt-4">
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      Age = Today - Max Dataset Date from Data Vintage
                      table(table at bottom of Source Summary page)
                    </React.Fragment>
                  }
                  placement="top"
                >
                  <Button>
                    <i
                      class="fa fa-question-circle"
                      style={{ color: "#176b87", fontSize: "25px" }}
                      aria-hidden="true"
                    ></i>
                  </Button>
                </HtmlTooltip>
              </div>
            </div>
          ) : state?.check == "Max Length Check" ? (
            <div className="col-12 mt-2">
              <label className="fw-bold">
                Maximum Value <span className="spann">*</span>
              </label>
              <input
                className="form-control"
                value={state?.max_value}
                name="max_value"
                onInput={handleChange}
                type="number"
              ></input>
            </div>
          ) : state?.check == "Min Length Check" ? (
            <div className="col-12 mt-2">
              <label className="fw-bold">
                Minimum Value <span className="spann">*</span>
              </label>
              <input
                className="form-control"
                type="number"
                value={state?.min_value}
                name="min_value"
                onInput={handleChange}
              ></input>
            </div>
          ) : state?.check == "Validity Check" ? (
            <div className=" mt-2 row">
              <div className="col-4">
                <select
                  className="form-control"
                  value={state?.validity_type}
                  name="validity_type"
                  onChange={handleChange}
                >
                  <option value="">Select Type</option>

                  <option value="valid format">Valid format</option>
                  <option value="valid values">Valid values</option>
                  <option value="valid length">Valid length</option>
                  <option value="invalid values">Invalid values</option>
                </select>
              </div>
              <div className="col-8">
                <input
                  type="text"
                  value={state?.validation_value}
                  name="validation_value"
                  onInput={handleChange}
                  className="form-control"
                ></input>
              </div>
            </div>
          ) : state?.check == "Mandatory Fields Check" ? (
            <div className="col-12 mt-4">
              <div className="dropdown-container">
                <Select
                  options={optionList}
                  placeholder="Please select"
                  value={selectedOptions}
                  onChange={handleSelect}
                  isSearchable={true}
                  isClearable={true}
                  isMulti={true}
                />
              </div>
            </div>
          ) : state?.check == "Multi-Column Unique Index" ? (
            <div className="col-12 mt-4">
              <div className="dropdown-container">
                <Select
                  options={optionList}
                  placeholder="Please select"
                  value={selectedOptions}
                  onChange={handleSelect}
                  isSearchable={true}
                  isClearable={true}
                  isMulti={true}
                />
              </div>
            </div>
          ) : state?.check == "Metadata Check" ? (
            <div className="col-12 mt-4">
              <input
                className="form-control"
                type="text"
                value={columnNames?.join(",")}
                disabled
              ></input>
            </div>
          ) : state?.check == "Row Count Validation" ? (
            <>
              <div className="row">
                <div className="col-6 mt-2">
                  <label className="fw-bold">Min Range</label>
                  <input
                    className="form-control"
                    type="number"
                    value={state?.rowcount_minlength}
                    name="rowcount_minlength"
                    onInput={handleChange}
                  ></input>
                </div>
                <div className="col-6 mt-2">
                  <label className="fw-bold">Max Range</label>
                  <input
                    className="form-control"
                    type="number"
                    value={state?.rowcount_maxlength}
                    name="rowcount_maxlength"
                    onInput={handleChange}
                  ></input>
                </div>
              </div>
            </>
          ) : state?.check == "Relationship Validation" ? (
            <div className="col-12 mt-2">
              <label className="fw-bold">
                Query Text <span className="spann">*</span>
              </label>
              <textarea
                className="form-control"
                value={state?.query_text}
                name="query_text"
                rows={4}
                onChange={handleChange}
              ></textarea>
            </div>
          ) : null}
          <div className="col-12 mt-2">
            <label className="fw-bold">
              Select <span className="spann">*</span>
            </label>
            <div className="d-flex justify-content-start">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="result_type"
                  value="Warning"
                  onClick={handleChange}
                  checked={state?.result_type == "Warning" ? true : false}
                  id="WarningFail1"
                />
                <label class="form-check-label" for="WarningFail1">
                  Warning
                </label>
              </div>
              <div class="form-check ms-3">
                <input
                  class="form-check-input"
                  type="radio"
                  name="result_type"
                  value="Fail"
                  onClick={handleChange}
                  checked={state?.result_type == "Fail" ? true : false}
                  id="WarningFail2"
                />
                <label class="form-check-label" for="WarningFail2">
                  Fail
                </label>
              </div>
            </div>
          </div>
        </>
      ) : null}
      <span className="spann" style={{ display: `${errMsg}` }}>
        Please fill all the required fields
      </span>
      <span className="spann" style={{ display: `${minMaxErr}` }}>
        Minimum value should not be greater than Maximum value
      </span>
      <div className="col-12 mt-2 mt-4">
        <div className="d-flex justify-content-end">
          <button
            class="btn mx-1 btn-sm h-100  pe-4 ps-4"
            style={{ backgroundColor: "#176b87", color: "white" }}
            onClick={() => {
              handleSave();
            }}
          >
            Save
          </button>
          <button
            class="btn mx-1 btn-sm h-100 pe-3 ps-3"
            style={{ backgroundColor: "#176b87", color: "white" }}
            onClick={() => {
              InsideTestRule();
            }}
          >
            Test Rule
          </button>
        </div>
      </div>
    </div>
  );
}

export default Rules;

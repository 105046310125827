import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net';
import '../css/Home.css';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import '../css/animate.css';
import axios from 'axios';

var initcolumn = {
    Id: ["", 1],
    Source: ["", 2],
    Table: ["", 3],
    Level: ["", 4],
    Column: ["", 5],
    Check: ["", 6],
    Condition: ["", 7],
    Result: ["", 8],
    Author: ["", 9]
}

const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
    const [name, value] = cookie.split('=');
    acc[name] = decodeURIComponent(value);
    return acc;
}, {});

function AdminRules(props) {
    const tableRef = useRef(null);
    const dataTableRef = useRef(null);
    const [clickedRow, setClickedRow] = useState(null);
    const [columnText, setColumnText] = useState(initcolumn);
    const [isFilter, setIsFilter] = useState(true)
    const [rulesData, setRulesData] = useState([]);
    const [sources, setSources] = useState([]);
    useEffect(() => {
        if (rulesData.length) {
            if (tableRef.current && !dataTableRef.current) {
                // Only initialize the DataTable if it hasn't been initialized before
                const dataTableInstance = $(tableRef.current).DataTable({
                    searching: true,
                    ordering: isFilter,
                    language: {
                        searchPlaceholder: "Search records",
                    },
                    lengthChange: false,
                });

                dataTableRef.current = dataTableInstance; // Store the DataTable instance
                // if (dataTableRef.current) {
                //   dataTableRef.current.order([1, 'asc']).draw();
                // }
            }
        }
    }, [rulesData]);


    useEffect(() => {
        if (isFilter) {
            setColumnText(initcolumn)
        }
    }, [isFilter])


    const sourcesList = async () => {
        let result = await axios.get('/getSourceTable/')
        setSources(result?.data?.source)
    }
    useEffect(() => {
        setRulesData(props?.tableData)
        sourcesList()
    }, [props])



    useEffect(() => {
        if (rulesData.length) {
            Object.keys(columnText)?.map((ele) => {
                var temp = columnText[ele]
                dataTableRef.current.column(temp[1] - 1).search(temp[0]).draw();
            })
        }
    }, [columnText])

    const handleText = (e, position) => {
        setColumnText({
            ...columnText,
            [e.target.name]: [e.target.value, position]
        });
    }


    const handleRowClick = (index) => {
        // Update the state to track the clicked row index
        setClickedRow(index);
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverData, setPopoverData] = useState([]);
    const handleClick = (event, data) => {
        setAnchorEl(event.currentTarget);
        setPopoverData(data)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    var popoverStyle = {
        border: " 0px solid rgba(0, 0, 0, .2)",
        borderRadius: "2px",
        fontFamily: "Roboto,sans-serif",
        boxShadow: " 0 2px 10px rgba(0, 0, 0, .2)",
        padding: "5px 15px 10px 15px",
        lineHeight: " 24px",
        fontSize: "14px",
        width: "135px",
        height: "115px",
    }


    const removeStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        borderRadius: '5px',
        boxShadow: 24,
        p: 4,
    };
    const [testOpen, setTestOpen] = useState(false);
    const handleTestClose = () => setTestOpen(false);

    const activateRule = async () => {
        setTestOpen(false)
        try {
            var sendData = {
                "id": popoverData?.RULE_ID,
                "project": props?.project_name,
                "ruleType": "tableRule"
            }

            const headers = {
                'X-CSRFToken': cookies.csrftoken,
                'Content-Type': 'application/json'
            };
            var data = await axios.post('/accounts/adminTableRule/', { ...sendData }, { headers })

            if (!data.data.Error) {
                Swal.fire({title:data.data.Result, text:'', icon:'success',
                confirmButtonColor: "#176b87",});
            } else {
                Swal.fire({title:"Error", text:data.data.Error, icon:'error',
                confirmButtonColor: "#176b87",});
            }
            props.reRender();
        } catch (error) {
            console.log(error);
        }

    }

    const confirmation = () => {
        setAnchorEl(null)
        setTestOpen(true)
    }
    return (
        <div >
            {/* {FullPageLoader ? <div className='position-relative' >
                <div className='backdrop'></div>
                <div className='spinner-container'><Spinner /></div>

            </div> : null} */}
            {/* <div className='card adq-card p-3'> */}
            <div className='d-flex justify-content-between'>
                {/* <h2 className='adq-source'>Table Rules</h2> */}
                <div></div>
                <div className='d-flex justify-content-end'>
                    <button className='btn ms-1 btn-sm  py-0 filter-colour' onClick={() => { setIsFilter(!isFilter) }}>Filter</button>
                </div>
            </div>

            <div className='table-responsive mt-2'>
                <table id="data-table-basic" class="table table-striped" ref={tableRef}>
                    <thead>

                        <tr className={isFilter ? 'd-none' : ''}>
                            <th ><input className='form-control adq-w-85' value={columnText.Id[0]} name="Id" onInput={(e) => handleText(e, 1)} placeholder='Search' type="text"></input></th>
                            <th><select className='form-control adq-w-85' value={columnText.Source[0]} name="Source" onInput={(e) => handleText(e, 2)}>
                                <option value="">Select</option>
                                {
                                    sources?.filter((ele) => ele !== 'All')?.map((ele) => (
                                        <option value={ele?.SOURCE}>{ele?.SOURCE}</option>
                                    ))
                                }
                            </select></th>
                            <th><input className='form-control adq-w-85' value={columnText.Table[0]} name="Table" onInput={(e) => handleText(e, 3)} placeholder='Search' type="text"></input></th>
                            <th><input className='form-control adq-w-85' value={columnText.Level[0]} name="Level" onInput={(e) => handleText(e, 4)} placeholder='Search' type="text"></input></th>
                            <th ><input className='form-control adq-w-85' value={columnText.Column[0]} name="Column" onInput={(e) => handleText(e, 5)} placeholder='Search' type="text"></input></th>
                            <th><input className='form-control adq-w-85' value={columnText.Check[0]} name="Check" onInput={(e) => handleText(e, 6)} placeholder='Search' type="text"></input></th>
                            <th><input className='form-control adq-w-85' value={columnText.Condition[0]} name="Condition" onInput={(e) => handleText(e, 7)} placeholder='Search' type="text"></input></th>
                            <th><input className='form-control adq-w-85' value={columnText.Result[0]} name="Result" onInput={(e) => handleText(e, 8)} placeholder='Search' type="text"></input></th>
                            <th><input className='form-control adq-w-85' value={columnText.Author[0]} name="Author" onInput={(e) => handleText(e, 9)} placeholder='Search' type="text"></input></th>
                        </tr>
                        <tr>
                            <th>Rule ID</th>
                            <th>Source</th>
                            <th>Table Name</th>
                            <th>Validation Level</th>
                            <th>Column</th>
                            <th>Check</th>
                            <th>Test Condition</th>
                            <th>Result Type</th>
                            <th>Author</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rulesData?.map((ele, index) => {
                                return (
                                    <tr
                                        key={ele.pk}
                                        className={index === clickedRow ? 'clicked-row adq-pointer' : 'adq-pointer'}
                                        onClick={() => handleRowClick(index)}
                                    >
                                        <td onClick={(e) => handleClick(e, ele)} variant="contained">{ele?.RULE_ID}</td>
                                        <td onClick={(e) => handleClick(e, ele)} variant="contained">{ele?.SOURCE}</td>
                                        <td onClick={(e) => handleClick(e, ele)} variant="contained">{ele?.TABLE_NAME}</td>
                                        <td onClick={(e) => handleClick(e, ele)} variant="contained">{ele?.VALIDATION_TYPE}</td>
                                        <td onClick={(e) => handleClick(e, ele)} variant="contained">{ele?.COLUMN_NAME}</td>
                                        <td onClick={(e) => handleClick(e, ele)} variant="contained">{ele?.CHECK}</td>
                                        <td onClick={(e) => handleClick(e, ele)} variant="contained">{ele?.TEST_CONDITION}</td>
                                        <td onClick={(e) => handleClick(e, ele)} variant="contained">{ele?.RESULT_TYPE}</td>
                                        <td onClick={(e) => handleClick(e, ele)} variant="contained">{ele?.AUTHOR}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                    {/* <tfoot>
                        <tr>
                            <th>Source</th>
                            <th>Run At</th>
                            <th>Table Name</th>
                            <th>Test Name</th>
                            <th>Status</th>
                            <th>Discrepancy Count</th>
                        </tr>
              </tfoot> */}
                </table>
            </div>

            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center', // Center vertically
                    horizontal: 'right', // Align to the right side
                }}
                transformOrigin={{
                    vertical: 'center', // Center vertically
                    horizontal: 'left', // Align to the left side
                }}
                style={{ borderRadius: "5px" }}
            >
                <Typography style={popoverStyle} >
                    <div className='text-center  d-flex justify-content-center h-100 align-items-center'>
                        <button className='btn button_positive mx-auto' onClick={() => confirmation()}>Activate Rule</button>
                    </div>
                </Typography>
            </Popover>




            <Modal
                open={testOpen}
                onClose={handleTestClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={removeStyle}
                //className='animated zoomIn'
                >
                    <h5>Do you want to activate this rule?</h5>
                    <div className='d-flex  justify-content-end'>
                        <button className='  mx-1 btn button_warning' onClick={() => { handleTestClose() }}>No</button>
                        <button className=' mx-1 btn button_positive' onClick={() => { activateRule() }}>Yes</button>
                    </div>
                </Box>
            </Modal>
            {/* </div> */}
        </div>
    )
}

export default AdminRules
import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net";
import "../css/Home.css";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "../css/animate.css";
import axios from "axios";
import { Chip, MenuItem, Select, OutlinedInput } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
// import { styled } from "@mui/system";
import { alpha, styled } from "@mui/material/styles";

var initcolumn = {
  Id: ["", 1],
  Source: ["", 2],
  Table: ["", 3],
  Level: ["", 4],
  Column: ["", 5],
  Check: ["", 6],
  Condition: ["", 7],
  Result: ["", 8],
  Author: ["", 9],
};

const BootstrapInput = styled(TextField)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-root": { padding: 0 },
  "& fieldset": { padding: "5px" },
  "& .MuiInputBase-input": {
    borderRadius: "6px",
    position: "absloute",
    padding: "5px",
    // border: "1px solid",
    // borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 16,
    transition: theme.transitions.create([
      // "border-color",
      "background-color",
      // "box-shadow",
    ]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
}));

const modelStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  // p: 2,
  width: "800px",
};

const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
  const [name, value] = cookie.split("=");
  acc[name] = decodeURIComponent(value);
  return acc;
}, {});

const intiSources = {
  transferProcess: "",
  sourceDisplayName: "",
  sourceName: "",
  disableSource: false,
  frequency: "",
  dataArea: "",
};

function SourcesDataTable(props) {
  const tableRef = useRef(null);
  const dataTableRef = useRef(null);
  const [clickedRow, setClickedRow] = useState(null);
  const [columnText, setColumnText] = useState(initcolumn);
  const [isFilter, setIsFilter] = useState(true);
  const [rulesData, setRulesData] = useState([]);
  const [changedState, setChangedState] = useState([]);
  const [project, setProject] = useState("");
  const [modelOpen, setModelOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [newSources, setNewSources] = useState(intiSources);
  const [sourcesError1, setSourcesError1] = useState();
  const [sourcesError2, setSourcesError2] = useState();
  const [tableOfSource, setTableOfSource] = useState([{ value: "" }]);
  const [tableSource, setTableSource] = useState([{ value: "" }]);
  const [editData, setEditData] = useState({});
  const [openEdit, setOpenEdit] = useState(false);
  const [sourcesEditError1, setSourcesEditError1] = useState(false);
  const [disableSource, setDisableSource] = useState({});
  const [conformModel, setConformModel] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [Tablevalues, setTablevalues] = useState([]);
  const [inputCounts, setInputCounts] = useState({});
  const [totalInputsCount, setTotalInputsCount] = useState(0);
  const handleNext = (e) => {
    e.preventDefault();
    if (
      newSources.sourceName &&
      newSources.sourceDisplayName &&
      newSources.transferProcess
    ) {
      setStep(step + 1);
      setSourcesError1(false);
    } else {
      setSourcesError1(true);
    }
  };

  const handleAddSource = async (e) => {
    e.preventDefault();
    if (Tablevalues.length === 0) {
      var tablenames = tableSource
        ?.filter((ele) => ele.value !== "")
        ?.map((ele) => ele.value);
      if (tablenames.length) {
        var sendData = { ...newSources };
        sendData["tableNames"] = tablenames;
        const headers = {
          "X-CSRFToken": cookies.csrftoken,
          "Content-Type": "application/json",
        };
        try {
          var data = await axios.post(
            "/accounts/addSource/",
            { ...sendData },
            { headers }
          );
          Swal.fire({
            title: "Source Added successfully",
            text: "",
            icon: "success",
            confirmButtonColor: "#176b87",
          });
          props.reRender();
        } catch (error) {
          console.log(error);
        }
      } else {
        setSourcesError2(true);
      }
    } else if (Tablevalues.length !== 0) {
      var tablenames = tableOfSource.map((item) => {
        return {
          ...item,
          values: item.values.filter((value) => value.trim() !== ""),
        };
      });

      console.log("abjh", tablenames);
      console.log(tableOfSource);
      if (tablenames) {
        var sendData = { ...newSources };
        sendData["tableNames"] = tablenames;
        const headers = {
          "X-CSRFToken": cookies.csrftoken,
          "Content-Type": "application/json",
        };
        try {
          var data = await axios.post(
            "/accounts/addSource/",
            { ...sendData },
            { headers }
          );
          Swal.fire({
            title: "Source Added successfully",
            text: "",
            icon: "success",
            confirmButtonColor: "#176b87",
          });
          props.reRender();
        } catch (error) {
          console.log(error);
        }
      } else {
        setSourcesError2(true);
      }
    }
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };
  useEffect(() => {
    if (rulesData.length) {
      if (tableRef.current && !dataTableRef.current) {
        // Only initialize the DataTable if it hasn't been initialized before
        const dataTableInstance = $(tableRef.current).DataTable({
          searching: true,
          ordering: isFilter,
          language: {
            searchPlaceholder: "Search records",
          },
          lengthChange: false,
        });

        dataTableRef.current = dataTableInstance; // Store the DataTable instance
        // if (dataTableRef.current) {
        //   dataTableRef.current.order([1, 'asc']).draw();
        // }
      }
    }
  }, [rulesData]);

  useEffect(() => {
    if (isFilter) {
      setColumnText(initcolumn);
    }
  }, [isFilter]);

  useEffect(() => {
    setRulesData(props?.tableData);
    setProject(props?.project);
  }, [props]);

  useEffect(() => {
    if (rulesData.length) {
      Object.keys(columnText)?.map((ele) => {
        var temp = columnText[ele];
        dataTableRef.current
          .column(temp[1] - 1)
          .search(temp[0])
          .draw();
      });
    }
  }, [columnText]);

  const handleText = (e, position) => {
    setColumnText({
      ...columnText,
      [e.target.name]: [e.target.value, position],
    });
  };

  const handleRowClick = (index) => {
    setClickedRow(index);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverData, setPopoverData] = useState([]);
  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setPopoverData(data);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  var popoverStyle = {
    border: " 0px solid rgba(0, 0, 0, .2)",
    borderRadius: "2px",
    fontFamily: "Roboto,sans-serif",
    boxShadow: " 0 2px 10px rgba(0, 0, 0, .2)",
    padding: "5px 15px 10px 15px",
    lineHeight: " 24px",
    fontSize: "14px",
    width: "135px",
    height: "115px",
  };

  const removeStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };
  const [testOpen, setTestOpen] = useState(false);
  const handleTestClose = () => setTestOpen(false);

  const activateRule = async () => {
    setTestOpen(false);
    try {
      props.reRender();
    } catch (error) {
      console.log(error);
    }
  };

  const confirmation = () => {
    setAnchorEl(null);
    setTestOpen(true);
  };

  const handleChange = (e, index) => {
    let value = e.target.value;
    let name = e.target.name;
    let newTable = [...rulesData];
    newTable[index] = {
      ...newTable[index],
      [name]: value,
    };
    if (name == "frequency") {
      newTable[index]["expected_day"] = "";
    }
    setRulesData(newTable);
    const changedIndex = changedState.findIndex(
      (item) => item.source === newTable[index].source
    );
    var changedData = [];
    if (changedIndex !== -1) {
      changedData = [...changedState];
      changedData[changedIndex][name] = value;
      if (name == "frequency") {
        changedData[changedIndex]["expected_day"] = "";
      }
      setChangedState(changedData);
    } else {
      changedData = [
        ...changedState,
        {
          ...newTable[index],
        },
      ];
      if (name == "frequency") {
        changedData[changedData.length - 1]["expected_day"] = "";
      }
    }

    setChangedState(changedData);
  };

  const handleSaveSource = async () => {
    try {
      const headers = {
        "X-CSRFToken": cookies.csrftoken,
        "Content-Type": "application/json",
      };
      var sendData = {
        project: project,
        data: [...changedState],
      };

      var data = await axios.post(
        "/accounts/adminLvlSourceFrequencyChange/",
        { ...sendData },
        { headers }
      );
      Swal.fire({
        title: "Successfully Chagned",
        text: "",
        icon: "success",
        confirmButtonColor: "#176b87",
      });
      props.reRender();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSourceChange = (e) => {
    const { name, value } = e.target;
    const regex = /^[a-zA-Z\s_]*$/;
    console.log(name, value);
    if (name === "sourceName") {
      setNewSources({
        ...newSources,
        sourceName: value,
        sourceDisplayName: value,
      });
    } else {
      setNewSources({
        ...newSources,
        [name]: value,
      });
    }
    if (name === "disableSource") {
      setNewSources({
        ...newSources,
        [name]: e.target.checked,
      });
    } else if (name == "transferProcess") {
      setNewSources({
        ...newSources,
        [name]: value,
      });
    } else if (regex.test(value)) {
      setNewSources({
        ...newSources,
        [name]: value,
      });
    }
  };

  const handleFrequency = (e, name) => {
    var newValue = { ...newSources };
    var frequency = [...newValue["frequency"]];
    if (e.target.checked) {
      newValue["frequency"] = frequency.push(name);
    } else {
      frequency = frequency.filter((item) => item != name);
    }
    newValue["frequency"] = frequency;
    setNewSources(newValue);
  };

  const editSources = async (source) => {
    try {
      var datas = await axios.get(`/accounts/sourceDetailsToEdit/${source}`);
      setEditData(datas?.data);
      setOpenEdit(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleSourceEditChange = (e) => {
    const { value, name } = e.target;
    setSourcesEditError1(false);
    if (name == "source_display_name") {
      let newValue = { ...editData };
      newValue.SrcDetail[0]["source_display_name"] = value;
      setEditData(newValue);
    } else if (name == "process") {
      let newValue = { ...editData };
      newValue.data.process[0] = value;
      setEditData(newValue);
    }
  };

  const handleEditSave = async () => {
    if (editData.SrcDetail[0].source_display_name && editData.data.process[0]) {
      try {
        const headers = {
          "X-CSRFToken": cookies.csrftoken,
          "Content-Type": "application/json",
        };
        let sendData = {
          process: editData.data.process[0],
          source_display_name: editData.SrcDetail[0].source_display_name,
          source: editData.SrcDetail[0].source,
        };
        let data = await axios.post(
          "/accounts/updateSourceDetails/",
          { ...sendData },
          { headers }
        );
        setOpenEdit(false);
        Swal.fire({
          title: "Source Updated successfully",
          text: "",
          icon: "success",
          confirmButtonColor: "#176b87",
        });
        props.reRender();
      } catch (error) {
        console.log(error);
      }
    } else {
      setSourcesEditError1(true);
    }
  };

  const handleCheck = (ele) => {
    setDisableSource(ele);
    setConformModel(true);
  };

  const handleConformClose = () => {
    setConformModel(false);
  };

  const handleDisableSource = async () => {
    try {
      var sendData = {
        source: disableSource.source,
        data_area: disableSource.data_area,
        project: project,
        checked: !disableSource.source_enabled,
      };
      const headers = {
        "X-CSRFToken": cookies.csrftoken,
        "Content-Type": "application/json",
      };
      var datas = await axios.post(
        "/accounts/addflag/",
        { data: { ...sendData } },
        { headers }
      );
      setConformModel(false);
      Swal.fire({
        title: "Source Updated successfully",
        text: "",
        icon: "success",
        confirmButtonColor: "#176b87",
      });
      props.reRender();
    } catch (error) {
      console.log(error);
    }
  };

  const handleChanges = (event, newValue) => {
    if (newValue) {
      setSelectedValues(newValue);
    }
  };

  const filterOptions = (options, params) => {
    if (!options) return [];
    const filtered = options.filter((option) =>
      option.name?.toLowerCase().includes(params.inputValue?.toLowerCase())
    );
    if (
      params.inputValue?.trim() !== "" &&
      !filtered.some(
        (option) =>
          option.name?.toLowerCase() === params.inputValue?.toLowerCase()
      )
    ) {
      filtered.push({
        id: "",
        name: `Add Data Area ${params.inputValue}`,
      });
    }
    return filtered;
  };

  useEffect(() => {
    const newTableOfSource = Tablevalues.map((value) => ({
      name: value,
      values: [""], // Initialize with one input value
    }));
    setTableOfSource(newTableOfSource);
    setInputCounts(
      Tablevalues.reduce((acc, value) => ({ ...acc, [value]: 1 }), {})
    );
    calculateTotalInputsCount(newTableOfSource);
  }, [Tablevalues]);

  const calculateTotalInputsCount = (newTableOfSource) => {
    const totalCount = newTableOfSource.reduce(
      (total, item) => total + item.values.length,
      0
    );
    setTotalInputsCount(totalCount);
  };

  const handleAddTablecolum = (e) => {
    e.preventDefault();
    if (tableSource.length < 16) {
      setTableSource([...tableSource, { value: "" }]);
    }
  };

  const handleRemoveTablecolumn = (e) => {
    e.preventDefault();
    var newData = [...tableSource];
    if (newData.length != 1) {
      setTableSource(newData.slice(0, -1));
    }
  };

  const handleTableNameChanges = (e, index) => {
    let newData = [...tableSource];
    console.log(e);
    const regex = /^[a-zA-Z_]*$/;
    const newValue = e.target.value;
    console.log(e.target.value);
    if (regex.test(newValue)) {
      setSourcesError2(false);
      newData[index] = { value: newValue };
      setTableSource(newData);
    }
  };

  const handleTableNameChange = (event, tableName, valueIndex) => {
    const regex = /^[a-zA-Z_]*$/;
    const newTableOfSource = [...tableOfSource];
    const itemIndex = newTableOfSource.findIndex(
      (item) => item.name === tableName
    );
    newTableOfSource[itemIndex].values[valueIndex] = event.target.value;
    if (regex.test(newTableOfSource[itemIndex].values[valueIndex])) {
      setTableOfSource(newTableOfSource);
    }
  };

  const handleAddTable = (tableName) => {
    const newTableOfSource = [...tableOfSource];
    const itemIndex = newTableOfSource.findIndex(
      (item) => item.name === tableName
    );
    if (totalInputsCount < 16) {
      // Check if total inputs count is less than 16
      newTableOfSource[itemIndex].values.push("");
      setTableOfSource(newTableOfSource);
      setInputCounts((prevCounts) => ({
        ...prevCounts,
        [tableName]: prevCounts[tableName] + 1,
      }));
      setTotalInputsCount(totalInputsCount + 1);
    } else {
      // Optionally show an alert or message indicating the overall limit is reached
      console.log("Maximum total inputs reached");
    }
  };

  const handleRemoveTable = (tableName, valueIndex) => {
    const newTableOfSource = [...tableOfSource];
    const itemIndex = newTableOfSource.findIndex(
      (item) => item.name === tableName
    );
    newTableOfSource[itemIndex].values.splice(valueIndex, 1);
    setTableOfSource(newTableOfSource);
    setInputCounts((prevCounts) => ({
      ...prevCounts,
      [tableName]: prevCounts[tableName] - 1,
    }));
    setTotalInputsCount(totalInputsCount - 1);
  };

  return (
    <div>
      {/* {FullPageLoader ? <div className='position-relative' >
                <div className='backdrop'></div>
                <div className='spinner-container'><Spinner /></div>

            </div> : null} */}
      {/* <div className='card adq-card p-3'> */}
      <div className="d-flex justify-content-between">
        {/* <h2 className='adq-source'>Table Rules</h2> */}
        <div></div>
        <div className="d-flex justify-content-end">
          <button
            className="btn ms-1 btn-sm  py-0"
            style={{ backgroundColor: "#176b87", color: "white" }}
            onClick={() => {
              setIsFilter(!isFilter);
            }}
          >
            Filter
          </button>
          {/* <button className='btn ms-1 btn-sm h-75 py-0' style={{ backgroundColor: "#176b87", color: 'white' }} onClick={toggleDrawer(true, 'Add')}>Add Rules</button> */}
        </div>
      </div>

      <div className="table-responsive mt-2">
        <table id="data-table-basic" class="table table-striped" ref={tableRef}>
          <thead>
            <tr className={isFilter ? "d-none" : ""}>
              <th>
                <input
                  className="form-control"
                  value={columnText.Id[0]}
                  style={{ minWidth: "85px" }}
                  name="Id"
                  onInput={(e) => handleText(e, 1)}
                  placeholder="Search"
                  type="text"
                ></input>
              </th>
              <th>
                <input
                  className="form-control"
                  value={columnText.Source[0]}
                  style={{ minWidth: "85px" }}
                  name="Source"
                  onInput={(e) => handleText(e, 2)}
                  placeholder="Search"
                  type="text"
                ></input>
              </th>
              <th>
                <input
                  className="form-control"
                  value={columnText.Table[0]}
                  style={{ minWidth: "85px" }}
                  name="Table"
                  onInput={(e) => handleText(e, 3)}
                  placeholder="Search"
                  type="text"
                ></input>
              </th>
              <th>
                <input
                  className="form-control"
                  value={columnText.Level[0]}
                  style={{ minWidth: "85px" }}
                  name="Level"
                  onInput={(e) => handleText(e, 4)}
                  placeholder="Search"
                  type="text"
                ></input>
              </th>
              <th>
                <input
                  className="form-control"
                  value={columnText.Column[0]}
                  style={{ minWidth: "85px" }}
                  name="Column"
                  onInput={(e) => handleText(e, 5)}
                  placeholder="Search"
                  type="text"
                ></input>
              </th>
            </tr>
            <tr>
              <th></th>
              <th>Source</th>
              <th>Date Area</th>
              <th>Frequency</th>
              <th>Expected Day</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {rulesData?.map((ele, index) => {
              return (
                <tr
                  key={ele.pk}
                  className={index === clickedRow ? "clicked-row" : ""}
                  onClick={() => handleRowClick(index)}
                  style={{ cursor: "pointer" }}
                >
                  <td className="text-center">
                    <input
                      type="checkbox"
                      onClick={() => {
                        handleCheck(ele);
                      }}
                      checked={ele?.source_enabled}
                    />
                  </td>
                  <td>{ele?.source_display_name}</td>
                  <td className="text-center">
                    {ele?.data_area ? ele.data_area : "-"}
                  </td>
                  <td className="pt-1 pb-1">
                    <select
                      className="form-control "
                      value={ele?.frequency}
                      name="frequency"
                      onChange={(event) => {
                        handleChange(event, index);
                      }}
                    >
                      <option value="">Select Frequency</option>
                      <option value="weekdays only">
                        Daily (Weekdays only)
                      </option>
                      <option value="daily">Daily (All seven days)</option>
                      <option value="weekly">Weekly</option>
                      <option value="monthly">Monthly</option>
                      <option value="quarterly">Quarterly</option>
                    </select>
                  </td>
                  <td className="pt-0 pb-0">
                    {ele?.frequency == "weekly" ? (
                      <select
                        className="form-control"
                        onChange={(event) => {
                          handleChange(event, index);
                        }}
                        name="expected_day"
                        value={ele?.expected_day}
                      >
                        <option value="">Select day</option>
                        <option value="sunday">Sunday</option>
                        <option value="monday">Monday</option>
                        <option value="tuesday">Tuesday</option>
                        <option value="wednesday">Wednesday</option>
                        <option value="thursday">Thursday</option>
                        <option value="friday">Friday</option>
                        <option value="saturday">Saturday</option>
                      </select>
                    ) : ele?.frequency == "monthly" ? (
                      <select
                        className="form-control"
                        onChange={(event) => {
                          handleChange(event, index);
                        }}
                        name="expected_day"
                        value={ele?.expected_day}
                      >
                        <option value="">Select date</option>
                        {Array.from({ length: 31 }, (_, i) => i + 1)?.map(
                          (option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          )
                        )}
                      </select>
                    ) : (
                      <p className="text-center">NA</p>
                    )}
                  </td>
                  <td className="text-center">
                    <i
                      onClick={() => {
                        editSources(ele?.source);
                      }}
                      class="fas fa-edit"
                    ></i>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="d-flex justify-content-end mt-3 mb-2">
          <button
            className="btn button_positive me-2"
            onClick={() => handleSaveSource()}
            disabled={changedState.length > 0 ? false : true}
          >
            Save
          </button>
          <button
            className="btn button_negative"
            onClick={() => {
              setModelOpen(true);
            }}
          >
            Add Source
          </button>
        </div>
      </div>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center", // Center vertically
          horizontal: "right", // Align to the right side
        }}
        transformOrigin={{
          vertical: "center", // Center vertically
          horizontal: "left", // Align to the left side
        }}
        style={{ borderRadius: "5px" }}
      >
        <Typography style={popoverStyle}>
          <div className="text-center  d-flex justify-content-center h-100 align-items-center">
            <button
              className="btn button_positive mx-auto"
              onClick={() => confirmation()}
            >
              Activate Rule
            </button>
          </div>
        </Typography>
      </Popover>

      <Modal
        open={modelOpen}
        onClose={() => setModelOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={modelStyle}
          //className='animated zoomIn'
        >
          <div
            className="d-flex justify-content-center align-items-center p-3"
            style={{
              backgroundColor: "#176b87",
              color: "white",
              borderTopRightRadius: "5px",
              borderTopLeftRadius: "5px",
              height: "40px",
            }}
          >
            <h5 className="text-uppercase">Create Source</h5>
          </div>
          <form className="p-4">
            {step === 1 && (
              <div>
                <p className="fw-bold">Step 1</p>
                <div className="row">
                  <div className="col-6">
                    <label className="fw-bold">
                      Source Name <span className="spann">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="sourceName"
                      placeholder="Enter Source Name"
                      onInput={handleSourceChange}
                      value={newSources?.sourceName}
                    />
                  </div>
                  <div className="col-6">
                    <label className="fw-bold">
                      Source Display Name <span className="spann">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="sourceDisplayName"
                      placeholder="Enter Display Name"
                      onInput={handleSourceChange}
                      value={newSources?.sourceDisplayName}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-6">
                    <label className="fw-bold">
                      Transfer Process <span className="spann">*</span>
                    </label>
                    <select
                      className="form-control"
                      onChange={handleSourceChange}
                      name="transferProcess"
                      value={newSources?.transferProcess}
                    >
                      <option value="" selected>
                        Select Transfer process
                      </option>
                      <option value="By Mail">By Mail</option>
                      <option value="S3 Bucket">S3 Bucket</option>
                      <option value="SalesForce API">SalesForce API</option>
                      <option value="Shared DB - Snowflake">
                        Shared DB - Snowflake
                      </option>
                      <option value="Snowflake to Snowflake">
                        Snowflake to Snowflake
                      </option>
                    </select>
                  </div>
                  <div className="col-6">
                    <label className="fw-bold">Data Area</label>
                    <Autocomplete
                      multiple
                      value={Tablevalues}
                      style={{ width: "100%" }}
                      onChange={(event, newValue) => {
                        const isDuplicate = (val) =>
                          Tablevalues.some(
                            (item) => item.toLowerCase() === val.toLowerCase()
                          );
                        const getCleanValue = (item) => {
                          if (typeof item === "string") {
                            return item.replace("Add Data Area ", "");
                          } else if (item?.inputValue) {
                            return item?.inputValue;
                          } else if (item.name.startsWith("Add Data Area ")) {
                            return item.name.substring("Add Data Area ".length);
                          } else {
                            return item.name;
                          }
                        };

                        if (newValue && Array.isArray(newValue)) {
                          setTimeout(() => {
                            const uniqueValues = newValue.reduce((acc, item) => {
                              const val = getCleanValue(item);
                              if (
                                !acc.some(
                                  (existingItem) =>
                                    existingItem.toLowerCase() === val.toLowerCase()
                                ) &&
                                acc.length < 4
                              ) {
                                acc.push(val);
                              }
                              return acc;
                            }, []);
                            setTablevalues(uniqueValues);
                          }, 3000);
                        }
                        
                         else if (Tablevalues.length < 4) {
                          if (
                            typeof newValue === "string" &&
                            !isDuplicate(getCleanValue(newValue))
                          ) {
                            setTablevalues((prev) => [
                              ...prev,
                              getCleanValue(newValue),
                            ]);
                          } else if (
                            newValue &&
                            newValue?.inputValue &&
                            !isDuplicate(getCleanValue(newValue))
                          ) {
                            setTablevalues((prev) => [
                              ...prev,
                              getCleanValue(newValue),
                            ]);
                          }
                        }
                      }}
                      filterOptions={(options, params) => {
                        const regex = /^[a-zA-Z\s_]*$/;
                        if (regex.test(params?.inputValue)) {
                          const filtered = options?.filter((option) =>
                            option?.name
                              .toLowerCase()
                              .includes(params?.inputValue?.toLowerCase())
                          );

                          if (
                            params?.inputValue?.trim() !== "" &&
                            !filtered?.some(
                              (option) =>
                                option.name.toLowerCase() ===
                                params.inputValue.toLowerCase()
                            )
                          ) {
                            filtered?.push({
                              name: `Add Data Area ${params.inputValue}`,
                            });
                          }

                          const newValues = filtered?.filter(
                            (option) => !option?.inputValue
                          );

                          return newValues || [];
                        } else {
                          return []; // Return an empty array if the input value doesn't match the regex
                        }
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="Sources"
                      options={[]}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option?.inputValue) {
                          return option?.inputValue;
                        }
                        return option?.name;
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>{option?.name}</li>
                      )}
                      sx={{ width: "62%" }}
                      freeSolo
                      renderInput={(params) => (
                        <BootstrapInput
                          {...params}
                          onKeyPress={(event) => {
                            const regex = /^[a-zA-Z\s_]*$/;
                            if (!regex.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          // variant="outlined"
                          // label="Sources"
                        />
                      )}
                    />
                  </div>
                  {/*   <div className='col-6'>
                                        <label className='fw-bold'>Frequency <span className='spann'>*</span></label>
                                        <div className='d-flex  justify-content-around mt-2'>
                                            <div>
                                                <input type='checkbox' checked={(newSources?.frequency).includes('Daily')} onChange={(event) => handleFrequency(event, "Daily")} name='frequency' />
                                                <span className='ms-1'>Daily</span>
                                            </div>
                                            <div>
                                                <input type='checkbox' checked={(newSources?.frequency).includes('Weekly')} onChange={(event) => handleFrequency(event, "Weekly")} name='frequency' />
                                                <span className='ms-1'>Weekly</span>
                                            </div>
                                            <div>
                                                <input type='checkbox' checked={(newSources?.frequency).includes('Monthly')} onChange={(event) => handleFrequency(event, "Monthly")} name='frequency' />
                                                <span className='ms-1'>Monthly</span>
                                            </div>
                                            <div>
                                                <input type='checkbox' checked={(newSources?.frequency).includes('Quarterly')} onChange={(event) => handleFrequency(event, "Quarterly")} name='frequency' />
                                                <span className='ms-1'>Quarterly</span>
                                            </div>
                                        </div>
                                    </div>
                                    */}
                </div>
                <div className="row mt-2">
                  <div className="col-12">
                    <label className="fw-bold">
                      Enable_Source <span className="spann">*</span>
                    </label>
                    <br />
                    <label className="switchaction mt-2">
                      <input
                        type="checkbox"
                        onChange={handleSourceChange}
                        checked={newSources?.disableSource}
                        name="disableSource"
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
                {sourcesError1 ? (
                  <span className=" spann">
                    Please fill all the required fields
                  </span>
                ) : null}
                <div className="d-flex justify-content-end mt-2">
                  <button className="btn button_positive " onClick={handleNext}>
                    Next
                  </button>
                </div>
              </div>
            )}

            {step === 2 && (
              <div>
                <p className="fw-bold">Step 2</p>
                <div className="row">
                  {Tablevalues?.length === 0 ? (
                    <div className="row">
                      <label className="fw-bold">
                        Table Name <span className="spann">*</span>
                      </label>
                      {tableSource.map((field, index) => (
                        <div className="col-6 mt-2">
                          <input
                            type="text"
                            className="form-control"
                            onInput={(event) => {
                              handleTableNameChanges(event, index);
                            }}
                            value={field?.value}
                            placeholder="Enter Table Name"
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="row">
                      {tableOfSource.map((item, index) => (
                        <div className="col-12 mt-2" key={index}>
                          <label style={{ fontWeight: "600" }}>
                            {item.name}
                          </label>
                          {item.values.map((value, valueIndex) => (
                            <div className="d-flex mt-2" key={valueIndex}>
                              <input
                                type="text"
                                className="form-control"
                                value={value}
                                onChange={(e) =>
                                  handleTableNameChange(
                                    e,
                                    item.name,
                                    valueIndex
                                  )
                                }
                                placeholder={`Enter Table Name for ${item.name}`}
                              />
                              {valueIndex === 0 && (
                                <div className="d-flex justify-content-end align-items-center ms-2">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-outline-primary"
                                    onClick={() => handleAddTable(item.name)}
                                  >
                                    +
                                  </button>
                                </div>
                              )}
                              {item.values.length > 1 && (
                                <div className="d-flex justify-content-end align-items-center ms-2">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-outline-danger"
                                    onClick={() =>
                                      handleRemoveTable(item.name, valueIndex)
                                    }
                                  >
                                    -
                                  </button>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {Tablevalues?.length === 0 ? (
                  <div className="d-flex justify-content-end">
                    <div className="d-flex justify-content-end mt-2">
                      <button
                        className="btn button_warning me-2"
                        onClick={handleAddTablecolum}
                      >
                        +
                      </button>
                    </div>
                    <div className="d-flex justify-content-end mt-2">
                      <button
                        className="btn button_positive"
                        onClick={handleRemoveTablecolumn}
                      >
                        -
                      </button>
                    </div>
                  </div>
                ) : null}
                {sourcesError2 ? (
                  <span className=" spann">
                    Please add atleast one Table name
                  </span>
                ) : null}
                <div className="d-flex justify-content-end"></div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex justify-content-end mt-2">
                    <button
                      className="btn button_warning "
                      onClick={handlePrevious}
                    >
                      Previous
                    </button>
                  </div>
                  <div className="d-flex justify-content-end mt-2">
                    <button
                      type="button"
                      className="btn button_positive"
                      onClick={handleAddSource}
                    >
                      Create
                    </button>
                  </div>
                </div>
              </div>
            )}
          </form>
        </Box>
      </Modal>

      <Modal
        open={testOpen}
        onClose={handleTestClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={removeStyle}
          //className='animated zoomIn'
        >
          <h5>Do you want to activate this rule?</h5>
          <div className="d-flex  justify-content-end">
            <button
              className="  mx-1 btn button_warning"
              onClick={() => {
                handleTestClose();
              }}
            >
              No
            </button>
            <button
              className=" mx-1 btn button_positive"
              onClick={() => {
                activateRule();
              }}
            >
              Yes
            </button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openEdit}
        onClose={handleEditClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={removeStyle}
          //className='animated zoomIn'
        >
          {editData.SrcDetail ? (
            <div>
              <p className="fw-bold">Edit Source</p>
              <div className="row">
                <div className="col-12">
                  <label className="fw-bold">Source Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="sourceName"
                    placeholder="Enter Source Name"
                    disabled
                    value={editData?.SrcDetail?.[0]?.source}
                  />
                </div>
                <div className="col-12 mt-2">
                  <label className="fw-bold">Source Display Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="source_display_name"
                    placeholder="Enter Display Name"
                    onInput={handleSourceEditChange}
                    value={editData?.SrcDetail[0]?.source_display_name}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12">
                  <label className="fw-bold">Transfer Process </label>
                  <select
                    className="form-control"
                    onChange={handleSourceEditChange}
                    name="process"
                    value={editData?.data?.process[0]}
                  >
                    <option value="" selected>
                      Select Transfer process
                    </option>
                    <option value="By Mail">By Mail</option>
                    <option value="S3 Bucket">S3 Bucket</option>
                    <option value="SalesForce API">SalesForce API</option>
                    <option value="Shared DB - Snowflake">
                      Shared DB - Snowflake
                    </option>
                    <option value="Snowflake to Snowflake">
                      Snowflake to Snowflake
                    </option>
                  </select>
                </div>
              </div>

              {sourcesEditError1 ? (
                <span className=" spann">
                  Please fill all the required fields
                </span>
              ) : null}
              <div className="d-flex justify-content-end mt-2">
                <button
                  className="btn button_positive"
                  onClick={handleEditSave}
                >
                  Save
                </button>
              </div>
            </div>
          ) : null}
        </Box>
      </Modal>

      <Modal
        open={conformModel}
        onClose={handleConformClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={removeStyle}
          //className='animated zoomIn'
        >
          {disableSource.source_enabled ? (
            <h5>Do you want to remove the source?</h5>
          ) : (
            <h5>Do you want to Add the source?</h5>
          )}
          <div className="d-flex  justify-content-end">
            <button
              className="  mx-1 btn button_warning"
              onClick={() => {
                handleConformClose();
              }}
            >
              No
            </button>
            <button
              className=" mx-1 btn button_positive"
              onClick={handleDisableSource}
            >
              Yes
            </button>
          </div>
        </Box>
      </Modal>
      {/* </div> */}
    </div>
  );
}

export default SourcesDataTable;

import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net";
import "../css/Home.css";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { SwipeableDrawer } from "@mui/material";
import "sweetalert2/dist/sweetalert2.min.css";
import Rules from "./Rules";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "../css/animate.css";
import axios from "axios";
import { Bars } from "react-loader-spinner";
import Spinner from "../widgets/Spinner";

var initcolumn = {
  Id: ["", 1],
  Source: ["", 2],
  Table: ["", 3],
  Level: ["", 4],
  Column: ["", 5],
  Check: ["", 6],
  Condition: ["", 7],
  Result: ["", 8],
  Author: ["", 9],
};
var invalidIndex = [];
function RulesDataTable(props) {
  const tableRef = useRef(null);
  const dataTableRef = useRef(null);
  const [clickedRow, setClickedRow] = useState(null);
  const [columnText, setColumnText] = useState(initcolumn);
  const [isFilter, setIsFilter] = useState(true);
  const [rulesData, setRulesData] = useState([]);
  const [rulesTblLoader, setRulesTblLoader] = useState(false);
  const [FullPageLoader, setFullPageLoader] = useState(false);
  const [testResult, setTestResult] = useState({});
  const [resultModal, setResultModal] = useState({ type: "" });
  const [source, setSource] = useState([]);
  const [filterData, setFilterData] = useState({ target: { value: "" } });

  useEffect(() => {
    const storedFilterData = JSON.parse(localStorage.getItem("filterData"));
    const dat = storedFilterData
      ? `${storedFilterData.source_type}_${storedFilterData.entity_name}`
      : "";
    setFilterData({ target: { value: dat } });
    const timer = setTimeout(() => {
      getSourceName();
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const dataParam = params.get("data");

    if (dataParam) {
      try {
        const decodedData = decodeURIComponent(dataParam);
        const jsonData = atob(decodedData);
        const dataSelect = JSON.parse(jsonData);
        const dat = dataSelect
          ? `${dataSelect.source_type}_${dataSelect.entity_name}`
          : "";
        setFilterData({ target: { value: dat } });
      } catch (error) {
        console.error("Error decoding data:", error);
      }
    }
  }, [props]);

  useEffect(() => {
    if (filterData && filterData.target.value) {
      handleText(filterData, 2);
    }
  }, [filterData.target.value]);

  const getSourceName = async () => {
    try {
      let data = await axios.get("/getSourceTable/");
      setSource(data.data.source);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (rulesData.length) {
      if (tableRef.current && !dataTableRef.current) {
        // Only initialize the DataTable if it hasn't been initialized before
        const dataTableInstance = $(tableRef.current).DataTable({
          searching: true,
          ordering: isFilter,
          language: {
            searchPlaceholder: "Search records",
          },
          lengthChange: false,
        });

        dataTableRef.current = dataTableInstance; // Store the DataTable instance
        if (dataTableRef.current) {
          dataTableRef.current.order([0, "desc"]).draw();
        }
      }
    }
  }, [rulesData]);

  useEffect(() => {
    if (isFilter) {
      setColumnText(initcolumn);
    }
  }, [isFilter]);

  useEffect(() => {
    setRulesData(props?.tableData);
  }, [props]);

  useEffect(() => {
    if (rulesData.length) {
      Object.keys(columnText)?.map((ele) => {
        var temp = columnText[ele];
        dataTableRef.current
          .column(temp[1] - 1)
          .search(temp[0])
          .draw();
      });
    }
  }, [columnText]);

  const handleText = (e, position) => {
    setColumnText({
      ...columnText,
      [e.target.name]: [e.target.value, position],
    });
  };

  const handleRowClick = (index) => {
    // Update the state to track the clicked row index
    setClickedRow(index);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverData, setPopoverData] = useState([]);

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setPopoverData(data);
    const selectedRow = $(tableRef.current)
      .DataTable()
      .row(".clicked-row")
      .data();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  var popoverStyle = {
    border: " 0px solid rgba(0, 0, 0, .2)",
    borderRadius: "2px",
    fontFamily: "Roboto,sans-serif",
    boxShadow: " 0 2px 10px rgba(0, 0, 0, .2)",
    padding: "5px 15px 10px 15px",
    lineHeight: " 24px",
    fontSize: "14px",
    width: "135px",
    height: "115px",
  };

  const [isOpen, setIsOpen] = useState(false);
  const [DrawerContent, setDrawerContent] = useState(null);
  const handleSave = (results) => {
    if (results.action != "Test Rule") {
      setIsOpen(false);
    }
    setTestOpen(true);
    var temp = { ...results };
    setResultModal(temp);
  };

  const TestStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 2,
    width: "40%",
    padding: 0,
  };

  const removeStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };
  const [testOpen, setTestOpen] = useState(false);
  const handleTestOpen = async (data) => {
    if (data == "Yes") {
    } else {
      setFullPageLoader(true);
      setAnchorEl(null);
      try {
        var data = await axios.get(
          `/testRuleAfterSave/`,
          { params: { id: popoverData?.RULE_ID } },
          {
            withCredentials: true,
          }
        );
        let modalData = data.data;
        if (data?.data?.ERROR) {
          const indexToPush = rulesData.findIndex(
            (item) => item.RULE_ID === popoverData?.RULE_ID
          );
          invalidIndex.push(indexToPush);
        }
        modalData.action = "TestRule";
        setResultModal(modalData);
      } catch (error) {
        console.log(error);
        setFullPageLoader(false);
      }
      setFullPageLoader(false);
      setTestOpen(true);
    }
  };
  const handleTestClose = () => {
    var isReload = { ...resultModal };
    if (isReload.type == "reload") {
      props.reRenderTable();
    }
    setTestOpen(false);
  };
  const toggleDrawer =
    (open, data = null) =>
    (event) => {
      if (
        event &&
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      switch (data) {
        case "Add":
          setDrawerContent(
            <Rules
              handleSavePop={(res) => {
                handleSave(res);
              }}
              handleTestOpen={handleTestOpen}
            ></Rules>
          );
          break;
        case "Edit":
          setDrawerContent(
            <Rules
              data={popoverData}
              handleSavePop={(res) => {
                handleSave(res);
              }}
              handleTestOpen={handleTestOpen}
            ></Rules>
          );
          break;
        default:
          setDrawerContent(null);
      }
      setAnchorEl(null);
      setIsOpen(open);
    };

  const [removeOpen, setRemoveOpen] = useState(false);
  const handleRemove = () => {
    setAnchorEl(false);
    setRemoveOpen(true);
  };

  const handleremoveClose = () => {
    setRemoveOpen(false);
  };

  const removeData = () => {
    setRemoveOpen(false);
    props.handleRemove(popoverData?.RULE_ID);
  };
  // const editRuleDataCall =  async ()=>{

  //     let data ={
  //         id:2
  //     }
  //     let result = await axios.post('/editRule',)
  // }
  return (
    <div className="p-4 mt-5">
      {FullPageLoader ? (
        <div className="position-relative">
          <div className="backdrop"></div>
          <div className="spinner-container">
            <Spinner />
          </div>
        </div>
      ) : null}
      <div className="card adq-card p-3">
        <div className="d-flex justify-content-between">
          <h2 className="adq-source">Raw Data Rules</h2>
          <div className="d-flex justify-content-end">
            <button
              className="btn ms-1 btn-sm h-100 ps-4 pe-4"
              style={{ backgroundColor: "#176b87", color: "white" }}
              onClick={() => {
                setIsFilter(!isFilter);
              }}
            >
              Filter
            </button>
            <button
              className="btn ms-1 btn-sm h-100 py-0  ps-3 pe-3"
              style={{ backgroundColor: "#176b87", color: "white" }}
              onClick={toggleDrawer(true, "Add")}
            >
              Add Rule
            </button>
          </div>
        </div>
        {rulesTblLoader ? (
          <div className=" d-flex justify-content-center align-items-center text-center">
            <Bars type="Puff" color="#176b87" height={100} width={100} />
            <h6>Loading . . .</h6>
          </div>
        ) : (
          <div className="table-responsive mt-2">
            <table
              id="data-table-basic"
              class="table table-striped"
              ref={tableRef}
            >
              <thead>
                <tr className={isFilter ? "d-none" : ""}>
                  <th>
                    <input
                      className="form-control"
                      value={columnText.Id[0]}
                      style={{ minWidth: "85px" }}
                      name="Id"
                      onInput={(e) => handleText(e, 1)}
                      placeholder="Search"
                      type="text"
                    ></input>
                  </th>
                  <th>
                    <select
                      className="form-control"
                      value={columnText.Source[0]}
                      style={{ minWidth: "190px" }}
                      name="Source"
                      onInput={(e) => handleText(e, 2)}
                    >
                      <option value="">Select</option>
                      {source?.map((ele) => (
                        <option value={ele.SOURCE_NAME}>{ele.SOURCE}</option>
                      ))}
                    </select>
                  </th>
                  <th>
                    <input
                      className="form-control"
                      value={columnText.Table[0]}
                      style={{ minWidth: "85px" }}
                      name="Table"
                      onInput={(e) => handleText(e, 3)}
                      placeholder="Search"
                      type="text"
                    ></input>
                  </th>
                  <th>
                    <input
                      className="form-control"
                      value={columnText.Level[0]}
                      style={{ minWidth: "85px" }}
                      name="Level"
                      onInput={(e) => handleText(e, 4)}
                      placeholder="Search"
                      type="text"
                    ></input>
                  </th>
                  <th>
                    <input
                      className="form-control"
                      value={columnText.Column[0]}
                      style={{ minWidth: "85px" }}
                      name="Column"
                      onInput={(e) => handleText(e, 5)}
                      placeholder="Search"
                      type="text"
                    ></input>
                  </th>
                  <th>
                    <select
                      className="form-control"
                      value={columnText.Check[0]}
                      style={{ minWidth: "150px" }}
                      name="Check"
                      onChange={(e) => handleText(e, 6)}
                    >
                      <option value="">Select</option>
                      <option value="Duplicate Validation">
                        Duplicate Validation
                      </option>
                      <option value="Freshness Validation">
                        Freshness Validation
                      </option>
                      <option value="Max Length Check">Max Length Check</option>
                      <option value="Min Length Check">Min Length Check</option>
                      <option value="Null Validation">Null Validation</option>
                      <option value="Row Count Validation">
                        Row Count Validation
                      </option>
                      <option value="Relationship Validation">
                        Relationship Validation
                      </option>
                      <option value="Mandatory Field Check">
                        Mandatory Field Check
                      </option>
                      <option value="Validity Check">Validity Check</option>
                      <option value="Metadata Check">Metadata Check</option>
                      <option value="Multi-Column Unique Index">
                        Multi-Column Unique Index
                      </option>
                    </select>
                  </th>
                  <th>
                    <input
                      className="form-control"
                      value={columnText.Condition[0]}
                      style={{ minWidth: "85px" }}
                      name="Condition"
                      onInput={(e) => handleText(e, 7)}
                      placeholder="Search"
                      type="text"
                    ></input>
                  </th>
                  <th>
                    <input
                      className="form-control"
                      value={columnText.Result[0]}
                      style={{ minWidth: "85px" }}
                      name="Result"
                      onInput={(e) => handleText(e, 8)}
                      placeholder="Search"
                      type="text"
                    ></input>
                  </th>
                  <th>
                    <input
                      className="form-control"
                      value={columnText.Author[0]}
                      style={{ minWidth: "85px" }}
                      name="Author"
                      onInput={(e) => handleText(e, 9)}
                      placeholder="Search"
                      type="text"
                    ></input>
                  </th>
                </tr>
                <tr>
                  <th>Rule ID</th>
                  <th>Source</th>
                  <th>Table Name</th>
                  <th>Validation Level</th>
                  <th>Column</th>
                  <th>Check</th>
                  <th>Test Condition</th>
                  <th>Result Type</th>
                  <th>Author</th>
                </tr>
              </thead>
              <tbody>
                {rulesData?.map((ele, index) => {
                  return (
                    <tr
                      key={ele.RULE_ID}
                      className={
                        index === clickedRow
                          ? "clicked-row"
                          : ele?.RULE_ERROR || invalidIndex.includes(index)
                          ? "invalid-rule"
                          : ""
                      }
                      onClick={() => handleRowClick(index)}
                      style={{ cursor: "pointer" }}
                    >
                      <td
                        onClick={(e) => handleClick(e, ele)}
                        variant="contained"
                      >
                        {ele?.RULE_ID}
                      </td>
                      <td
                        onClick={(e) => handleClick(e, ele)}
                        variant="contained"
                      >
                        {ele?.SOURCE}
                      </td>
                      <td
                        onClick={(e) => handleClick(e, ele)}
                        variant="contained"
                      >
                        {ele?.TABLE_NAME}
                      </td>
                      <td
                        onClick={(e) => handleClick(e, ele)}
                        variant="contained"
                      >
                        {ele?.VALIDATION_TYPE}
                      </td>
                      <td
                        onClick={(e) => handleClick(e, ele)}
                        variant="contained"
                      >
                        {ele?.COLUMN_NAME}
                      </td>
                      <td
                        onClick={(e) => handleClick(e, ele)}
                        variant="contained"
                      >
                        {ele?.CHECK}
                      </td>
                      <td
                        onClick={(e) => handleClick(e, ele)}
                        variant="contained"
                      >
                        {ele?.TEST_CONDITION}
                      </td>
                      <td
                        onClick={(e) => handleClick(e, ele)}
                        variant="contained"
                      >
                        {ele?.RESULT_TYPE}
                      </td>
                      <td
                        onClick={(e) => handleClick(e, ele)}
                        variant="contained"
                      >
                        {ele?.AUTHOR}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              {/* <tfoot>
                        <tr>
                            <th>Source</th>
                            <th>Run At</th>
                            <th>Table Name</th>
                            <th>Test Name</th>
                            <th>Status</th>
                            <th>Discrepancy Count</th>
                        </tr>
              </tfoot> */}
            </table>
          </div>
        )}
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center", // Center vertically
            horizontal: "right", // Align to the right side
          }}
          transformOrigin={{
            vertical: "center", // Center vertically
            horizontal: "left", // Align to the left side
          }}
          style={{ borderRadius: "5px" }}
        >
          <Typography style={popoverStyle}>
            <div>
              <button
                className="btn btn-sm w-100 mt-2 h-75 py-0"
                style={{ backgroundColor: "#176b87", color: "white" }}
                onClick={toggleDrawer(true, "Edit")}
              >
                Edit Rule
              </button>
              <button
                className="btn btn-sm w-100 mt-2 h-75 py-0"
                style={{ backgroundColor: "#176b87", color: "white" }}
                onClick={() => {
                  handleTestOpen();
                }}
              >
                Test Rule
              </button>
              <button
                className="btn btn-sm w-100 mt-2 h-75 py-0"
                style={{ backgroundColor: "#176b87", color: "white" }}
                onClick={() => {
                  handleRemove();
                }}
              >
                Remove Rule
              </button>
            </div>
          </Typography>
        </Popover>

        <SwipeableDrawer
          anchor="right"
          open={isOpen}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          PaperProps={{ style: { width: "30%" } }}
          transitionDuration={1000}
        >
          <div role="presentation">
            <div className="p-5">{DrawerContent}</div>
          </div>
        </SwipeableDrawer>

        <Modal
          open={testOpen}
          onClose={handleTestClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={TestStyle}
            //className='animated zoomIn'
          >
            <table className="table table-striped">
              <thead>
                <tr>
                  <th
                    style={{
                      backgroundColor: "#176b87",
                      color: "white",
                      borderTopRightRadius: "5px",
                      borderTopLeftRadius: "5px",
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-center mb-2 ">
                      {resultModal.action ? (
                        <div className="text-center text-uppercase ">
                          <h6 className=" ">
                            Operation Performed :{" "}
                            <span>{resultModal?.action}</span>
                          </h6>
                        </div>
                      ) : null}
                      <i
                        class="fa-solid fa-x"
                        style={{ fontSize: "10px" }}
                        onClick={handleTestClose}
                      ></i>
                    </div>
                  </th>
                </tr>
              </thead>
            </table>

            <div className="p-3">
              <table class="table table-striped">
                <thead></thead>
                <tbody>
                  {Object.keys(resultModal)?.map((ele) => {
                    if (
                      resultModal[ele] != null &&
                      resultModal[ele] != "" &&
                      ele != "type" &&
                      ele != "action"
                    ) {
                      return (
                        <tr>
                          <td className="fw-bold">{ele}</td>
                          {/* <td>{resultModal[ele]}</td> */}
                          {resultModal[ele] == "PASSED" ? (
                            <td>
                              <span
                                style={{
                                  backgroundColor: "rgb(180, 239, 211)",
                                }}
                              >
                                {resultModal[ele]}
                              </span>
                            </td>
                          ) : resultModal[ele] == "FAILED" ? (
                            <td>
                              <span
                                style={{
                                  backgroundColor: "rgb(249, 190, 190)",
                                }}
                              >
                                {resultModal[ele]}
                              </span>
                            </td>
                          ) : resultModal[ele] == "WARNING" ? (
                            <td>
                              <span
                                style={{
                                  backgroundColor: "rgb(252, 240, 183)",
                                }}
                              >
                                {resultModal[ele]}
                              </span>{" "}
                            </td>
                          ) : (
                            <td>{resultModal[ele]}</td>
                          )}
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
              <div className="d-flex justify-content-around">
                <button
                  className="btn btn-sm h-100 py-0"
                  onClick={handleTestClose}
                  style={{
                    backgroundColor: "#176b87",
                    color: "white",
                    width: "10%",
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          </Box>
        </Modal>

        <Modal
          open={removeOpen}
          onClose={handleremoveClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={removeStyle}
            //className='animated zoomIn'
          >
            <h5>Are you sure you want to delete ?</h5>
            <div className="d-flex  justify-content-end">
              <button
                className="btn  btn-sm mx-1 h-75 py-0"
                style={{ backgroundColor: "#666666", color: "white" }}
                onClick={() => {
                  handleremoveClose();
                }}
              >
                No
              </button>
              <button
                className="btn mx-1  btn-sm h-75 py-0"
                style={{ backgroundColor: "#176b87", color: "white" }}
                onClick={() => {
                  removeData();
                }}
              >
                Yes
              </button>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default RulesDataTable;

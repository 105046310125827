import React, { useState } from "react";
import "../css/NavBar.css";
import "../css/animate.css";
import logo from "../images/newlogo.png";
import { useEffect } from "react";
import axios from "axios";
import { Outlet, useLocation } from "react-router";
import ApiClient from "./ApiClient";
function NavBar(props) {
  const [currentActiveTab, setCurrentActiveTab] = useState();
  const [project, setProject] = useState([]);
  const [currentProject, setCurrentProject] = useState("");
  const [userData, setUserData] = useState();
  const [parsedData, setParsedData] = useState(null);
  const toggle = (tab, dataParam) => {
    if (currentActiveTab !== tab) {
      setCurrentActiveTab(tab);
      const url = dataParam
        ? `/${tab}?data=${encodeURIComponent(dataParam)}`
        : `/${tab}`;
      window.location.href = url;
    }
    const dropdownToggle = document.querySelector(".dropdown-menu.show");
    if (dropdownToggle) {
      dropdownToggle.classList.remove("show");
    }
  };

  const getSource = async () => {
    try {
      var data = await axios.get(`/accounts/addProjects/`, {
        withCredentials: true,
      });
      const queryString = window.location.search;
      const params = new URLSearchParams(queryString);
      const dataParam = params.get("data");

      if (dataParam) {
        try {
          const decodedData = decodeURIComponent(dataParam);
          const jsonData = atob(decodedData);
          const dataSelect = JSON.parse(jsonData);
          setParsedData(dataSelect);
          setProject(data?.data?.project_name);
          setCurrentProject(dataSelect);
        } catch (error) {
          console.error("Error decoding data:", error);
        }
      }
      if (data.data.selected_project) {
        setProject(data?.data?.project_name);
        setCurrentProject(data?.data?.selected_project);
        fetchData(data?.data?.selected_project,data?.data?.project_name);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const location = useLocation();
  const handleLogin = async () => {
    try {
      var datas = await axios.post(`/LoginFunction`, {
        username: "BB",
        password: `admin123`,
      });
      sessionStorage.setItem(
        "userData",
        JSON.stringify(datas.data.user_details[0])
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const initialLogin = async () => {
      let storedUserData = JSON.parse(sessionStorage.getItem("userData"));
      setUserData(storedUserData);
      if (!storedUserData) {
        await handleLogin();
      }
      document.body.classList.remove("LoginBody");
      setUserData(JSON.parse(sessionStorage.getItem("userData")));
      getSource();
      setCurrentActiveTab(location.pathname);
    };
    initialLogin();
    // const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
    //   const [name, value] = cookie.split("=");
    //   acc[name] = decodeURIComponent(value);
    //   return acc;
    // }, {});

    // // if (
    // //   !cookies.csrftoken ||
    // //   cookies.csrftoken === "" ||
    // //   cookies.csrftoken == ""
    // // ) {
    // } else {
    // setUserData(JSON.parse(userData));
    // getSource();
    // setCurrentActiveTab(location.pathname);
    // }
  }, [location.pathname]);

  const handleProject = async (e) => {
    var value = e.target.value;
    try {
      var data = await axios.get(`/switchProject/${value}`, {
        withCredentials: true,
      });
      setCurrentProject(value);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  const fetchData = async (value,project) => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const dataParam = params.get("data");

    if (dataParam) {
      try {
        const decodedData = decodeURIComponent(dataParam);
        const jsonData = atob(decodedData);
        // localStorage.setItem("filterData", jsonData);
        const data = JSON.parse(jsonData);
        if (data) {
          const projectContainsEntityGroup = project.some(
            (proj) => proj.project_name.toLowerCase() === data?.entity_group.toLowerCase()
          );          
          if (projectContainsEntityGroup) {
            if (data?.entity_group !== value.toLowerCase()) {
              try {
                const response = await axios.get(
                  `/switchProject/${(data?.entity_group).toUpperCase()}`,
                  {
                    withCredentials: true,
                  }
                );
                setCurrentProject((data?.entity_group).toUpperCase());
                setTimeout(() => {
                  window.location.reload();
                }, 5000);
              } catch (error) {
                console.log("Error fetching project data:", error);
              }
            }
          }
        }
        setParsedData(data);
      } catch (error) {
        console.error("Error decoding data:", error);
      }
    }
  };
  //   fetchData();
  // }, [parsedData]);

  //dropdown
  //   const handleLogout = async () => {
  //     try {
  //       var data = await axios.get(`/logoutView/`, {
  //         withCredentials: true,
  //       });
  //       document.cookie =
  //         "csrftoken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //       document.cookie =
  //         "sessionid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //       //   window.location.href = "/";
  //       await handleLogin();
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  return (
    <>
      <nav
        class="navbar navbar-expand-xl bg-body-tertiary fixed-top adq-nav py-0"
        aria-label="Sixth navbar example"
      >
        <div class="container-fluid">
          <div className="d-flex" style={{ cursor: "pointer" }}>
            <img src={logo} width="60" height="50" className="ms-2"></img>
            <span
              className="pt-2 mt-1"
              style={{ fontWeight: "600", fontSize: "18px" }}
            >
              AceDO
            </span>
            {/* <p className='adq-name my-auto' style={{
                            fontSize: "1.3rem", color: '#031b4e',
                            fontWeight: '700'
                        }}>&nbsp; Ace Data Ops</p> */}
          </div>
          <button
            class="navbar-toggler h-75"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarsExample06"
            aria-controls="navbarsExample06"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse " id="navbarsExample06">
            <div className="d-flex justify-content-between w-100">
              <div className="d-lg-none d-xl-block"></div>
              <ul
                class="navbar-nav d-flex flex-row"
                style={{ cursor: "pointer" }}
              >
                <li class="nav-item">
                  <a
                    className={`nav-link ${
                      currentActiveTab === "/" ? "active" : ""
                    }`}
                    onClick={() => {
                      toggle(
                        "",
                        new URLSearchParams(window.location.search).get("data")
                      );
                    }}
                  >
                    <i class="fa-solid fa-house"></i>&nbsp;Home
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    className={`nav-link ${
                      currentActiveTab === "/DataReliability" ? "active" : ""
                    }`}
                    onClick={() => {
                      toggle(
                        "DataReliability",
                        new URLSearchParams(window.location.search).get("data")
                      );
                    }}
                  >
                    <i class="fa-solid fa-clock-rotate-left"></i>&nbsp;Data
                    Reliability History
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    className={`nav-link ${
                      currentActiveTab === "/Incident" ? "active" : ""
                    }`}
                    onClick={() => {
                      toggle(
                        "Incident",
                        new URLSearchParams(window.location.search).get("data")
                      );
                    }}
                  >
                    <i class="fa-solid fa-triangle-exclamation"></i>
                    &nbsp;Incidents
                  </a>
                </li>
                <li class="nav-item dropdown">
                  <a
                    className={`nav-link dropdown-toggle ${
                      currentActiveTab === "/MetricRules" ||
                      currentActiveTab === "/TableRules"
                        ? "active"
                        : ""
                    }`}
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="fa-solid fa-viruses"></i>&nbsp; Rules
                  </a>
                  <ul class="dropdown-menu animated zoomIn position-absolute">
                    <li>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          toggle(
                            "RawDataRules",
                            new URLSearchParams(window.location.search).get(
                              "data"
                            )
                          );
                        }}
                      >
                        Raw Data Rules
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          toggle(
                            "MetricRules",
                            new URLSearchParams(window.location.search).get(
                              "data"
                            )
                          );
                        }}
                      >
                        Metric Rules
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <a
                    className={`nav-link dropdown-toggle ${
                      currentActiveTab === "/RawDataDashboard" ||
                      currentActiveTab === "/MetricDashboard" ||
                      currentActiveTab === "/TableLineage"
                        ? "active"
                        : ""
                    }`}
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="fa-solid fa-bars"></i>&nbsp;Dashboards
                  </a>
                  <ul class="dropdown-menu animated zoomIn position-absolute">
                    <li>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          toggle(
                            "RawDataDashboard",
                            new URLSearchParams(window.location.search).get(
                              "data"
                            )
                          );
                        }}
                      >
                        Raw Data Dashboards
                      </a>
                    </li>
                    {/* <li>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          toggle("MetricDashboard");
                        }}
                      >
                        Metric Dashboards
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          toggle("TableLineage");
                        }}
                      >
                        Table Lineage
                      </a>
                    </li> */}
                  </ul>
                </li>
              </ul>
              <div className="d-flex justify-content-center ">
                {/* <div className=''> */}
                <select
                  className="form-control wider-select w-100"
                  value={currentProject}
                  onChange={(event) => {
                    handleProject(event);
                  }}
                >
                  <option value="" disabled>
                    {" "}
                    Select Project
                  </option>
                  {project?.map((ele) => {
                    return (
                      <option value={ele.project_name}>
                        {ele.display_name}
                      </option>
                    );
                  })}
                </select>
                {/* </div> */}
                <div className="">
                  <ul class="navbar-nav  me-3" style={{ cursor: "pointer" }}>
                    <li class="nav-item dropdown ms-auto ">
                      <a
                        class="nav-link dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fa fa-user" aria-hidden="true"></i>
                      </a>
                      <div
                        class="dropdown-menu  dropdown-menu-end animated zoomIn position-absolute"
                        aria-labelledby="navbarDropdown"
                      >
                        <p className="d-flex justify-content-center fw-bold">
                          {userData?.username}
                        </p>

                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="/Settings">
                          Settings
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item">Logout</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <Outlet />
    </>
  );
}

export default NavBar;

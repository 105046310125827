import React, { useEffect, useState } from 'react'
import '../css/Home.css'
import axios from 'axios';
import Spinner from '../widgets/Spinner';

var initialRule = {
    source_metric: "",
    metric_name: "",
    l1Query: "",
    l2Query: "",
    l3Query: "",
    expected_value: "",
    field_name: "",
    qc_type: "",
    qc_comment: "",
    is_self_validation: "FALSE",
    table_name: "",
    data_Area: ""
}
function MRules(props) {
    const [state, setState] = useState(initialRule);
    const [title, setTitle] = useState('Add Rule');
    const [Lquery, setLquery] = useState([]);
    const [QcSatate, setQcSatate] = useState([]);
    const [source, setSource] = useState([])
    const [dataArea, setDataArea] = useState([])
    const [errMsg, setErrMsg] = useState('none')
    const [sourceLoader, setSourceLoader] = useState(false)
    const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
        const [name, value] = cookie.split('=');
        acc[name] = decodeURIComponent(value);
        return acc;
    }, {});
    const queryMapping = {
        L1: 'l1Query',
        L2: 'l2Query',
        L3: 'l3Query'
    };
    function handleChange(evt) {
        const value = evt.target.value;
        const newState = { ...state };
        newState[evt.target.name] = value;
        setErrMsg('none')
        if (evt.target.name == "qc_type") {


            if (value == "Stand alone") {
                newState.is_self_validation = 'TRUE';
            }
            else{
                newState.is_self_validation = 'FALSE';
            }

            if (value == "L1 Vs L2") {
                setLquery(['L1', 'L2'])
            } else if (value == "L2 Vs L3") {
                setLquery(['L2', 'L3'])
            } else if (value == "L1 Vs L3") {
                setLquery(['L1', 'L3'])
            } else if (value == "L1 Vs L2 Vs L3") {
                setLquery(['L1', 'L2', 'L3'])
            } else if (value == "Current Vs Previous") {
                setLquery([])
                setQcSatate([])
            } else {
                setLquery([])
                setQcSatate(null)
            }
            newState['l1Query'] = '';
            newState['l2Query'] = '';
            newState['l3Query'] = '';
        }
        if (evt.target.name == "source") {
            // console.log('jiji')
            newState.data_Area = "";
            dataAreaListCall(evt.target.value)
        }
        setState(newState);
    }



    useEffect(() => {
        if (Array.isArray(QcSatate)) {
            setLquery(QcSatate)
        } else {
            if (QcSatate == "L1") {
                setLquery(['L1'])
            } else if (QcSatate == "L2") {
                setLquery(['L2'])
            } else if (QcSatate == "L3") {
                setLquery(['L3'])
            }
        }
    }, [QcSatate])


    function handleChangeQC(evt, setMulti = false) {
        const value = evt.target.value;
        if (setMulti) {
            try {
                var array = [...QcSatate];
            } catch (error) {
                var array = []
            }
            const index = array.indexOf(value);

            // If the element is in the array, remove it. Otherwise, add it.
            if (index !== -1) {
                const newArray = [...array];
                newArray.splice(index, 1);
                setQcSatate(newArray);
                const newState = { ...state };
                newState[queryMapping[value]] = '';
                setState(newState);
            } else {
                setQcSatate([...array, value]);
            }
        } else {
            const newState = { ...state };
            newState['l1Query'] = '';
            newState['l2Query'] = '';
            newState['l3Query'] = '';
            setState(newState);
            setQcSatate(value);
        }
    }

    const getEditData = async (id) => {
        try {
            let data = await axios.get(`/editMetricRule/${id}`)
            if (data.data.data.qc_type == "L1 Vs L2") {
                setLquery(['L1', 'L2'])
            } else if (data.data.data.qc_type == "L2 Vs L3") {
                setLquery(['L2', 'L3'])
            } else if (data.data.data.qc_type == "L1 Vs L3") {
                setLquery(['L1', 'L3'])
            } else if (data.data.data.qc_type == "L1 Vs L2 Vs L3") {
                setLquery(['L1', 'L2', 'L3'])
            } else if (data.data.data.qc_type == "Stand alone") {
                if (data.data.data.l1Query != "") {
                    setQcSatate('L1')
                } else if (data.data.data.l2Query != "") {
                    setQcSatate('L2');
                } else if (data.data.data.l3Query != "") {
                    setQcSatate('L3')
                }
            } else if (data.data.data.qc_type == "Current Vs Previous") {
                let tempArr = [];
                if (data.data.data.l2Query != "") {
                    tempArr.push('L2')
                }
                if (data.data.data.l3Query != "") {
                    tempArr.push('L3');
                }
                setQcSatate(tempArr)
            }
            let schemaData = data.data.data.table_name.split('.')[0];
            setState({
                ...data?.data?.data,
                Schema: schemaData
            })
            dataAreaListCall(data?.data?.data?.source)
        } catch (error) {

        }
    }
    useEffect(() => {
        if (props.data) {
            if (Object.keys(props.data).length > 0) {
                setTitle('Edit Rule')
                if (props.data.QC_ID) {
                    getEditData(props.data.QC_ID)
                }
            }
        } else {
            setTitle('Add Rule');
        }
    }, [props])
    const sourceListCall = async () => {
        let data = await axios.get('/getSourceTable/')
        setSource(data.data.source)
    }
    const dataAreaListCall = async (source) => {
        console.log(state)
        let data = await axios.get('/source_to_dataarea_mapping/', { params: { "source_name": source } })
        setDataArea(data.data.data)
    }
    const saveRule = async () => {
        // props.handleSavePop()
        const headers = {
            'X-CSRFToken': cookies.csrftoken,
            'Content-Type': 'application/json'
        };
        let data = state
        try {
            if (data.source && data.table_name && data.field_name && data.metric_name && data.qc_type && data.Schema) {
                if (
                    (data.qc_type === "Stand alone" && (data.l1Query || data.l2Query || data.l3Query)) ||
                    (data.qc_type === "Current Vs Previous" && QcSatate.length) ||
                    (data.qc_type === "L1 Vs L2" && data.l1Query && data.l2Query) ||
                    (data.qc_type === "L1 Vs L3" && data.l1Query && data.l3Query) ||
                    (data.qc_type === "L2 Vs L3" && data.l2Query && data.l3Query) ||
                    (data.qc_type === "L1 Vs L2 Vs L3" && data.l1Query && data.l2Query && data.l3Query)
                ) {
                    var isCall = true;
                    if (data.qc_type === "Current Vs Previous") {
                        QcSatate?.forEach((ele) => {
                            if (!data[queryMapping[ele]]) {
                                setErrMsg('block')
                                isCall = false
                            }
                        })
                    }
                    if (isCall) {
                        Object.keys(data)?.map((ele)=>data[ele]=data[ele].replace(/\n/g, '').trim())
                        let result = await axios.post('/addMetric/', { data }, { headers })
                        let propsData = {'Result': result.data.message}
                        propsData.type = 'reload'
                        if (title == 'Add Rule') {
                            propsData.action = 'Add Rule'
                        } else if (title == 'Edit Rule') {
                            propsData.action = 'Edit Rule'
                        }
                        props.handleSavePop(propsData)
                    }
                }
                else {
                    setErrMsg('block')
                }
            }
            else {
                setErrMsg('block')
            }
        }
        catch (error) {
            console.log(error);
        }


    }
    const testRuleIn = async () => {
        // props.handleTestOpen('Yes')
        setSourceLoader(true)
        const headers = {
            'X-CSRFToken': cookies.csrftoken,
            'Content-Type': 'application/json'
        };
        let data = state
        try {
            if (data.source && data.table_name && data.field_name && data.metric_name && data.qc_type && data.Schema) {
                if (
                    (data.qc_type === "Stand alone" && (data.l1Query || data.l2Query || data.l3Query)) ||
                    (data.qc_type === "Current Vs Previous" && QcSatate.length) ||
                    (data.qc_type === "L1 Vs L2" && data.l1Query && data.l2Query) ||
                    (data.qc_type === "L1 Vs L3" && data.l1Query && data.l3Query) ||
                    (data.qc_type === "L2 Vs L3" && data.l2Query && data.l3Query) ||
                    (data.qc_type === "L1 Vs L2 Vs L3" && data.l1Query && data.l2Query && data.l3Query)
                ) {

                    var isCall = true;
                    if (data.qc_type === "Current Vs Previous") {
                        QcSatate?.forEach((ele) => {
                            if (!data[queryMapping[ele]]) {
                                setErrMsg('block')
                                isCall = false
                            }
                        })
                    }
                    if (isCall) {
                        Object.keys(data)?.map((ele)=>data[ele]=data[ele].replace(/\n/g, '').trim())
                        let result = await axios.post('/testMetricRule/', { data }, { headers })
                        let propsData = result.data
                        propsData.type = 'NotReload'
                        propsData.action = 'Test Rule'
                        props.handleSavePop(propsData)
                    }
                }
                else {
                    throw new Error()
                }
            }
            else {
                throw new Error()
            }
        }
        catch (error) {
            console.log(error);
            setErrMsg('block')
        }
        setSourceLoader(false)
    }
    useEffect(() => {
      const timer = setTimeout(() => {
        sourceListCall();
      }, 3000);

      return () => clearTimeout(timer);
    }, []);
    return (
        <div className=''>
            {sourceLoader ? <div className='position-relative' >
                <div className='backdrop'></div>
                <div className='spinner-container'><Spinner /></div>

            </div> : null}
            <h2 className='adq-source'>{title}</h2>
            <div className='row'>
                <div className='col-6 mt-2'>
                    <label className='fw-bold'>Source <span className="spann">*</span></label>
                    <select className='form-control' name='source' value={state?.source} onChange={handleChange}>
                        <option value="" selected disabled> Select Source</option>
                        {source?.map((e) => <option value={e.SOURCE}>{e.SOURCE}</option>)}
                    </select>
                </div>
                <div className='col-6 mt-2'>
                    <label className='fw-bold'>Data Area</label>
                    <select className='form-control' name='data_Area' value={state?.data_Area} onChange={handleChange}>
                        <option value="" selected > Select Data Area</option>
                        {dataArea?.map((e) => <option value={e}>{e}</option>)}

                    </select>
                </div>
                <div className='col-6 mt-2'>
                    <label className='fw-bold'>Schema <span className="spann">*</span></label>
                    <select className='form-control' name='Schema' value={state?.Schema} onChange={handleChange}>
                        <option value="" selected disabled> Select Schema</option>
                        <option value="L1">L1</option>
                        <option value="L2">L2</option>
                        <option value="L3">L3</option>
                    </select>
                </div>
                <div className='col-6 mt-2'>
                    <label className='fw-bold'>Table Name <span className="spann">*</span></label>
                    <input type='text' className='form-control' placeholder='Enter Table Name' name='table_name' value={state?.table_name} onInput={handleChange}></input>
                </div>
                <div className='col-6 mt-2'>
                    <label className='fw-bold'>Field Name <span className="spann">*</span></label>
                    <input type='text' className='form-control' placeholder='Enter Field Name' name='field_name' value={state?.field_name} onInput={handleChange}></input>
                </div>
                <div className='col-6 mt-2'>
                    <label className='fw-bold'>Metric Name <span className="spann">*</span></label>
                    <input type='text' className='form-control' placeholder='Enter Metric Name' name='metric_name' value={state?.metric_name} onInput={handleChange}></input>
                </div>
                <div className='col-6 mt-2'>
                    <label className='fw-bold'>QC Type <span className="spann">*</span></label>
                    <select className='form-control' name='qc_type' value={state?.qc_type} onChange={handleChange}>
                        <option value="" selected disabled> Select QC Type</option>
                        <option value="L1 Vs L2">L1 Vs L2</option>
                        <option value="L1 Vs L3">L1 Vs L3</option>
                        <option value="L2 Vs L3">L2 Vs L3</option>
                        <option value="L1 Vs L2 Vs L3">L1 Vs L2 Vs L3</option>
                        <option value="Current Vs Previous">Current Vs Previous</option>
                        <option value="Stand alone">Standalone</option>
                    </select>
                </div>
                <div className='col-6 mt-2'>
                    <label className='fw-bold'>Is Self Validating ? <span className="spann">*</span></label>
                    <div className='d-flex justify-content-start mt-1'>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="flexRadioDefault1" name='is_self_validation' value="TRUE" onClick={handleChange} checked={state?.is_self_validation == "TRUE" ? true : false} />
                            <label class="form-check-label" for="flexRadioDefault1">
                                True
                            </label>
                        </div>
                        <div class="form-check ms-3">
                            <input class="form-check-input" type="radio" name='is_self_validation' value="FALSE" onClick={handleChange} id="flexRadioDefault2" checked={state?.is_self_validation == "FALSE" ? true : false} />
                            <label class="form-check-label" for="flexRadioDefault2">
                                False
                            </label>
                        </div>
                    </div>
                </div>
                {
                    state?.is_self_validation == "TRUE" ?
                        <div className='col-6 mt-2'>
                            <label className='fw-bold'>Expected Value <span className="spann">*</span></label>
                            <input type='text' className='form-control' placeholder='Enter Expected Value' name='expected_value' value={state?.expected_value} onInput={handleChange}></input>
                        </div>
                        : null
                }
                {
                    state?.qc_type == "Current Vs Previous" ?
                        <>
                            <label className='fw-bold'>Current Vs Previous <span className="spann">*</span></label>
                            <div className='d-flex justify-content-start mt-1'>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="CorP1" name='CurrentPrevious' value="L2" onClick={(e) => { handleChangeQC(e, true) }} checked={QcSatate.includes("L2") ? true : false} />
                                    <label className="form-check-label fw-bold" for="CorP1">
                                        L2 Query <span className="spann">*</span>
                                    </label>
                                </div>
                                <div class="form-check ms-3">
                                    <input class="form-check-input" type="checkbox" name='CurrentPrevious' value="L3" onClick={(e) => { handleChangeQC(e, true) }} s id="CorP2" checked={QcSatate.includes("L3") ? true : false} />
                                    <label className="form-check-label fw-bold" for="CorP2">
                                        L3 Query <span className="spann">*</span>
                                    </label>
                                </div>
                            </div>
                        </>
                        : state?.qc_type == "Stand alone" ?
                            <>
                                <label className='fw-bold'>Standalone <span className="spann">*</span></label>
                                <div className='d-flex justify-content-start mt-1'>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" id="stand1" name='CurrentPrevious' value="L1" onClick={handleChangeQC} checked={QcSatate == "L1" ? true : false} />
                                        <label class="form-check-label" for="stand1">
                                            L1 Query
                                        </label>
                                    </div>
                                    <div class="form-check ms-3">
                                        <input class="form-check-input" type="radio" id="stand2" name='CurrentPrevious' value="L2" onClick={handleChangeQC} checked={QcSatate == "L2" ? true : false} />
                                        <label class="form-check-label" for="stand2">
                                            L2 Query
                                        </label>
                                    </div>
                                    <div class="form-check ms-3">
                                        <input class="form-check-input" type="radio" name='CurrentPrevious' value="L3" onClick={handleChangeQC} id="stand3" checked={QcSatate == "L3" ? true : false} />
                                        <label class="form-check-label" for="stand3">
                                            L3 Query
                                        </label>
                                    </div>
                                </div>
                            </>
                            : null
                }
                {
                    Lquery?.map((ele) => {
                        return (

                            <div className='col-12 mt-2'>

                                <label className='fw-bold'>{ele} Query <span className="spann">*</span></label>
                                <textarea className='form-control' value={state[`${(ele.toLowerCase())}Query`]} placeholder={`Write ${ele} Query`} name={`${(ele.toLowerCase())}Query`} onInput={handleChange}>{state?.ele}</textarea>
                            </div>
                        )
                    })
                }
                <div className='col-12 mt-2'>
                    <label className='fw-bold'>Query Comment</label>
                    <textarea className='form-control' placeholder='Write QC Comment' name='qc_comment' onInput={handleChange} value={state?.qc_comment}></textarea>
                </div>
            </div>

            <span className='spann' style={{ display: `${errMsg}` }}>Please fill all the required fields</span>
            <div className='col-12 mt-2 mt-4'>
                <div className='d-flex justify-content-end'>
                    <button class="btn mx-1 btn-sm h-100 pe-4 ps-4" style={{ backgroundColor: "#176b87", color: 'white' }} onClick={() => { saveRule() }} >Save</button>
                    <button class="btn mx-1 btn-sm h-100 pe-3 ps-3" style={{ backgroundColor: "#176b87", color: 'white' }} onClick={() => { testRuleIn() }} >Test Rule</button>
                </div>
            </div>
        </div>
    )
}

export default MRules
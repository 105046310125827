import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Swal from "sweetalert2";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Spinner from "../widgets/Spinner";
import "../css/projectTab.css";
import axios from "axios";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Calender from "./Calendar";
import SourcesDataTable from "./SourcesDataTable";

function ProjectTab() {
  const [FullPageLoader, setFullPageLoader] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [allProjects, setAllProjects] = useState([]);
  const [defaultPrj, setDefaultPrj] = useState([]);
  const [currentPrj, setCurrentPrj] = useState([]);
  const [awsKeySelected, setAwsKeySelected] = useState(false);
  const [passwordSelected, setPasswordSelected] = useState(false);
  const [ModelOpen, setModelOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [confirmationModelOpen, setConfirmationModelOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [TargetDbErrMsg, setTargetDbErrMsg] = useState("none");
  const [createProjectError1, setCreateProjectError1] = useState("none");
  const [createProjectError2, setCreateProjectError2] = useState("none");
  const [usersList, setUsersList] = useState([]);
  const [usersModelOpen, setUsersModelOpen] = useState(false);
  const [lastUser, setLastUser] = useState([]);
  const [addRemove, setAddRemove] = useState("add");
  const [notification, setNotification] = useState([]);
  const [popoverData, setPopoverData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmationNotificationOpen, setConfirmationNotificationOpen] =
    useState(false);
  const [clickedRow, SetClickedRow] = useState();
  const [currentproject, setCurrentproject] = useState();
  const [addNotificationModelOpen, setAddNotificationModelOpen] =
    useState(false);
  const [notificationData, setNotificationData] = useState({
    appLink: "",
    channel: "",
    notfyAPP: "",
  });
  const [NotificationStatus, setNotificationStatus] = useState("Add");
  const [allSources, setAllSources] = useState([]);
  const [notificationErrMsg, setNotificationErrMsg] = useState("none");
  const initialTargetData = {
    project: currentPrj.project_name,
    project_database_type: "",
    project_userName: "",
    project_aws_key: "",
    project_password: "",
    project_warehouse: "",
    project_account: "",
    project_database: "",
    project_role: "",
    project_schema: "",
    project_hostname: "",
    project_httpPath: "",
    project_accessToken: "",
    project_port: "",
    project_driver: "",
  };
  const [connectionDataSf, setConnectionDataSf] = useState(initialTargetData);
  const modelStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 2,
    width: "800px",
    padding: 0,
  };

  const NotificationStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 2,
    width: "500px",
  };

  const userModelStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    // p: 2,
    width: "500px",
  };
  const handleTabSelect = (index) => {
    setSelectedTabIndex(index);
  };
  const projectCall = async () => {
    let data = await axios.get("/accounts/addProjects/");
    setAllProjects(data.data.project_name);
    let defaultP = data.data.project_name.filter(
      (e) => e.project_name == data.data.defaultProject
    );
    let selected = data.data.project_name.filter(
      (e) => e.project_name == data.data.selected_project
    );
    setDefaultPrj(defaultP[0]);
    setCurrentPrj(selected[0]);
  };
  const crtprojectCall = async () => {
    let data = await axios.get("/accounts/addProjects/");
    setCurrentproject(data?.data?.selected_project);
  };
  const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
    const [name, value] = cookie.split("=");
    acc[name] = decodeURIComponent(value);
    return acc;
  }, {});

  const fetchProjectDetails = async () => {
    let data = {
      project: currentPrj.project_name,
    };

    const headers = {
      "X-CSRFToken": cookies.csrftoken,
      "Content-Type": "application/json",
    };
    let dataFetched = await axios.post(
      `/accounts/fetchProject/`,
      { data },
      { headers }
    );
    if (dataFetched.data.Result != "Project Not Found") {
      setConnectionDataSf(dataFetched.data.Result);
    } else {
      setConnectionDataSf({
        ...initialTargetData,
        project_database_type: dataFetched.data.dbType[0],
      });
    }
    if (dataFetched.data.Result.project_aws_key) {
      setAwsKeySelected(true);
      setPasswordSelected(false);
    } else {
      setPasswordSelected(true);
      setAwsKeySelected(false);
    }
  };

  const handleInputData = (event) => {
    var name = event.target.name;
    setTargetDbErrMsg("none");
    var newConnectionDataSf = {
      ...connectionDataSf,
      [name]: event.target.value,
    };
    setConnectionDataSf(newConnectionDataSf);
  };
  useEffect(() => {
    projectCall();
    crtprojectCall();
  }, []);
  useEffect(() => {
    if (currentPrj.length != 0) {
      fetchProjectDetails();
      getAllSources();
      getNotification();
      usersListCall();
    }
  }, [currentPrj]);

  const getAllSources = async () => {
    try {
      setAllSources([]);
      const headers = {
        "X-CSRFToken": cookies.csrftoken,
        "Content-Type": "application/json",
      };
      let datas = await axios.post(
        "/getAllSources/",
        { project: currentPrj?.project_name },
        { headers }
      );
      setAllSources(datas.data.Result);
    } catch (error) {
      console.log(error);
    }
  };
  const handleRadioChange = (e) => {
    const name = e.target.name;

    if (name == "password") {
      setAwsKeySelected(false);
      setPasswordSelected(true);
      setConnectionDataSf({
        ...connectionDataSf,
        project_aws_key: "",
      });
    } else {
      setAwsKeySelected(true);
      setPasswordSelected(false);
      setConnectionDataSf({
        ...connectionDataSf,
        project_password: "",
      });
    }
  };

  let initialProjectState = {
    project_name: "",
    display_name: "",
    database_type: "",
    template: "",
    sources: [],
    unselectedSources: [],
    is_default: 0,
  };
  const [createProject, setCreateProject] = useState(initialProjectState);

  const handleNext = (e) => {
    e.preventDefault();
    if (
      createProject.project_name &&
      createProject.display_name &&
      createProject.database_type
    ) {
      setStep(step + 1);
      // setCreateProjectError1('none')
    } else {
      setCreateProjectError1("block");
    }
  };
  const [sources, setSources] = useState([]);
  const sourcesCall = async (e) => {
    e.preventDefault();
    if (createProject.template) {
      setStep(step + 1);
      if (createProject.template) {
        let data = await axios.get(
          `/accounts/sourcesForAddProject/${createProject.template}`
        );
        let sourceList = data.data.data.map((e) => ({
          name: e,
          isChecked: true,
        }));
        setSources(sourceList);
      }
    } else {
      setCreateProjectError2("none");
      setConfirmationModelOpen(true);
    }
  };

  const toggleSource = (sourceName) => {
    setSources((prevList) =>
      prevList.map((source) =>
        source.name === sourceName
          ? { ...source, isChecked: !source.isChecked }
          : source
      )
    );
  };

  const checkAll = () => {
    setSources((prevList) =>
      prevList.map((source) => ({ ...source, isChecked: true }))
    );
  };

  const uncheckAll = () => {
    setSources((prevList) =>
      prevList.map((source) => ({ ...source, isChecked: false }))
    );
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleChange = (e) => {
    setCreateProjectError1("none");
    setCreateProjectError2("none");
    const { name, value } = e.target;
    if (name === "project_name") {
      setCreateProject({
        ...createProject,
        project_name: value,
        display_name: value, // Update display_name with project_name
      });
    } else {
      setCreateProject({
        ...createProject,
        [name]: value,
      });
    }
    // setCreateProject({ ...createProject, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const checkedSources = sources
      .filter((source) => source.isChecked)
      .map((source) => source.name);
    const uncheckedSources = sources
      .filter((source) => !source.isChecked)
      .map((source) => source.name);

    // setCreateProject({...createProject,sources:checkedSources })
    setCreateProject((createProject) => ({
      ...createProject,
      sources: checkedSources,
      unselectedSources: uncheckedSources,
    }));
    setConfirmationModelOpen(true);
  };
  const defaultPrjCheckBox = () => {
    setIsChecked(!isChecked);

    if (isChecked == false) {
      setCreateProject({ ...createProject, is_default: 1 });
    } else {
      setCreateProject({ ...createProject, is_default: 0 });
    }
  };
  const createNewProject = async () => {
    let data = createProject;
    data.project = currentPrj.project_name;
    const headers = {
      "X-CSRFToken": cookies.csrftoken,
      "Content-Type": "application/json",
    };
    try {
      setFullPageLoader(true);
      const datas = await axios.post(
        "/connect_to_new_database/",
        { data },
        { headers }
      );
      console.log(data, datas?.data?.Result);
      setFullPageLoader(false);
      setCreateProject(initialProjectState);
      setSources([]);
      setStep(1);
      setConfirmationModelOpen(false);
      setModelOpen(false);
      if (datas?.data?.Result) {
        Swal.fire({
          title: datas?.data?.Result,
          text: "",
          icon: "success",
          confirmButtonColor: "#176b87",
        });
      } else {
        Swal.fire({
          title: "Error",
          text: datas?.data?.Error,
          icon: "error",
          confirmButtonColor: "#176b87",
        });
      }
        setTimeout(() => {
          window.location.reload();
        }, 3000);
    } catch (error) {
      console.log(error);
    }
  };
  const testConnection = async () => {
    let data = connectionDataSf;
    data.project = currentPrj.project_name;
    const headers = {
      "X-CSRFToken": cookies.csrftoken,
      "Content-Type": "application/json",
    };
    try {
      if (connectionDataSf.project_accessToken) {
        if (
          connectionDataSf.project_database &&
          connectionDataSf.project_database_type &&
          connectionDataSf.project_schema &&
          connectionDataSf.project_accessToken &&
          connectionDataSf.project_httpPath &&
          connectionDataSf.project_hostname
        ) {
          setFullPageLoader(true);
          let datas = await axios.post(
            "/accounts/testProjectConnection/",
            { data },
            { headers }
          );
          setFullPageLoader(false);
          if (datas.data.success) {
            Swal.fire({
              title: "Connection Tested successfully",
              text: "",
              icon: "success",
              confirmButtonColor: "#176b87",
            });
          } else {
            Swal.fire({
              title: "Error",
              text: "Connection Failed",
              icon: "error",
              confirmButtonColor: "#176b87",
            });
          }
        } else {
          setTargetDbErrMsg("block");
        }
      } else {
        if (
          connectionDataSf.project_account &&
          connectionDataSf.project_database_type &&
          connectionDataSf.project_database &&
          connectionDataSf.project_userName &&
          (connectionDataSf.project_aws_key ||
            connectionDataSf.project_password) &&
          connectionDataSf.project_warehouse &&
          connectionDataSf.project_role &&
          connectionDataSf.project_schema
        ) {
          setFullPageLoader(true);
          let datas = await axios.post(
            "/accounts/testProjectConnection/",
            { data },
            { headers }
          );
          setFullPageLoader(false);
          if (datas.data.success) {
            Swal.fire({
              title: "Connection Tested successfully",
              text: "",
              icon: "success",
              confirmButtonColor: "#176b87",
            });
          } else {
            Swal.fire({
              title: "Error",
              text: "Connection Failed",
              icon: "error",
              confirmButtonColor: "#176b87",
            });
          }
        } else {
          setTargetDbErrMsg("block");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveConnection = async () => {
    let data = {
      ...connectionDataSf,
      project_database_type: connectionDataSf.project_database_type[0],
    };
    data.project = currentPrj.project_name;
    const headers = {
      "X-CSRFToken": cookies.csrftoken,
      "Content-Type": "application/json",
    };
    try {
      if (connectionDataSf.project_accessToken) {
        if (
          connectionDataSf.project_database &&
          connectionDataSf.project_database_type &&
          connectionDataSf.project_schema &&
          connectionDataSf.project_accessToken &&
          connectionDataSf.project_httpPath &&
          connectionDataSf.project_hostname
        ) {
          setFullPageLoader(true);
          let datas = await axios.post(
            "accounts/addProjectSettings/",
            { data },
            { headers }
          );
          setFullPageLoader(false);
          if (datas.data.success) {
            Swal.fire({
              title: "Connection Saved successfully",
              text: "",
              icon: "success",
              confirmButtonColor: "#176b87",
            });
          } else {
            Swal.fire({
              title: "Error",
              text: "Connection Failed",
              icon: "error",
              confirmButtonColor: "#176b87",
            });
          }
        } else {
          setTargetDbErrMsg("block");
        }
      } else {
        if (
          connectionDataSf.project_account &&
          connectionDataSf.project_database_type &&
          connectionDataSf.project_database &&
          connectionDataSf.project_userName &&
          (connectionDataSf.project_aws_key ||
            connectionDataSf.project_password) &&
          connectionDataSf.project_warehouse &&
          connectionDataSf.project_role &&
          connectionDataSf.project_schema
        ) {
          setFullPageLoader(true);
          let datas = await axios.post(
            "accounts/addProjectSettings/",
            { data },
            { headers }
          );
          setFullPageLoader(false);
          if (datas.data.success) {
            Swal.fire({
              title: "Connection Saved successfully",
              text: "",
              icon: "success",
              confirmButtonColor: "#176b87",
            });
          } else {
            Swal.fire({
              title: "Error",
              text: "Connection Failed",
              icon: "error",
              confirmButtonColor: "#176b87",
            });
          }
        } else {
          setTargetDbErrMsg("block");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeDefaultPrj = async (e) => {
    try {
      const dftProject = e.target.value;
      let data = await axios.get("/accounts/ChangeDefaultProject/", {
        params: { default_project: dftProject },
      });
      if (data.data.success) {
        let tempProj = [...allProjects];
        let defaultProject = tempProj.filter(
          (ele) => ele.project_name == dftProject
        );
        setDefaultPrj(defaultProject[0]);
        Swal.fire({
          title: data.data.popupMessage,
          text: "",
          icon: "success",
          confirmButtonColor: "#176b87",
        });
      } else {
        Swal.fire({
          title: "Error",
          text: data.data.popupMessage,
          icon: "error",
          confirmButtonColor: "#176b87",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeCurrentPrj = async (e) => {
    let targetValue = e.target.value;
    await fetchProjectDetails();
    let selectedProject = allProjects.filter(
      (ele) => targetValue == ele.project_name
    );
    setCurrentPrj(selectedProject[0]);
    setConnectionDataSf(initialTargetData);
    setCurrentproject(targetValue);
    getNotification();
  };

  const deleteProject = async () => {
    let data = {
      project: currentPrj.project_name,
    };

    const headers = {
      "X-CSRFToken": cookies.csrftoken,
      "Content-Type": "application/json",
    };
    let datas = await axios.post(
      "/accounts/deleteProject/",
      { data },
      { headers }
    );
  };

  const usersListCall = async () => {
    let data = await axios.get(
      `/accounts/usersList/${currentPrj.project_name}`
    );
    setUsersList(data.data.data);
  };

  const handleUsers = (e) => {
    setLastUser(e);
    if (!e.edited && !e.checked) {
      setAddRemove("add");
      const updatedUsers = usersList.map((ele) => {
        if (ele.id == e.id) {
          return { ...ele, checked: !ele.checked };
        }
        return ele;
      });
      setUsersList(updatedUsers);
    } else {
      setAddRemove("remove");
      const updatedUsers = usersList.map((ele) => {
        if (ele.id == e.id) {
          return { ...ele, checked: !ele.checked };
        }
        return ele;
      });
      setUsersList(updatedUsers);
    }

    if (!e?.edited) {
      setUsersModelOpen(true);
    }
  };

  const addRemoveUser = async () => {
    let dataVar = usersList.filter((e) => e.id == lastUser.id);
    dataVar[0].project = currentPrj.project_name;
    dataVar[0].user = dataVar[0].username;
    delete dataVar[0].id;
    dataVar[0].checked = dataVar[0].checked ? "true" : "false";
    let data = dataVar[0];
    const headers = {
      "X-CSRFToken": cookies.csrftoken,
      "Content-Type": "application/json",
    };
    let datas = await axios.post("/accounts/userFlag/", { data }, { headers });
    usersListCall();
    setUsersModelOpen(false);
  };

  const cancelRemoveUser = (e) => {
    e.preventDefault();
    setUsersModelOpen(false);
    let parameter = {
      username: lastUser.username,
      id: lastUser.id,
      checked: !lastUser.checked,
      edited: true,
    };
    handleUsers(parameter);
  };
  const cancelAddUser = (e) => {
    setUsersModelOpen(false);
    let parameter = {
      username: lastUser.username,
      id: lastUser.id,
      checked: !lastUser.checked,
      edited: true,
    };
    handleUsers(parameter);
  };

  const getNotification = async () => {
    try {
      var notData = await axios.get(
        `accounts/fetchNotifySettings/${currentproject}`
      );
      setNotification(notData?.data?.Result);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteNotification = async () => {
    var sendData = {
      applicationName: popoverData?.NOTIFICATION_APP,
      previousChannel: popoverData?.CHANNEL,
      prvConnectionlink: popoverData?.APP_CONNECTOR_LINK,
      project: currentproject,
      process: "delete",
    };
    const headers = {
      "X-CSRFToken": cookies.csrftoken,
      "Content-Type": "application/json",
    };
    try {
      let data = await axios.post(
        `/accounts/editNotifySettings/`,
        { ...sendData },
        { headers }
      );
      setConfirmationNotificationOpen(false);
      getNotification();
      Swal.fire({
        title: "Notification Deleted Successfully",
        text: "",
        icon: "success",
        confirmButtonColor: "#176b87",
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  var popoverStyle = {
    border: " 0px solid rgba(0, 0, 0, .2)",
    borderRadius: "2px",
    fontFamily: "Roboto,sans-serif",
    boxShadow: " 0 2px 10px rgba(0, 0, 0, .2)",
    padding: "5px 15px 10px 15px",
    lineHeight: " 24px",
    fontSize: "14px",
    width: "200px",
    height: "115px",
  };
  const handleIsDelete = () => {
    setAnchorEl(false);
    setConfirmationNotificationOpen(true);
  };
  const handleClick = (event, data, index) => {
    setAnchorEl(event.currentTarget);
    setPopoverData(data);
    SetClickedRow(index);
  };

  const handleAddNotification = () => {
    setNotificationStatus("Add");
    setNotificationData({
      appLink: "",
      channel: "",
      notfyAPP: "",
    });
    setAddNotificationModelOpen(true);
  };

  const handleNotificationData = (evt) => {
    setNotificationErrMsg("none");
    setNotificationData({
      ...notificationData,
      [evt.target.name]: evt.target.value,
    });
  };

  const sendNewNotification = async () => {
    var sendData = { ...notificationData };
    sendData["project"] = currentproject;
    const headers = {
      "X-CSRFToken": cookies.csrftoken,
      "Content-Type": "application/json",
    };
    try {
      if (sendData?.appLink && sendData?.channel && sendData?.notfyAPP) {
        var data = await axios.post(
          "/accounts/saveNotifySettings/",
          { ...sendData },
          { headers }
        );
        setAddNotificationModelOpen(false);
        getNotification();
        Swal.fire({
          title: "New Notification Added Successfully",
          text: "",
          icon: "success",
          confirmButtonColor: "#176b87",
        });
      } else {
        setNotificationErrMsg("block");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const sendUpdateNotification = async () => {
    var Data = { ...notificationData };
    var sendData = {
      applicationName: popoverData?.NOTIFICATION_APP,
      channel: Data?.channel,
      connectionLink: Data?.appLink,
      previousChannel: popoverData?.CHANNEL,
      process: "save",
      prvConnectionlink: popoverData?.APP_CONNECTOR_LINK,
      project: currentproject,
    };

    const headers = {
      "X-CSRFToken": cookies.csrftoken,
      "Content-Type": "application/json",
    };
    try {
      if (sendData?.connectionLink && sendData?.channel) {
        try {
          var data = await axios.post(
            "/accounts/editNotifySettings/",
            { ...sendData },
            { headers }
          );
          setAddNotificationModelOpen(false);
          getNotification();
          Swal.fire({
            title: "Notification Updated Successfully",
            text: "",
            icon: "success",
            confirmButtonColor: "#176b87",
          });
        } catch (error) {
          setAddNotificationModelOpen(false);
          Swal.fire({
            title: "Error",
            text: "Error occurred in Edit",
            icon: "error",
            confirmButtonColor: "#176b87",
          });
        }
      } else {
        setNotificationErrMsg("block");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNotificationEdit = () => {
    setAnchorEl(false);
    setNotificationData({
      appLink: popoverData?.APP_CONNECTOR_LINK,
      channel: popoverData?.CHANNEL,
      notfyAPP: popoverData?.NOTIFICATION_APP,
    });
    setNotificationStatus("Edit");
    setAddNotificationModelOpen(true);
  };

  return (
    <div className="pt-2 m-2 mainContainer ">
      {FullPageLoader ? (
        <div className="position-relative">
          <div className="backdrop"></div>
          <div className="spinner-container">
            <Spinner />
          </div>
        </div>
      ) : null}

      <Modal
        open={ModelOpen}
        onClose={() => setModelOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={modelStyle}
          //className='animated zoomIn'
        >
          {/* <h6>Create Project</h6> */}
          <table className="table table-striped">
            <thead>
              <tr>
                <th
                  style={{
                    backgroundColor: "#176b87",
                    color: "white",
                    borderTopRightRadius: "5px",
                    borderTopLeftRadius: "5px",
                  }}
                >
                  <h5>Create Project</h5>
                </th>
              </tr>
            </thead>
          </table>
          <form className="p-3">
            {step === 1 && (
              <div>
                <h5 className="fw-bold">Step 1</h5>
                <div className="d-flex justify-content-between ">
                  <label className="w-50 fw-bold">
                    Project Name <span className="spann">*</span>
                  </label>
                  <input
                    type="text"
                    name="project_name"
                    value={createProject.project_name}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="d-flex justify-content-between mt-2">
                  <label className="w-50 fw-bold">
                    Display Name <span className="spann">*</span>
                  </label>
                  <input
                    type="text"
                    name="display_name"
                    value={createProject.display_name}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="d-flex justify-content-between mt-2">
                  <label className="w-50 fw-bold">
                    Database Type <span className="spann">*</span>
                  </label>
                  <select
                    name="database_type"
                    value={createProject.database_type}
                    onChange={handleChange}
                    className="form-control"
                  >
                    <option>Select Database Type</option>
                    <option>Snowflake</option>
                    <option>Databricks</option>
                    <option>Azure Synapse</option>
                    <option>AWS RedShift</option>
                  </select>
                </div>
                <span
                  className=" spann "
                  style={{ display: `${createProjectError1}` }}
                >
                  Please fill all the required fields
                </span>
                <div className="d-flex justify-content-end mt-2">
                  <button className="btn button_positive " onClick={handleNext}>
                    Next
                  </button>
                </div>
              </div>
            )}

            {step === 2 && (
              <div>
                <h5 className="fw-bold">Step 2</h5>
                <div className="d-flex justify-content-between ">
                  <label className="w-50 fw-bold">
                    Template <span className="spann">*</span>
                  </label>
                  <select
                    name="template"
                    value={createProject.template}
                    onChange={handleChange}
                    className="form-control"
                  >
                    <option value="">_Blank</option>
                    <option value="Pharma Commercial">Pharma Commercial</option>
                  </select>
                </div>
                <span
                  className=" spann "
                  style={{ display: `${createProjectError2}` }}
                >
                  Please fill all the required fields
                </span>

                <div className="d-flex justify-content-between">
                  <div className="d-flex justify-content-end mt-2">
                    <button
                      className="btn button_warning "
                      onClick={handlePrevious}
                    >
                      Previous
                    </button>
                  </div>
                  <div className="d-flex justify-content-end mt-2">
                    <button
                      className="btn button_positive "
                      onClick={sourcesCall}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            )}

            {step === 3 && (
              <div>
                <h5 className="fw-bold">Step 3</h5>
                <div className="d-flex justify-content-around">
                  <label>
                    <input
                      type="radio"
                      name="clickRadio"
                      value="checkAll"
                      onClick={checkAll}
                      className="me-2"
                    />
                    Check All
                  </label>

                  <label>
                    <input
                      type="radio"
                      value="uncheckAll"
                      name="clickRadio"
                      onClick={uncheckAll}
                      className="me-2"
                    />
                    Uncheck All
                  </label>
                </div>

                <div className="row mt-3">
                  {sources.map((e) => {
                    return (
                      <div key={e.name} className="col-4">
                        <label>
                          <input
                            type="checkbox"
                            onChange={() => toggleSource(e.name)}
                            checked={e.isChecked}
                            className="me-2 "
                          />
                          {e.name}
                        </label>
                      </div>
                    );
                  })}
                </div>

                <div className="d-flex justify-content-between">
                  <div className="d-flex justify-content-end mt-2">
                    <button
                      className="btn button_warning "
                      onClick={handlePrevious}
                    >
                      Previous
                    </button>
                  </div>
                  <div className="d-flex justify-content-end mt-2">
                    <button
                      className="btn button_positive "
                      onClick={handleSubmit}
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              </div>
            )}
          </form>
        </Box>
      </Modal>

      <Modal
        open={confirmationModelOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modelStyle}>
          <div className="p-3">
            <p className="text-center">
              On clicking Proceed button, a new project and a project database
              will be created with the details selected earlier. Do you want to
              Proceed?
            </p>

            <label>
              <input
                type="checkbox"
                className="me-2"
                checked={isChecked}
                onChange={defaultPrjCheckBox}
              />
              Make this default project
            </label>
            <div className=" d-flex justify-content-around">
              <button
                className="btn button_warning me-5 mt-2"
                onClick={() => setConfirmationModelOpen(false)}
              >
                Cancel
              </button>
              <button
                className="btn button_positive mt-2"
                onClick={() => {
                  createNewProject();
                  setConfirmationModelOpen(false);
                  setModelOpen(false);
                }}
              >
                Proceed
              </button>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={usersModelOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={userModelStyle}>
          <div className="p-3">
            <p className="text-center">Do you want to {addRemove} the user ?</p>
            <div className=" d-flex justify-content-around">
              <button
                className="btn button_warning me-5 mt-2"
                onClick={cancelRemoveUser}
              >
                Cancel
              </button>
              <button
                className="btn button_positive mt-2"
                onClick={addRemoveUser}
              >
                Proceed
              </button>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={confirmationNotificationOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={NotificationStyle}>
          <div>
            <h5 className="text-center mt-3">
              Do you want to delete this record ?
            </h5>
            <div className=" d-flex justify-content-end mt-4">
              <button
                className="btn button_warning me-3 mt-2"
                onClick={() => setConfirmationNotificationOpen(false)}
              >
                Cancel
              </button>
              <button
                className="btn button_positive mt-2 ms-1"
                onClick={() => {
                  deleteNotification();
                }}
              >
                Proceed
              </button>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={addNotificationModelOpen}
        onClose={() => setAddNotificationModelOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={userModelStyle}>
          <div>
            <div
              className="d-flex justify-content-center align-items-center p-3"
              style={{
                backgroundColor: "#176b87",
                color: "white",
                borderTopRightRadius: "5px",
                borderTopLeftRadius: "5px",
                height: "40px",
              }}
            >
              <h5 className="text-uppercase">
                {NotificationStatus} Notification
              </h5>
            </div>
            <div className="p-3">
              <div className="row mt-4">
                <div className="col-6">
                  <label className="fw-bold">
                    Application Name <span className="spann">*</span>
                  </label>
                </div>
                <div className="col-6">
                  <select
                    className="form-control"
                    value={notificationData?.notfyAPP}
                    name="notfyAPP"
                    disabled={NotificationStatus == "Add" ? false : true}
                    onChange={handleNotificationData}
                  >
                    <option value="" selected disabled>
                      Select Application
                    </option>
                    <option value="Slack">Slack</option>
                    <option value="Teams">Teams</option>
                  </select>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6">
                  <label className="fw-bold">
                    Channel Description <span className="spann">*</span>
                  </label>
                </div>
                <div className="col-6">
                  <input
                    className="form-control"
                    value={notificationData?.channel}
                    name="channel"
                    onInput={handleNotificationData}
                    type="text"
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12">
                  <label className="fw-bold">
                    Connection Link <span className="spann">*</span>
                  </label>
                </div>
                <div className="col-12">
                  {/* <input className='form-control' value={notificationData?.appLink} name='appLink' onInput={handleNotificationData} type='text' /> */}
                  <textarea
                    className="form-control"
                    name="appLink"
                    onInput={handleNotificationData}
                    value={notificationData?.appLink}
                  ></textarea>
                </div>
              </div>
              <span
                className="spann"
                style={{ display: `${notificationErrMsg}` }}
              >
                Please fill all the required fields
              </span>
              <div className="d-flex  justify-content-end mt-4">
                {NotificationStatus == "Add" ? (
                  <button
                    className="btn button_positive"
                    onClick={() => {
                      sendNewNotification();
                    }}
                  >
                    Add
                  </button>
                ) : (
                  <button
                    className="btn button_positive"
                    onClick={() => {
                      sendUpdateNotification();
                    }}
                  >
                    Update
                  </button>
                )}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <div>
        <div className=" d-flex justify-content-between  firstDiv ">
          <label className="w-50">Default Project -</label>
          <select
            className=" me-5 form-control"
            value={defaultPrj.project_name}
            onChange={changeDefaultPrj}
          >
            <option value="">Please select</option>
            {allProjects.map((e) => (
              <option value={e.project_name}>{e.display_name}</option>
            ))}
          </select>
        </div>
        <div className=" d-flex justify-content-between mt-2 firstDiv">
          <label className="w-50">Current Project -</label>
          <select
            className=" me-5 form-control"
            value={currentPrj.project_name}
            onChange={changeCurrentPrj}
          >
            <option value="">Please select</option>
            {allProjects.map((e) => (
              <option value={e.project_name}>{e.display_name}</option>
            ))}
          </select>
        </div>
        <h6 className="mt-3 ">
          Application Database -{" "}
          <span style={{ fontWeight: "350" }}>
            ADQ_
            <span className="text-uppercase ">{currentPrj.project_name}</span>
          </span>
        </h6>
        <div className="buttonDiv">
          <button className="btn button_negative  me-5" disabled>
            Delete Project
          </button>
          <button
            className="btn button_positive"
            onClick={() => {
              setModelOpen(true);
            }}
          >
            Create Project
          </button>
          {/* <button className='btn button_negative' onClick={deleteProject} disabled={true}>Delete Project</button> */}
        </div>
      </div>
      <hr />

      <div className="">
        <Tabs selectedIndex={selectedTabIndex} onSelect={handleTabSelect}>
          <TabList className="horizontal-tabs">
            <Tab
              className={`tab-item ${
                selectedTabIndex === 0 ? "active-tab" : ""
              }`}
            >
              Target DB Settings
            </Tab>
            <Tab
              className={`tab-item ${
                selectedTabIndex === 1 ? "active-tab" : ""
              }`}
            >
              Source Settings
            </Tab>
            <Tab
              className={`tab-item ${
                selectedTabIndex === 2 ? "active-tab" : ""
              }`}
            >
              Users
            </Tab>
            <Tab
              className={`tab-item ${
                selectedTabIndex === 3 ? "active-tab" : ""
              }`}
            >
              Notification Settings
            </Tab>
          </TabList>

          <TabPanel>
            <div>
              {!connectionDataSf?.project_accessToken ? (
                <div>
                  <div className=" d-flex justify-content-between  firstDiv ">
                    <label className="w-50">
                      Database Type <span className="spann">*</span>
                    </label>
                    <input
                      type="text"
                      className="me-5 form-control"
                      value={connectionDataSf?.project_database_type}
                      onChange={handleInputData}
                      disabled={true}
                      name="database_type"
                    />
                  </div>
                  <div className=" d-flex justify-content-between mt-2 firstDiv ">
                    <label className="w-50">
                      Username <span className="spann">*</span>
                    </label>
                    <input
                      type="text"
                      className="me-5 form-control"
                      value={connectionDataSf?.project_userName}
                      onInput={handleInputData}
                      name="project_userName"
                    />
                  </div>

                  <div className="d-flex justify-content-between mt-2 firstDiv">
                    <label className="w-50">
                      <input
                        type="radio"
                        name="awsKey"
                        checked={awsKeySelected}
                        onChange={handleRadioChange}
                      />
                      AWS Key <span className="spann">*</span>
                    </label>
                    <input
                      type="text"
                      className="me-5 form-control"
                      value={
                        awsKeySelected ? connectionDataSf?.project_aws_key : ""
                      }
                      disabled={!awsKeySelected}
                      onChange={handleInputData}
                      name="project_aws_key"
                    />
                  </div>
                  <div className="d-flex justify-content-between mt-2 firstDiv">
                    <label className="w-50">
                      <input
                        type="radio"
                        name="password"
                        checked={passwordSelected}
                        onChange={handleRadioChange}
                      />
                      Password <span className="spann">*</span>
                    </label>
                    <input
                      type="text"
                      className="me-5 form-control"
                      value={
                        passwordSelected
                          ? connectionDataSf?.project_password
                          : ""
                      }
                      disabled={!passwordSelected}
                      onChange={handleInputData}
                      name="project_password"
                    />
                  </div>

                  <div className=" d-flex justify-content-between mt-2 firstDiv ">
                    <label className="w-50">
                      Account <span className="spann">*</span>
                    </label>
                    <input
                      type="text"
                      className="me-5 form-control"
                      value={connectionDataSf?.project_account}
                      onChange={handleInputData}
                      name="project_account"
                    />
                  </div>
                  <div className=" d-flex justify-content-between mt-2 firstDiv ">
                    <label className="w-50">
                      Warehouse <span className="spann">*</span>
                    </label>
                    <input
                      type="text"
                      className="me-5 form-control"
                      value={connectionDataSf?.project_warehouse}
                      onChange={handleInputData}
                      name="project_warehouse"
                    />
                  </div>
                  <div className=" d-flex justify-content-between mt-2 firstDiv ">
                    <label className="w-50">
                      Database <span className="spann">*</span>
                    </label>
                    <input
                      type="text"
                      className="me-5 form-control"
                      value={connectionDataSf?.project_database}
                      onChange={handleInputData}
                      name="project_database"
                    />
                  </div>
                  <div className=" d-flex justify-content-between mt-2 firstDiv ">
                    <label className="w-50">
                      Role <span className="spann">*</span>
                    </label>
                    <input
                      type="text"
                      className="me-5 form-control"
                      value={connectionDataSf?.project_role}
                      onChange={handleInputData}
                      name="project_role"
                    />
                  </div>
                  <div className=" d-flex justify-content-between mt-2 firstDiv ">
                    <label className="w-50">
                      Schema <span className="spann">*</span>
                    </label>
                    <input
                      type="text"
                      className="me-5 form-control"
                      value={connectionDataSf?.project_schema}
                      name="project_schema"
                      onChange={handleInputData}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <div className=" d-flex justify-content-between  firstDiv ">
                    <label className="w-50">
                      Database Type <span className="spann">*</span>
                    </label>
                    <input
                      type="text"
                      className="me-5 form-control"
                      value={connectionDataSf?.project_database_type}
                      onChange={handleInputData}
                      disabled={true}
                      name="database_type"
                    />
                  </div>
                  <div className=" d-flex justify-content-between mt-2 firstDiv ">
                    <label className="w-50">
                      Database <span className="spann">*</span>
                    </label>
                    <input
                      type="text"
                      className="me-5 form-control"
                      value={connectionDataSf?.project_database}
                      onChange={handleInputData}
                      name="project_database"
                    />
                  </div>
                  <div className=" d-flex justify-content-between mt-2 firstDiv ">
                    <label className="w-50">
                      Schema <span className="spann">*</span>
                    </label>
                    <input
                      type="text"
                      className="me-5 form-control"
                      value={connectionDataSf?.project_schema}
                      name="project_schema"
                      onChange={handleInputData}
                    />
                  </div>

                  <div className=" d-flex justify-content-between mt-2 firstDiv ">
                    <label className="w-50">
                      Server Hostname <span className="spann">*</span>
                    </label>
                    <input
                      type="text"
                      className="me-5 form-control"
                      value={connectionDataSf?.project_hostname}
                      name="project_hostname"
                      onChange={handleInputData}
                    />
                  </div>
                  <div className=" d-flex justify-content-between mt-2 firstDiv ">
                    <label className="w-50">
                      Http Path <span className="spann">*</span>
                    </label>
                    <input
                      type="text"
                      className="me-5 form-control"
                      value={connectionDataSf?.project_httpPath}
                      name="project_httpPath"
                      onChange={handleInputData}
                    />
                  </div>
                  <div className=" d-flex justify-content-between mt-2 firstDiv ">
                    <label className="w-50">
                      Access Token <span className="spann">*</span>
                    </label>
                    <input
                      type="text"
                      className="me-5 form-control"
                      value={connectionDataSf?.project_accessToken}
                      name="project_accessToken"
                      onChange={handleInputData}
                    />
                  </div>
                </div>
              )}

              <span
                className="spann mt-2"
                style={{ display: `${TargetDbErrMsg}` }}
              >
                Please fill all the required fields
              </span>

              <div className="buttonDiv2">
                <button
                  className="btn button_warning me-5"
                  onClick={testConnection}
                >
                  Test Connection
                </button>
                <button
                  className="btn button_positive "
                  onClick={saveConnection}
                >
                  Save Connection
                </button>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="mt-3 mb-3">
              {allSources.length ? (
                <SourcesDataTable
                  tableData={allSources}
                  project={currentPrj.project_name}
                  reRender={() => {
                    getAllSources();
                  }}
                />
              ) : null}
            </div>
            {currentPrj.project_name != "" && allSources.length ? (
              <Calender project={currentPrj.project_name} />
            ) : null}
          </TabPanel>
          <TabPanel>
            <div className="row">
              {usersList?.map((e) => {
                return (
                  <div key={e.username} className="col-4">
                    <label>
                      <input
                        type="checkbox"
                        onChange={() => handleUsers(e)}
                        checked={e.checked}
                        className="me-2 "
                      />
                      {e.username}
                    </label>
                  </div>
                );
              })}
            </div>
          </TabPanel>
          <TabPanel>
            <div>
              <div className="d-flex justify-content-between">
                <p>Notification Channels linked to the project:</p>
                <button
                  className="btn button_positive "
                  onClick={() => handleAddNotification()}
                >
                  Add Notification
                </button>
              </div>
              <div className="mt-4 table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Application</th>
                      <th>Channel Description </th>
                      <th>Connection link</th>
                    </tr>
                  </thead>
                  <tbody>
                    {notification?.map((ele, index) => {
                      return (
                        <tr
                          className={index === clickedRow ? "clicked-row" : ""}
                        >
                          <td
                            onClick={(e) => handleClick(e, ele, index)}
                            variant="contained"
                          >
                            {ele?.NOTIFICATION_APP}
                          </td>
                          <td
                            onClick={(e) => handleClick(e, ele, index)}
                            variant="contained"
                          >
                            {ele?.CHANNEL}
                          </td>
                          <td
                            onClick={(e) => handleClick(e, ele, index)}
                            variant="contained"
                          >
                            {ele?.APP_CONNECTOR_LINK}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center", // Center vertically
          horizontal: "right", // Align to the right side
        }}
        transformOrigin={{
          vertical: "center", // Center vertically
          horizontal: "left", // Align to the left side
        }}
        style={{ borderRadius: "5px" }}
      >
        <Typography style={popoverStyle}>
          <div className="h-100 pt-2 text-center">
            <button
              className="btn button_positive"
              onClick={() => {
                handleNotificationEdit();
              }}
            >
              {" "}
              Update Notification
            </button>
            <button
              className="btn button_warning mt-2"
              onClick={() => {
                handleIsDelete();
              }}
            >
              Delete Notification
            </button>
          </div>
        </Typography>
      </Popover>
    </div>
  );
}

export default ProjectTab;

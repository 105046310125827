import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net";
import axios from "axios";
import moment from "moment-timezone";
import { useParams } from "react-router-dom";
var initcolumn = {
  ID: ["", 1],
  Source: ["", 2],
  Table: ["", 3],
  Type: ["", 4],
  Dataarea: ["", 5],
  Details: ["", 6],
  TOTAL: ["", 7],
  MISMATCHES: ["", 8],
  Action: ["", 9],
  Status: ["", 10],
};
function IncidentTable(props) {
  const tableRef = useRef(null);
  const dataTableRef = useRef(null);
  const [incidentData, setIncidentData] = useState([]);
  const [isFilter, setIsFilter] = useState(true);
  const [columnText, setColumnText] = useState(initcolumn);
  const [clickedRow, SetClickedRow] = useState();
  const [source, setSource] = useState([]);
  const [listOfTypes, setListOfTypes] = useState([]);
  const [filterData, setFilterData] = useState({ target: { value: '' } });
  $.extend($.fn.dataTableExt.oSort, {
    "datetime-custom-pre": function (a) {
      var dateParts = a.split(/\/|, |\s|\:/);

      var month = parseInt(dateParts[0], 10);
      var day = parseInt(dateParts[1], 10);
      var year = parseInt(dateParts[2], 10);
      var hours = parseInt(dateParts[3], 10);
      var minutes = parseInt(dateParts[4], 10);

      var date = new Date(year, month - 1, day, hours, minutes);
      return date.getTime();
    },

    "datetime-custom-asc": function (a, b) {
      return a - b;
    },

    "datetime-custom-desc": function (a, b) {
      return b - a;
    },
  });
  $.extend($.fn.dataTableExt.oSort, {
    "time-based-pre": function (a) {
      var timeParts = a.split(" ");
      var totalMinutes = 0;
      for (var i = 0; i < timeParts.length; i++) {
        if (timeParts[i].includes("d")) {
          totalMinutes += parseInt(timeParts[i]) * 24 * 60; // Convert days to minutes
        } else if (timeParts[i].includes("h")) {
          totalMinutes += parseInt(timeParts[i]) * 60; // Convert hours to minutes
        } else if (timeParts[i].includes("min")) {
          totalMinutes += parseInt(timeParts[i]); // Use minutes as is
        }
      }
      return totalMinutes;
    },

    "time-based-asc": function (a, b) {
      return a - b;
    },

    "time-based-desc": function (a, b) {
      return b - a;
    },
  });
  useEffect(() => {
    if (incidentData.length) {
      if (tableRef.current && !dataTableRef.current) {
        // Only initialize the DataTable if it hasn't been initialized before
        const dataTableInstance = $(tableRef.current).DataTable({
          language: {
            searchPlaceholder: "Search records",
          },
          columnDefs: [
            { type: "time-based", targets: [6] },
            { type: "date-custom", targets: [5] }, // Apply time-based sorting to column 0
          ],
          lengthChange: false,
        });

        dataTableRef.current = dataTableInstance; // Store the DataTable instance
        if (dataTableRef.current) {
          dataTableRef.current.order([6, "asc"]).draw();
        }
      }
    }
  }, [incidentData]);

  useEffect(() => {
    setIncidentData(props.incidentData);
    setListOfTypes(props.sourceList);
    if (props.defaultSelect != null) {
      dataTableRef.current?.search(props.defaultSelect)?.draw();
      let index = incidentData.findIndex(
        (obj) => obj.incdId == props.defaultSelect
      );
      SetClickedRow(index);
    }
  }, [props]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getSourceName();
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const getSourceName = async () => {
    try {
      setSource([]);
      let data = await axios.get("/getSourceTable/");
      setSource(data.data.source);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (dataTableRef.current) {
      Object.keys(columnText).map((ele) => {
        var temp = columnText[ele];
        dataTableRef.current
          .column(temp[1] - 1)
          .search(temp[0])
          .draw();
      });
    }
  }, [columnText]);
  useEffect(() => {
    if (isFilter) {
      setColumnText(initcolumn);
    }
  }, [isFilter]);

  const handleText = (e, position) => {
    setColumnText({
      ...columnText,
      [e.target.name]: [e.target.value, position],
    });
  };
  var param = useParams();
  const handleClick = (ele, index) => {
    if (param.component != undefined) {
      window.location.href = "/Incident";
    }
    props.handleIncident(ele);
    SetClickedRow(index);
  };

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const dataParam = params.get("data");

    if (dataParam) {
      try {
        const decodedData = decodeURIComponent(dataParam);
        const jsonData = atob(decodedData);
        const dataSelect = JSON.parse(jsonData);
        const dat = dataSelect
          ? `${dataSelect.source_type}_${dataSelect.entity_name}`
          : "";
        setFilterData({ target: { value: dat } });
      } catch (error) {
        console.error("Error decoding data:", error);
      }
    }
  }, [props]);

  useEffect(() => {
    if (filterData && filterData.target.value) {
      handleText(filterData, 3);
    }
  }, [filterData.target.value]);

  const diffDate = (inputDate) => {
    const currentDate = new Date();
    const differenceInMilliseconds = currentDate - new Date(inputDate);
    const minutes = Math.floor((differenceInMilliseconds / 1000 / 60) % 60);
    const hours = Math.floor((differenceInMilliseconds / 1000 / 60 / 60) % 24);
    const days = Math.floor(differenceInMilliseconds / 1000 / 60 / 60 / 24);

    const dayStr = days ? `${days}d ` : "";
    const hoursStr = hours ? `${hours}h ` : "";
    const minutesstr = minutes ? `${minutes}min ` : "";
    return dayStr + hoursStr + minutesstr + "ago";
  };

  const convertDate = (input) => {
    const usEasternTimezone = "America/New_York";
    const date_format = "YYYY-MM-DD HH:mm:ss"; // Adjust this format as needed

    if (input && input !== "NO-DATE") {
      const newone = moment.utc(input, date_format);
      // const localTime = newone.tz(usEasternTimezone);
      const formattedLocalTime = newone.format("MM-DD-YYYY HH:mm");
      return formattedLocalTime;
    }
  };
  return (
    <div>
      <div className="d-flex justify-content-end mt-3 mb-3">
        <button
          className="btn ms-1"
          style={{ backgroundColor: "#176b87", color: "white" }}
          onClick={() => {
            setIsFilter(!isFilter);
          }}
        >
          Filter
        </button>
      </div>

      <div className="table-responsive">
        <table id="data-table-basic" class="table table-striped" ref={tableRef}>
          <thead>
            <tr className={isFilter ? "d-none" : ""}>
              <th>
                <input
                  className="form-control"
                  value={columnText.ID[0]}
                  style={{ minWidth: "85px" }}
                  name="ID"
                  onInput={(e) => handleText(e, 1)}
                  placeholder="Search"
                  type="text"
                ></input>
              </th>
              <th>
                <select
                  className="form-control"
                  value={columnText.Source[0]}
                  style={{ minWidth: "85px" }}
                  name="Source"
                  onInput={(e) => handleText(e, 2)}
                >
                  <option value="">Select</option>
                  {listOfTypes
                    ?.filter((ele) => ele !== "All")
                    ?.map((ele) => (
                      <option value={ele}>{ele}</option>
                    ))}
                </select>
              </th>
              <th>
                <select
                  className="form-control"
                  value={columnText.Table[0]}
                  style={{ minWidth: "85px" }}
                  name="Table"
                  onInput={(e) => handleText(e, 3)}
                >
                  <option value="">Select</option>
                  {source?.map((ele) => (
                    <option value={ele?.SOURCE}>{ele?.SOURCE}</option>
                  ))}
                </select>
              </th>
              <th>
                <input
                  className="form-control"
                  value={columnText.Type[0]}
                  style={{ minWidth: "85px" }}
                  name="Type"
                  onInput={(e) => handleText(e, 4)}
                  placeholder="Search"
                  type="text"
                ></input>
              </th>
              <th>
                <input
                  className="form-control"
                  value={columnText.Dataarea[0]}
                  style={{ minWidth: "85px" }}
                  name="Dataarea"
                  onInput={(e) => handleText(e, 5)}
                  placeholder="Search"
                  type="text"
                ></input>
              </th>
              <th>
                <input
                  className="form-control"
                  value={columnText.Details[0]}
                  style={{ minWidth: "85px" }}
                  name="Details"
                  onInput={(e) => handleText(e, 6)}
                  placeholder="Search"
                  type="text"
                ></input>
              </th>
              <th>
                <input
                  disabled
                  className="form-control"
                  value={columnText.TOTAL[0]}
                  style={{ minWidth: "85px" }}
                  name="TOTAL"
                  onInput={(e) => handleText(e, 7)}
                  placeholder="Search"
                  type="text"
                ></input>
              </th>
              <th>
                <input
                  className="form-control"
                  value={columnText.MISMATCHES[0]}
                  style={{ minWidth: "85px" }}
                  name="MISMATCHES"
                  onInput={(e) => handleText(e, 8)}
                  placeholder="Search"
                  type="text"
                ></input>
              </th>
              <th>
                <input
                  className="form-control"
                  value={columnText.Action[0]}
                  style={{ minWidth: "85px" }}
                  name="Action"
                  onInput={(e) => handleText(e, 9)}
                  placeholder="Search"
                  type="text"
                ></input>
              </th>
              <th>
                <input
                  className="form-control"
                  value={columnText.Status[0]}
                  style={{ minWidth: "85px" }}
                  name="Status"
                  onInput={(e) => handleText(e, 10)}
                  placeholder="Search"
                  type="text"
                ></input>
              </th>
            </tr>
            <tr>
              <th>Incident ID </th>
              <th>Type</th>
              <th>Source</th>
              <th>Table / Metric</th>
              <th>Data Area</th>
              <th>Run At</th>
              <th>Incident Age</th>
              <th>Total Records</th>
              <th>Mismatches</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {incidentData?.map((ele, index) => {
              return (
                <tr className={index === clickedRow ? "clicked-row" : ""}>
                  <td>{ele?.incdId}</td>
                  <td>{ele?.incidentTbl?.type}</td>
                  <td>{ele?.incidentTbl?.source}</td>
                  <td>{ele?.incidentTbl?.table_name}</td>
                  <td>{ele?.incidentTbl?.data_area || "-"}</td>
                  <td>{convertDate(ele?.incidentTbl?.time)}</td>
                  <td>{diffDate(ele?.incidentTbl?.time)}</td>
                  <td className="text-center">
                    {ele?.incidentTbl?.total_record_count || "-"}
                  </td>
                  <td className="text-center">
                    {ele?.incidentTbl?.error_record_count || "-"}
                  </td>
                  <td>
                    {" "}
                    <button
                      className="btn"
                      style={{ backgroundColor: "#176b87", color: "white" }}
                      onClick={() => {
                        handleClick(ele, index);
                      }}
                    >
                      Expand
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default IncidentTable;
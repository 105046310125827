import React, { useEffect, useRef, useState } from 'react'
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net';
import '../css/Home.css';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import '../css/animate.css';
import axios from 'axios';
import { Bars } from 'react-loader-spinner';
import RulesDataTable from './RulesDataTable';
import ApiClient from "./ApiClient"

function TableRules() {

  const [rulesData, setRulesData] = useState([]);
  const [rulesTblLoader, setRulesTblLoader] = useState(false);
  const [FullPageLoader, setFullPageLoader] = useState(false);

  const getRulesTable = async () => {
    setRulesTblLoader(true);
    try {
      var data = await axios.get(`/table_rules/`, {
        withCredentials: true
      });
      setRulesData(data?.data?.context?.data);
    } catch (error) {
      console.log(error);
    }
    setRulesTblLoader(false)
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      getRulesTable();
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const removeData = async (id) => {
    setFullPageLoader(true)
    try {
      var data = await axios.get(`/removeRule/${id}`, {
        withCredentials: true
      });
      setFullPageLoader(false)
      Swal.fire({title:'Raw data Rule Removed Successfully', text:'', icon:'success',
      confirmButtonColor: "#176b87",});
      getRulesTable();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      {rulesTblLoader ? <div className='mt-5 pt-5  d-flex justify-content-center align-items-center text-center'>
        <Bars type="Puff" color="#176b87" height={100} width={100} />
        <h6 >Loading . . .</h6>
      </div> :
        <RulesDataTable tableData={rulesData} handleRemove={(id) => { removeData(id) }} reRenderTable={()=>{getRulesTable()}}></RulesDataTable>
      }
    </div>
  )
}

export default TableRules
import cytoscape from 'cytoscape';
import React, { useEffect, useState, useRef } from 'react';
import tableImage from "../images/table.png";
import procedureImage from "../images/procedure.png";
import dagre from 'cytoscape-dagre';
import '../css/graphCss.css'

function Cytoscape(props) {
    cytoscape.use(dagre);
    const [graphData, setGraphData] = useState({ 'nodes': [] });
    const cyRef = useRef(null);
    const [zoomLevel, setZoomLevel] = useState(1);

    useEffect(() => {
        if (graphData['nodes']?.length) {
            const cy = cytoscape({
                container: document.getElementById('cyes'),
                boxSelectionEnabled: false,
                autounselectify: true,
                elements: graphData,
                layout: {
                    name: 'dagre', // Use the 'dagre' layout
                    nodeSep: 50,
                    edgeSep: 20,
                    rankDir: 'LR',
                    rankSep: 50,
                    minLen: function (edge) { return 10; },
                    avoidOverlap: true,
                    nodeDimensionsIncludeLabels: true,
                    directed: true,
                    fit: true,
                    padding: 50,
                },
                // zoom: 1,


                style: cytoscape.stylesheet()
                    .selector("node")
                    .css({
                        height: 40,
                        width: 40,
                        "border-radius": 6,
                        "background-fit": "cover",
                        "border-width": 0,
                        "border-opacity": 0.5,
                        content: "data(id)",
                        "font-size": "8px",
                        shape: "square",
                        color: "rgba(0, 0, 128, 0.85)",
                    })
                    .selector(".eating")
                    .css({
                        "border-color": "red",
                    })
                    .selector(".eater")
                    .css({
                        "border-width": 9,
                    })
                    .selector("edge")
                    .css({
                        width: 4,
                        "target-arrow-shape": "triangle",
                        "line-color": "#C7C6E8",
                        "target-arrow-color": "rgba(0, 0, 128, 0.85)",
                        "curve-style": "taxi",
                        "taxi-direction": "rightward",
                        content: "data(edgeName)",
                        "font-size": "8px",
                        color: "grey",
                    })
                    .selector("edge.highlighted")
                    .css({
                        "line-color": "rgba(0, 0, 128, 0.85)",
                        "target-arrow-color": "rgba(0, 0, 128, 0.85)",
                        "z-index": 1,
                    })
                    .selector('node[type="InputTable"]')
                    .css({
                        "background-color": "blue",
                        "background-image": `url(${tableImage})`,
                    })
                    .selector('node[type="JobName"]')
                    .css({
                        "background-color": "green",
                        "background-image": `url(${procedureImage})`,
                    })
                    .selector('node[type="OutputTable"]')
                    .css({
                        "background-color": "yellow",
                        "background-image": `url(${tableImage})`,
                    })
                    .selector('node[type="InputTable and OutputTable"]')
                    .css({
                        "background-color": "yellow",
                        "background-image": `url(${tableImage})`,
                    })

            });

            // Store the cytoscape instance in the ref
            cyRef.current = cy;

            cy.on('click', 'node', function (event) {
                var targetNode = event.target;
                var successorsEdges = event.target.successors();
                var predecessorsEdges = event.target.predecessors();
                var selectedNodeId = targetNode.id();

                // Remove class 'highlighted' from all elements
                cy.elements().removeClass('highlighted');

                // Add class 'highlighted' to target node
                targetNode.addClass('highlighted');

                // Highlight successors edges
                successorsEdges.addClass('highlighted');

                // Highlight predecessors edges
                predecessorsEdges.addClass('highlighted');
            });

            cy.animate(
                {
                    pan: { x: 100, y: 100 },
                    zoom: 0.5,
                },
                {
                    duration: 1000,
                }
            );
            cy.center();
            cy.fit();
        }

    }, [graphData]);

    useEffect(() => {
        setGraphData(props.lineageData)
    }, [props])

    const handleZoomIn = () => {
        if (cyRef.current) {
            const newZoomLevel = zoomLevel * 1.1;
            setZoomLevel(newZoomLevel);
            cyRef.current.zoom(newZoomLevel);
        }
    };

    const handleZoomOut = () => {
        if (cyRef.current) {
            const newZoomLevel = zoomLevel * 0.9;
            setZoomLevel(newZoomLevel);
            cyRef.current.zoom(newZoomLevel);
        }
    };

    const handleResetZoom = () => {
        if (cyRef.current) {
            setZoomLevel(0.5);
            cyRef.current.zoom(0.5);
            cyRef.current.center();
        }
    };

    const handleMoveUp = () => {
        if (cyRef.current) {
            cyRef.current.panBy({ x: 0, y: -50 }); // Adjust the y value as needed
        }
    };

    const handleMoveDown = () => {
        if (cyRef.current) {
            cyRef.current.panBy({ x: 0, y: 50 }); // Adjust the y value as needed
        }
    };

    const handleMoveLeft = () => {
        if (cyRef.current) {
            cyRef.current.panBy({ x: -50, y: 0 }); // Adjust the y value as needed
        }
    };

    const handleMoveRight = () => {
        if (cyRef.current) {
            cyRef.current.panBy({ x: 50, y: 0 }); // Adjust the y value as needed
        }
    };

    return (
        <div className=''>
            <div id="cyes" className='lineage-layout' />
            <div className='zoom-controls'>
                <button className="zoom-in" onClick={handleZoomIn}><i className="fa fa-search-plus"></i> </button>
                <button className="zoom-out" onClick={handleZoomOut}><i className="fa fa-search-minus"></i> </button>
                <button className="reset-zoom" onClick={handleResetZoom}><i className="fa fa-arrows-alt"></i></button>
            </div>


            <div className="direction-pad">
                <button className="navigate-button up-button" onClick={handleMoveUp}><i className="fa fa-angle-double-up"></i></button>
                <button className="navigate-button down-button" onClick={handleMoveDown}><i className="fa fa-angle-double-down"></i></button>
                <button className="navigate-button left-button" onClick={handleMoveLeft}><i className="fa fa-angle-double-left"></i></button>
                <button className="navigate-button right-button" onClick={handleMoveRight}><i className="fa fa-angle-double-right"></i>
                </button>

            </div>
            <div class="joystick-container">
                <div class="joystick">
                    <div class="handle"></div>
                </div>
            </div>

        </div>
    );

}
export default Cytoscape
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "react-tabs/style/react-tabs.css";
import "../css/incident.css";
import { Tab, Tabs, TabList } from "react-tabs";
import ReactECharts from "echarts-for-react";
import ArrowBackIosNewSharp from "@mui/icons-material/ArrowBackIosNewSharp";
import ArrowForwardIosSharp from "@mui/icons-material/ArrowForwardIosSharp";
import { Bars } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import { SwipeableDrawer } from "@mui/material";
import Report from "./Report";
import { Modal, Box } from "@mui/material";
import Select from "react-select";
import { Oval } from "react-loader-spinner";
import Tooltip from "@mui/material/Tooltip";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

function MetricDashboard() {
  const navigate = useNavigate();
  const [selectedTabRule, setSelectedTabRule] = useState(0);
  const [iframeData, setIframeData] = useState([]);
  const [chartData, setChartData] = useState();
  const [chartType, setChartType] = useState("line");
  const [scrollPosition, setScrollPosition] = useState(0);
  const contentRef = useRef(null);
  const [parentlist, setParentList] = useState();
  const [childlist, setChildtList] = useState();
  const [selectedParent, setSelectedParent] = useState(null);
  const [selectedChild, setSelectedChild] = useState(null);
  const [graphLoader, setGraphLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [editchart, setEditchart] = useState(false);
  const [deletechart, setdeletechart] = useState(false);
  const [success, setSuccess] = useState(false);
  const [selectedParentName, setSelectedParentName] = useState("");
  const [selectedChildName, setSelectedChildName] = useState("");
  const [tailId, setTailId] = useState();
  const [querydata, SetQueryData] = useState();
  const [xAxisOptions, setXAxisOptions] = useState([]);
  const [legendOptions, setLegendOptions] = useState([]);
  const [yAxisOptions, setYAxisOptions] = useState([]);
  const [selectedYOptions, setSelectedYOptions] = useState([]);
  const [tailname, settailname] = useState("");
  const [value, setValue] = useState(null);
  const [valuess, setValuess] = useState(null);
  const [warn, setWarn] = useState("");
  const [warning, setWarning] = useState("");
  const [warnings, setWarnings] = useState("");
  const [legend, setLegend] = useState("");
  const [chartPreviewData, setChartPreviewData] = useState();
  const [userData, setUserData] = useState({
    query: "",
    x_axis: "",
    name: "",
    chart_type: "vertical_bar",
  });

  const handleChangeYAxis = (selectedOptions) => {
    setSelectedYOptions(selectedOptions);
  };
  const handleAnalystInput = (evt) => {
    var val = evt.target.value;
    SetQueryData({
      ...querydata,
      [evt.target.name]: val,
    });
    if (evt.target.name == "x_axis") {
      console.log([
        ...yAxisOptions?.map((e) => ({
          value: e.value,
          label: e.label,
          isDisabled: e.value == val ? true : false,
        })),
      ]);
      setYAxisOptions([
        ...yAxisOptions?.map((e) => ({
          value: e.value,
          label: e.label,
          isDisabled: e.value == val ? true : false,
        })),
      ]);
    }
  };

  const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
    const [name, value] = cookie.split("=");
    acc[name] = decodeURIComponent(value);
    return acc;
  }, {});

  const headers = {
    "X-CSRFToken": cookies.csrftoken,
    "Content-Type": "application/json",
  };

  const removeStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 0,
    height: "500px",
    overflow: "scroll",
  };

  const remove = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };

  const getsource = async () => {
    try {
      var pData = await axios.get("/charts/parent-child-names/");
      console.log(pData?.data?.parent_list);
      setParentList(pData?.data?.parent_list);
      setChildtList(pData?.data?.child_dict);
      if (pData?.data?.parent_list.length > 0) {
        const initialParent = pData.data.parent_list[0]?.id;
        console.log(
          "initttt",
          initialParent,
          pData.data.child_dict[initialParent][0]?.id
        );
        setSelectedParent(initialParent);
        setSelectedChild(pData.data.child_dict[initialParent][0]?.id);
        checkParams(pData?.data?.child_dict);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getsource();
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const handleParentChange = (parent) => {
    setSelectedParent(parent?.id);
    setSelectedChild(childlist[parent?.id][0]?.id);
  };

  const handleChildChange = (child) => {
    setSelectedChild(parseInt(child, 10));
  };

  const sendData = async () => {
    setChartData();
    setGraphLoader(true);
    try {
      const payload = {
        parent_name: selectedParent,
        child_name: selectedChild,
      };
      const response = await axios.post("/charts/charts-display/", payload, {
        headers: headers,
      });
      // setChartData(response?.data?.charts);
      // console.log(response?.data?.charts);
      // setGraphLoader(false);
      if (
        response?.data?.charts?.length === 0 &&
        response?.data?.message !== "Charts data fetched successfully"
      ) {
        Swal.fire({
          title: response?.data?.message,
          icon: "error",
        });
        setGraphLoader(false);
      } else if (
        response?.data?.charts?.length === 0 &&
        response?.data?.message === "Charts data fetched successfully"
      ) {
        window.location.reload();
      } else {
        setChartData(response?.data?.charts);
        console.log(response?.data?.charts);
        setGraphLoader(false);
      }
    } catch (error) {
      setGraphLoader(false);
      console.error("Error sending POST request:", error);
    }
  };
  useEffect(() => {
    if (selectedParent !== null && selectedChild !== null) {
      console.log("ddfdfd", selectedChild, selectedParent);
      sendData();
    }
  }, [selectedParent, selectedChild]);

  useEffect(() => {
    if (parentlist && childlist) {
      // if (param.component != undefined) {
      //   if (selectedParent != param.component) {
      //     console.log(selectedParent,param);
      //     window.location.href = "/MetricDashboard";
      //   }
      // }

      const parent = parentlist.find((item) => item.id === selectedParent);
      setSelectedParentName(parent ? parent.name : "");

      const child = childlist[selectedParent]?.find(
        (item) => item.id === selectedChild
      );
      setSelectedChildName(child ? child.name : "");
    }
  }, [selectedParent, selectedChild]);

  var param = useParams();
  const checkParams = (chlids) => {
    if (param.component != undefined) {
      setSelectedChild(chlids[parseInt(param.component)]?.[0]?.id);
      setSelectedParent(parseInt(param.component));
    }
  };

  useEffect(() => {
    console.log("Selected Tab Index on page load:", selectedTabRule);
    console.log(
      "REPORT_NAME on page load:",
      iframeData[selectedTabRule]?.REPORT_NAME
    );
  }, [selectedTabRule, iframeData]);

  const handleTabRule = (index) => {
    console.log(index);
    setSelectedTabRule(index);
  };
  const getDashboard = async () => {
    try {
      var data = await axios.get("/getPowerBIReport/metric_dashboard");
      setIframeData(data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    // getDashboard();
    console.log("index", selectedTabRule?.REPORT_NAME);
  }, []);

  const handleChartTypeChange = (event) => {
    setChartType(event.target.value);
  };

  const calculateMaxScroll = () => {
    if (
      contentRef.current &&
      contentRef.current.offsetWidth &&
      contentRef != null
    ) {
      const contentWidth = contentRef.current.offsetWidth;
      const containerWidth = contentRef.current.parentElement.offsetWidth;
      const maxScroll = contentWidth - containerWidth;
      return maxScroll >= 0 ? maxScroll : 0;
    }
    return 0;
  };

  const scrollLeft = () => {
    if (contentRef.current) {
      const maxScroll = calculateMaxScroll();
      setScrollPosition(Math.max(scrollPosition - 450, 0));
    }
  };

  const scrollRight = () => {
    if (contentRef.current) {
      const maxScroll = calculateMaxScroll();
      setScrollPosition(Math.min(scrollPosition + 450, maxScroll));
    }
  };

  // Recalculate max scroll on resize
  useEffect(() => {
    const handleResize = () => {
      if (contentRef.current) {
        const maxScroll = calculateMaxScroll();
        setScrollPosition(Math.min(scrollPosition, maxScroll));
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [scrollPosition]);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setUserData({
      query: "",
      x_axis: "",
      name: "",
      chart_type: "vertical_bar",
    });
    setXAxisOptions([]);
    setYAxisOptions([]);
    setLegendOptions([]);
    setSelectedYOptions([]);
    setValue(null);
    setValuess(null);
    setSuccess(false);
    setWarnings("");
    setLegend("");
    setChartPreviewData();
    setIsOpen(open);
  };

  const handleEditClose = () => setEditchart(false);
  const handleDeleteClose = () => setdeletechart(false);

  const handleDeletechart = async (id) => {
    setGraphLoader(true);
    try {
      const payload = {
        tile_id: tailId,
        is_enabled: "False",
      };
      const response = await axios.post(
        "/charts/chart-enable-disable/",
        payload,
        {
          headers: headers,
        }
      );
      console.log(response?.data?.charts);
      sendData();
      setdeletechart(false);
      setTailId();
      Swal.fire({
        title: "Chart Disabled",
        text: "",
        icon: "success",
        confirmButtonColor: "#176b87",
      });
    } catch (error) {
      setGraphLoader(false);
      setdeletechart(false);
      setTailId();
      console.error("Error sending POST request:", error);
    }
  };

  const getchartEdit = async (id) => {
    setSuccess(false);
    setWarn("");
    setWarning("");
    try {
      var pData = await axios.get(`/charts/edit-chart/?tile_id=${id}`);
      SetQueryData(pData?.data?.tile_data);
      var column_list = pData?.data?.tile_data?.column_names;
      setXAxisOptions([...column_list]);
      setLegendOptions([...column_list]);
      setYAxisOptions([
        ...column_list?.map((e) => ({
          value: e,
          label: e,
          isDisabled: e == pData?.data?.tile_data?.x_axis ? true : false,
        })),
      ]);
      setSelectedYOptions([
        ...pData?.data?.tile_data?.y_axis?.map((e) => ({ value: e, label: e })),
      ]);
      console.log(pData?.data?.tile_data);
    } catch (error) {
      console.log(error);
    }
  };

  const handlesave = async () => {
    let validationTemp = { ...querydata };
    delete validationTemp.legend;
    const emptyUserDataKeys = Object.keys(validationTemp)?.filter(
      (key) => querydata[key] === ""
    );
    if (emptyUserDataKeys.length > 0) {
      setWarning("Please fill out all user data fields");
      return;
    }
    if (selectedYOptions.length === 0) {
      setWarning("Please select at least one option for Y axis");
      return;
    }

    try {
      const queries = {
        Source: selectedParentName,
        tile_id: tailId,
        Child: selectedChildName,
        ...querydata,
        y_axis: selectedYOptions?.map((option) => option.value),
        name: tailname,
      };
      const response = await axios.post("/charts/chart-data-load/", queries, {
        headers: headers,
      });
      const pData = response?.data?.column_list;
      setEditchart(false);
      Swal.fire({
        title: "Successfully Updated",
        icon: "success",
      });
      sendData();
    } catch (error) {
      setXAxisOptions([]);
      setYAxisOptions([]);
      setLegendOptions([]);
      setSelectedYOptions([]);
      setEditchart(false);
      console.error("Error submitting form:");
      Swal.fire({
        title: "Something went wrong. Please try again later",
        icon: "error",
      });
    }
  };

  const handelqueries = async () => {
    setSuccess(false);
    setWarn("");
    setWarning("");
    if (querydata?.query === "") {
      setSuccess(false);
      setWarning("Please Enter the Query");
    } else {
      setGraphLoader(true);
      try {
        const query = {
          query: querydata?.query,
        };
        setXAxisOptions([]);
        setLegendOptions([]);
        setYAxisOptions([]);
        const response = await axios.post("/charts/query-execution/", query, {
          headers: headers,
        });
        const pData = response?.data?.column_list;
        setXAxisOptions([...pData]);
        setLegendOptions([...pData]);
        setYAxisOptions([...pData?.map((e) => ({ value: e, label: e }))]);
        setSelectedYOptions([]);
        SetQueryData({
          ...querydata,
          x_axis: "",
          legend: "",
          chart_type: "vertical_bar",
        });
        setGraphLoader(false);
        setSuccess(false);
        setWarn("Query check, ok");
        setWarning("");
      } catch (error) {
        setGraphLoader(false);
        setSuccess(false);
        setWarn("");
        setWarning(error?.response?.data?.error || "An error occurred");
        console.error("Error submitting form:", error?.response?.data?.error);
      }
    }
  };

  const downloadCSV = (data) => {
    let csvContent = "";
    if (!data || !data.data || !data.data.xAxis || !data.data.yAxis) {
      console.error("Invalid data format. Missing xAxis or yAxis.");
      return;
    }

    if (data?.chartType === "db") {
      console.log("1", csvContent);
      const xAxisLength = data?.data?.xAxis?.length;
      const yAxisLength = data?.data?.yAxis[0]?.data?.length;
      csvContent =
        "data:text/csv;charset=utf-8," +
        "xAxis," +
        data?.data?.yAxis?.map((y) => y.name)?.join(",") +
        "\n";

      for (let i = 0; i < xAxisLength; i++) {
        const rowData = [data.data.xAxis[i]];
        for (let j = 0; j < yAxisLength; j++) {
          rowData.push(data?.data?.yAxis[j]?.data[i]);
        }
        csvContent += rowData.join(",") + "\n";
      }
    } else if (data?.chartType !== "db") {
      console.log("2", csvContent);
      csvContent = "data:text/csv;charset=utf-8,";
      csvContent += `${data.data.xAxis.join(",")}\n`;
      csvContent += `${data.data.yAxis.join(",")}`;
    }

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", data?.heading);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="p-4 mt-5 h-100">
      <div className="w-100 d-flex justify-content-end ">
        <button
          className="btn button_positive mb-2"
          onClick={() => {
            setIsOpen(true);
            setXAxisOptions([]);
            setYAxisOptions([]);
            setLegendOptions([]);
            setSelectedYOptions([]);
            setValue(null);
            setValuess(null);
            setSuccess(false);
          }}
        >
          Add Report
        </button>
      </div>
      <div className="card adq-card p-3">
        {parentlist?.length > 0 ? (
          <>
            {" "}
            <div className="overallscroll">
              <span onClick={scrollLeft} className="arrowalign">
                <ArrowBackIosNewSharp />
              </span>

              <div className="tabsscroll">
                <div className="scrollheader" />
                <div
                  ref={contentRef}
                  style={{
                    display: "inline-block",
                    transition: "transform 0.3s ease",
                    transform: `translateX(-${scrollPosition}px)`,
                  }}
                >
                  {" "}
                  <Tabs
                    selectedIndex={parentlist?.findIndex(
                      (item) => item.id == selectedParent
                    )}
                  >
                    <TabList className="horizontal-tabs pb-3">
                      {parentlist?.map((parent, index) => (
                        <Tab
                          key={parent?.id}
                          className={`tab-item ${
                            selectedParent === parent?.id ? "active-tab" : ""
                          }`}
                          onClick={() => handleParentChange(parent)}
                        >
                          {parent?.name}
                        </Tab>
                      ))}
                    </TabList>
                  </Tabs>
                </div>
              </div>

              <span onClick={scrollRight} className="arrowalign">
                <ArrowForwardIosSharp />
              </span>
            </div>
            <div className="mb-2 d-flex ms-auto w-100 justify-content-end">
              <div className="mt-1 mx-2">
                <span style={{ fontSize: "16px", fontWeight: "700" }}>
                  {" "}
                  Displaying page :
                </span>
              </div>
              <div style={{ width: "15%" }}>
                {" "}
                <select
                  className="form-control"
                  value={selectedChild}
                  onChange={(e) => handleChildChange(e.target.value)}
                >
                  {selectedParent &&
                    childlist[selectedParent]?.map((child) => (
                      <option key={child?.id} value={child?.id}>
                        {child?.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </>
        ) : (
          <h5
            className="text-center"
            style={{
              color: "#000000a6",
              position: "relative",
              top: "35vh",
            }}
          >
            No Reports Found
          </h5>
        )}
        <div
          className={chartData?.length >= 1 ? "" : "row"}
          style={{
            height: "70vh",
            overflow: "auto",
            display: "block !important",
          }}
        >
          {" "}
          <div className="m-auto d-flex justify-content-center">
            {" "}
            {graphLoader && (
              <div className="">
                {" "}
                <Bars type="Puff" color="#176b87" height={70} width={100} />
                <h6 className="mt-2 ms-3">Loading . . .</h6>
              </div>
            )}
          </div>
          <div className="row w-100">
            {chartData &&
              chartData?.length !== 0 &&
              chartData.map((chart, index) => (
                <div
                  className={chartData?.length <= 2 ? "col-md-12" : "col-md-6"}
                  key={index}
                  style={{ marginBottom: "20px" }}
                >
                  {chart?.data?.xAxis?.length === 0 ? (
                    <div
                      style={{
                        padding: "1%",
                        border: "2px solid whitesmoke",
                        backgroundColor: "white",
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#000000a6",
                      }}
                    >
                      <span>{chart?.heading}</span>
                      <div className="mt-5">
                        {" "}
                        <h3 className="text-center" style={{ color: "#ccc" }}>
                          No Data Found
                        </h3>
                      </div>
                    </div>
                  ) : (
                    <ReactECharts
                      style={{
                        padding: "1%",
                        border: "2px solid whitesmoke",
                        backgroundColor: "white",
                      }}
                      option={{
                        color: [
                          "#0bb4ff",
                          "#50e991",
                          "#e6d800",
                          "#9b19f5",
                          "#00bfa0",
                          "#ffa300",
                          "#dc0ab4",
                          "#b3d4ff",
                        ],
                        title: {
                          text: chart?.heading,
                          textStyle: {
                            fontSize: 15,
                          },
                        },
                        tooltip: {
                          trigger: "axis",
                          axisPointer: {
                            type: "shadow",
                          },
                        },
                        toolbox: {
                          feature: {
                            dataView: {
                              show: true,
                              readOnly: false,
                              title: "Data View",
                              lang: ["Data View", "Close", "Refresh"],
                              optionToContent: function (opt) {
                                let axisData =
                                  chart?.graphType === "horizontal_bar"
                                    ? opt.yAxis[0].data
                                    : opt.xAxis[0].data;
                                let series = opt.series;

                                let table =
                                  '<table class="table" style="width:100%;";><thead><tr><th style="text-align: start;"></th>';

                                for (let i = 0, l = series.length; i < l; i++) {
                                  table += `<th style="text-align: start !important;">${
                                    series[i]?.name ? series[i].name : ""
                                  }</th>`;
                                }

                                table += "</tr></thead><tbody>";

                                for (
                                  let i = 0, l = axisData?.length;
                                  i < l;
                                  i++
                                ) {
                                  table += "<tr>";
                                  table += `<td style="boder"><span style="margin-left:2%">${axisData[i]}</span></td>`;
                                  for (
                                    let j = 0, k = series.length;
                                    j < k;
                                    j++
                                  ) {
                                    let dataValue = series[j].data[i];
                                    // Replace null with "No data"
                                    dataValue =
                                      dataValue === null
                                        ? "No data"
                                        : dataValue;
                                    table += `<td>${dataValue}</td>`;
                                  }
                                  table += "</tr>";
                                }

                                table += "</tbody></table>";
                                return table;
                                // let yAxisData = opt.yAxis[0].data; // Extracting yAxis data
                                // let series = opt.series;
                                // let table =
                                //   '<table class="table" style="width:100%;"><thead><tr><th style="text-align: start;"></th>';

                                // for (let i = 0, l = series.length; i < l; i++) {
                                //   table += `<th style="text-align: start !important;">${
                                //     series[i]?.name ? series[i].name : ""
                                //   }</th>`;
                                // }

                                // table += "</tr></thead><tbody>";

                                // for (
                                //   let i = 0, l = yAxisData?.length;
                                //   i < l;
                                //   i++
                                // ) {
                                //   table += "<tr>";
                                //   table += `<td style="boder"><span style="margin-left:2%">${yAxisData[i]}</span></td>`;
                                //   for (
                                //     let j = 0, k = series.length;
                                //     j < k;
                                //     j++
                                //   ) {
                                //     let dataValue = series[j].data[i];
                                //     // Replace null with "No data"
                                //     dataValue =
                                //       dataValue === null
                                //         ? "No data"
                                //         : dataValue;
                                //     table += `<td>${dataValue}</td>`;
                                //   }
                                //   table += "</tr>";
                                // }

                                // table += "</tbody></table>";
                                // return table;
                              },
                            },

                            magicType: { show: true, type: ["line", "bar"] },

                            saveAsImage: { show: true },
                            myTest3: {
                              show: true,
                              title: "Export",
                              icon: 'path://"M15.608,6.262h-2.338v0.935h2.338c0.516,0,0.934,0.418,0.934,0.935v8.879c0,0.517-0.418,0.935-0.934,0.935H4.392c-0.516,0-0.935-0.418-0.935-0.935V8.131c0-0.516,0.419-0.935,0.935-0.935h2.336V6.262H4.392c-1.032,0-1.869,0.837-1.869,1.869v8.879c0,1.031,0.837,1.869,1.869,1.869h11.216c1.031,0,1.869-0.838,1.869-1.869V8.131C17.478,7.099,16.64,6.262,15.608,6.262z M9.513,11.973c0.017,0.082,0.047,0.162,0.109,0.226c0.104,0.106,0.243,0.143,0.378,0.126c0.135,0.017,0.274-0.02,0.377-0.126c0.064-0.065,0.097-0.147,0.115-0.231l1.708-1.751c0.178-0.183,0.178-0.479,0-0.662c-0.178-0.182-0.467-0.182-0.645,0l-1.101,1.129V1.588c0-0.258-0.204-0.467-0.456-0.467c-0.252,0-0.456,0.209-0.456,0.467v9.094L8.443,9.553c-0.178-0.182-0.467-0.182-0.645,0c-0.178,0.184-0.178,0.479,0,0.662L9.513,11.973z"',
                              onclick: function () {
                                console.log("save", chart);
                                downloadCSV(chart);
                              },
                            },
                            myTest: {
                              show: true,
                              title: "Edit",
                              icon: 'path://"M14.078 7.061l2.861 2.862-10.799 10.798-3.584.723.724-3.585 10.798-10.798zm0-2.829l-12.64 12.64-1.438 7.128 7.127-1.438 12.642-12.64-5.691-5.69zm7.105 4.277l2.817-2.82-5.691-5.689-2.816 2.817 5.69 5.692z', // Path to your custom icon
                              onclick: function () {
                                settailname(chart?.heading);
                                getchartEdit(chart?.tile_id);
                                setTailId(chart?.tile_id);
                                setEditchart(true);
                              },
                            },
                            myTest2: {
                              show: true,
                              title: "Disable",
                              icon: 'path://"M17.114,3.923h-4.589V2.427c0-0.252-0.207-0.459-0.46-0.459H7.935c-0.252,0-0.459,0.207-0.459,0.459v1.496h-4.59c-0.252,0-0.459,0.205-0.459,0.459c0,0.252,0.207,0.459,0.459,0.459h1.51v12.732c0,0.252,0.207,0.459,0.459,0.459h10.29c0.254,0,0.459-0.207,0.459-0.459V4.841h1.511c0.252,0,0.459-0.207,0.459-0.459C17.573,4.127,17.366,3.923,17.114,3.923M8.394,2.886h3.214v0.918H8.394V2.886z M14.686,17.114H5.314V4.841h9.372V17.114z M12.525,7.306v7.344c0,0.252-0.207,0.459-0.46,0.459s-0.458-0.207-0.458-0.459V7.306c0-0.254,0.205-0.459,0.458-0.459S12.525,7.051,12.525,7.306M8.394,7.306v7.344c0,0.252-0.207,0.459-0.459,0.459s-0.459-0.207-0.459-0.459V7.306c0-0.254,0.207-0.459,0.459-0.459S8.394,7.051,8.394,7.306', // Path to your custom icon
                              onclick: function () {
                                setTailId(chart?.tile_id);
                                setdeletechart(true);
                              },
                            },
                          },
                        },
                        legend: {
                          // Try 'horizontal'
                          type: "scroll",
                          orient: "horizontal",
                          // right: 10,
                          padding: [26, 26, 26, 26],
                          data: chart?.xAxis,
                          icon: "circle",
                        },

                        grid: {
                          top: 80,
                          bottom: 20,
                          left: 10,
                          right: 10,
                          containLabel: true,
                        },
                        xAxis: {
                          type:
                            chart?.graphType === "horizontal_bar"
                              ? "value"
                              : "category",
                          data:
                            chart?.graphType === "horizontal_bar"
                              ? null
                              : chart?.data?.xAxis,
                          axisLabel: {
                            rotate:
                              chart?.graphType === "horizontal_bar"
                                ? undefined
                                : 0,
                          },
                        },
                        yAxis: {
                          type:
                            chart?.graphType === "horizontal_bar"
                              ? "category"
                              : "value",
                          data:
                            chart?.graphType === "horizontal_bar"
                              ? chart?.data?.xAxis
                              : null,
                          axisLabel: {
                            rotate:
                              chart?.graphType === "horizontal_bar"
                                ? 0
                                : undefined,
                          },
                        },
                        series:
                          chart?.chartType === "db"
                            ? chart.data.yAxis.map(
                                (seriesData, seriesIndex) => ({
                                  name: seriesData?.name,
                                  type:
                                    seriesData?.type === "vertical_bar" ||
                                    seriesData?.type === "horizontal_bar"
                                      ? "bar"
                                      : "line",
                                  data: seriesData.data,
                                  barGap: "0%",
                                  showSymbol: false,
                                })
                              )
                            : [
                                {
                                  data: chart?.data?.yAxis,
                                  type:
                                    chart?.chartType === "vertical_bar" ||
                                    chart?.chartType === "horizontal_bar"
                                      ? "bar"
                                      : "line",
                                  areaStyle: {},
                                  showSymbol: false,
                                },
                              ],
                      }}
                    />
                  )}
                </div>
              ))}
            {chartData?.length === 0 && (
              <>
                <div
                  style={{
                    position: "relative",
                    top: "35%",
                    color: "#a3a2a2",
                  }}
                >
                  <h3 className="text-center">No Data Found</h3>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <SwipeableDrawer
        anchor="right"
        open={isOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        PaperProps={{ style: { width: "50%" } }}
        transitionDuration={1000}
      >
        <Report
          setIsOpen={setIsOpen}
          setUserData={setUserData}
          setXAxisOptions={setXAxisOptions}
          setYAxisOptions={setYAxisOptions}
          setLegendOptions={setLegendOptions}
          setSelectedYOptions={setSelectedYOptions}
          setValue={setValue}
          setValuess={setValuess}
          value={value}
          valuess={valuess}
          userData={userData}
          xAxisOptions={xAxisOptions}
          yAxisOptions={yAxisOptions}
          legendOptions={legendOptions}
          selectedYOptions={selectedYOptions}
          setSuccess={setSuccess}
          success={success}
          warnings={warnings}
          setWarnings={setWarnings}
          legend={legend}
          setLegend={setLegend}
          setChartPreviewData={setChartPreviewData}
          chartPreviewData={chartPreviewData}
          selectedParent={selectedParent}
          setSelectedParentId={setSelectedParent}
        />
      </SwipeableDrawer>

      <Modal
        open={editchart}
        onClose={handleEditClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        visible={true}
      >
        <Box sx={removeStyle}>
          <table className="table table-striped">
            <thead>
              <tr>
                <th
                  style={{
                    backgroundColor: "#176b87",
                    color: "white",
                    borderTopRightRadius: "5px",
                    borderTopLeftRadius: "5px",
                  }}
                >
                  <div className="d-flex justify-content-between  align-items-center mb-2">
                    <div></div>
                    <div className="text-center text-uppercase ">
                      <h6 className="mt-2 ">Edit QC Report</h6>
                    </div>
                    <i
                      class="fa-solid fa-x"
                      onClick={() => setEditchart(false)}
                      style={{ cursor: "pointer" }}
                    ></i>
                  </div>
                </th>
              </tr>
            </thead>
          </table>
          <div className=" mainContainer ">
            <div className=" p-3   h-100">
              <div className="  mt-2 row">
                {" "}
                <div className="col-md-3">
                  {" "}
                  <label>
                    Report Name <span className="spann">*</span>
                  </label>
                </div>
                <div className="col-md-9">
                  {" "}
                  <input
                    type="text"
                    style={{ width: "100%" }}
                    className=" form-control"
                    value={selectedParentName}
                    name="name"
                    // onChange={(e) => handleAnalystInput(e)}
                    disabled
                  />
                </div>
              </div>
              <div className="row mt-2  ">
                {" "}
                <div className="col-md-3">
                  <label>
                    Page Name <span className="spann">*</span>
                  </label>
                </div>
                <div className="col-md-9">
                  {" "}
                  <input
                    type="text"
                    style={{ width: "100%" }}
                    className=" form-control"
                    value={selectedChildName}
                    name="name"
                    // onChange={(e) => handleAnalystInput(e)}
                    disabled
                  />
                </div>
              </div>
              <div className="row mt-2  ">
                {" "}
                <div className="col-md-3">
                  <label>
                    Graph Name <span className="spann">*</span>
                  </label>
                </div>
                <div className="col-md-9">
                  {" "}
                  <input
                    type="text"
                    style={{ width: "100%" }}
                    className=" form-control"
                    value={tailname}
                    name="name"
                    // onChange={(e) => handleAnalystInput(e)}
                    disabled
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <div>
                  <label>
                    Query <span className="spann">*</span>
                  </label>
                </div>
                <div className="d-flex">
                  {success && (
                    <div className="ms-auto me-2  mt-1">
                      <span style={{ color: "red" }}>Please Run the Query</span>
                    </div>
                  )}
                  {warn && (
                    <div className="ms-auto me-2  mt-1">
                      <span style={{ color: "green" }}>{warn}</span>
                    </div>
                  )}
                  {!graphLoader ? (
                    <>
                      {" "}
                      <Tooltip title="Run your Query" arrow>
                        <PlayArrowIcon
                          className="me-2 ms-auto fs-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => handelqueries()}
                        />
                      </Tooltip>
                    </>
                  ) : (
                    <div className=" me-2 mb-2">
                      {" "}
                      <Oval
                        visible={true}
                        height="30"
                        width="80"
                        color="#9284fc"
                        secondaryColor="#b5b5b6"
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </div>
                  )}
                </div>
              </div>
              <textarea
                type="text"
                rows="6"
                className="me-2 form-control"
                value={querydata?.query}
                name="query"
                onChange={(e) => {
                  handleAnalystInput(e);
                  setXAxisOptions([]);
                  setYAxisOptions([]);
                  setSelectedYOptions([]);
                  setLegendOptions([]);
                  setSuccess(true);
                  setWarn("");
                }}
              />
              {warning && (
                <div className="ms-auto me-2  mt-1">
                  <span style={{ color: "red" }}>{warning}</span>
                </div>
              )}

              <div className="row mt-2  ">
                <div className="col-md-3">
                  <label>
                    X Axis Label <span className="spann">*</span>
                  </label>
                </div>
                <div className="col-md-9">
                  <select
                    type="text"
                    className="me-5 form-control"
                    value={querydata?.x_axis || ""}
                    name="x_axis"
                    onChange={(e) => handleAnalystInput(e)}
                  >
                    <option value="">Please select</option>
                    {xAxisOptions.map((e) => {
                      return (
                        <option
                          value={e}
                          disabled={selectedYOptions.some(
                            (option) => option.value === e
                          )}
                        >
                          {e}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <>
                <div className=" row mt-2  ">
                  <div className="col-md-3">
                    <label>
                      Y Axis Label <span className="spann">*</span>
                    </label>
                  </div>
                  <div className="col-md-9">
                    <Select
                      isMulti
                      className="form-control me-5 px-0"
                      options={yAxisOptions}
                      value={selectedYOptions}
                      onChange={(e) => {
                        handleChangeYAxis(e);
                        if (selectedYOptions?.length <= 1) {
                          SetQueryData({ ...querydata, legend: "" });
                        }
                      }}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          border: "1px solid #dee2e6",
                          width: "100%",
                        }),
                        container: (provided) => ({
                          ...provided,
                          border: "none",
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="row mt-2  ">
                  <div className="col-md-3">
                    <label>Display Legend </label>
                  </div>
                  <div className="col-md-9">
                    <select
                      type="text"
                      className="form-control"
                      value={querydata?.legend}
                      name="legend"
                      onChange={(e) => handleAnalystInput(e)}
                      disabled={selectedYOptions.length > 1}
                    >
                      <option value="">Please select</option>
                      {legendOptions.map((e) => {
                        return (
                          <option
                            value={e}
                            disabled={
                              selectedYOptions.some(
                                (option) => option.value === e
                              ) || e == querydata?.x_axis
                            }
                          >
                            {e}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="row mt-2  ">
                  <div className="col-md-3">
                    <label>
                      Chart Type <span className="spann">*</span>
                    </label>
                  </div>
                  <div className="col-md-9">
                    <select
                      type="text"
                      className="me-5 form-control"
                      value={querydata?.chart_type}
                      name="chart_type"
                      onChange={(e) => handleAnalystInput(e)}
                    >
                      <option value="horizontal_bar">Horizontal Bar</option>
                      <option value="vertical_bar">Vertical Bar</option>
                      <option value="line">Line</option>
                    </select>
                  </div>
                </div>
              </>

              <div className="mt-2 w-100 d-flex justify-content-end ">
                <button
                  className="btn button_positive me-2 mt-2"
                  onClick={() => {
                    handlesave();
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={deletechart}
        onClose={handleDeleteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={remove}>
          <h5>Do you want to disable this Graph?</h5>
          <div className="d-flex  justify-content-end">
            <button
              className="  mx-1 btn button_warning"
              onClick={() => {
                setdeletechart(false);
              }}
            >
              No
            </button>
            <button
              className=" mx-1 btn button_positive"
              onClick={() => {
                handleDeletechart();
                setdeletechart(false);
              }}
            >
              Yes
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default MetricDashboard;

import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net';
import '../css/Home.css';
import moment from 'moment-timezone';
import { Tab } from 'react-tabs';
var initcolumn = {
    Source: ["", 1],
    RunAt: ["", 2],
    TableName: ["", 3],
    TestName: ["", 4],
    Status: ["", 5],
    Discrepancy: ["", 6]
}
var metricColumn = {
    Source: ["", 1],
    RunAt: ["", 2],
    TableName: ["", 3],
    TestName: ["", 4],
    Status: ["", 5],
    L1_VALUE: ["", 6],
    L2_VALUE: ["", 7],
    L3_VALUE: ["", 8]
  }

function SourceTableView(props) {
    const tableRef = useRef(null);
    const dataTableRef = useRef(null);
    const [isFilter, setIsFilter] = useState(true)
    const [columnText, setColumnText] = useState(initcolumn);
    const [metricColumnText, setMetricColumnText] = useState(metricColumn);
    useEffect(() => {
        if (tableRef.current && !dataTableRef.current) {
            // Only initialize the DataTable if it hasn't been initialized before
            $.extend($.fn.dataTableExt.oSort, {
                "datetime-custom-pre": function (a) {
                    var dateParts = a.split(/\/|, |\s|\:/);

                    var month = parseInt(dateParts[0], 10);
                    var day = parseInt(dateParts[1], 10);
                    var year = parseInt(dateParts[2], 10);
                    var hours = parseInt(dateParts[3], 10);
                    var minutes = parseInt(dateParts[4], 10);

                    var date = new Date(year, month - 1, day, hours, minutes);
                    return date.getTime();
                },

                "datetime-custom-asc": function (a, b) {
                    return a - b;
                },

                "datetime-custom-desc": function (a, b) {
                    return b - a;
                }
            });
            const dataTableInstance = $(tableRef.current).DataTable({
                pageLength: 5,
                lengthChange: false,
                columnDefs: [
                    { type: 'date-custom', targets: [1] } // Apply time-based sorting to column 0
                ],
                language: {
                    searchPlaceholder: "Search records",
                }
            });

            dataTableRef.current = dataTableInstance; // Store the DataTable instance
            if (dataTableRef.current) {
                dataTableRef.current.order([1, 'asc']).draw();
            }
        }

    }, []);

    useEffect(() => {
        if (isFilter) {
            setColumnText(initcolumn)
        }
    }, [isFilter])

    useEffect(() => {

        Object.keys(columnText)?.map((ele) => {
            var temp = columnText[ele]
            dataTableRef.current.column(temp[1] - 1).search(temp[0]).draw();
        })

    }, [columnText])

    const handleText = (e, position) => {
        console.log((props?.ruleType,"props?.ruleType"));
        if (props?.ruleType !=="Metric"){
            setColumnText({
                ...columnText,
                [e.target.name]: [e.target.value, position],
            });
        }
        else{
            setMetricColumnText({
                ...metricColumnText,
                [e.target.name]: [e.target.value, position]
            });
    }}
    const convertDate = (input) => {
        const usEasternTimezone = 'America/New_York';
        const date_format = 'YYYY-MM-DD HH:mm:ss'; // Adjust this format as needed

        if (input && input !== 'NO-DATE') {
            const newone = moment.utc(input, date_format);
            const localTime = newone.tz(usEasternTimezone);
            const formattedLocalTime = localTime.format('MM-DD-YYYY HH:mm');
            return formattedLocalTime;
        }
    }

    
    useEffect(() => {
        var table = props?.tableData;
        dataTableRef.current.clear().draw();
        if (props?.ruleType === "Metric"){
            table?.map((ele,index)=>{
                var temp = "";
                if(ele.inc_id != null){
                 temp=`<tr style="cursor: pointer;" onclick={window.location.href="/Incident/${ele?.inc_id}"}>
                <td >${ele?.SOURCE}</td>
                <td  >${convertDate(ele?.DATE_UPDATED)} </td>
                <td >${ele?.TABLE_NAME}</td>
                <td >${ele?.QC_TYPE}</td>
                <td ><span style="${ele.STATUS === 'pass' ? 'background-color: rgb(180, 239, 211); color: black;' : ele.STATUS === 'fail' ? 'background-color: rgb(249, 190, 190); color: black' : 'background-color: rgb(252, 240, 183); color: black'}">${ele?.STATUS == 'pass' ? 'PASSED' : ele?.STATUS == 'fail' ? 'FAILED' : 'WARNING'}</span></td>
                <td >${ele?.L1_VALUE}</td>
                <td >${ele?.L2_VALUE}</td>
                <td >${ele?.L3_VALUE}</td>
            </tr>`;
                }else{
                     temp=`<tr>
                    <td >${ele?.SOURCE}</td>
                    <td  >${convertDate(ele?.DATE_UPDATED)} </td>
                    <td >${ele?.TABLE_NAME}</td>
                    <td >${ele?.QC_TYPE}</td>
                    <td ><span style="${ele.STATUS === 'pass' ? 'background-color: rgb(180, 239, 211); color: black;' : ele.STATUS === 'fail' ? 'background-color: rgb(249, 190, 190); color: black' : 'background-color: rgb(252, 240, 183); color: black'}">${ele?.STATUS == 'pass' ? 'PASSED' : ele?.STATUS == 'fail' ? 'FAILED' : 'WARNING'}</span></td>
                    <td >${ele?.L1_VALUE}</td>
                    <td >${ele?.L2_VALUE}</td>
                    <td >${ele?.L3_VALUE}</td>
                </tr>`;
                }
                dataTableRef.current.row.add($(temp)).draw();
            })
        }
        else{
        table?.map((ele,index)=>{
            var temp = "";
        //     if(ele.inc_id != null){
        //      temp=`<tr style="cursor: pointer;" onclick={window.location.href="/Incident/${ele?.inc_id}"}>
        //     <td >${ele?.data_source}</td>
        //     <td  >${convertDate(ele?.time)} </td>
        //     <td >${ele?.table_name}</td>
        //     <td >${ele?.test_name}</td>
        //     <td ><span style="${ele.status === 'pass' ? 'background-color: rgb(180, 239, 211); color: black;' : ele.status === 'fail' ? 'background-color: rgb(249, 190, 190); color: black' : 'background-color: rgb(252, 240, 183); color: black'}">${ele?.status == 'pass' ? 'PASSED' : ele?.status == 'fail' ? 'FAILED' : 'WARNING'}</span></td>
        //     <td >${ele?.discrepancy_count}</td>
        // </tr>`;
        //     }else{
                 temp=`<tr>
                <td >${ele?.data_source}</td>
                <td  >${convertDate(ele?.time)} </td>
                <td >${ele?.table_name}</td>
                <td >${ele?.test_name}</td>
                <td ><span style="${ele.status === 'pass' ? 'background-color: rgb(180, 239, 211); color: black;' : ele.status === 'fail' ? 'background-color: rgb(249, 190, 190); color: black' : 'background-color: rgb(252, 240, 183); color: black'}">${ele?.status == 'pass' ? 'PASSED' : ele?.status == 'fail' ? 'FAILED' : 'WARNING'}</span></td>
                <td >${ele?.discrepancy_count}</td>
            </tr>`;
            // }
            dataTableRef.current.row.add($(temp)).draw();
        })
    }
    }, [props])




    return (
        <div>
            <div className='d-flex justify-content-between mb-3'>
                <h3 className='adq-source'>Table View</h3>
                <button className='btn me-1' style={{ backgroundColor: '#176b87', color: 'white' }} onClick={() => { setIsFilter(!isFilter) }}>Filter</button>
            </div>
            {props?.ruleType === "RawData" ? 
                (<div className='table-responsive'>
                {/* <div className='table-responsive'> */}
                    <table id="data-table-basic" class="table table-striped" ref={tableRef}>
                        <thead>
                            <tr className={isFilter ? 'd-none' : ''}>
                                <th ><input className='form-control' value={columnText.Source[0]} style={{ minWidth: "85px" }} name="Source" onInput={(e) => handleText(e, 1)} placeholder='Search' type="text"></input></th>
                                <th><input className='form-control' value={columnText.RunAt[0]} style={{ minWidth: "85px" }} name="RunAt" onInput={(e) => handleText(e, 2)} placeholder='Search' type="text"></input></th>
                                <th><input className='form-control' value={columnText.TableName[0]} style={{ minWidth: "85px" }} name="TableName" onInput={(e) => handleText(e, 3)} placeholder='Search' type="text"></input></th>
                                <th><input className='form-control' value={columnText.TestName[0]} style={{ minWidth: "85px" }} name="TestName" onInput={(e) => handleText(e, 4)} placeholder='Search' type="text"></input></th>
                                <th ><input className='form-control' value={columnText.Status[0]} style={{ minWidth: "85px" }} name="Status" onInput={(e) => handleText(e, 5)} placeholder='Search' type="text"></input></th>
                                <th><input className='form-control' value={columnText.Discrepancy[0]} style={{ minWidth: "85px" }} name="Discrepancy" onInput={(e) => handleText(e, 6)} placeholder='Search' type="text"></input></th>
                            </tr>
                            <tr>
                                <th>Source</th>
                                <th>Run At</th>
                                <th>Table Name</th>
                                <th>Test Name</th>
                                <th>Status</th>
                                <th>Discrepancy Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {
                                tableData?.map((ele) => {
                                    return (
                                        <tr className={ele.status == "fail" ? 'bg-custom' : null}>
                                            <td >{ele?.data_source}</td>
                                            <td >{ele?.time}</td>
                                            <td >{ele?.table_name}</td>
                                            <td >{ele?.test_name}</td>
                                            <td >{ele?.status}</td>
                                            <td >{ele?.discrepancy_count}</td>
                                        </tr>
                                    )
                                })
                            } */}
                        </tbody>
                        {/* <tfoot>
                            <tr>
                                <th>Source</th>
                                <th>Run At</th>
                                <th>Table Name</th>
                                <th>Test Name</th>
                                <th>Status</th>
                                <th>Discrepancy Count</th>
                            </tr>
                        </tfoot> */}
                    </table>
                </div>):
                (<div className='table-responsive'>
                <table id="data-table-basic" class="table table-striped" ref={tableRef}>
                    <thead>
                        <tr className={isFilter ? 'd-none' : ''}>
                            <th ><input className='form-control ' value={metricColumnText.Source[0]} style={{ minWidth: "85px" }} name="Source" onInput={(e) => handleText(e, 1)} placeholder='Search' type="text"></input></th>
                            <th><input className='form-control' value={metricColumnText.RunAt[0]} style={{ minWidth: "85px" }} name="RunAt" onInput={(e) => handleText(e, 2)} placeholder='Search' type="text"></input></th>
                            <th><input className='form-control' value={metricColumnText.TableName[0]} style={{ minWidth: "85px" }} name="TableName" onInput={(e) => handleText(e, 3)} placeholder='Search' type="text"></input></th>
                            <th><input className='form-control' value={metricColumnText.TestName[0]} style={{ minWidth: "85px" }} name="TestName" onInput={(e) => handleText(e, 4)} placeholder='Search' type="text"></input></th>
                            <th ><input className='form-control' value={metricColumnText.Status[0]} style={{ minWidth: "85px" }} name="Status" onInput={(e) => handleText(e, 5)} placeholder='Search' type="text"></input></th>
                            <th><input className='form-control' value={metricColumnText.L1_VALUE[0]} style={{ minWidth: "85px" }} name="L1_VALUE" onInput={(e) => handleText(e, 6)} placeholder='Search' type="text" ></input></th>
                            <th><input className='form-control' value={metricColumnText.L2_VALUE[0]} style={{ minWidth: "85px" }} name="L2_VALUE" onInput={(e) => handleText(e, 7)} placeholder='Search' type="text"></input></th>
                            <th><input className='form-control' value={metricColumnText.L3_VALUE[0]} style={{ minWidth: "85px" }} name="L3_VALUE" onInput={(e) => handleText(e, 8)} placeholder='Search' type="text"></input></th>
                        </tr>
                        <tr>
                            <th>Source</th>
                            <th>Run At</th>
                            <th>Table Name</th>
                            <th>Test Name</th>
                            <th>Status</th>
                            <th>L1_VALUE</th>
                            <th>L2_VALUE</th>
                            <th>L3_VALUE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* {
                            tableData?.map((ele) => {
                                return (
                                    <tr className={ele.status == "fail" ? 'bg-custom' : null}>
                                        <td >{ele?.data_source}</td>
                                        <td >{ele?.time}</td>
                                        <td >{ele?.table_name}</td>
                                        <td >{ele?.test_name}</td>
                                        <td >{ele?.status}</td>
                                        <td >{ele?.discrepancy_count}</td>
                                    </tr>
                                )
                            })
                        } */}
                    </tbody>
                    {/* <tfoot>
                        <tr>
                            <th>Source</th>
                            <th>Run At</th>
                            <th>Table Name</th>
                            <th>Test Name</th>
                            <th>Status</th>
                            <th>Discrepancy Count</th>
                        </tr>
                    </tfoot> */}
                </table>
            </div>)
            }
        </div>
    )
}

export default SourceTableView
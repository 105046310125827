import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net';
import '../css/Home.css';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import '../css/animate.css';
import axios from 'axios';

var initcolumn = {
    Id: ["", 1],
    Source: ["", 2],
    Table: ["", 3],
    Level: ["", 4],
    Column: ["", 5],
    Check: ["", 6],
    Condition: ["", 7],
    Result: ["", 8],
    Author: ["", 9]
}


var initReport = {
    id: null,
    project: "",
    report_name: "",
    pageDisplay: "",
    iFrameLink: "",
    env_Report: "",
    isActive: "1",
}

const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
    const [name, value] = cookie.split('=');
    acc[name] = decodeURIComponent(value);
    return acc;
}, {});

function PowerBI(props) {
    const tableRef = useRef(null);
    const dataTableRef = useRef(null);
    const [clickedRow, setClickedRow] = useState(null);
    const [columnText, setColumnText] = useState(initcolumn);
    const [isFilter, setIsFilter] = useState(true)
    const [rulesData, setRulesData] = useState([]);
    const [newReport, setNewReport] = useState(false)
    const [title, setTitle] = useState('New')
    const [allProjects, setAllProjects] = useState([]);
    const [reportState, setReportState] = useState(initReport)
    const [errorReport, setErrorReport] = useState('')
    useEffect(() => {
        if (rulesData.length) {
            if (tableRef.current && !dataTableRef.current) {
                // Only initialize the DataTable if it hasn't been initialized before
                const dataTableInstance = $(tableRef.current).DataTable({
                    searching: true,
                    ordering: isFilter,
                    language: {
                        searchPlaceholder: "Search records",
                    },
                    lengthChange: false,
                });

                dataTableRef.current = dataTableInstance; // Store the DataTable instance
                // if (dataTableRef.current) {
                //   dataTableRef.current.order([1, 'asc']).draw();
                // }
            }
        }
    }, [rulesData]);


    useEffect(() => {
        if (isFilter) {
            setColumnText(initcolumn)
        }
    }, [isFilter])

    useEffect(() => {
        setRulesData(props?.tableData)
        setAllProjects(props?.projects)
    }, [props])

    const handleChange = (e) => {
        setErrorReport('')
        setReportState({
            ...reportState,
            [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        if (rulesData.length) {
            Object.keys(columnText)?.map((ele) => {
                var temp = columnText[ele]
                dataTableRef.current.column(temp[1] - 1).search(temp[0]).draw();
            })
        }
    }, [columnText])

    const handleText = (e, position) => {
        setColumnText({
            ...columnText,
            [e.target.name]: [e.target.value, position]
        });
    }


    const handleRowClick = (index) => {
        // Update the state to track the clicked row index
        setClickedRow(index);
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverData, setPopoverData] = useState([]);
    const handleClick = (event, data) => {
        setAnchorEl(event.currentTarget);
        setPopoverData(data)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    var popoverStyle = {
        border: " 0px solid rgba(0, 0, 0, .2)",
        borderRadius: "2px",
        fontFamily: "Roboto,sans-serif",
        boxShadow: " 0 2px 10px rgba(0, 0, 0, .2)",
        padding: "5px 15px 10px 15px",
        lineHeight: " 24px",
        fontSize: "14px",
        width: "150px",
        height: "115px",
    }


    const removeStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        borderRadius: '5px',
        boxShadow: 24,
        // p: 4,
        width: '40%'
    };
    const [testOpen, setTestOpen] = useState(false);
    const handleTestClose = () => setTestOpen(false);
    const handleReportClose = () => setNewReport(false);
    const DeleteRule = async () => {
        setTestOpen(false)
        try {
            var sendData = {
                "id": popoverData?.pk,
                "project": popoverData?.fields?.PROJECT_NAME,
                "report_name": popoverData?.fields?.REPORT_NAME,
                "pageDisplay": popoverData?.fields?.PAGE_TO_DISPLAY_ON,
                "iFrameLink": popoverData?.fields?.IFRAME_LINK,
                "env_Report": popoverData?.fields?.ENV,
                "isActive": popoverData?.fields?.IS_ACTIVE,
                "process": "delete"
            }

            const headers = {
                'X-CSRFToken': cookies.csrftoken,
                'Content-Type': 'application/json'
            };
            var data = await axios.post('/accounts/powerBIsettings/', { ...sendData }, { headers })

            if (!data.data.Error) {
                Swal.fire({title:data.data.Result, text:'', icon:'success',
                confirmButtonColor: "#176b87",});
            } else {
                Swal.fire({title:"Error", text:data.data.Error, icon:'error',
                confirmButtonColor: "#176b87",});
            }
            props.reRender();
        } catch (error) {
            console.log(error);
        }

    }

    const handleAdd = () => {
        setTitle('New')
        setNewReport(true)
    }

    const handleSave = async () => {
        if (reportState.project && reportState.report_name && reportState.pageDisplay && reportState.iFrameLink && reportState.env_Report) {
            var sendData = { ...reportState }

            sendData['process'] = "create";

            setErrorReport('')
            try {
                const headers = {
                    'X-CSRFToken': cookies.csrftoken,
                    'Content-Type': 'application/json'
                };
                var data = await axios.post('/accounts/powerBIsettings/', { ...sendData }, { headers });
                setNewReport(false)
                if (!data.data.Error) {
                    Swal.fire({title:data.data.Result, text:'', icon:'success',
                    confirmButtonColor: "#176b87",});
                    props.reRender()
                } else {
                    Swal.fire({title:"Error", text:data.data.Error, icon:'error',
                    confirmButtonColor: "#176b87",});
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            setErrorReport('All the fields are required')
        }
    }

    const EditReport = () => {
        setTitle('Edit')
        setReportState({
            "id": popoverData?.pk,
            "project": popoverData?.fields?.PROJECT_NAME,
            "report_name": popoverData?.fields?.REPORT_NAME,
            "pageDisplay": popoverData?.fields?.PAGE_TO_DISPLAY_ON,
            "iFrameLink": popoverData?.fields?.IFRAME_LINK,
            "env_Report": popoverData?.fields?.ENV,
            "isActive": popoverData?.fields?.IS_ACTIVE,
        })
        setNewReport(true)
    }


    const confirmation = () => {
        setAnchorEl(null)
        setTestOpen(true)
    }
    return (
        <div >
            {/* {FullPageLoader ? <div className='position-relative' >
                <div className='backdrop'></div>
                <div className='spinner-container'><Spinner /></div>

            </div> : null} */}
            {/* <div className='card adq-card p-3'> */}
            <div className='d-flex justify-content-between'>
                {/* <h2 className='adq-source'>Table Rules</h2> */}
                <div></div>
                <div className='d-flex justify-content-end'>
                    <button className='mx-1 btn button_positive' onClick={() => { setIsFilter(!isFilter) }}>Filter</button>
                    <button className='mx-1 btn button_positive' onClick={() => { handleAdd() }}>Add  Report</button>
                </div>
            </div>
            {
                rulesData.length ?
                    <div className='table-responsive mt-2'>
                        <table id="data-table-basic" class="table table-striped" ref={tableRef}>
                            <thead>

                                <tr className={isFilter ? 'd-none' : ''}>
                                    <th ><input className='form-control' value={columnText.Id[0]} style={{ minWidth: "85px" }} name="Id" onInput={(e) => handleText(e, 1)} placeholder='Search' type="text"></input></th>
                                    <th><input className='form-control' value={columnText.Source[0]} style={{ minWidth: "85px" }} name="Source" onInput={(e) => handleText(e, 2)} placeholder='Search' type="text"></input></th>
                                    <th><input className='form-control' value={columnText.Table[0]} style={{ minWidth: "85px" }} name="Table" onInput={(e) => handleText(e, 3)} placeholder='Search' type="text"></input></th>
                                    <th><input className='form-control' value={columnText.Level[0]} style={{ minWidth: "85px" }} name="Level" onInput={(e) => handleText(e, 4)} placeholder='Search' type="text"></input></th>
                                    <th ><input className='form-control' value={columnText.Column[0]} style={{ minWidth: "85px" }} name="Column" onInput={(e) => handleText(e, 5)} placeholder='Search' type="text"></input></th>
                                    <th><input className='form-control' value={columnText.Check[0]} style={{ minWidth: "85px" }} name="Check" onInput={(e) => handleText(e, 6)} placeholder='Search' type="text"></input></th>
                                    <th><input className='form-control' value={columnText.Condition[0]} style={{ minWidth: "85px" }} name="Condition" onInput={(e) => handleText(e, 7)} placeholder='Search' type="text"></input></th>
                                </tr>
                                <tr>
                                    <th>ID</th>
                                    <th>Project Name</th>
                                    <th>Report Name</th>
                                    <th>Page To Display on</th>
                                    <th>Environment</th>
                                    <th>IFrame Link</th>
                                    <th>Is Active</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    rulesData?.map((ele, index) => {
                                        return (
                                            <tr
                                                key={ele.pk}
                                                className={index === clickedRow ? 'clicked-row' : ''}
                                                onClick={() => handleRowClick(index)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <td onClick={(e) => handleClick(e, ele)} variant="contained">{ele?.pk}</td>
                                                <td onClick={(e) => handleClick(e, ele)} variant="contained">{ele?.fields?.PROJECT_NAME}</td>
                                                <td onClick={(e) => handleClick(e, ele)} variant="contained">{ele?.fields?.REPORT_NAME}</td>
                                                <td onClick={(e) => handleClick(e, ele)} variant="contained">{ele?.fields?.PAGE_TO_DISPLAY_ON}</td>
                                                <td onClick={(e) => handleClick(e, ele)} variant="contained">{ele?.fields?.ENV}</td>
                                                <td onClick={(e) => handleClick(e, ele)} variant="contained">{ele?.fields?.IFRAME_LINK}</td>
                                                <td onClick={(e) => handleClick(e, ele)} variant="contained">{ele?.fields?.IS_ACTIVE == true ? "True" : "False"}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                            {/* <tfoot>
            <tr>
                <th>Source</th>
                <th>Run At</th>
                <th>Table Name</th>
                <th>Test Name</th>
                <th>Status</th>
                <th>Discrepancy Count</th>
            </tr>
  </tfoot> */}
                        </table>
                    </div>
                    : <div className='text-center'>No data Found</div>
            }


            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center', // Center vertically
                    horizontal: 'right', // Align to the right side
                }}
                transformOrigin={{
                    vertical: 'center', // Center vertically
                    horizontal: 'left', // Align to the left side
                }}
                style={{ borderRadius: "5px" }}
            >
                <Typography style={popoverStyle} >
                    <div className='h-100 pt-2 text-center'>
                        <button className='btn  button_positive mx-auto' onClick={() => EditReport()}>Edit Report </button>
                        <button className='btn button_warning mt-2' onClick={() => confirmation()}>   Delete Report </button>
                    </div>
                </Typography>
            </Popover>




            <Modal
                open={testOpen}
                onClose={handleTestClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={removeStyle}
                //className='animated zoomIn'
                >
                    <div className='p-5'>
                        <h5>Do you want to activate this rule?</h5>
                        <div className='d-flex  justify-content-end text-center'>
                            <button className='  mx-1 btn button_warning' onClick={() => { handleTestClose() }}>No</button>
                            <button className=' mx-1 btn button_positive' onClick={() => { DeleteRule() }}>Yes</button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={newReport}
                onClose={handleReportClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={removeStyle}>

                    <div className='d-flex justify-content-center align-items-center p-3' style={{ backgroundColor: '#176b87', color: 'white', borderTopRightRadius: '5px', borderTopLeftRadius: '5px', height: '40px' }}>
                        <h5 className='text-uppercase'>{title} Report</h5>
                    </div>
                    <div className='p-4'>
                        <div className='row'>
                            <div className='col-6'>
                                <label className='fw-bold'>Project Name <span className='spann'>*</span></label>
                                <select className='form-control' name='project' value={reportState.project} onChange={handleChange}>
                                    <option value="">Select Project</option>
                                    {
                                        allProjects?.map((ele) => {
                                            return (
                                                <option value={ele?.project_name}>{ele?.display_name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='col-6'>
                                <label className='fw-bold'>Report Name <span className='spann'>*</span></label>
                                <input className='form-control' name='report_name' value={reportState.report_name} onInput={handleChange} />
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-6'>
                                <label className='fw-bold'>Page to display on <span className='spann'>*</span></label>
                                <select className='form-control' name='pageDisplay' value={reportState.pageDisplay} onChange={handleChange}>
                                    <option value="">Select Page </option>
                                    <option value="metric_dashboard"> Metric Dashboard </option>
                                    <option value="source_summary_dashboard">Source Summary Dashboard </option>
                                    <option value="dashboard">  Dashboard </option>
                                </select>
                            </div>
                            <div className='col-6'>
                                <label className='fw-bold'>Environment <span className='spann'>*</span></label>
                                <select class="form-control" name="env_Report" value={reportState.env_Report} onChange={handleChange} >
                                    <option value="">Select environment </option>
                                    <option value="PROD"> PROD </option>
                                    <option value="DEV"> DEV</option>
                                </select>
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-6'>
                                <label className='fw-bold'>Is Active <span className='spann'>*</span></label>
                                <div className='d-flex justify-content-start'>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" id="flexRadioDefault1" name='isActive' value="1" onClick={handleChange} checked={reportState?.isActive == "1" ? true : false} />
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            Yes
                                        </label>
                                    </div>
                                    <div class="form-check ms-3">
                                        <input class="form-check-input" type="radio" id="flexRadioDefault2" name='isActive' value="0" onClick={handleChange} checked={reportState?.isActive == "0" ? true : false} />
                                        <label class="form-check-label" for="flexRadioDefault2">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                            </div>
                        </div>
                        <div className='mt-2'>
                            <label className='fw-bold'>IFrame Link <span className='spann'>*</span></label>
                            <textarea className='form-control' value={reportState.iFrameLink} name='iFrameLink' onInput={handleChange}></textarea>
                        </div>
                        {
                            errorReport != '' ?
                                <p className='spann mt-2'>{errorReport}</p>
                                : null
                        }
                        <div className='d-flex  justify-content-end mt-4'>
                            <button className=' mx-1 btn button_positive' onClick={() => { handleSave() }}>Save</button>
                        </div>
                    </div>
                </Box>
            </Modal>
            {/* </div> */}
        </div>
    )
}

export default PowerBI
import React, { useState, useEffect } from 'react'
import '../css/Home.css';
import { Bar } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import DataReliabilityChart from './DataReliabilityChart';
import { SwipeableDrawer } from '@mui/material';
import DataReliabilityTable from './DataReliabilityTable';
import axios from 'axios'
import Spinner from '../widgets/Spinner';
import { Bars } from 'react-loader-spinner';
import moment from 'moment';
import ApiClient from "./ApiClient"
import { useParams } from 'react-router-dom';
import { format } from 'echarts';
import snowflakelogo from '../images/snowflake.png';
import databrickslogo from '../images/databricks.png';
import azuresynapselogo from '../images/azuresynapse.png';
import awsredshiftlogo from '../images/awsredshift.png';

function DataReliability() {
    const [selectedBarIndex, setSelectedBarIndex] = useState(-1);
    const [isTooltipFixed, setIsTooltipFixed] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const [lookBackInput, setLookBackInput] = useState('')
    const [lookBackInputHis, setLookBackInputHis] = useState('')
    const [rowChk, setRowChk] = useState()
    const [nullChk, setNullChk] = useState()
    const [duplicateChk, setDuplicateChk] = useState()
    const [validityChk, setValidityChk] = useState()
    const [mandtryChk, setMandtryChk] = useState()
    const [relatnChk, setRelatnChk] = useState()
    const [freshChk, setFreshChk] = useState()
    const [lenChk, setLenChk] = useState()
    const [compChk, setCompChk] = useState()
    const [selectedSource, setSelectedSource] = useState();
    const [historyChart, setHistoryChart] = useState([]);
    const [incidentChart, setIncidentChart] = useState([]);
    const [historyTable, setHistoryTable] = useState([]);
    const [FullPageLoader, setFullPageLoader] = useState(false)
    const [sourceLoader, setSourceLoader] = useState(false)
    const [headerOfIncident, setHeaderOfIncident] = useState([])
    const [borderOfIncident, setBorderOfIncident] = useState([]);
    const [chartLable,setChartLable]=useState([]);
    const [lookbackDate, setLookbackDate] = useState({ 'startDate': '', 'endDate': '' });
    const [hisLookbackDate, setHisLookbackDate] = useState({ 'startDate': '', 'endDate': '' })
    const genColor = (colorid) => {
        var color = [];
        for (var i = 0; i < 100; i++) {
            color.push(colorid)
        }
        return color
    }
    const [backgroundColor, setBackgroundColor] = useState({ pass: genColor('#5470c6'), fail: genColor('#ee6666'), warning: genColor('#fac858') })

    const handleDateLookBack = (event) => {
        var value = event.target.value;
        var name = event.target.name;
        var newDate = { ...lookbackDate, [name]: value };
        if (name == "startDate") {
            newDate = { ...newDate, ['endDate']: '' }
        }
        setLookbackDate(newDate)
    }

    const handleHisDateLookBack = (event) => {
        var value = event.target.value;
        var name = event.target.name;
        var newDate = { ...hisLookbackDate, [name]: value };
        if (name == "startDate") {
            newDate = { ...newDate, ['endDate']: '' }
        }
        setHisLookbackDate(newDate)
    }
    useEffect(() => {
        if (lookbackDate.startDate && lookbackDate.endDate) {
            // getSourceSummaryData(0, lookbackDate)
            // chartDataCall('4');
            headersCall(lookbackDate)
            chartDataCall(lookbackDate)
            miniChartDataCall(lookbackDate, '')
            projectSelected()
        }
    }, [lookbackDate])

    useEffect(() => {
        if (hisLookbackDate.startDate && hisLookbackDate.endDate) {
            // getSourceSummaryData(0, lookbackDate)
            // chartDataCall('4');
            historyPageApi(hisLookbackDate, selectedSource)
            IncidentApi(hisLookbackDate, selectedSource)
            IncidentHeader(hisLookbackDate, selectedSource)
        }
    }, [hisLookbackDate])

    var initialGraphData = {
        labels: [],
        datasets: [
            {
                label: 'Pass',
                backgroundColor: backgroundColor.pass,
                borderColor: 'transparent',
                borderWidth: 2,
                data: [],
            },
            {
                label: 'Fail',
                backgroundColor: backgroundColor.fail,
                borderColor: 'transparent',
                borderWidth: 2,
                data: [],
            },
            {
                label: 'Warning',
                backgroundColor: backgroundColor.warning,
                borderColor: 'transparent',
                borderWidth: 2,
                data: [],
            }],
    }
    const [bigChartData, setBigChartData] = useState(initialGraphData)
    const [selectedPrj, setSelectedPrj] = useState([])
    const handleClickOutsideTooltip = (event) => {
        if (isTooltipFixed && event.target.className !== 'tooltip-trigger') {
            setIsTooltipFixed(false);
        }
    };

    const history = () => {
        setIsTooltipFixed(false)
        setIsOpen(true);
        if (lookback == '1') {
            historyPageApi(7, selectedSource)
            IncidentApi(7, selectedSource)
            IncidentHeader(7, selectedSource)
        } else if (lookback == '2') {
            historyPageApi(30, selectedSource)
            IncidentApi(30, selectedSource)
            IncidentHeader(30, selectedSource)
        } else if (lookback == '3') {
            historyPageApi(90, selectedSource)
            IncidentApi(90, selectedSource)
            IncidentHeader(90, selectedSource)
        } else if (lookback == '4') {
            // historyPageApi TODO
        } else {
            historyPageApi(lookBackInput, selectedSource)
            IncidentApi(lookBackInput, selectedSource)
            IncidentHeader(lookBackInput, selectedSource)
        }
        setLookbackHis(lookback)
    }

    const IncidentHeader = async (lookback, source,table=false) => {
        try {
            if (lookback?.startDate && lookback?.endDate) {
                var data = await axios.get(`/source_checks_history/`, { params: { 'source': source, 'startDate': hisLookbackDate.startDate, 'endDate': hisLookbackDate.endDate } }, {
                    withCredentials: true
                })
                setHeaderOfIncident(data.data.Result)
            } else {
               if(table){
                var data = await axios.get(`/source_checks_history/`, { params: { 'source': source, 'daysToFilter': lookback ,'table':table} })
                setHeaderOfIncident(data.data.Result)
               }else{
                var data = await axios.get(`/source_checks_history/`, { params: { 'source': source, 'daysToFilter': lookback } })
                setHeaderOfIncident(data.data.Result)
               }
            }

        } catch (error) {
            console.log(error);
        }
    }

    const IncidentApi = async (lookback, source, type = '') => {
        try {
            if (lookback?.startDate && lookback?.endDate) {
                if (type == '') {
                    var data = await axios.get(`/reliability/tableBasedChartData/`, { params: { 'source': source, 'startDate': hisLookbackDate.startDate, 'endDate': hisLookbackDate.endDate } }, {
                        withCredentials: true
                    })
                } else {
                    var data = await axios.get(`/reliability/tableBasedChartData/`, { params: { 'source': source, 'checkname': type, 'startDate': hisLookbackDate.startDate, 'endDate': hisLookbackDate.endDate } }, {
                        withCredentials: true
                    })
                }
                setIncidentChart(data?.data?.data)
                setHistoryTable(data?.data?.tblData)
            } else {
                if (type == '') {
                    if(param.tablename){
                        var data = await axios.get(`/reliability/tableBasedChartData/`, { params: { 'source': source, 'daysToFilter': lookback ,'table':param.tablename} })
                    }else{
                        var data = await axios.get(`/reliability/tableBasedChartData/`, { params: { 'source': source, 'daysToFilter': lookback } })
                    }
                } else {
                   if(param.tablename){
                    var data = await axios.get(`/reliability/tableBasedChartData/`, { params: { 'source': source, 'daysToFilter': lookback, 'checkname': type,'table':param.tablename } })
                   }else{
                    var data = await axios.get(`/reliability/tableBasedChartData/`, { params: { 'source': source, 'daysToFilter': lookback, 'checkname': type } })
                   }
                }
                setIncidentChart(data?.data?.data)
                setHistoryTable(data?.data?.tblData)
            }

        } catch (error) {
            console.log(error);
        }
    }

    const historyPageApi = async (lookback, source,table=false) => {
        try {
            if (lookback?.startDate && lookback?.endDate) {
                let data = await axios.get(`/reliability/srcBasedChecks/`, { params: { 'sourceToFilter': source, 'startDate': hisLookbackDate.startDate, 'endDate': hisLookbackDate.endDate } }, {
                    withCredentials: true
                })
                setHistoryChart(data.data.data)
                var border = data?.data?.data?.map((ele) => 2)
                setBorderOfIncident(border);
            } else {
                if(table){
                    let data = await axios.get(`/reliability/srcBasedChecks/`, { params: { 'sourceToFilter': source, 'daysToFilter': lookback ,'table':table} })
                    setHistoryChart(data.data.data)
                    var border = data?.data?.data?.map((ele) => 2)
                    setBorderOfIncident(border);
                }else{
                    let data = await axios.get(`/reliability/srcBasedChecks/`, { params: { 'sourceToFilter': source, 'daysToFilter': lookback } })
                    setHistoryChart(data.data.data)
                    var border = data?.data?.data?.map((ele) => 2)
                    setBorderOfIncident(border);
                }
                
            }

        } catch (error) {
            console.log(error);
        }
    }

    const CustomTooltip = ({ data }) => {
        if (isTooltipFixed && selectedBarIndex !== -1) {
            const totalPass = data.datasets[0].data[selectedBarIndex]
            const totalFail = data.datasets[1].data[selectedBarIndex];
            const totalWarn = data.datasets[2].data[selectedBarIndex];
            let num = 0

            if (!Number.isInteger(totalPass) || !Number.isInteger(totalPass) || !Number.isInteger(totalPass)) {
                num = 2
            }

            const tooltipStyle = {
                position: 'absolute',
                left: `${tooltipPosition.x}px`,
                top: `${tooltipPosition.y}px`,
                zIndex: '1',
                width: '20%'
            };

            return (
                <div className="p-2 card" style={tooltipStyle}>
                    <div className='d-flex'>
                        <p className='mb-0 d-flex'><div className='adq-tooltip'></div>Pass: {totalPass.toFixed(num)}%</p>
                    </div>
                    <div className='d-flex justify-content-between'>

                        <p className='mb-0 d-flex'><div className='adq-tooltip'></div>Fail: {totalFail.toFixed(num)}%</p>
                    </div>
                    <div className='d-flex '>

                        <p className='mb-0 d-flex'><div className='adq-tooltip'></div>Warn: {totalWarn.toFixed(num)}%</p>
                    </div>
                    <span onClick={() => history()} className='text-end' style={{ color: "#176b87", cursor: 'pointer' }}>History</span>
                </div>
            );
        }
        return null;
    };

    const handleBarClick = (event, elements) => {

        if (elements.length > 0) {
            const clickedBarIndex = elements[0].index;
            setSelectedBarIndex(clickedBarIndex);
            const chartInstance = elements[0].chart;
            let selectedSources = bigChartData.labels[clickedBarIndex]
            setSelectedSource(selectedSources)
            if (lookback == '1') {
                miniChartDataCall(7, selectedSources)
            } else if (lookback == '2') {
                miniChartDataCall(30, selectedSources)
            } else if (lookback == '3') {
                miniChartDataCall(90, selectedSources)
            } else if (lookback == '4') {
                // miniChartDataCall TODO
            } else {
                miniChartDataCall(lookBackInput, selectedSources)
            }

            const x = elements[0].element.x;
            const y = elements[0].element.y;

            setTooltipPosition({ x, y });
            setIsTooltipFixed(!isTooltipFixed);

            var newBackground = { pass: genColor('#5470c6'), fail: genColor('#ee6666'), warning: genColor('#fac858') }
            newBackground.pass[clickedBarIndex] = '#032ba5';
            newBackground.fail[clickedBarIndex] = '#c12424';
            newBackground.warning[clickedBarIndex] = '#f4f833';
            setBackgroundColor(newBackground)

        }

    };

    const projectSelected = async () => {
        let data = await axios.get('/accounts/addProjects/')
        let selectedPrj = data.data.project_name.filter((e) => e.project_name == data.data.selected_project
        )
        setSelectedPrj(selectedPrj[0])

    }
    const sourceOptions = {
        type: 'bar',
        indexAxis: 'y',
        onClick: (event, elements) => handleBarClick(event, elements),
        scales: {
            x: {
                beginAtZero: true,
            }
        },
        scales: {

            x: {
                stacked: true,
                max: 100
            },
            y: {
                stacked: true,
            },

        },
        plugins: {
            title: {
                display: false,
                text: 'Checks By Sources',
                font: {
                    size: 24,
                    fontWeight: 700,
                    lineHeight: 1.1,
                    color: '#333',
                }
            },
            tooltip: {
                enabled: false,
                mode: 'nearest',
            },
        },
    };



    const initialDonutChartDataCall = {
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: [' #3498db', '#2ecc71', '#e74c3c', '#f1c40f', '#9b59b6', '#95a5a6', '#ff6b6b', ' #ffaa2b', '#cc66cc'],
            },
        ],
    };
    const [initialDonutChartData, setInitialDonutChartData] = useState(initialDonutChartDataCall)
    const chartoption = {
        legend: {
            display: true,
            position: 'bottom'
        },
        plugins: {
            legend: {
                display: true,
                position: 'right',
                labels: {
                    boxWidth: 15,
                    font: {
                        size: 10,
                    },
                    padding: 1,
                },
            }, tooltip: {
                callbacks: {
                    label: (context) => {
                        const value = context.raw;
                        return `${value}%`;
                    },
                },
            }
        },
        maintainAspectRatio: false, // Allow chart to maintain aspect ratio
        responsive: true, // Make the chart responsive
        height: 100,
    }

    const [isOpen, setIsOpen] = useState(false);

    const [headersData, setHeadersData] = useState()
    // const [graphData, setGraphData] = useState()
    
    const logoName = headersData?.database.replace(' ','').toLowerCase();
    const logoMap = {
        snowflake: snowflakelogo,
        databricks: databrickslogo,
        awsredshift: awsredshiftlogo,
        azuresynapse: azuresynapselogo,
    };
    let logoPath = logoMap[logoName];

    const headersCall = async (days) => {

        try {
            if (days?.startDate && days?.endDate) {
                let data = await axios.get(`/reliability/data_reliability_header/`, { params: { 'startDate': lookbackDate.startDate, 'endDate': lookbackDate.endDate } }, {
                    withCredentials: true
                })
                setHeadersData(data.data)
            } else {
                let data = await axios.get(`/reliability/data_reliability_header/`, { params: { 'daysToFilter': days } })
                setHeadersData(data.data)
            }
        } catch (error) {
            console.log(error);
        }

    }
    const chartDataCall = async (days) => {
        setSourceLoader(true)
        try {
            if (days?.startDate && days?.endDate) {
                let data = await axios.get(`/reliability/chartDataDataRel/`, { params: { 'startDate': lookbackDate.startDate, 'endDate': lookbackDate.endDate } }, {
                    withCredentials: true
                })
                let sourcesList = data.data.scrChartD.map((e) => e[0].source)
                let passArr = data.data.scrChartD.map((e) => e[0].pssCnt)
                let failArr = data.data.scrChartD.map((e) => e[0].failCnt)
                let warnArr = data.data.scrChartD.map((e) => e[0].warnCnt)

                const updatedData = {
                    ...bigChartData,
                    labels: sourcesList,
                    datasets: [
                        {
                            ...bigChartData.datasets[0],
                            data: passArr,
                        },
                        {
                            ...bigChartData.datasets[1],
                            data: failArr,
                        },
                        {
                            ...bigChartData.datasets[2],
                            data: warnArr,
                        },
                    ],
                };
                const updatedDonutData = {
                    ...initialDonutChartData,
                    labels: data?.data?.checkBsdD?.checks,
                    datasets: [
                        {
                            data: data?.data?.checkBsdD?.total,
                            backgroundColor: [' #3498db', '#2ecc71', '#e74c3c', '#f1c40f', '#9b59b6', '#95a5a6', '#ff6b6b', ' #ffaa2b', '#cc66cc'],
                        },
                    ],
                }
                setInitialDonutChartData(updatedDonutData)
                setBigChartData(updatedData)
                setChartLable(data?.data?.checkBsdD?.check)
            } else {
                let data = await axios.get(`/reliability/chartDataDataRel/`, { params: { 'daysToFilter': days } })
                let sourcesList = data.data.scrChartD.map((e) => e[0].source)
                let passArr = data.data.scrChartD.map((e) => e[0].pssCnt)
                let failArr = data.data.scrChartD.map((e) => e[0].failCnt)
                let warnArr = data.data.scrChartD.map((e) => e[0].warnCnt)

                const updatedData = {
                    ...bigChartData,
                    labels: sourcesList,
                    datasets: [
                        {
                            ...bigChartData.datasets[0],
                            data: passArr,
                        },
                        {
                            ...bigChartData.datasets[1],
                            data: failArr,
                        },
                        {
                            ...bigChartData.datasets[2],
                            data: warnArr,
                        },
                    ],
                };
                if (data?.data?.checkBsdD?.checks) {
                    const updatedDonutData = {
                        ...initialDonutChartData,
                        labels: data?.data?.checkBsdD?.checks,
                        datasets: [
                            {
                                data: data?.data?.checkBsdD?.total,
                                backgroundColor: [' #3498db', '#2ecc71', '#e74c3c', '#f1c40f', '#9b59b6', '#95a5a6', '#ff6b6b', ' #ffaa2b', '#cc66cc'],
                            },
                        ],
                    }
                    setInitialDonutChartData(updatedDonutData)
                } else {
                    setInitialDonutChartData(initialDonutChartDataCall)
                }
                setBigChartData(updatedData)
                setChartLable(data?.data?.checkBsdD?.check)
            }

        } catch (error) {
            console.log(error);
        }
        setSourceLoader(false);
    }

    useEffect(() => {
        setBigChartData({
            ...bigChartData,
            datasets: [{
                ...bigChartData.datasets[0],
                backgroundColor: backgroundColor.pass,
            }, { ...bigChartData.datasets[1], backgroundColor: backgroundColor.fail }, {
                ...bigChartData.datasets[2], backgroundColor: backgroundColor.warning
            }]
        })
        console.log("form",backgroundColor);
    }, [backgroundColor])


    const miniChartDataCall = async (days, source) => {
        try {
            if (days?.startDate && days?.endDate) {
                setRowChk()
                setNullChk()
                setMandtryChk()
                setFreshChk()
                setLenChk()
                setValidityChk()
                setRelatnChk()
                setCompChk()
                setDuplicateChk()
                let data = await axios.get(`/reliability/checkChartData/`, { params: { 'startDate': lookbackDate.startDate, 'endDate': lookbackDate.endDate, 'sourceName': source } })
                let rawData = data.data.checkVals
                let rowCheckArr = []
                let nullCheckArr = []
                let duplicateCheckArr = []
                let validityCheckArr = []
                let mandatoryCheckArr = []
                let relationCheckArr = []
                let freshnessCheckArr = []
                let lengthCheckArr = []
                let compositeCheckArr = []

                const rowCheck = rawData.map((e) => {
                    if (e.length > 0) {
                        rowCheckArr.push([e[0].run_date__date, e[0].row_pass, e[0].row_fail, e[0].row_warn])
                        nullCheckArr.push([e[0].run_date__date, e[0].null_pass, e[0].nul_fail, e[0].null_warn])
                        duplicateCheckArr.push([e[0].run_date__date, e[0].dup_pass, e[0].dup_fail, e[0].dup_warn])
                        validityCheckArr.push([e[0].run_date__date, e[0].val_pass, e[0].val_fail, e[0].val_warn])
                        mandatoryCheckArr.push([e[0].run_date__date, e[0].man_pass, e[0].man_fail, e[0].man_warn])
                        relationCheckArr.push([e[0].run_date__date, e[0].rel_pass, e[0].rel_fail, e[0].rel_warn])
                        freshnessCheckArr.push([e[0].run_date__date, e[0].fresh_pass, e[0].fresh_fail, e[0].fresh_warn])
                        lengthCheckArr.push([e[0].run_date__date, e[0].len_pass, e[0].len_fail, e[0].len_warn])
                        compositeCheckArr.push([e[0].run_date__date, e[0].compkey_pass, e[0].compkey_fail, e[0].compkey_warn])
                    }
                })
                setRowChk(rowCheckArr)
                setNullChk(nullCheckArr)
                setMandtryChk(mandatoryCheckArr)
                setFreshChk(freshnessCheckArr)
                setLenChk(lengthCheckArr)
                setValidityChk(validityCheckArr)
                setRelatnChk(relationCheckArr)
                setCompChk(compositeCheckArr)
                setDuplicateChk(duplicateCheckArr)
            } else {
                let data = await axios.get(`/reliability/checkChartData/`, { params: { 'daysToFilter': days, 'sourceName': source } })
                let rawData = data.data.checkVals
                let rowCheckArr = []
                let nullCheckArr = []
                let duplicateCheckArr = []
                let validityCheckArr = []
                let mandatoryCheckArr = []
                let relationCheckArr = []
                let freshnessCheckArr = []
                let lengthCheckArr = []
                let compositeCheckArr = []

                const rowCheck = rawData.map((e) => {
                    if (e.length > 0) {
                        rowCheckArr.push([e[0].run_date__date, e[0].row_pass, e[0].row_fail, e[0].row_warn])
                        nullCheckArr.push([e[0].run_date__date, e[0].null_pass, e[0].nul_fail, e[0].null_warn])
                        duplicateCheckArr.push([e[0].run_date__date, e[0].dup_pass, e[0].dup_fail, e[0].dup_warn])
                        validityCheckArr.push([e[0].run_date__date, e[0].val_pass, e[0].val_fail, e[0].val_warn])
                        mandatoryCheckArr.push([e[0].run_date__date, e[0].man_pass, e[0].man_fail, e[0].man_warn])
                        relationCheckArr.push([e[0].run_date__date, e[0].rel_pass, e[0].rel_fail, e[0].rel_warn])
                        freshnessCheckArr.push([e[0].run_date__date, e[0].fresh_pass, e[0].fresh_fail, e[0].fresh_warn])
                        lengthCheckArr.push([e[0].run_date__date, e[0].len_pass, e[0].len_fail, e[0].len_warn])
                        compositeCheckArr.push([e[0].run_date__date, e[0].compkey_pass, e[0].compkey_fail, e[0].compkey_warn])
                    }
                })
                setRowChk(rowCheckArr)
                setNullChk(nullCheckArr)
                setMandtryChk(mandatoryCheckArr)
                setFreshChk(freshnessCheckArr)
                setLenChk(lengthCheckArr)
                setValidityChk(validityCheckArr)
                setRelatnChk(relationCheckArr)
                setCompChk(compositeCheckArr)
                setDuplicateChk(duplicateCheckArr)
            }

        } catch (error) {
            console.log(error);
        }
    }

    var param = useParams();

useEffect(() => {
    if(param.component != undefined && param.tablename != undefined){
        console.log(param.component,"param",param.tablename);
        console.log(chartLable);
        setSelectedSource(param.component);
        miniChartDataCall(90, param.component);
        setIsOpen(true);
        historyPageApi(90, param.component,param.tablename)
        IncidentApi(90, param.component)
        IncidentHeader(90, param.component,param.tablename)
        setLookbackHis('3')
        var lowercaseArray = bigChartData?.labels?.map((element) => element.toLowerCase());
        var indexId =lowercaseArray.indexOf((param.component).toLowerCase());
        var newBackground = { pass: genColor('#5470c6'), fail: genColor('#ee6666'), warning: genColor('#fac858') }
        console.log(indexId,chartLable);
        newBackground.pass[indexId] = '#032ba5';
        newBackground.fail[indexId] = '#c12424';
        newBackground.warning[indexId] = '#f4f833';
        console.log(newBackground);
        setTimeout(() => {
            setBackgroundColor(newBackground)
        }, 2000);
       
    }
}, [param,chartLable])


    useEffect(() => {
      const timer = setTimeout(() => {
        headersCall(90);
        chartDataCall(90);
        miniChartDataCall(90, "");
        projectSelected();
      }, 3000);

      return () => clearTimeout(timer);
    }, []);
    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            if (lookBackInput != '') {
                headersCall(lookBackInput);
                chartDataCall(lookBackInput)
                miniChartDataCall(lookBackInput, '')
                setLookback('')
            }

        }
    }

    function handleChangeLook(event) {
        if (!isNaN(event.target.value)) {
            const firstValue = (event.target.value).trim()
            const trimmedValue = firstValue.replace(/\./g, '');
            if (trimmedValue <= 300) {
                if (trimmedValue == '0') {
                    setLookBackInput('')
                } else {
                    setLookBackInput(trimmedValue);
                }
            } else {
                setLookBackInput('');

            }

            // setLookBackInput(event.target.value);
        }
    }

    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setIsTooltipFixed(false)
        setIsOpen(open);
        if(param.tablename != undefined){
            window.location.href="/DataReliability"
        }
    };



    const data = {
        labels: incidentChart?.map((ele) => ele?.table_name),
        datasets: [
            {
                label: 'Pass',
                backgroundColor: '#5470c6',
                borderColor: 'transparent',
                borderWidth: 2,
                data: incidentChart?.map((ele) => ele?.passCnt),
                barThickness: 10,
            },
            {
                label: 'Fail',
                backgroundColor: '#ee6666',
                borderColor: 'transparent',
                borderWidth: 2,
                data: incidentChart?.map((ele) => ele?.failCnt),
                barThickness: 10,
            },
            {
                label: 'Warning',
                backgroundColor: '#fac858',
                borderColor: 'transparent',
                borderWidth: 2,
                data: incidentChart?.map((ele) => ele?.warnCnt),
                barThickness: 10,
            }],
    }

    // Chart options
    const handleIncident = (event, elements, reset = '') => {
        if (reset == 'reset') {
            if (lookbackHis == '1') {
                IncidentApi(7, selectedSource)
            } else if (lookbackHis == '2') {
                IncidentApi(30, selectedSource)
            } else if (lookbackHis == '3') {
                IncidentApi(90, selectedSource)
            } else if (lookbackHis == '4') {
                //   IncidentApi(90,selectedSource,type) TODO
            } else {
                IncidentApi(lookBackInputHis, selectedSource)
            }
            var newBorder = borderOfIncident?.map((ele) => 2);
            setBorderOfIncident(newBorder)
        } else {
            var type = historyChart?.[elements[0]?.datasetIndex]?.test_name;
            var newBorder = borderOfIncident?.map((ele) => 2);
            newBorder[elements[0]?.datasetIndex] = 1;
            setBorderOfIncident(newBorder)
            if (lookbackHis == '1') {
                IncidentApi(7, selectedSource, type)
            } else if (lookbackHis == '2') {
                IncidentApi(30, selectedSource, type)
            } else if (lookbackHis == '3') {
                IncidentApi(90, selectedSource, type)
            } else if (lookbackHis == '4') {
                //   IncidentApi(90,selectedSource,type) TODO
            } else {
                IncidentApi(lookBackInputHis, selectedSource, type)
            }
        }

    }
    const options = {
        responsive: true,
        type: 'bar',
        indexAxis: 'y', // Set the index axis to 'y' for vertical bars
        scales: {
            xAxes: [{
                barThickness: 10
            }]
        }
    };

    const historyChartColor = ['#3498db', '#2ecc71', '#e74c3c', '#f1c40f', '#9b59b6', '#95a5a6', '#ff6b6b', '#ffaa2b', '#cc66cc']

    const data2 = {
        labels: [''],
        datasets:
            historyChart?.map((ele, index) => {
                return (
                    {
                        label: ele?.test_name,
                        backgroundColor: historyChartColor[index],
                        borderColor: borderOfIncident[index] == 2 ? 'transparent' : 'black',
                        borderWidth: borderOfIncident[index],
                        data: [ele?.count],
                        barThickness: 20,
                    }
                )
            })
        ,
    }


    const options2 = {
        type: 'bar',
        indexAxis: 'y', // Set the index axis to 'y' for vertical bars
        onClick: (event, elements) => handleIncident(event, elements),
        scales: {
            x: {
                beginAtZero: true,
            }
        },
        scales: {

            x: {
                stacked: true,
                display: false
            },
            y: {
                stacked: true,
                display: false
            },

        },
        plugins: {
            title: {
                display: false,
                text: 'Checks By Sources',
                font: {
                    size: 24,
                    fontWeight: 700,
                    lineHeight: 1.1,
                    color: '#333',
                }
            },
            datalabels: {
                display: true,
                color: '#ffffff', // Color of the labels
            },
            legend: {
                display: true, // Display the legend
                position: 'top', // Position of the legend (top, bottom, left, right, or custom)
                labels: {
                    font: {
                        size: 10
                    }, // Adjust the font size of the legend labels
                },
            },
        }
    };

    const [lookback, setLookback] = useState('3');
    const toggleLookback = tab => {
        if (lookback !== tab) {
            setLookback(tab);
            if (tab == '1') {
                headersCall(7)
                chartDataCall(7)
                miniChartDataCall(7, '')
            } else if (tab == '2') {
                headersCall(30)
                chartDataCall(30)
                miniChartDataCall(30, '')
            } else if (tab == '3') {
                headersCall(90)
                chartDataCall(90)
                miniChartDataCall(90, '')
            }

            setLookBackInput('')
        }
    }



    const [lookbackHis, setLookbackHis] = useState('1');
    const toggleLookbackHis = tab => {
        if (lookbackHis !== tab) {
            setLookbackHis(tab);
            if (tab == '1') {
                historyPageApi(7, selectedSource)
                IncidentApi(7, selectedSource)
                IncidentHeader(7, selectedSource)
            } else if (tab == '2') {
                historyPageApi(30, selectedSource)
                IncidentApi(30, selectedSource)
                IncidentHeader(30, selectedSource)
            } else if (tab == '3') {
                historyPageApi(90, selectedSource)
                IncidentApi(90, selectedSource)
                IncidentHeader(90, selectedSource)
            }
            setLookBackInputHis('')
        }
    }
    function handleKeyPressHis(event) {
        if (event.key === 'Enter') {
            historyPageApi(lookBackInputHis, selectedSource)
            IncidentApi(lookBackInputHis, selectedSource)
            IncidentHeader(lookBackInputHis, selectedSource)
            setLookbackHis('')
        }
    }

    function handleChangeLookHis(event) {
        if (!isNaN(event.target.value)) {
            const firstValue = (event.target.value).trim()
            const trimmedValue = firstValue.replace(/\./g, '');
            if (trimmedValue <= 300) {
                if (trimmedValue == '0') {
                    setLookBackInputHis('')
                } else {
                    setLookBackInputHis(trimmedValue);
                }
            } else {
                setLookBackInputHis('')
            }
        }
    }



    const handleReset = () => {
        setBackgroundColor({ pass: genColor('#5470c6'), fail: genColor('#ee6666'), warning: genColor('#fac858') })
        if (lookback == '1') {
            miniChartDataCall(7, '')
        } else if (lookback == '2') {
            miniChartDataCall(30, '')
        } else if (lookback == '3') {
            miniChartDataCall(90, '')
        } else if (lookback == '4') {
            // miniChartDataCall TODO
        } else {
            miniChartDataCall(lookBackInput, '')
        }
    }
    return (
        <div className='p-4 mt-5' >

            {FullPageLoader ? <div className='position-relative' >
                <div className='backdrop'></div>
                <div className='spinner-container'><Spinner /></div>

            </div> : null}

            <div className='row mb-0'>

                <div className='col-12  adq-source d-flex justify-content-end'>
                    <div>
                        <div className='d-flex  align-items-center justify-content-end'>
                            <p className='mb-0' style={{ fontSize: '14px' }}>Lookback Period : </p>
                            <button type="button" className={`adq-button ${lookback === '1' ? 'active' : ''}`} onClick={() => { toggleLookback('1'); }}>1 Week</button>
                            <button type="button" className={`adq-button ${lookback === '2' ? 'active' : ''}`} onClick={() => { toggleLookback('2'); }}>1 Month</button>
                            <button type="button" className={`adq-button ${lookback === '3' ? 'active' : ''}`} onClick={() => { toggleLookback('3'); }} >3 Months</button>
                            <button type="button" className={`adq-button ${lookback === '4' ? 'active' : ''}`} onClick={() => { toggleLookback('4'); }} style={{ borderRadius: '3px', outline: "none", border: "none", fontSize: "14px", padding: "4px", marginLeft: '5px', boxShadow: '1px 0px 5px 0px #e2cdcd' }}>Custom</button>
                            <input type='text' placeholder='Enter' className='form-control ms-1' style={{ width: '13%', height: '26px' }} value={lookBackInput} onInput={handleChangeLook} onKeyPress={handleKeyPress} />
                        </div>
                        {
                            lookback === '4' ?
                                <div className='d-flex justify-content-end mt-2 '>
                                    <div className='row w-75'>
                                        <div className='col-6 '>
                                            <label className='fs-6'>Start Date</label>
                                            <input type="date" className='form-control w-100' value={lookbackDate?.startDate} name='startDate' onInput={handleDateLookBack} max={moment(new Date()).format('YYYY-MM-DD')}></input>
                                        </div>
                                        <div className=' col-6 '>
                                            <label className='fs-6'>End Date</label>
                                            <input type="date" className='form-control w-100' min={lookbackDate?.startDate} value={lookbackDate?.endDate} name='endDate' max={moment(new Date()).format('YYYY-MM-DD')} onInput={handleDateLookBack}></input>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                </div>
            </div>

            <div className='row mt-0'>
                <div className='col-6 px-1'>
                    <div className='card p-3 adq-card h-100'>
                        <div className='d-flex'>
                            <h2 className='adq-source'>{selectedPrj?.display_name} </h2>
                            {/* <p className='db-logo'> <img src={logoPath} alt="Logo" style={{ width: '25px', height: '25px', marginRight: '5px'}} />{headersData?.database}</p> */}
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div className='me-2'>
                                <p className='mb-0'>Sources</p>
                                <span class="counter font-weight-normal">{headersData?.source_count}</span>
                            </div>
                            <div className='me-2'>
                                <p className='mb-0'>Tables</p>
                                <span class="counter font-weight-normal">{headersData?.total_table}</span>
                            </div>
                            <div className='me-2'>
                                <p className='mb-0'>Rules</p>
                                <span class="counter font-weight-normal">{headersData?.rulesCnt}</span>
                            </div>
                            <div className='me-2'>
                                <p className='mb-0'>Checks</p>
                                <span class="counter font-weight-normal">9</span>
                            </div>
                            <div className='me-2'>
                                <p className='mb-0'>Pass</p>
                                <span class="counter font-weight-normal">{headersData?.headerCounts?.pssCnt}</span>
                            </div>
                            <div className='me-2'>
                                <p className='mb-0'>Fail</p>
                                <span class="counter font-weight-normal">{headersData?.headerCounts?.failCnt}</span>
                            </div>
                            <div className='me-2'>
                                <p className='mb-0'>Warning</p>
                                <span class="counter font-weight-normal">{headersData?.headerCounts?.warnCnt}</span>
                            </div>


                        </div>
                    </div>
                </div>
                <div className='col-3 px-1'>
                    <div className='card p-3 adq-card' >
                        <h2 className='adq-source'>Monitors</h2>

                        <div style={{ height: "100px" }}>
                            {
                                initialDonutChartData?.datasets[0]?.data.length == 0 ?
                                    <div class=" d-flex justify-content-center align-items-center h-100">
                                        <div>
                                            <p class="text-center">No Data Found</p>
                                        </div>
                                    </div> :
                                    <Doughnut data={initialDonutChartData} options={chartoption} className='mx-auto' />
                            }
                        </div>
                    </div>
                </div>
                <div className='col-3 px-1'>
                    <div className='card p-3 adq-card h-100'>
                        <div className='d-flex'>
                            <h2 className='adq-source'>Visualizations </h2>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div className='me-2'>
                                <p className='mb-0'>Sources</p>
                                <span class="counter font-weight-normal">{headersData?.powerBIdata?.dataAreaCnt}</span>
                            </div>
                            <div className='me-2'>
                                <p className='mb-0'>Dashboards</p>
                                <span class="counter font-weight-normal">{headersData?.powerBIdata?.dashboardCnt}</span>
                            </div>
                            <div className='me-2'>
                                <p className='mb-0'>Tiles</p>
                                <span class="counter font-weight-normal">{headersData?.powerBIdata?.tilecCnt}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-6 px-1 '>
                    <div className='card  adq-card p-3 h-100'>
                        <div className='d-flex justify-content-between'>
                            <h2 className='adq-source text-center'>Checks By Sources</h2>
                            <button className='btn btn-sm  h-75 pt-0' style={{ backgroundColor: "#176b87", color: 'white' }} onClick={() => handleReset()}>Reset</button>
                        </div>
                        <div className='col-4 mt-4 w-100 vh-100 d-flex justify-content-center align-items-center '>
                            {sourceLoader ? <div className='text-center'>
                                <Bars type="Puff" color="#176b87" height={100} width={100} />
                                <h6>Loading . . .</h6>
                            </div> : bigChartData?.labels?.length <= 0 ? <div class="container d-flex justify-content-center align-items-center vh-100">
                                <div>
                                    <p class="text-center">No Data Found</p>
                                </div>
                            </div> :
                                <Bar data={bigChartData} options={sourceOptions} width={100} height={110} />

                            }
                        </div>
                        <CustomTooltip data={bigChartData} />
                    </div>
                </div>
                <div className='col-6 px-1'>
                    <div className='row ' style={{ height: '33%' }}>
                        <div className='col-6 pe-1 h-100'>
                            {mandtryChk?.length <= 0 ? <div class="container d-flex justify-content-center align-items-center h-100 border border-secondary " style={{ minHeight: '250px' }} >
                                <div>
                                    <p class="text-center">No Data Found</p>
                                </div>
                            </div> : <div className='card p-3 adq-card h-100'>
                                <DataReliabilityChart data={mandtryChk} title="Mandatory Field Inspector" ></DataReliabilityChart>
                            </div>}

                        </div>
                        <div className='col-6 ps-1 h-100'>
                            {validityChk?.length <= 0 ? <div class="container d-flex justify-content-center align-items-center h-100 border border-secondary" style={{ minHeight: '250px' }}>
                                <div>
                                    <p class="text-center">No Data Found</p>
                                </div>
                            </div> :
                                <div className='card p-3 adq-card h-100'>
                                    <DataReliabilityChart data={validityChk} title="Validity Checker"></DataReliabilityChart>
                                </div>
                            }
                        </div>

                    </div>
                    <div className='row mt-2 ' style={{ height: '33%' }}>
                        <div className='col-6 pe-1 h-100'>
                            {freshChk?.length <= 0 ? <div class="container d-flex justify-content-center align-items-center h-100 border border-secondary " style={{ minHeight: '250px' }} >
                                <div>
                                    <p class="text-center">No Data Found</p>
                                </div>
                            </div> : <div className='card p-3 adq-card  h-100'>
                                <DataReliabilityChart data={freshChk} title="Freshness Validator"></DataReliabilityChart>
                            </div>}

                        </div>
                        <div className='col-6 ps-1 h-100'>
                            {
                                relatnChk?.length <= 0 ? <div class="container d-flex justify-content-center align-items-center h-100 border border-secondary " style={{ minHeight: '250px' }} >
                                    <div>
                                        <p class="text-center">No Data Found</p>
                                    </div>
                                </div> : <div className='card p-3 adq-card h-100'>
                                    <DataReliabilityChart data={relatnChk} title="Relationship Validator"></DataReliabilityChart>
                                </div>
                            }

                        </div>
                    </div>
                    <div className='row mt-2' style={{ height: '32%' }}>
                        <div className='col-6 pe-1 h-100' >
                            {
                                lenChk?.length <= 0 ? <div class="container d-flex justify-content-center align-items-center h-100 border border-secondary " style={{ minHeight: '250px' }} >
                                    <div>
                                        <p class="text-center">No Data Found</p>
                                    </div>
                                </div> : <div className='card p-3 adq-card h-100'>
                                    <DataReliabilityChart data={lenChk} title="Length Inspector" ></DataReliabilityChart>
                                </div>
                            }

                        </div>
                        <div className='col-6 ps-1 h-100' >
                            {
                                compChk?.length <= 0 ? <div class="container d-flex justify-content-center align-items-center h-100 border border-secondary " style={{ minHeight: '250px' }} >
                                    <div>
                                        <p class="text-center">No Data Found</p>
                                    </div>
                                </div> : <div className='card p-3 adq-card h-100'>
                                    <DataReliabilityChart data={compChk} title="Composite Key Procter"  ></DataReliabilityChart>
                                </div>
                            }

                        </div>
                    </div>
                </div>

            </div>
            <div className='row px-0' style={{ height: "300px" }}>
                <div className='d-flex justify-content-between mt-2 h-100 pe-0 ps-0'>
                    <div className='col-4 px-1 h-100'>
                        {
                            nullChk?.length <= 0 ? <div class="container d-flex justify-content-center align-items-center h-100 border border-secondary " style={{ minHeight: '250px' }} >
                                <div>
                                    <p class="text-center">No Data Found</p>
                                </div>
                            </div> : <div className='card p-3 adq-card h-100'>
                                <DataReliabilityChart data={nullChk} title="Null Detector"></DataReliabilityChart>
                            </div>
                        }

                    </div>
                    <div className='col-4 px-1  h-100'>
                        {
                            rowChk?.length <= 0 ? <div class="container d-flex justify-content-center align-items-center h-100 border border-secondary " style={{ minHeight: '250px' }} >
                                <div>
                                    <p class="text-center">No Data Found</p>
                                </div>
                            </div> : <div className='card p-3 adq-card  h-100'>
                                <DataReliabilityChart data={rowChk} title="Row Count Checker"></DataReliabilityChart>
                            </div>
                        }

                    </div>
                    <div className='col-4 px-1  h-100'>
                        {
                            duplicateChk?.length <= 0 ? <div class="container d-flex justify-content-center align-items-center h-100 border border-secondary " style={{ minHeight: '250px' }} >
                                <div>
                                    <p class="text-center">No Data Found</p>
                                </div>
                            </div> : <div className='card p-3 adq-card  h-100'>
                                <DataReliabilityChart data={duplicateChk} title="Duplication Detector"></DataReliabilityChart>
                            </div>
                        }

                    </div>
                </div>
            </div>

            <SwipeableDrawer
                anchor="right"
                open={isOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                PaperProps={{ style: { width: "55%" } }}
                transitionDuration={1000}
            >
                <div role="presentation">
                    <div className='p-5 pt-3'>
                        <div className='row'>
                            <div className='col-4'>
                                <h6>Source : <span className='spann'>{selectedSource}</span></h6>
                            </div>
                            <div className='col-8 ps-3 adq-source'>
                                <div>
                                    <div className='d-flex  align-items-center justify-content-end'>
                                        <p className='mb-0' style={{ fontSize: '14px' }}>Lookback Period : </p>
                                        <button type="button" style={{ fontSize: '10px' }} className={`adq-button ${lookbackHis === '1' ? 'active' : ''}`} onClick={() => { toggleLookbackHis('1'); }}>1 Week</button>
                                        <button type="button" style={{ fontSize: '10px' }} className={`adq-button ${lookbackHis === '2' ? 'active' : ''}`} onClick={() => { toggleLookbackHis('2'); }}>1 Month</button>
                                        <button type="button" style={{ fontSize: '10px' }} className={`adq-button ${lookbackHis === '3' ? 'active' : ''}`} onClick={() => { toggleLookbackHis('3'); }} >3 Month</button>
                                        <button type="button" className={`adq-button  ${lookbackHis === '4' ? 'active' : ''}`} style={{ fontSize: '10px' }} onClick={() => { toggleLookbackHis('4'); }}>Custom</button>
                                        <input type='text' id='HistoryInputDataReliability' placeholder='Enter' value={lookBackInputHis} onInput={handleChangeLookHis} onKeyPress={handleKeyPressHis} className='form-control ms-1' style={{ width: '13%', height: '20px' }}></input>
                                    </div>
                                    {
                                        lookbackHis === '4' ?
                                            <div className='d-flex justify-content-end mt-2 '>
                                                <div className='row  '>
                                                    <div className='col-6 '>
                                                        <label style={{ fontSize: '12px' }}>Start Date</label>
                                                        <input type="date" style={{ height: '27px' }} className='form-control w-100' value={hisLookbackDate?.startDate} name='startDate' onInput={handleHisDateLookBack} max={moment(new Date()).format('YYYY-MM-DD')} ></input>
                                                    </div>
                                                    <div className=' col-6 '>
                                                        <label style={{ fontSize: '12px' }}>End Date</label>
                                                        <input type="date" style={{ height: '27px' }} className='form-control w-100' min={hisLookbackDate?.startDate} value={hisLookbackDate?.endDate} name='endDate' max={moment(new Date()).format('YYYY-MM-DD')} onInput={handleHisDateLookBack}></input>
                                                    </div>
                                                </div>

                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            {
                                param.tablename == undefined ? 
                                <div className='col-2 px-1'>
                                    <div className='card h-100 adq-card'>
                                        <div className='my-auto'>
                                            <p className='text-center mb-0 mt-1' style={{ fontSize: '13px', fontWeight: '700' }}>TOTAL TABLES</p>
                                            <p className='mb-0 text-center'>{headerOfIncident?.tblCnt}</p>
                                        </div>
                                    </div>
                                </div>
                                :null
                            }
                            <div className={param.tablename != undefined ? 'col-3 px-1':'col-2 px-1'}>
                                <div className='card h-100 adq-card'>
                                    <div className='my-auto'>
                                        <p className='text-center mb-0 mt-1' style={{ fontSize: '13px', fontWeight: '700' }}>INCIDENTS</p>
                                        <p className='mb-0 text-center'>{headerOfIncident?.totIncd}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={param.tablename != undefined ? 'col-3 px-1':'col-2 px-1'}>
                                <div className='card h-100 adq-card'>
                                    <div className='my-auto'>
                                        <p className='text-center mb-0 mt-1' style={{ fontSize: '13px', fontWeight: '700' }}>ERRORS</p>
                                        <p className='mb-0 text-center'>{headerOfIncident?.failCnt}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={param.tablename != undefined ? 'col-3 px-1':'col-2 px-1'}>
                                <div className='card  h-100 adq-card'>
                                    <div className='my-auto'>
                                        <p className='text-center mb-0 mt-1' style={{ fontSize: '13px', fontWeight: '700' }}>WARNINGS</p>
                                        <p className='mb-0 text-center'>{headerOfIncident?.warnCnt}</p>
                                    </div>
                                </div>
                            </div>
                            {
                                param.tablename == undefined ?
                                <div className='col-2 px-1'>
                                    <div className='card  h-100 adq-card'>
                                        <div className='my-auto'>
                                            <p className='text-center mb-0 mt-1' style={{ fontSize: '13px', fontWeight: '700' }}>INCIDENT FREE TABLES</p>
                                            <p className='mb-0 text-center'>{headerOfIncident?.incidentFree}</p>
                                        </div>
                                    </div>
                                 </div>:null
                            }
                            <div className={param.tablename != undefined ? 'col-3 px-1':'col-2 px-1'}>
                                <div className='card h-100 adq-card'>
                                    <div className='my-auto'>
                                        <p className='text-center mb-0 mt-1' style={{ fontSize: '13px', fontWeight: '700' }}>AVERAGE</p>
                                        <p className='mb-0 text-center'>{headerOfIncident?.avgIncdOnTbl}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Bar data={data2} options={options2} width={100} height={20} />
                        <div className='d-flex justify-content-between'>
                            <p className='adq-source'> Table Dashboard</p>
                            <button class="btn btn-sm  h-75 pt-0" style={{ backgroundColor: "#176b87", color: 'white' }} onClick={() => { handleIncident([], [], 'reset') }}>Reset</button>
                        </div>
                        <Bar data={data} options={options} width={100} height={60} />
                        <div className="mt-3">
                            <DataReliabilityTable data={historyTable}></DataReliabilityTable>
                        </div>
                    </div>
                </div>

            </SwipeableDrawer>
            {isTooltipFixed && window.addEventListener('click', handleClickOutsideTooltip)
            }
        </div >
    )
}

export default DataReliability;
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Cytoscape from './Cytoscape';
import Spinner from '../widgets/Spinner';
import ApiClient from "./ApiClient"
const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
    const [name, value] = cookie.split('=');
    acc[name] = decodeURIComponent(value);
    return acc;
}, {});
const headers = {
    'X-CSRFToken': cookies.csrftoken,
    'Content-Type': 'application/json'
};
function TableLineage() {

    const [source, setSource] = useState([]);
    const [table, setTable] = useState([]);
    const [lineageData, setLineageData] = useState({ 'nodes': [] });
    const [state, setState] = useState({ source: '', table: '' });
    const [FullPageLoader, setFullPageLoader] = useState(false);
    const getSource = async () => {
        try {
            var data = await axios.post('/lineage/parseSourceName/', {}, { headers });
            setSource(data?.data?.data)
        } catch (error) {
            console.log(error);
        }
    }

    const getTable = async (source) => {
        if (source != "") {
            try {
                var data = await axios.post('/lineage/getfileName/', { 'sourceName': source }, { headers })
                setTable(data?.data?.data)
            } catch (error) {
                console.log(error);
                setTable([])
            }
        } else {
            setTable([])
        }
    }
    const handleChange = (e) => {
        let newState = { ...state }

        if (e.target.name == "source") {
            getTable(e.target.value)
            newState['table'] = '';
        }
        newState[e.target.name] = e.target.value
        setState(newState)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
          getSource();
        }, 3000);
  
        return () => clearTimeout(timer);
      }, []);

    const getLineageData = async (source, table) => {
        setFullPageLoader(true);
        if (source != '') {
            try {
                var data = await axios.post('/lineage/metric_lineage_tableData_validation/', { 'source_name': source, 'file_name': table }, { headers })
                setLineageData(data?.data?.data)
            } catch (error) {
                console.log(error);
            }
        }
        setFullPageLoader(false)
    }
    const apply = () => {
        var lineage = { ...state }
        getLineageData(lineage.source, lineage.table);
    }

    return (
        <div className='p-4 mt-5 h-100'>
            {FullPageLoader ? <div className='position-relative' >
                <div className='backdrop'></div>
                <div className='spinner-container'><Spinner /></div>

            </div> : null}
            <div className='card adq-card p-3' style={{ minHeight: '70vh' }}>
                <h2 className='adq-source'>Table Lineage</h2>
                <div className='row'>
                    <div className='col-3'>
                        <label className='fw-bold'> Source</label>
                        <select name='source' value={state?.source} className='form-control' onChange={handleChange}>
                            <option value="" selected>Select Source</option>
                            {
                                source?.map((ele) => {
                                    return (
                                        <option value={ele}>{ele}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className='col-3'>
                        <label className='fw-bold'> Table</label>
                        <select name='table' value={state?.table} className='form-control' onChange={handleChange} >
                            <option value="" selected>Select Table</option>
                            {
                                table.length ?
                                    table?.map((ele) => {
                                        return (
                                            <option value={ele}>{ele}</option>
                                        )
                                    }) : null
                            }
                        </select>
                    </div>
                    <div className='col-3'>
                        <button className='btn mt-4 ' style={{ backgroundColor: "#176b87", color: 'white' }} onClick={() => { apply() }} >Search</button>
                    </div>
                </div>
                <div className='mt-4'>
                    {
                        lineageData['nodes'].length ?
                            <Cytoscape lineageData={lineageData} />
                            : null
                    }


                </div>
            </div>
        </div>
    )
}

export default TableLineage
import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net';
import '../css/Home.css';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { SwipeableDrawer } from '@mui/material';
import 'sweetalert2/dist/sweetalert2.min.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Spinner from '../widgets/Spinner';

import '../css/animate.css';
import MRules from './MRules';
import axios from 'axios';

var initcolumn = {
    Metric_Id: ["", 1],
    Source: ["", 2],
    Area: ["", 3],
    Table: ["", 4],
    Field: ["", 5],
    Metrics: ["", 6],
    Type: ["", 7],
    Self: ["", 8],
    Expected: ["", 9],
    L1: ["", 10],
    L2: ["", 11],
    L3: ["", 12],
    Description: ["", 13]
}
function MetricDataTable(props) {
    const tableRef = useRef(null);
    const dataTableRef = useRef(null);
    const [clickedRow, setClickedRow] = useState(null);
    const [columnText, setColumnText] = useState(initcolumn);
    const [isFilter, setIsFilter] = useState(true)
    const [metrics, setMetrics] = useState([])
    const [resultModal, setResultModal] = useState({ type: '' })
    const [FullPageLoader, setFullPageLoader] = useState(false);
    const [source, setSource] = useState([]);

    useEffect(() => {
        const timer = setTimeout(() => {
          getSourceName();
        }, 3000);
  
        return () => clearTimeout(timer);
      }, []);

    const getSourceName = async () => {
        try {
            let data = await axios.get('/getSourceTable/')
            setSource(data.data.source)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (metrics.length) {
            if (tableRef.current && !dataTableRef.current) {
                // Only initialize the DataTable if it hasn't been initialized before
                const dataTableInstance = $(tableRef.current).DataTable({
                    searching: true,
                    ordering: isFilter,
                    language: {
                        searchPlaceholder: "Search records",
                    },
                    lengthChange: false,
                });

                dataTableRef.current = dataTableInstance; // Store the DataTable instance
                if (dataTableRef.current) {
                    dataTableRef.current.order([0, 'desc']).draw();
                }
            }
        }
        document.body.classList.add('bg-salmon');
    }, [metrics]);


    useEffect(() => {
        if (metrics.length) {
            if (isFilter) {
                setColumnText(initcolumn)
            }
        }
    }, [isFilter])




    useEffect(() => {
        if (metrics.length) {
            Object.keys(columnText)?.map((ele) => {
                var temp = columnText[ele]
                dataTableRef.current.column(temp[1] - 1).search(temp[0]).draw();
            })
        }
    }, [columnText])

    const handleText = (e, position) => {
        setColumnText({
            ...columnText,
            [e.target.name]: [e.target.value, position]
        });
    }


    const handleRowClick = (index) => {
        // Update the state to track the clicked row index
        setClickedRow(index);
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverData, setPopoverData] = useState([]);
    const handleClick = (event, data) => {
        setAnchorEl(event.currentTarget);
        setPopoverData(data)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    var popoverStyle = {
        border: " 0px solid rgba(0, 0, 0, .2)",
        borderRadius: "2px",
        fontFamily: "Roboto,sans-serif",
        boxShadow: " 0 2px 10px rgba(0, 0, 0, .2)",
        padding: "5px 15px 10px 15px",
        lineHeight: " 24px",
        fontSize: "14px",
        width: "135px",
        height: "135px",
    }



    const [isOpen, setIsOpen] = useState(false);
    const [DrawerContent, setDrawerContent] = useState(null);
    const handleSave = (results) => {
        if (results.action != "Test Rule") {
            setIsOpen(false);
        }
        setTestOpen(true)
        var temp = { ...results }
        setResultModal(temp)

        // setTimeout(() => {
        //     Swal.fire('Metric Rules Saved Successfully', '', 'success');
        //     setTimeout(() => {
        //         Swal.close();
        //     }, 2000);
        // }, 500);
    }


    const TestStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        borderRadius: '5px',
        boxShadow: 24,
        p: 4,
        padding: 0,
    };


    const removeStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        borderRadius: '5px',
        boxShadow: 24,
        p: 4,
    };
    const [testOpen, setTestOpen] = useState(false);
    const handleTestOpen = async (newTest) => {
        if (newTest == 'Yes') {
            setAnchorEl(null);
            setFullPageLoader(true)
            try {
                let data = await axios.get('/prefectMetricValidation/', { params: { 'qcID': popoverData.QC_ID } })
                let modalData = data.data
                modalData.action = 'TestRule'
                setResultModal(modalData)
            } catch (error) {
                setFullPageLoader(false)

            }

            setFullPageLoader(false)
            setTestOpen(true)
        } else {
            setTestOpen(true)
        }

    };
    const handleTestClose = () => {
        var isReload = { ...resultModal }
        if (isReload.type == "reload") {
            props.reRenderTable()
        }
        setTestOpen(false)
    };
    const toggleDrawer = (open, data = null) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        switch (data) {
            case 'Add':
                setDrawerContent(<MRules handleSavePop={(ele) => { handleSave(ele) }} handleTestOpen={handleTestOpen}></MRules>);
                break;
            case 'Edit':
                setDrawerContent(<MRules data={popoverData} handleSavePop={(ele) => { handleSave(ele) }} handleTestOpen={handleTestOpen}></MRules>);
                break;
            default:
                setDrawerContent(null)
        }
        setAnchorEl(null)
        setIsOpen(open);
    };

    const [removeOpen, setRemoveOpen] = useState(false);
    const handleRemove = () => {
        setAnchorEl(false)
        setRemoveOpen(true)
    }

    const handleremoveClose = () => {
        setRemoveOpen(false)
    }

    const removeData = () => {
        setRemoveOpen(false)
        props.removeData(popoverData?.QC_ID)
    }

    useEffect(() => {
        setMetrics(props?.tableData)
    }, [props])

    return (
        <div className='p-4 mt-5'>
            {FullPageLoader ? <div className='position-relative' >
                <div className='backdrop'></div>
                <div className='spinner-container'><Spinner /></div>

            </div> : null}
            <div className='card adq-card p-3'>
                <div className='d-flex justify-content-between'>
                    <h2 className='adq-source'>Metric Rules</h2>
                    <div className='d-flex justify-content-end'>
                        <button className='btn ms-1 btn-sm h-100  pe-4 ps-4' style={{ backgroundColor: "#176b87", color: 'white' }} onClick={() => { setIsFilter(!isFilter) }}>Filter</button>
                        <button className='btn ms-1 btn-sm h-100 pe-3 ps-3' style={{ backgroundColor: "#176b87", color: 'white' }} onClick={toggleDrawer(true, 'Add')}>Add Rule</button>
                    </div>
                </div>
                <div className='table-responsive mt-2'>
                    <table id="data-table-basic" class="table table-striped" ref={tableRef}>
                        <thead>

                            <tr className={isFilter ? 'd-none' : ''}>
                                <th ><input className='form-control' value={columnText.Metric_Id[0]} style={{ minWidth: "85px" }} name="Metric_Id" onInput={(e) => handleText(e, 1)} placeholder='Search' type="text"></input></th>
                                <th><select className='form-control' value={columnText.Source[0]} style={{ minWidth: "190px" }} name="Source" onInput={(e) => handleText(e, 2)}><option value="">Select</option>
                                    {
                                        source?.map((ele) => (
                                            <option value={ele.SOURCE}>{ele.SOURCE}</option>
                                        ))
                                    }
                                </select></th>
                                <th><input className='form-control' value={columnText.Area[0]} style={{ minWidth: "85px" }} name="Area" onInput={(e) => handleText(e, 3)} placeholder='Search' type="text"></input></th>
                                <th><input className='form-control' value={columnText.Table[0]} style={{ minWidth: "85px" }} name="Table" onInput={(e) => handleText(e, 4)} placeholder='Search' type="text"></input></th>
                                <th ><input className='form-control' value={columnText.Field[0]} style={{ minWidth: "85px" }} name="Field" onInput={(e) => handleText(e, 5)} placeholder='Search' type="text"></input></th>
                                <th><input className='form-control' value={columnText.Metrics[0]} style={{ minWidth: "85px" }} name="Metrics" onInput={(e) => handleText(e, 6)} placeholder='Search' type="text"></input></th>
                                <th><input className='form-control' value={columnText.Type[0]} style={{ minWidth: "85px" }} name="Type" onInput={(e) => handleText(e, 7)} placeholder='Search' type="text"></input></th>
                                <th><input className='form-control' value={columnText.Self[0]} style={{ minWidth: "85px" }} name="Self" onInput={(e) => handleText(e, 8)} placeholder='Search' type="text"></input></th>
                                <th><input className='form-control' value={columnText.Expected[0]} style={{ minWidth: "85px" }} name="Expected" onInput={(e) => handleText(e, 9)} placeholder='Search' type="text"></input></th>
                                <th><input className='form-control' value={columnText.L1[0]} style={{ minWidth: "85px" }} name="L1" onInput={(e) => handleText(e, 10)} placeholder='Search' type="text"></input></th>
                                <th><input className='form-control' value={columnText.L2[0]} style={{ minWidth: "85px" }} name="L2" onInput={(e) => handleText(e, 11)} placeholder='Search' type="text"></input></th>
                                <th><input className='form-control' value={columnText.L3[0]} style={{ minWidth: "85px" }} name="L3" onInput={(e) => handleText(e, 12)} placeholder='Search' type="text"></input></th>
                                <th><input className='form-control' value={columnText.Description[0]} style={{ minWidth: "85px" }} name="Description" onInput={(e) => handleText(e, 13)} placeholder='Search' type="text"></input></th>
                            </tr>
                            <tr>
                                <th>Metric Id</th>
                                <th>Source</th>
                                <th>Data Area</th>
                                <th>Table Name</th>
                                <th>Field Name</th>
                                <th>Metrics Name</th>
                                <th>Qc Type</th>
                                <th>Is Self Validating</th>
                                <th>Expected Result</th>
                                <th>L1 Query</th>
                                <th>L2 Query</th>
                                <th>L3 Query</th>
                                <th>Description Comment</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                metrics?.map((ele, index) => {
                                    return (
                                        <tr
                                            key={ele.QC_ID}
                                            className={index === clickedRow ? 'clicked-row' : ''}
                                            onClick={() => handleRowClick(index)}
                                            style={{
                                                cursor: 'pointer', minHeight: '25px',
                                                height: '25px'
                                            }}
                                        >
                                            <td onClick={(e) => handleClick(e, ele)} variant="contained">{ele?.METRIC_ID}</td>
                                            <td onClick={(e) => handleClick(e, ele)} variant="contained">{ele?.SOURCE}</td>
                                            <td onClick={(e) => handleClick(e, ele)} variant="contained">{ele?.DATA_AREA}</td>
                                            <td onClick={(e) => handleClick(e, ele)} variant="contained">{ele?.TABLE_NAME}</td>
                                            <td onClick={(e) => handleClick(e, ele)} variant="contained">{ele?.FIELD_NAME}</td>
                                            <td onClick={(e) => handleClick(e, ele)} variant="contained">{ele?.METRICS_NAME}</td>
                                            <td onClick={(e) => handleClick(e, ele)} variant="contained">{ele?.QC_TYPE}</td>
                                            <td onClick={(e) => handleClick(e, ele)} variant="contained">{ele?.IS_SELF_VALIDATING}</td>
                                            <td onClick={(e) => handleClick(e, ele)} variant="contained">{ele?.EXPECTED_RESULT}</td>
                                            <td onClick={(e) => handleClick(e, ele)} variant="contained"><span style={{ height: '130px', display: ' block', overflowY: 'scroll' }}>{ele?.L1_QUERY}</span></td>
                                            <td onClick={(e) => handleClick(e, ele)} variant="contained"><span style={{ height: '130px', display: ' block', overflowY: 'scroll' }}>{ele?.L2_QUERY}</span></td>
                                            <td onClick={(e) => handleClick(e, ele)} variant="contained"><span style={{ height: '130px', display: ' block', overflowY: 'scroll' }}>{ele?.L3_QUERY}</span></td>
                                            <td onClick={(e) => handleClick(e, ele)} variant="contained">{ele?.DESCRIPTION_COMMENT}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                        {/* <tfoot>
                        <tr>
                            <th>Source</th>
                            <th>Run At</th>
                            <th>Table Name</th>
                            <th>Test Name</th>
                            <th>Status</th>
                            <th>Discrepancy Count</th>
                        </tr>
              </tfoot> */}
                    </table>
                </div>
                <Modal
                    open={testOpen}
                    onClose={handleTestClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={TestStyle}
                    //className='animated zoomIn'
                    >
                        <table className="table table-striped">
                            <thead >
                                <tr>
                                    <th style={{ backgroundColor: '#176b87', color: 'white', borderTopRightRadius: '5px', borderTopLeftRadius: '5px' }}>
                                        <div className='d-flex justify-content-between mb-2'>
                                            {resultModal.action ? <div className='text-center text-uppercase'><h6  >Operation Performed : <span>{resultModal?.action}</span></h6></div> : null}
                                            <i class="fa-solid fa-x" style={{ fontSize: '10px' }} onClick={handleTestClose}></i>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                        <div className='p-3'>
                            <table class="table table-striped" >
                                <thead>
                                </thead>
                                <tbody>
                                    {

                                        Object.keys(resultModal)?.map((ele) => {
                                            if (resultModal[ele] != null && resultModal[ele] != '' && ele != "type" && ele != 'action') {
                                                return (
                                                    <tr>
                                                        <td className='fw-bold'>{ele}</td>
                                                        {resultModal[ele]=="PASSED" ? <td><span   style={{backgroundColor:'rgb(180, 239, 211)'}}>{resultModal[ele]}</span></td> : resultModal[ele]=="FAILED" ? <td ><span style={{backgroundColor:'rgb(249, 190, 190)'}}>{resultModal[ele]}</span></td> : resultModal[ele]=="WARNING" ? <td ><span style={{backgroundColor:'rgb(252, 240, 183)'}}>{resultModal[ele]}</span> </td> :<td>{resultModal[ele]}</td>}
                                                    </tr>
                                                )
                                            }
                                        })
                                    }
                                </tbody>
                            </table>
                            <div className='d-flex justify-content-around'>
                                <button className='btn btn-sm h-100 py-0' onClick={handleTestClose} style={{ backgroundColor: "#176b87", color: 'white', width: '10%' }}>OK</button>
                            </div>
                        </div>
                    </Box>
                </Modal>
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'center', // Center vertically
                        horizontal: 'right', // Align to the right side
                    }}
                    transformOrigin={{
                        vertical: 'center', // Center vertically
                        horizontal: 'left', // Align to the left side
                    }}
                    style={{ borderRadius: "5px" }}
                >
                    <Typography style={popoverStyle} >
                        <div>
                            <button className='btn btn-sm w-100 mt-2' style={{ backgroundColor: "#176b87", color: 'white' }} onClick={toggleDrawer(true, 'Edit')}>Edit Rule</button>
                            <button className='btn btn-sm w-100 mt-2' style={{ backgroundColor: "#176b87", color: 'white' }} onClick={() => { handleTestOpen("Yes") }}>Test Rule</button>
                            <button className='btn btn-sm w-100 mt-2' style={{ backgroundColor: "#176b87", color: 'white' }} onClick={() => { handleRemove() }}>Remove Rule</button>
                        </div>
                    </Typography>
                </Popover>


                <SwipeableDrawer
                    anchor="right"
                    open={isOpen}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                    PaperProps={{ style: { width: "40%" } }}
                    transitionDuration={1000}
                >
                    <div role="presentation" >
                        <div className='p-5'>
                            {
                                DrawerContent
                            }
                        </div>
                    </div>
                </SwipeableDrawer>



                <Modal
                    open={removeOpen}
                    onClose={handleremoveClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={removeStyle}
                    //className='animated zoomIn'
                    >
                        <h5>Are you sure you want to delete ?</h5>
                        <div className='d-flex  justify-content-end'>
                            <button className='btn btn-info mx-1' onClick={() => { handleremoveClose() }}>No</button>
                            <button className='btn mx-1' style={{ backgroundColor: "#176b87", color: 'white' }} onClick={() => { removeData() }}>Yes</button>
                        </div>
                    </Box>
                </Modal>
            </div>
        </div>
    )
}

export default MetricDataTable
import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net';
import '../css/Home.css';

var initcolumn = {
    Source: ["", 1],
    RunAt: ["", 2],
    TableName: ["", 3],
    TestName: ["", 4],
    Status: ["", 5],
    Discrepancy: ["", 6]
}


function OtherCheckIncident(props) {
    const tableRef = useRef(null);
    const dataTableRef = useRef(null);
    const [isFilter, setIsFilter] = useState(true)
    const [columnText, setColumnText] = useState(initcolumn);
    const [tableData, setTableData] = useState([])
    const [crtCategory, setCrtCategory] = useState('')
    useEffect(() => {
        if (tableData.length) {
            if (tableRef.current && !dataTableRef.current) {
                // Only initialize the DataTable if it hasn't been initialized before
                const dataTableInstance = $(tableRef.current).DataTable({
                    pageLength: 5,
                    lengthChange: false,
                    language: {
                        searchPlaceholder: "Search records",
                    }
                });

                dataTableRef.current = dataTableInstance; // Store the DataTable instance
                if (dataTableRef.current) {
                    dataTableRef.current.order([0, 'desc']).draw();
                }
            }
        }

    }, [tableData]);

    useEffect(() => {
        if (isFilter) {
            setColumnText(initcolumn)
        }
    }, [isFilter])

    useEffect(() => {
        if (tableData.length) {
            Object.keys(columnText)?.map((ele) => {
                var temp = columnText[ele]
                dataTableRef.current.column(temp[1] - 1).search(temp[0]).draw();
            })
        }
    }, [columnText])

    const handleText = (e, position) => {
        setColumnText({
            ...columnText,
            [e.target.name]: [e.target.value, position]
        });
    }

    useEffect(() => {
        if (props.tableData) {
            setTableData(props.tableData)
            setCrtCategory(props.type)
        }
    }, [props])
    return (
        <div className='table-responsive'>
            <table id="data-table-basic" class="table table-striped" ref={tableRef}>
                {
                    crtCategory == "Raw Data Rule" ?
                        <>
                            <thead>
                                <tr className={isFilter ? 'd-none' : ''}>
                                    <th ><input className='form-control' value={columnText.Source[0]} style={{ minWidth: "85px" }} name="Source" onInput={(e) => handleText(e, 1)} placeholder='Search' type="text"></input></th>
                                    <th><input className='form-control' value={columnText.RunAt[0]} style={{ minWidth: "85px" }} name="RunAt" onInput={(e) => handleText(e, 2)} placeholder='Search' type="text"></input></th>
                                    <th><input className='form-control' value={columnText.TableName[0]} style={{ minWidth: "85px" }} name="TableName" onInput={(e) => handleText(e, 3)} placeholder='Search' type="text"></input></th>
                                    <th><input className='form-control' value={columnText.TestName[0]} style={{ minWidth: "85px" }} name="TestName" onInput={(e) => handleText(e, 4)} placeholder='Search' type="text"></input></th>
                                </tr>
                                <tr>
                                    <th>Check Type</th>
                                    <th>Check Level</th>
                                    <th>Check Details</th>
                                    <th>Failure Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    tableData?.map((ele) => {
                                        return (
                                            <tr>
                                                <td >{ele[0]}</td>
                                                <td >{ele[1]}</td>
                                                <td >{ele[2]}</td>
                                                <td >{ele[3]}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </>
                        :
                        <>
                            <thead>
                                <tr className={isFilter ? 'd-none' : ''}>
                                    <th ><input className='form-control' value={columnText.Source[0]} style={{ minWidth: "85px" }} name="Source" onInput={(e) => handleText(e, 1)} placeholder='Search' type="text"></input></th>
                                    <th><input className='form-control' value={columnText.RunAt[0]} style={{ minWidth: "85px" }} name="RunAt" onInput={(e) => handleText(e, 2)} placeholder='Search' type="text"></input></th>
                                    <th><input className='form-control' value={columnText.TableName[0]} style={{ minWidth: "85px" }} name="TableName" onInput={(e) => handleText(e, 3)} placeholder='Search' type="text"></input></th>
                                    <th><input className='form-control' value={columnText.TestName[0]} style={{ minWidth: "85px" }} name="TestName" onInput={(e) => handleText(e, 4)} placeholder='Search' type="text"></input></th>
                                </tr>
                                <tr>
                                    <th>Check Type</th>
                                    <th>Check Level</th>
                                    <th>Check Details</th>
                                    <th>Failure Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    tableData?.map((ele) => {
                                        return (
                                            <tr>
                                                <td >{ele[1]}</td>
                                                <td >Column level</td>
                                                <td className='fw-bold' style={{ cursor: 'pointer' }} onClick={() => props.handleRowDetails(ele[0])}>Rule Details</td>
                                                <td >Error</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </>
                }
            </table>
        </div>
    )
}

export default OtherCheckIncident
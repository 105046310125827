import React, { useState, useEffect } from 'react'
import Spinner from '../widgets/Spinner';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../css/settings.css'
import ProjectTab from './ProjectTab';
import AdminRules from './AdminRules';
import axios from 'axios';
import AdminMetric from './AdminMetric';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Swal from 'sweetalert2';
import PowerBI from './PowerBI';
import ApiClient from "./ApiClient"
const intiNewUser = {
    userName: "",
    userEmail: "",
    userPassword: "",
    userConPassword: "",
    userRole: "",
    userProject: ""
}
const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
    const [name, value] = cookie.split('=');
    acc[name] = decodeURIComponent(value);
    return acc;
}, {});
function Settings() {
    const [FullPageLoader, setFullPageLoader] = useState(false)
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectRulesPrj, setSelectRulesPrj] = useState('');
    const [rulesTableData, setRulesTableData] = useState([]);
    const [allProject, setAllProjects] = useState([]);
    const [metricTableData, setMetricTableData] = useState([]);
    const [user, setUser] = useState([]);
    const [addUserModelOpen, setAddUserModelOpen] = useState(false)
    const [newUser, setNewUser] = useState(intiNewUser);
    const [userError, setUserError] = useState('');
    const [currentProject, setCurrentProject] = useState('')
    const [powerBIData, PowerBIData] = useState([])
    const [defaultPrj, setDefaultPrj] = useState('')
    const [userData, setUserData] = useState({})
    const modelStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        borderRadius: '5px',
        boxShadow: 24,
        // p: 2,
        width: '40%'
    };



    const handleNewUser = (evt) => {
        var value = evt.target.value;
        setNewUser({
            ...newUser,
            [evt.target.name]: value
        })
        setUserError('')
    }

    const newUserCreate = async () => {
        if (newUser.userName && newUser.userEmail && newUser.userPassword && newUser.userConPassword && newUser.userRole && newUser.userProject) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newUser.userEmail)) {
                if (newUser.userPassword === newUser.userConPassword) {
                    delete newUser.userConPassword;
                    const headers = {
                        'X-CSRFToken': cookies.csrftoken,
                        'Content-Type': 'application/json'
                    };
                    setUserError('');
                    try {
                        var data = await axios.post('/accounts/addNewUser/', { ...newUser }, { headers });
                        setAddUserModelOpen(false)
                        if (!data.data.Error) {
                            Swal.fire({title:data.data.Result, text:'', icon:'success',
                            confirmButtonColor: "#176b87",});
                        } else {
                            Swal.fire({title:"Error", text:data.data.Error, icon:'error',
                            confirmButtonColor: "#176b87",});
                        }
                    } catch (error) {
                        console.log(error);
                    }
                } else {
                    setUserError('Passwords doesn\'t match')
                }
            } else {
                setUserError('Please enter valid Email')
            }
        } else {
            setUserError('All the fields are required')
        }
    }

    const handleUserList = async (index) => {
        var userList = [...user];
        var changeUser = userList[index];
        changeUser['is_superuser'] = !changeUser.is_superuser
        var role = changeUser.is_superuser ? 'admin' : 'analyst';
        try {
            var data = await axios.get(`/accounts/switchUserRole/${changeUser.username}/${role}`)
            getUser();
            if (!data.data.Error) {
                Swal.fire({title:data.data.Result, text:'', icon:'success',
                confirmButtonColor: "#176b87",});
            } else {
                Swal.fire({title:"Error", text:data.data.Error, icon:'error',
                confirmButtonColor: "#176b87",});
            }
        } catch (error) {
            console.log(error);
        }
    }
    const handleTabSelect = (index) => {
        setSelectedTabIndex(index);
    };
    const getTableRules = async () => {
        setRulesTableData([])
        if (selectRulesPrj != "") {
            try {
                var data = await axios.get(`/accounts/adminTableRule/${selectRulesPrj}/TESTRULE`)
                setRulesTableData(data?.data?.data)
            } catch (error) {
                console.log(error);
            }
        }
    }

    const getMetricRules = async () => {
        setMetricTableData([])
        if (selectRulesPrj != "") {
            try {
                var data = await axios.get(`/accounts/adminTableRule/${selectRulesPrj}/METRIC`)
                setMetricTableData(data?.data?.data)
            } catch (error) {
                console.log(error);
            }
        }
    }

    const getUser = async () => {
        try {
            var data = await axios.get('/accounts/fetchUserRole/')
            var temp = data?.data?.Result
            setUser(temp.slice());

        } catch (error) {
            console.log(error);
        }
    }

    const getReports = async () => {
        PowerBIData([])
        if (currentProject != "") {
            try {
                var data = await axios.get(`/accounts/admin_index/admin/${currentProject}`)
                PowerBIData(data?.data?.context?.powerBIdata);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const editAnalystInfo = async () => {
        const headers = {
            'X-CSRFToken': cookies.csrftoken,
            'Content-Type': 'application/json'
        };
        let data = {
            dftPrj: defaultPrj,
            email: userData.email,
        }
        try {
            setFullPageLoader(true)
            let result = await axios.post('/accounts/userChanges/', data, { headers })
            setFullPageLoader(false)
            if (result.data.Result == 'Success') {
                Swal.fire({title:result.data.Result, text:'', icon:'success',
                confirmButtonColor: "#176b87",});
            } else {
                Swal.fire({title:"Error", text:data.data.Error, icon:'error',
                confirmButtonColor: "#176b87",});
            }
        } catch (error) {
            console.log(error)
        }

    }
    const handleAnalystSelect = (e) => {
        setDefaultPrj(e.target.value)
    }
    useEffect(() => {
        getReports()
    }, [currentProject])


    useEffect(() => {
        getTableRules()
        getMetricRules()
    }, [selectRulesPrj])
    const getProject = async () => {
        try {
            let data = await axios.get('/accounts/addProjects/')
            setAllProjects(data?.data?.project_name);
            setSelectRulesPrj(data?.data?.selected_project)
            setCurrentProject(data?.data?.selected_project)
            setDefaultPrj(data?.data?.defaultProject)
        } catch (error) {
            console.log(error);
        }
    }

    const handleAnalystInput = (evt) => {
        var value = evt.target.value;
        setUserData({
            ...userData,
            [evt.target.name]: value
        })
        setUserError('')
    }
    useEffect(() => {
        getProject()
        getUser()
        if (sessionStorage.getItem('userData') != null) {
            setUserData(JSON.parse(sessionStorage.getItem('userData')))
        } else {
            handleLogout()
        }
    }, [])


    const handleLogout = async () => {
        try {
            var data = await axios.get(`/logoutView/`, {
                withCredentials: true
            });
            document.cookie = "csrftoken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            document.cookie = "sessionid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            window.location.href = "/";
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div className='mt-5 p-4'>
            {FullPageLoader ? <div className='position-relative' >
                <div className='backdrop'></div>
                <div className='spinner-container'><Spinner /></div>

            </div> : null}
            {
                !userData?.is_superuser ? <div className='card p-3 adq-card h-100'>
                    <h6>Edit Profile</h6>
                    <div className=' d-flex justify-content-between mt-2 firstDiv '>
                        <label className='w-50'>Username  <span className='spann'>*</span></label>
                        <input type='text' className='me-5 form-control' value={userData.username} name='username' disabled={true} onChange={(e) => handleAnalystInput(e)} />
                    </div>
                    <div className=' d-flex justify-content-between mt-2 firstDiv '>
                        <label className='w-50'>E-mail Address  <span className='spann'>*</span></label>
                        <input type='text' className='me-5 form-control' value={userData.email} name='email' onChange={(e) => handleAnalystInput(e)} />
                    </div>
                    <div className=' d-flex justify-content-between mt-2 firstDiv '>
                        <label className='w-50'>Default project  <span className='spann'>*</span></label>
                        <select type='text' className='me-5 form-control' name='project_userName' value={defaultPrj} onChange={handleAnalystSelect}>
                            <option value=''>Please select</option>
                            {allProject.map((e) => {
                                return <option value={e.project_name}>{e.display_name}</option>
                            })}
                        </select>
                    </div>
                    <div className='mt-2' style={{ marginLeft: '26.8%' }}>
                        <button className='btn button_positive ' onClick={editAnalystInfo}>Save Connection</button>
                    </div>

                </div> :
                    <div className='card p-3 adq-card h-100'>

                        <Tabs selectedIndex={selectedTabIndex} onSelect={handleTabSelect}>
                            <TabList className="horizontal-tabs">
                                <Tab className={`tab-item ${selectedTabIndex === 0 ? 'active-tab' : ''}`}>Project</Tab>
                                <Tab className={`tab-item ${selectedTabIndex === 1 ? 'active-tab' : ''}`}>Roles</Tab>
                                <Tab className={`tab-item ${selectedTabIndex === 2 ? 'active-tab' : ''}`}>Raw Data Rules</Tab>
                                <Tab className={`tab-item ${selectedTabIndex === 3 ? 'active-tab' : ''}`}>Metric Rules</Tab>
                                {/* <Tab className={`tab-item ${selectedTabIndex === 4 ? 'active-tab' : ''}`}>Reports</Tab> */}
                            </TabList>

                            <TabPanel>
                                <ProjectTab />
                            </TabPanel>
                            <TabPanel>
                                <div>
                                    <div className='d-flex justify-content-end mb-2'>
                                        <button className='btn button_positive ' onClick={() => setAddUserModelOpen(true)} >Add User</button>
                                    </div>
                                    <div className='col-6'>
                                        <table className='table table-striped'>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th className='text-center'>User Role</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    user.length ?
                                                        user.map((ele, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>{ele?.username}</td>
                                                                    <td>
                                                                        <div className='d-flex  justify-content-center'>
                                                                            <p className='me-2'>Analyst</p>
                                                                            <label className="switchaction">
                                                                                <input type="checkbox" checked={ele.is_superuser} onChange={() => { handleUserList(index) }} />
                                                                                <span class="slider round"></span>
                                                                            </label>
                                                                            <p className='ms-2'>Admin</p>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                        : null
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div>
                                    <div className='row ms-3'>
                                        <div className='col-3 d-flex'>
                                            <label>Project</label>
                                            <select className='form-control ms-2' value={selectRulesPrj} onChange={(e) => { setSelectRulesPrj(e.target.value) }}>
                                                {
                                                    allProject?.map((ele) => {
                                                        return (
                                                            <option value={ele?.project_name}>{ele?.display_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    {
                                        rulesTableData.length ?
                                            <AdminRules tableData={rulesTableData} reRender={() => { getTableRules() }} project_name={selectRulesPrj} />
                                            : <div className='text-center'>No data Found</div>
                                    }

                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div>
                                    <div className='row ms-3'>
                                        <div className='col-3 d-flex'>
                                            <label>Project</label>
                                            <select className='form-control ms-2' value={selectRulesPrj} onChange={(e) => { setSelectRulesPrj(e.target.value) }}>
                                                {
                                                    allProject?.map((ele) => {
                                                        return (
                                                            <option value={ele?.project_name}>{ele?.display_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    {
                                        metricTableData.length ?
                                            <AdminMetric tableData={metricTableData} reRender={() => { getMetricRules() }} project_name={selectRulesPrj} />
                                            : <div className='text-center'>No data Found</div>
                                    }

                                </div>
                            </TabPanel>
                            <TabPanel>
                                {
                                    powerBIData?.length ?
                                        <PowerBI tableData={powerBIData} projects={allProject} reRender={() => { getReports() }} project_name={selectRulesPrj} />
                                        : null
                                }
                            </TabPanel>
                        </Tabs>

                        <Modal
                            open={addUserModelOpen}
                            onClose={() => setAddUserModelOpen(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={modelStyle}>
                                <div className='d-flex justify-content-center align-items-center p-3' style={{ backgroundColor: '#176b87', color: 'white', borderTopRightRadius: '5px', borderTopLeftRadius: '5px', height: '40px' }}>
                                    <h5 className='text-uppercase'>Add User</h5>
                                </div>
                                <div className='p-3'>
                                    <div className='row'>
                                        <div className='col-6 mt-1'><label className='fw-bold'>User Name <span className='spann'>*</span></label></div>
                                        <div className='col-6'><input type='text' className='form-control' name='userName' value={newUser.userName} onChange={(e) => { handleNewUser(e) }} /></div>
                                    </div>
                                    <div className='row mt-2 '>
                                        <div className='col-6 mt-1'><label className='fw-bold'>E-mail <span className='spann'>*</span></label></div>
                                        <div className='col-6'><input type='text' className='form-control' name='userEmail' value={newUser.userEmail} onInput={(e) => { handleNewUser(e) }} autoComplete='false' /></div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-6 mt-1'><label className='fw-bold'>Password <span className='spann'>*</span></label></div>
                                        <div className='col-6'><input type='password' className='form-control' name='userPassword' value={newUser.userPassword} onInput={(e) => { handleNewUser(e) }} /></div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-6 mt-1'><label className='fw-bold'>Confirm Password <span className='spann'>*</span></label></div>
                                        <div className='col-6'><input type='password' className='form-control' name='userConPassword' value={newUser.userConPassword} onInput={(e) => { handleNewUser(e) }} /></div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-6 mt-1'><label className='fw-bold'>Role <span className='spann'>*</span></label></div>
                                        <div className='col-6'>
                                            <select class="form-control" name='userRole' value={newUser.userRole} onChange={(e) => { handleNewUser(e) }}>
                                                <option selected="" value="" disabled>Please select Role</option>
                                                <option value="Analyst">Analyst</option>
                                                <option value="Admin">Admin</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-6 mt-1'><label className='fw-bold'>Default Project <span className='spann'>*</span></label></div>
                                        <div className='col-6'>
                                            <select class="form-control" name='userProject' value={newUser.userProject} onChange={(e) => { handleNewUser(e) }}>
                                                <option selected="" value="" disabled>Select default Project</option>
                                                {
                                                    allProject?.map((ele) => {
                                                        return (
                                                            <option value={ele.project_name}>{ele.display_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    {
                                        userError != '' ?
                                            <p className='spann mt-2'>{userError}</p>
                                            : null
                                    }
                                    <div className='mt-4 d-flex justify-content-end '>
                                        <button className='btn button_positive' onClick={() => { newUserCreate() }}>Create</button>
                                    </div>
                                </div>
                            </Box>
                        </Modal>

                    </div>

            }



        </div>
    )
}

export default Settings
import React, { useEffect, useRef, useState } from "react";
import "../css/settings.css";
import { TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { alpha, styled } from "@mui/material/styles";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import axios from "axios";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Select from "react-select";
import Tooltip from "@mui/material/Tooltip";
import { Oval } from "react-loader-spinner";
import ReactECharts from "echarts-for-react";

export default function Report(props) {
  const {
    setIsOpen,
    setValuess,
    setValue,
    setSelectedYOptions,
    setLegendOptions,
    setYAxisOptions,
    setXAxisOptions,
    setUserData,
    selectedYOptions,
    legendOptions,
    yAxisOptions,
    xAxisOptions,
    userData,
    valuess,
    value,
    success,
    setSuccess,
    setWarnings,
    warnings,
    setLegend,
    legend,
    chartPreviewData,
    setChartPreviewData,
  } = props;
  const [selectedParent, setSelectedParent] = useState(null);
  const [selectedChild, setSelectedChild] = useState(null);
  const [parentlist, setParentList] = useState();
  const [childlist, setChildtList] = useState();
  const [graphLoader, setGraphLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [columnnames, setColumnName] = useState();
  const mainContainerRef = useRef(null);

  console.log(loader);

  const handleChangeYAxis = (selectedOptions) => {
    setSelectedYOptions(selectedOptions);
  };

  const handleAnalystInput = (evt) => {
    var val = evt.target.value;
    setUserData({
      ...userData,
      [evt.target.name]: val,
    });
    if (evt.target.name === "x_axis") {
      console.log([
        ...yAxisOptions?.map((e) => ({
          value: e.value,
          label: e.label,
          isDisabled: e.value == val ? true : false,
        })),
      ]);
      setYAxisOptions([
        ...yAxisOptions?.map((e) => ({
          value: e.value,
          label: e.label,
          isDisabled: e.value == val ? true : false,
        })),
      ]);
    }
  };

  console.log("sa", userData);
  const handlesave = async () => {
    let validationTemp = { ...userData };
    const emptyUserDataKeys = Object.keys(validationTemp)?.filter(
      (key) => userData[key] === ""
    );
    if (emptyUserDataKeys.length > 0) {
      setWarnings("Please fill out all user data fields");
      return;
    }
    if (selectedYOptions.length === 0) {
      setWarnings("Please select at least one option for Y axis");
      return;
    }
    if (value.name === null && valuess?.name === null) {
      setWarnings("Please select a value for Source or Child");
      return;
    }

    try {
      const queries = {
        ...userData,
        Source: value?.id !== "" ? value?.id : value?.name,
        Child: valuess?.id !== "" ? valuess?.id : valuess?.name,
        y_axis: selectedYOptions?.map((option) => option.value),
        tile_id: "",
        column_names: columnnames,
        legend: legend,
      };
      const response = await axios.post("/charts/chart-data-load/", queries, {
        headers: headers,
      });
      const pData = response?.data?.parent_id;
      setUserData({ query: "", x_axis: "", name: "" });
      setXAxisOptions([]);
      setYAxisOptions([]);
      setLegendOptions([]);
      setSelectedYOptions([]);
      setValue(null);
      setValuess(null);
      Swal.fire({
        title: "Successfully created",
        icon: "success",
      });
      setSuccess(false);
      setWarnings("");
      setIsOpen(false);
      setWarnings("");
      window.location.href = `/MetricDashboard/${pData}`;
    } catch (error) {
      setSuccess(false);
      setWarnings("Something went wrong. Please try again later");
      console.error("Error submitting form:");
    }

    console.log({});
  };

  const BootstrapInput = styled(TextField)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-root": { padding: 0 },
    "& fieldset": { border: "none" },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      border: "1px solid",
      borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
      fontSize: 16,
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));

  const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
    const [name, value] = cookie.split("=");
    acc[name] = decodeURIComponent(value);
    return acc;
  }, {});

  const headers = {
    "X-CSRFToken": cookies.csrftoken,
    "Content-Type": "application/json",
  };

  const filter = createFilterOptions();

  const getsource = async () => {
    try {
      var pData = await axios.get("/charts/parent-child-names/");
      console.log(pData?.data?.parent_list);
      setParentList(pData?.data?.parent_list);
      setChildtList(pData?.data?.child_dict);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getsource();
  }, []);

  const handelqueries = async () => {
    if (userData?.query === "") {
      setWarnings("Please Enter the Query");
    } else {
      setGraphLoader(true);
      try {
        setUserData({ ...userData, x_axis: "" });
        setLegend("");
        setSelectedYOptions([]);
        const query = {
          query: userData?.query,
        };
        setXAxisOptions([]);
        setLegendOptions([]);
        setYAxisOptions([]);
        const response = await axios.post("/charts/query-execution/", query, {
          headers: headers,
        });
        const pData = response?.data?.column_list;
        setColumnName(pData);
        setXAxisOptions([...pData]);
        setLegendOptions([...pData]);
        setYAxisOptions([...pData?.map((e) => ({ value: e, label: e }))]);
        setGraphLoader(false);
        setSuccess(true);
        setWarnings("");
        setChartPreviewData();
      } catch (error) {
        setGraphLoader(false);
        setSuccess(false);
        setChartPreviewData();
        console.error("Error submitting form:", error?.response?.data?.error);
        setWarnings(error?.response?.data?.error || "An error occurred");
      }
    }
  };

  const handlePreview = async () => {
    try {
      setLoader(true);
      const queries = {
        query: userData?.query,
        tile_id: "",
        parent_name: "",
        chart_type: userData?.chart_type,
        x_axis: userData?.x_axis,
        y_axis: selectedYOptions?.map((option) => option.value),
        legend: legend === "" ? "" : legend,
      };
      const response = await axios.post("/charts/charts-display/", queries, {
        headers: headers,
      });
      setChartPreviewData(response?.data?.charts);
      setLoader(false);

      if (mainContainerRef.current) {
        mainContainerRef.current.scrollTop = mainContainerRef.current.scrollHeight;
      }
    } catch (error) {
      setSuccess(false);
      setLoader(false);
      setWarnings("Something went wrong. Please try again later");
      console.error("Error submitting form:");
    }

    console.log({});
  };

  useEffect(() => {
    if (mainContainerRef.current) {
      mainContainerRef.current.scrollTop = mainContainerRef.current.scrollHeight;
    }
  }, [chartPreviewData]);


  return (
    <div className=" mainContainer " id="chartId" ref={mainContainerRef} style={{ overflowY: 'auto', maxHeight: '900px' }}>
      <div className=" p-3   h-100">
        <h6 className="fw-bold text-center mt-2 mb-4 ">Create QC Dashboard</h6>
        <div className="  mt-2 row">
          {" "}
          <div className="col-md-3">
            {" "}
            <label>
              Report Name <span className="spann">*</span>
            </label>
          </div>
          <div className="col-md-9">
            {" "}
            <Autocomplete
              value={value}
              style={{ width: "100%" }}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setValuess(null);
                  setSelectedParent(newValue);
                  setValue({
                    id: "",
                    name: newValue.replace("Add Source ", ""),
                  });
                } else if (newValue && newValue.inputValue) {
                  setValuess(null);
                  setSelectedParent(newValue.inputValue);
                  setValue({ id: "", name: newValue.inputValue });
                } else {
                  setValuess(null);
                  setSelectedParent(newValue);
                  if (
                    newValue &&
                    typeof newValue === "object" &&
                    newValue.name
                  ) {
                    if (newValue.name.startsWith("Add Source ")) {
                      setValue({
                        ...newValue,
                        name: newValue.name.substring("Add Source ".length),
                      });
                    } else {
                      setValue(newValue);
                    }
                  } else {
                    setValue(newValue);
                  }
                }
              }}
              filterOptions={(options, params) => {
                console.log(options, params);
                const filtered = options.filter((option) =>
                  option.name
                    .toLowerCase()
                    .includes(params.inputValue.toLowerCase())
                );
                if (
                  params.inputValue.trim() !== "" &&
                  !filtered.some(
                    (option) =>
                      option.name.toLowerCase() ===
                      params.inputValue.toLowerCase()
                  )
                ) {
                  filtered.push({
                    id: "",
                    name: `Add Source ${params.inputValue}`,
                  });
                }

                const newValues = filtered.filter(
                  (option) => !option.inputValue
                );

                return newValues;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="Source"
              options={parentlist}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option?.name;
              }}
              renderOption={(props, option) => (
                <li {...props}>{option?.name}</li>
              )}
              sx={{ width: "62%" }}
              freeSolo
              renderInput={(params) => <BootstrapInput {...params} />}
            />
          </div>
        </div>
        <div className="row mt-2  ">
          {" "}
          <div className="col-md-3">
            <label>
              Page Name <span className="spann">*</span>
            </label>
          </div>
          <div className="col-md-9">
            {" "}
            <Autocomplete
              value={valuess}
              style={{ width: "100%" }}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setValuess({
                    id: "",
                    name: newValue.replace("Add Source ", ""),
                  });
                } else if (newValue && newValue?.inputValue) {
                  setValuess({ id: "", name: newValue?.inputValue });
                } else {
                  if (
                    newValue &&
                    typeof newValue === "object" &&
                    newValue.name
                  ) {
                    if (newValue.name.startsWith("Add Source ")) {
                      setValuess({
                        ...newValue,
                        id: "",
                        name: newValue.name.substring("Add Source ".length),
                      });
                    } else {
                      setValuess(newValue);
                    }
                  } else {
                    setValuess(newValue);
                  }
                }
              }}
              filterOptions={(options, params) => {
                const filtered = options?.filter((option) =>
                  option?.name
                    .toLowerCase()
                    .includes(params?.inputValue?.toLowerCase())
                );
                if (
                  params?.inputValue?.trim() !== "" &&
                  !filtered?.some(
                    (option) =>
                      option.name.toLowerCase() ===
                      params.inputValue.toLowerCase()
                  )
                ) {
                  filtered?.push({ name: `Add Source ${params.inputValue}` });
                }

                const newValues = filtered?.filter(
                  (option) => !option?.inputValue
                );

                return newValues || [];
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="Sources"
              options={
                selectedParent && childlist && selectedParent.id
                  ? childlist[selectedParent.id] || []
                  : []
              }
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                if (option?.inputValue) {
                  return option?.inputValue;
                }
                return option?.name;
              }}
              renderOption={(props, option) => (
                <li {...props}>{option?.name}</li>
              )}
              sx={{ width: "62%" }}
              freeSolo
              renderInput={(params) => <BootstrapInput {...params} />}
            />
          </div>
        </div>
        <div className="row  mt-2  ">
          <div className="col-md-3">
            <label>
              Graph Name <span className="spann">*</span>
            </label>
          </div>
          <div className="col-md-9">
            <input
              type="text"
              style={{ width: "100%" }}
              className=" form-control"
              value={userData?.name}
              name="name"
              onChange={(e) => handleAnalystInput(e)}
            />
          </div>
        </div>

        <div className="d-flex justify-content-between mt-2">
          <div>
            <label>
              Query <span className="spann">*</span>
            </label>
          </div>
          <div className="d-flex">
            {success === true ? (
              <div className="ms-auto me-2  mt-1">
                <span style={{ color: "#39cf1c" }}>Query check, ok</span>
              </div>
            ) : (
              <div className="ms-auto me-2  mt-1">
                <span style={{ color: "gray" }}>Please check the query</span>
              </div>
            )}
            {!graphLoader ? (
              <>
                {" "}
                <Tooltip title="Run your Query" arrow>
                  <PlayArrowIcon
                    className="me-2 ms-auto fs-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => handelqueries()}
                  />
                </Tooltip>
              </>
            ) : (
              <div className=" me-2 mb-2">
                {" "}
                <Oval
                  visible={true}
                  height="30"
                  width="80"
                  color="#9284fc"
                  secondaryColor="#b5b5b6"
                  ariaLabel="oval-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            )}
          </div>
        </div>
        <textarea
          type="text"
          rows="6"
          className="me-2 form-control"
          value={userData.query}
          name="query"
          onChange={(e) => {
            handleAnalystInput(e);
            setXAxisOptions([]);
            setYAxisOptions([]);
            setSuccess(false);
            setChartPreviewData();
          }}
        />
        {warnings?.length > 0 && (
          <div>
            <span className="spann">{warnings}</span>
          </div>
        )}
        {xAxisOptions.length ? (
          <div className="row mt-2  ">
            <div className="col-md-3">
              <label>
                X Axis Label <span className="spann">*</span>
              </label>
            </div>
            <div className="col-md-9">
              <select
                type="text"
                className="me-5 form-control"
                value={userData?.x_axis}
                name="x_axis"
                onChange={(e) => {
                  handleAnalystInput(e);
                  setChartPreviewData();
                  setLoader(false);
                }}
              >
                <option value="">Please select</option>
                {xAxisOptions.map((e) => {
                  return (
                    <option
                      value={e}
                      disabled={selectedYOptions.some(
                        (option) => option.value === e
                      )}
                    >
                      {e}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        ) : null}
        {yAxisOptions.length ? (
          <>
            <div className=" row mt-2  ">
              <div className="col-md-3">
                <label>
                  Y Axis Label <span className="spann">*</span>
                </label>
              </div>
              <div className="col-md-9">
                <Select
                  isMulti
                  className="form-control me-5 px-0"
                  options={yAxisOptions}
                  value={selectedYOptions}
                  onChange={(e) => {
                    handleChangeYAxis(e);
                    setChartPreviewData();
                    setLoader(false);
                    console.log(selectedYOptions?.length);
                    if (selectedYOptions.length <= 1) {
                      setLegend("");
                    }
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "1px solid #dee2e6",
                      width: "100%",
                    }),
                    container: (provided) => ({
                      ...provided,
                      border: "none",
                    }),
                  }}
                />
              </div>
            </div>
            {legendOptions.length ? (
              <div className="row mt-2  ">
                <div className="col-md-3">
                  <label>Display Legend </label>
                </div>
                <div className="col-md-9">
                  <select
                    type="text"
                    className="form-control"
                    value={legend}
                    name="legend"
                    onChange={(e) => {
                      setLegend(e.target.value);
                      setChartPreviewData();
                      setLoader(false);
                    }}
                    disabled={selectedYOptions.length > 1}
                  >
                    <option value="">Please select</option>
                    {legendOptions.map((e) => {
                      return (
                        <option
                          value={e}
                          disabled={
                            selectedYOptions?.some(
                              (option) => option?.value === e
                            ) || e == userData?.x_axis
                          }
                        >
                          {e}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            ) : null}
            <div className="row mt-2  ">
              <div className="col-md-3">
                <label>
                  Chart Type <span className="spann">*</span>
                </label>
              </div>
              <div className="col-md-9">
                <select
                  type="text"
                  className="me-5 form-control"
                  value={userData?.chart_type}
                  name="chart_type"
                  onChange={(e) => {
                    handleAnalystInput(e);
                    setChartPreviewData();
                    setLoader(false);
                  }}
                >
                  {/* <option value="">Please select</option> */}
                  <option value="horizontal_bar">Horizontal Bar</option>
                  <option value="vertical_bar">Vertical Bar</option>
                  <option value="line">Line</option>
                </select>
              </div>
            </div>
          </>
        ) : null}
        <div className="mt-2 w-100 d-flex justify-content-end ">
          <button
            className="btn button_positive me-2 mt-2"
            onClick={() => {
              handlePreview();
            }}
            disabled={
              userData?.query === "" ||
              userData?.x_axis === "" ||
              userData?.chart_type === "" ||
              selectedYOptions.length === 0
            }
          >
            Preview
          </button>

          <button
            className="btn button_positive me-2 mt-2"
            onClick={() => {
              handlesave();
            }}
          >
            Create QC Dashboard
          </button>
        </div>
        <div className="mt-3">
          {" "}
          {loader && (
            <div className="me-2 mb-2 text-center">
              {" "}
              <div style={{ width: "fit-content", margin: "auto" }}>
                <Oval
                  visible={true}
                  height="70"
                  width="70"
                  color="#176b87"
                  secondaryColor="#b5b5b6"
                  ariaLabel="oval-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            </div>
          )}
          {!loader &&
            chartPreviewData &&
            chartPreviewData?.length !== 0 &&
            chartPreviewData.map((chart, index) => (
              <div
                className={
                  chartPreviewData?.length <= 2 ? "col-md-12" : "col-md-6"
                }
                key={index}
                style={{ marginBottom: "20px" }}
              >
                {chart?.data?.xAxis?.length === 0 ? (
                  <div
                    style={{
                      padding: "1%",
                      border: "2px solid whitesmoke",
                      backgroundColor: "white",
                      fontSize: "16px",
                      fontWeight: "700",
                      color: "#000000a6",
                    }}
                  >
                    <span>{chart?.heading}</span>
                    <div className="mt-5">
                      {" "}
                      <h3 className="text-center" style={{ color: "#ccc" }}>
                        No Data Found
                      </h3>
                    </div>
                  </div>
                ) : (
                  <ReactECharts
                    style={{
                      padding: "1%",
                      border: "2px solid whitesmoke",
                      backgroundColor: "white",
                    }}
                    option={{
                      color: [
                        "#0bb4ff",
                        "#50e991",
                        "#e6d800",
                        "#9b19f5",
                        "#00bfa0",
                        "#ffa300",
                        "#dc0ab4",
                        "#b3d4ff",
                      ],
                      title: {
                        text: chart?.heading,
                        textStyle: {
                          fontSize: 15,
                        },
                      },
                      tooltip: {
                        trigger: "axis",
                        axisPointer: {
                          type: "shadow",
                        },
                      },
                      legend: {
                        type: "scroll",
                        orient: "horizontal",
                        padding: [26, 26, 26, 26],
                        data: chart?.xAxis,
                        icon: "circle",
                      },
                      grid: {
                        top: 80,
                        bottom: 20,
                        left: 10,
                        right: 10,
                        containLabel: true,
                      },
                      xAxis: {
                        type:
                          chart?.graphType === "horizontal_bar"
                            ? "value"
                            : "category",
                        data:
                          chart?.graphType === "horizontal_bar"
                            ? null
                            : chart?.data?.xAxis,
                        axisLabel: {
                          rotate:
                            chart?.graphType === "horizontal_bar"
                              ? undefined
                              : 0,
                        },
                      },
                      yAxis: {
                        type:
                          chart?.graphType === "horizontal_bar"
                            ? "category"
                            : "value",
                        data:
                          chart?.graphType === "horizontal_bar"
                            ? chart?.data?.xAxis
                            : null,
                        axisLabel: {
                          rotate:
                            chart?.graphType === "horizontal_bar"
                              ? 0
                              : undefined,
                        },
                      },
                      series:
                        chart?.chartType === "db"
                          ? chart.data.yAxis.map((seriesData, seriesIndex) => ({
                              name: seriesData?.name,
                              type:
                                seriesData?.type === "vertical_bar" ||
                                seriesData?.type === "horizontal_bar"
                                  ? "bar"
                                  : "line",
                              data: seriesData.data,
                              barGap: "0%",
                              showSymbol: false,
                            }))
                          : [
                              {
                                data: chart?.data?.yAxis,
                                type:
                                  chart?.chartType === "vertical_bar" ||
                                  chart?.chartType === "horizontal_bar"
                                    ? "bar"
                                    : "line",
                                areaStyle: {},
                                showSymbol: false,
                              },
                            ],
                    }}
                  />
                )}
              </div>
            ))}
          {chartPreviewData?.length === 0 && (
            <>
              <div
                style={{
                  position: "relative",
                  top: "35%",
                  color: "#a3a2a2",
                }}
              >
                <h3 className="text-center">No Tails Available</h3>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

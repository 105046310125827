import { useEffect, useState } from 'react';
import './App.css';
import Home from './components/Home';
import Login from './components/Login';
import NavBar from './components/NavBar';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Navigate } from 'react-router-dom';
import DataReliability from './components/DataReliability';
import TableRules from './components/TableRules';
import MetricRules from './components/MetricRules';
import Coming from './components/Coming';
import Incident from './components/Incident';
import TableDashboard from './components/TableDashboard';
import MetricDashboard from './components/MetricDashboard';
import Settings from './components/Settings';
import TableLineage from './components/TableLineage';

function App() {
  // setTimeout(() => {
  //   document.cookie = "csrftoken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //   document.cookie = "sessionid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  // }, 10000);


  const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
    const [name, value] = cookie.split('=');
    acc[name] = decodeURIComponent(value);
    return acc;
  }, {});
  const isLogin = Boolean(cookies.sessionid)

  return (
    <div>
      {/* {
        isLogin ?
          <NavBar logOut={logOut}></NavBar>
          : <Login loginFunction={loginFunction} data={loginFailed}></Login>
      } */}
      <BrowserRouter>
        <Routes>
          {/* <Route path="login" element={<Login />} /> */}
          <Route path="/" element={<NavBar />}>
            <Route path='/' element={<Home />} />
            <Route path='/DataReliability' element={<DataReliability />} />
            <Route path='/DataReliability/:component/:tablename' element={<DataReliability />} />
            <Route path='/RawDataRules' element={<TableRules />} />
            <Route path='/MetricRules' element={<MetricRules />} />
            <Route path='/Incident' element={<Incident />} />
            <Route path='/Incident/:component' element={<Incident />} />
            <Route path='/Coming' element={<Coming />} />
            <Route path='/RawDataDashboard' element={<TableDashboard />} />
            <Route path='/MetricDashboard' element={<MetricDashboard />} />
            <Route path="/MetricDashboard/:component" element={<MetricDashboard />} />
            <Route path='/Settings' element={<Settings/>} />
            <Route path='/TableLineage' element={<TableLineage/>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
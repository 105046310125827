import React, { useEffect, useState } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar, dayjsLocalizer } from 'react-big-calendar'
import dayjs from 'dayjs'
import axios from 'axios';
const localizer = dayjsLocalizer(dayjs)

function Calender(props) {
  const [view, setView] = useState('month');
  const [calnderData, setCalnderData] = useState([])
  const [events, setEvents] = useState([]);
  const [project, setProject] = useState('');
  const handleView = (view) => {
    setView(view);
  };
  function eventStyleGetter(event, start, end, isSelected) {
    const style = {
      backgroundColor: view == "month" ? event.color : '', // Use the color property from the event
      color: view == "month" ? 'white' : 'black',
      border: '0px',
      borderRadius: '0px', // Border radius of the event
      border: 'none', // Border style of the event
      fontSize: '11px',
    };
    return {
      style: style
    };
  }

  const getCalnder = async () => {
    if (project != '') {
      try {
        var data = await axios.get('/accounts/calenderData/', { params: { 'project': project } });
        setCalnderData(data?.data?.data)
      } catch (error) {
        console.log(error);
      }
    }
  }

  useEffect(() => {
    setProject(props.project)
  }, [props])

  useEffect(() => {
    getCalnder()
  }, [project])


  const DailyEvents = (eventInfoArray) => {
    const today = new Date();
    const threeMonthsEarlier = new Date(today);
    threeMonthsEarlier.setMonth(today.getMonth() - 2); // Go back 2 months
    const threeMonthsLater = new Date(today);
    threeMonthsLater.setMonth(today.getMonth() + 3);
    const events = [];

    eventInfoArray.forEach((eventInfo) => {
      let currentDate = new Date(threeMonthsEarlier);

      while (currentDate < threeMonthsLater) {
        const start = new Date(currentDate);
        start.setHours(0, 0, 0, 0); // Set to 00:00:00:000

        const end = new Date(currentDate);
        end.setHours(23, 59, 59, 999); // 30 minutes duration, adjust as needed

        events.push({
          title: eventInfo.sources,
          start: start,
          end: end,
          color: '#176b87',
        });

        if (eventInfo.frequency === 'daily') {
          currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
        }

        // Add logic for other frequencies (e.g., monthly, etc.) if needed
      }
    });

    return events;
  };

  const weeklyEvents = (eventInfoArray) => {
    const today = new Date();
    const threeMonthsEarlier = new Date(today);
    threeMonthsEarlier.setMonth(today.getMonth() - 2); // Go back 2 months
    const threeMonthsLater = new Date(today);
    threeMonthsLater.setMonth(today.getMonth() + 3);
    const events = [];

    eventInfoArray.forEach((eventInfo) => {
      const expectedDay = eventInfo.expected_day.toLowerCase();
      const expectedDayIndex = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].indexOf(expectedDay);

      if (expectedDayIndex === -1) {
        // Invalid day, skip this event
        //  console.error(`Invalid expected_day for event: ${eventInfo.sources}`);
        return;
      }

      let currentDate = new Date(threeMonthsEarlier);
      currentDate.setDate(currentDate.getDate() + (expectedDayIndex - currentDate.getDay() + 7) % 7); // Calculate the next occurrence of the expected day

      while (currentDate < threeMonthsLater) {
        const start = new Date(currentDate);
        start.setHours(0, 0, 0, 0); // Set to 00:00:00:000

        const end = new Date(currentDate);
        end.setHours(23, 59, 59, 999); // 30 minutes duration, adjust as needed

        events.push({
          title: eventInfo.sources,
          start: start,
          end: end,
          color: '#d97f53'
        });

        currentDate.setDate(currentDate.getDate() + 7); // Move to the next occurrence of the expected day
      }
    });

    return events;
  };

  const MonthlyEvents = (eventInfoArray) => {
    const today = new Date();
    const threeMonthsEarlier = new Date(today);
    threeMonthsEarlier.setMonth(today.getMonth() - 2); // Go back 2 months
    const threeMonthsLater = new Date(today);
    threeMonthsLater.setMonth(today.getMonth() + 3);
    const events = [];

    eventInfoArray.forEach((eventInfo) => {
      const expectedDay = parseInt(eventInfo.expected_day, 10);

      if (isNaN(expectedDay) || expectedDay <= 0 || expectedDay > 31) {
        console.error(`Invalid expected_day for event: ${eventInfo.sources}`);
        return;
      }

      let currentDate = new Date(threeMonthsEarlier);
      currentDate.setDate(expectedDay); // Set the day of the month

      if (currentDate < today) {
        // If the expected day has already passed this month, add one month
        currentDate.setMonth(currentDate.getMonth() + 1);
      }

      while (currentDate < threeMonthsLater) {
        const start = new Date(currentDate);
        start.setHours(0, 0, 0, 0); // Set to 00:00:00:000

        const end = new Date(currentDate);
        end.setHours(23, 59, 59, 999); // 30 minutes duration, adjust as needed

        events.push({
          title: eventInfo.sources,
          start: start,
          end: end,
          color: "#ee6666",
        });

        currentDate.setMonth(currentDate.getMonth() + 1); // Move to the next month
      }
    });

    return events;
  };

  useEffect(() => {
    var daily = calnderData.filter((ele) => ele.frequency == "daily")
    var weekly = calnderData.filter((ele) => ele.frequency == "weekly");
    var monthly = calnderData.filter((ele) => ele.frequency == "monthly");
    var event = weeklyEvents(weekly).concat(DailyEvents(daily))
    event = event.concat(MonthlyEvents(monthly));
    setEvents(event);
    //setEvents();
  }, [calnderData])

  return (
    <div className='mt-5'>
      <div className='d-flex justify-content-center'>
        <div className='col-12'>
          <div className='card p-4'>
            <Calendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              onView={handleView}
              eventPropGetter={eventStyleGetter}
              views={['month', 'agenda']}
              style={{ height: 1000 }}
              showAllEvents={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Calender;

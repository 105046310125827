import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net';
import '../css/Home.css';
import moment from 'moment-timezone';
var initcolumn = {
    Table: ["", 1],
    RunAt: ["", 2],
    Test: ["", 3],
    Status: ["", 4],
    Discrepancy: ["", 5],
}
function DataReliabilityTable(props) {
    var tableRef = useRef(null);
    const dataTableRef = useRef(null);
    const [isFilter, setIsFilter] = useState(true)
    const [columnText, setColumnText] = useState(initcolumn);
    useEffect(() => {
        if (tableRef.current && !dataTableRef.current) {
            // Only initialize the DataTable if it hasn't been initialized before
            $.extend($.fn.dataTableExt.oSort, {
                "datetime-custom-pre": function (a) {
                    var dateParts = a.split(/\/|, |\s|\:/);

                    var month = parseInt(dateParts[0], 10);
                    var day = parseInt(dateParts[1], 10);
                    var year = parseInt(dateParts[2], 10);
                    var hours = parseInt(dateParts[3], 10);
                    var minutes = parseInt(dateParts[4], 10);

                    var date = new Date(year, month - 1, day, hours, minutes);
                    return date.getTime();
                },

                "datetime-custom-asc": function (a, b) {
                    return a - b;
                },

                "datetime-custom-desc": function (a, b) {
                    return b - a;
                }
            });
            const dataTableInstance = $(tableRef.current).DataTable({
                pageLength: 5,
                lengthChange: false,
                language: {
                    searchPlaceholder: "Search records",
                },
                columnDefs: [
                    { type: 'date-custom', targets: [1] } // Apply time-based sorting to column 0
                ],
            });

            dataTableRef.current = dataTableInstance; // Store the DataTable instance
            if (dataTableRef.current) {
                dataTableRef.current.order([2, 'desc']).draw();
            }
        }

    }, []);

    useEffect(() => {
        if (isFilter) {
            setColumnText(initcolumn)
        }
    }, [isFilter])

    useEffect(() => {
        Object.keys(columnText)?.map((ele) => {
            var temp = columnText[ele]
            dataTableRef.current.column(temp[1] - 1).search(temp[0]).draw();
        })
    }, [columnText])

    const handleText = (e, position) => {
        setColumnText({
            ...columnText,
            [e.target.name]: [e.target.value, position]
        });
    }

    const convertDate = (input) => {
        const usEasternTimezone = 'America/New_York';
        const date_format = 'YYYY-MM-DD HH:mm:ss'; // Adjust this format as needed

        if (input && input !== 'NO-DATE') {
            const newone = moment.utc(input, date_format);
            const localTime = newone.tz(usEasternTimezone);
            const formattedLocalTime = localTime.format('MM-DD-YYYY HH:mm');
            return formattedLocalTime;
        }
    }

    useEffect(() => {
        dataTableRef.current.clear().draw();

        var table = [];


        props.data?.map((ele) => {
            var temp = [];
            temp.push(ele?.fields?.table_name);
            temp.push(convertDate(ele?.fields?.time));
            temp.push(ele?.fields?.test_name);
            temp.push(ele?.fields?.status);
            temp.push(ele?.fields?.discrepancy_count)
            table.push(temp)
        })
        dataTableRef.current.rows.add(table).draw();
    }, [props])

    return (
        <div>
            <div className='d-flex justify-content-end mb-3'>
                <button className='btn ms-1 btn-sm  h-75 py-0' style={{ backgroundColor: "#176b87", color: 'white' }} onClick={() => { setIsFilter(!isFilter) }}>Filter</button>
            </div>
            <div className='table-responsive'>
                <table id="dataTable" class="table table-striped" ref={tableRef}>
                    <thead>
                        <tr className={isFilter ? 'd-none' : ''}>
                            <th><input className='form-control' value={columnText.Table[0]} style={{ minWidth: "85px" }} name="Table" onInput={(e) => handleText(e, 1)} placeholder='Search' type="text"></input></th>
                            <th><input className='form-control' value={columnText.RunAt[0]} style={{ minWidth: "85px" }} name="RunAt" onInput={(e) => handleText(e, 2)} placeholder='Search' type="text"></input></th>
                            <th><input className='form-control' value={columnText.Test[0]} style={{ minWidth: "85px" }} name="Test" onInput={(e) => handleText(e, 3)} placeholder='Search' type="text"></input></th>
                            <th><input className='form-control' value={columnText.Status[0]} style={{ minWidth: "85px" }} name="Status" onInput={(e) => handleText(e, 4)} placeholder='Search' type="text"></input></th>
                            <th><input className='form-control' value={columnText.Discrepancy[0]} style={{ minWidth: "85px" }} name="Discrepancy" onInput={(e) => handleText(e, 5)} placeholder='Search' type="text"></input></th>
                        </tr>
                        <tr>
                            <th>Table Name</th>
                            <th>Run At</th>
                            <th>Test Name</th>
                            <th>Status</th>
                            <th>Discrepancy Count</th>
                        </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default DataReliabilityTable
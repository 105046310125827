
import axios from 'axios';
// request interceptor
axios.interceptors.request.use(
    config => {
        //if (config.url !== '/LoginFunction') {
          //  if (!isLoggedIn()) {
                // Redirect to the login page
              //  window.location.href='/'
              //  return Promise.reject(new Error('User is not logged in'));
            //}
       // }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

const isLoggedIn = () => {
    var cookies = document.cookie.split('; ').reduce((acc, cookie) => {
        const [name, value] = cookie.split('=');
        acc[name] = decodeURIComponent(value);
        return acc;
    }, {});
    const token = Boolean(cookies.sessionid)
    return token;
};

export default axios;
import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net';
import '../css/Home.css';
import moment from 'moment-timezone';
var initcolumn = {
    Source: ["", 1],
    RunAt: ["", 2],
    TableName: ["", 3],
    TestName: ["", 4],
    Status: ["", 5],
    Discrepancy: ["", 6]
}


function IncidentErrorTable(props) {
    const tableRef = useRef(null);
    const dataTableRef = useRef(null);
    const [isFilter, setIsFilter] = useState(true)
    const [columnText, setColumnText] = useState(initcolumn);
    const [tableData, setTableData] = useState([]);
    const [crtCategory, setCrtCategory] = useState('');
    const [source,setSource] = useState({});

    const history=()=>{
        window.location.href = `/DataReliability/${source.source}/${source.table}`;
    }
    useEffect(() => {
        if (tableData.length) {
            if (tableRef.current && !dataTableRef.current) {
                // Only initialize the DataTable if it hasn't been initialized before
                $.extend($.fn.dataTableExt.oSort, {
                    "datetime-custom-pre": function (a) {
                        var dateParts = a.split(/\/|, |\s|\:/);
    
                        var month = parseInt(dateParts[0], 10);
                        var day = parseInt(dateParts[1], 10);
                        var year = parseInt(dateParts[2], 10);
                        var hours = parseInt(dateParts[3], 10);
                        var minutes = parseInt(dateParts[4], 10);
    
                        var date = new Date(year, month - 1, day, hours, minutes);
                        return date.getTime();
                    },
    
                    "datetime-custom-asc": function (a, b) {
                        return a - b;
                    },
    
                    "datetime-custom-desc": function (a, b) {
                        return b - a;
                    }
                });
                const dataTableInstance = $(tableRef.current).DataTable({
                    pageLength: 5,
                    lengthChange: false,
                    language: {
                        searchPlaceholder: "Search records",
                    },
                    columnDefs: [
                        { type: 'date-custom', targets: [0] } // Apply time-based sorting to column 0
                    ],
                });

                dataTableRef.current = dataTableInstance; // Store the DataTable instance
                if (dataTableRef.current) {
                    dataTableRef.current.order([0, 'desc']).draw();
                }
            }
        }

    }, [tableData]);

    useEffect(() => {
        if (isFilter) {
            setColumnText(initcolumn)
        }
    }, [isFilter])

    useEffect(() => {
        if (tableData.length) {
            Object.keys(columnText)?.map((ele) => {
                var temp = columnText[ele]
                dataTableRef.current.column(temp[1] - 1).search(temp[0]).draw();
            })
        }
    }, [columnText])

    const handleText = (e, position) => {
        setColumnText({
            ...columnText,
            [e.target.name]: [e.target.value, position]
        });
    }

    const handleDetails = (id) => {
        props.handleRowDetails(id)
    }
    useEffect(() => {
        setTableData(props.tableData)
        setCrtCategory(props.type)
        setSource({"source":props.source,"table":props.table})
    }, [props])

    const incidentErrDesc = (ruledetails) => {
        if (ruledetails) {
            const errorDescriptions = {
                "Row Count Validation": () => {
                    if (ruledetails.max_value && !ruledetails.min_value) {
                        return `Rows are above the provided threshold of ${ruledetails.max_value}`;
                    } else if (ruledetails.min_value && !ruledetails.max_value) {
                        return `Rows are below the provided threshold of ${ruledetails.min_value}`;
                    } else if (ruledetails.min_value !== "" && ruledetails.max_value !== "") {
                        return `Rows are not between the provided threshold of ${ruledetails.min_value} and ${ruledetails.max_value}`;
                    } else {
                        return "Provided threshold dosen't match the Rows";
                    }
                },
                "Freshness Validation": () => `Values older than expected date in ${ruledetails.table_name} ${ruledetails.column_name}`,
                "Relationship Validation": () => `Data Relationship failure`,
                "Null Validation": () => `${ruledetails.column_name} is found Null`,
                "Mandatory Fields Check": () => `${ruledetails.query_text} column(s) is missing in incoming file`,
                "Max Length Check": () => `${ruledetails.column_name} has exceeded the maximum allowed length of ${ruledetails.max_value}`,
                "Validity Check": () => {
                    switch (ruledetails.column_format.toUpperCase()) {
                        case 'INVALID VALUES':
                            return `${ruledetails.column_name} contains invalid value(s) ${ruledetails.format_value}`;
                        case 'VALID VALUES':
                            return `${ruledetails.column_name} contains value(s) other than ${ruledetails.format_value}`;
                        case 'VALID FORMAT':
                            return `${ruledetails.column_name} is not in the valid format ${ruledetails.format_value}`;
                        case 'VALID REGEX':
                            return `${ruledetails.column_name} is not in the valid format ${ruledetails.query_text}`;
                        default:
                            return "";
                    }
                },
                "Duplicate Validation": () => `Duplicate Values found in column ${ruledetails.column_name}`,
                "Metadata Check": () => `Schema error in table ${ruledetails.table_name} columns ${ruledetails.query_text} are missing`,
                "Min Length Check": () => `${ruledetails.column_name} does not meet the minimum allowed length of ${ruledetails.min_value}`,
                "Multi-Column Unique Index": () => `Unique Constraint Violation ${ruledetails.query_text}`,
                // "L1 vs L2": () => `Metric ${ruledetails.metrics_name} for the field ${ruledetails.field_name} returned inconsistent values`,
                // "L1 vs L3": () => `Metric ${ruledetails.metrics_name} for the field ${ruledetails.field_name} returned inconsistent values`,
                // "L2 vs L3": () => `Metric ${ruledetails.metrics_name} for the field ${ruledetails.field_name} returned inconsistent values`,
                // "L1 vs L2 vs L3": () => `Metric ${ruledetails.metrics_name} for the field ${ruledetails.field_name} returned inconsistent values`,
                // "Current vs Previous": () => `Metric ${ruledetails.metrics_name} for the field ${ruledetails.field_name} returned inconsistent values`,
                // "Standalone": () => `Metric ${ruledetails.metrics_name} for the field ${ruledetails.field_name} returned inconsistent values`,
            };
            const getDescription = errorDescriptions[ruledetails.check];
            return getDescription ? getDescription() : "";
        }
        else {
            return ""
        }
    }
    const convertDate = (input) => {
        const usEasternTimezone = 'America/New_York';
        const date_format = 'YYYY-MM-DD HH:mm:ss'; // Adjust this format as needed

        if (input && input !== 'NO-DATE') {
            const newone = moment.utc(input, date_format);
            const localTime = newone.tz(usEasternTimezone);
            const formattedLocalTime = localTime.format('MM-DD-YYYY HH:mm');
            return formattedLocalTime;
        }
    }
    return (
        <div>
            <div className='d-flex justify-content-start'  style={{marginBottom:"-4.3%",position:"relative",zIndex:"200"}}>
                <button onClick={()=>{history()}} className='mb-2 me-3 btn' style={{backgroundColor: '#176b87', color: 'white'}}>History</button>
            </div>
            <div className='table-responsive'>
            <table id="data-table-basic" class="table table-striped" ref={tableRef}>
                {
                    crtCategory == "Raw Data Rule" ?
                        <>
                            <thead>
                                <tr className={isFilter ? 'd-none' : ''}>
                                    <th ><input className='form-control' value={columnText.Source[0]} style={{ minWidth: "85px" }} name="Source" onInput={(e) => handleText(e, 1)} placeholder='Search' type="text"></input></th>
                                    <th><input className='form-control' value={columnText.RunAt[0]} style={{ minWidth: "85px" }} name="RunAt" onInput={(e) => handleText(e, 2)} placeholder='Search' type="text"></input></th>
                                    <th><input className='form-control' value={columnText.TableName[0]} style={{ minWidth: "85px" }} name="TableName" onInput={(e) => handleText(e, 3)} placeholder='Search' type="text"></input></th>
                                    <th><input className='form-control' value={columnText.TestName[0]} style={{ minWidth: "85px" }} name="TestName" onInput={(e) => handleText(e, 4)} placeholder='Search' type="text"></input></th>
                                </tr>
                                <tr>
                                    <th>Timestamp</th>
                                    <th>Check Type</th>
                                    <th>Rule Details</th>
                                    <th>Error Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    tableData?.map((ele) => {
                                    console.log(ele,"ele");
                                        try {
                                            var jsontemp = JSON.parse(ele[1].replace(/'/g, '"').replace(/\bNone\b/g, 'null').replace(/\bTrue\b/g, 'true').replace(/\bFalse\b/g, 'false'))
                                        } catch (error) {
                                            const validDataString = ele[1].replace(/Decimal\('([\d.]+)'\)/g, '$1');
                                            const sqlQueryPattern = /"([^"]*?)(?:"|$)/g;
                                            const placeholders = [];
                                            const textWithPlaceholders = validDataString.replace(sqlQueryPattern, (match, query) => {
                                                placeholders.push(query);
                                                return `"SQL_QUERY_${placeholders.length - 1}"`;
                                            });
                                            const textWithDoubleQuotes = textWithPlaceholders.replace(/'/g, '"');
                                            const finalText = textWithDoubleQuotes.replace(/"SQL_QUERY_(\d+)"/g, (match, index) => `"${placeholders[index]}"`);
                                            var temp = finalText.replace(/None/g, 'null').replace(/False/g, 'false').replace(/True/g, 'true');
                                            var jsontemp = JSON.parse(temp);
                                        }
                                        return (
                                            <tr>
                                                <td >{convertDate(ele[0])}</td>
                                                <td >{jsontemp?.check}</td>
                                                <td className='fw-bold' style={{ cursor: 'pointer' }} onClick={() => handleDetails(ele[2])}>Rule Details</td>
                                                <td >{incidentErrDesc(jsontemp)}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </>
                        : <>
                            <thead>
                                <tr className={isFilter ? 'd-none' : ''}>
                                    <th ><input className='form-control' value={columnText.Source[0]} style={{ minWidth: "85px" }} name="Source" onInput={(e) => handleText(e, 1)} placeholder='Search' type="text"></input></th>
                                    <th><input className='form-control' value={columnText.RunAt[0]} style={{ minWidth: "85px" }} name="RunAt" onInput={(e) => handleText(e, 2)} placeholder='Search' type="text"></input></th>
                                    <th><input className='form-control' value={columnText.TableName[0]} style={{ minWidth: "85px" }} name="TableName" onInput={(e) => handleText(e, 3)} placeholder='Search' type="text"></input></th>
                                    <th><input className='form-control' value={columnText.TestName[0]} style={{ minWidth: "85px" }} name="TestName" onInput={(e) => handleText(e, 4)} placeholder='Search' type="text"></input></th>
                                </tr>
                                <tr>
                                    <th>Timestamp</th>
                                    <th>Check Type</th>
                                    <th>Rule Details</th>
                                    <th>Error Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    tableData?.map((ele) => {
                                        const validDataString = ele[1].replace(/Decimal\('([\d.]+)'\)/g, '$1');

                                        const sqlQueryPattern = /"([^"]*?)(?:"|$)/g;
                                        const placeholders = [];

                                        const textWithPlaceholders = validDataString.replace(sqlQueryPattern, (match, query) => {
                                            placeholders.push(query);
                                            return `"SQL_QUERY_${placeholders.length - 1}"`;
                                        });
                                        const textWithDoubleQuotes = textWithPlaceholders.replace(/'/g, '"');
                                        const finalText = textWithDoubleQuotes.replace(/"SQL_QUERY_(\d+)"/g, (match, index) => `"${placeholders[index]}"`);

                                        var temp = finalText.replace(/None/g, 'null').replace(/False/g, 'false').replace(/True/g, 'true');
                                        let ruleDetails;
                                        try {
                                            ruleDetails = JSON.parse(temp);
                                            console.log(ruleDetails, "ruleDetails");
                                        } catch (e) {
                                            console.error("Error parsing JSON:", e);
                                            return null;
                                        }
                                        return (
                                            <tr>
                                                <td>{convertDate(ele[0])}</td>
                                                <td>{crtCategory}</td>
                                                <td className='fw-bold' style={{ cursor: 'pointer' }} onClick={() => handleDetails(ele[2])}>Rule Details</td>
                                                <td>{`Metric ${ruleDetails?.metrics_name} for the field ${ruleDetails?.field_name} returned inconsistent values`}</td>
                                            </tr>
                                        );
                                    })
                                    
                                }
                            </tbody>
                        </>
                }
            </table>
        </div>
        </div>
    )
}

export default IncidentErrorTable
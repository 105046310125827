import React, { useEffect } from "react";
import "../css/Home.css";
import SourceSummary from "./SourceSummary";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { useState } from "react";
import DataVintage from "./DataVintage";
import Spinner from "../widgets/Spinner";
import zIndex from "@mui/material/styles/zIndex";
import axios from "axios";
import { Bars } from "react-loader-spinner";
import moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ApiClient from "./ApiClient";

Chart.register(...registerables);
let initialChartState = {
  type: "bar",
  data: {
    labels: [],
    datasets: [
      {
        label: "Source-Fail",
        data: [],
        backgroundColor: "#ee6666",
        barThickness: 10,
      },
    ],
  },
  options: {
    indexAxis: "y",

    scales: {
      xAxes: [
        {
          barThickness: 10,
        },
      ],
    },
  },
};

function Home() {
  const [vintageData, setVintageData] = useState([]);
  const [sourceTableData, setSourceTableData] = useState([]);
  const [metricTable, setMetricTable] = useState([]);
  const [headersData, setHeadersData] = useState({
    total_check: 0,
    total_pass: 0,
    total_fail: 0,
    total_warn: 0,
    total_invalid: 0,
  });
  const [sourceForSummary, setSourceForSummary] = useState([]);
  const [graphLoader, setGraphLoader] = useState(false);
  const [vintageTblLoader, setVintageTblLoader] = useState(true);
  const [sourceSummaryLoader, setSourceSummaryLoader] = useState(false);
  const [lookBackInput, setLookBackInput] = useState("");
  const [FullPageLoader, setFullPageLoader] = useState(false);
  const [chartData, setChartData] = useState(initialChartState);
  const [metricChart, setMetricChart] = useState(initialChartState);
  const [lookback, setLookback] = useState("1");
  const [color, setColor] = useState("white");
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedOpt, setSelectedOpt] = useState("");
  const [lookbackDate, setLookbackDate] = useState({});
  const [parsedData, setParsedData] = useState(null);

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const dataParam = params.get("data");

    if (dataParam) {
      try {
        const decodedData = decodeURIComponent(dataParam);
        const jsonData = atob(decodedData);
        const data = JSON.parse(jsonData);
        setParsedData(data);
      } catch (error) {
        console.error("Error decoding data:", error);
      }
    }
  }, []);
  console.log(parsedData);
  const handleDateLookBack = (event) => {
    var value = event.target.value;
    var name = event.target.name;
    var newDate = { ...lookbackDate, [name]: value };
    if (name == "startDate") {
      newDate = { ...newDate, ["endDate"]: "" };
    }
    setLookbackDate(newDate);
  };
  const handleTabSelect = (index) => {
    setSelectedTabIndex(index);
  };
  useEffect(() => {
    if (lookbackDate.startDate && lookbackDate.endDate) {
      getSourceSummaryData(0, lookbackDate);
      chartDataCall("4");
      changeHeadersData("4");
    }
  }, [lookbackDate]);

  const loaderCall = () => {
    setFullPageLoader(true);
  };

  const toggleLookback = (tab) => {
    if (lookback !== tab) {
      setLookback(tab);
      setLookbackDate({ startDate: "", endDate: "" });
      setLookBackInput("");
      if (tab == "1") {
        getSourceSummaryData(7);
        chartDataCall(tab);
        changeHeadersData(tab);
      } else if (tab == "2") {
        getSourceSummaryData(30);
        chartDataCall(tab);
        changeHeadersData(tab);
      } else if (tab == "3") {
        getSourceSummaryData(90);
        chartDataCall(tab);
        changeHeadersData(tab);
      }
    }
  };

  const getVintageData = async () => {
    if (vintageData.length == 0) {
      try {
        setVintageTblLoader(true);
        var data = await axios.get(`/vintageData/`);
        var vintage = await data.data.vintageData;
        setVintageData(vintage);
        setVintageTblLoader(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getSourceSummaryData = async (days = 7, date = "") => {
    try {
      setSourceSummaryLoader(true);
      if (date != "") {
        var data = await axios.get(
          `/myDashboard/`,
          {
            params: {
              startDate: lookbackDate?.startDate,
              endDate: lookbackDate?.endDate,
            },
          },
          {
            withCredentials: true,
          }
        );
      } else {
        var data = await axios.get(
          `/myDashboard/`,
          { params: { daysToFilter: days } },
          {
            withCredentials: true,
          }
        );
      }
      // setHeadersData(data.data.context.headersData)
      // setSourceTableData(data.data.context.data);
      setSourceForSummary(data.data.context.sources.source_summary);
      setSourceSummaryLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  const findLookback = (tab) => {
    if (tab == "1") {
      return 7;
    } else if (tab == "2") {
      return 30;
    } else if (tab == "3") {
      return 90;
    } else if (tab == "5" || "") {
      // console.log(lookBackInput)
      return lookBackInput;
    }
  };

  const chartDataCall = async (tab) => {
    try {
      setColor("grey");
      setGraphLoader(true);
      setSourceSummaryLoader(true);
      setSourceTableData([]);
      setMetricTable([]);
      if (tab == "4") {
        var data = await axios.get(
          `/getChartData/`,
          {
            params: {
              startDate: lookbackDate.startDate,
              endDate: lookbackDate.endDate,
            },
          },
          {
            withCredentials: true,
          }
        );
        var metricData = await axios.get(
          `/getChartData/`,
          {
            params: {
              startDate: lookbackDate.startDate,
              endDate: lookbackDate.endDate,
              rule_name: "Metric Rule",
            },
          },
          {
            withCredentials: true,
          }
        );
      } else {
        var data = await axios.get(
          `/getChartData/`,
          { params: { daysToFilter: findLookback(tab) } },
          {
            withCredentials: true,
          }
        );
        var metricData = await axios.get(
          `/getChartData/`,
          {
            params: {
              daysToFilter: findLookback(tab),
              rule_name: "Metric Rule",
            },
          },
          {
            withCredentials: true,
          }
        );
        // console.log(metricData)
      }
      let FinalData = data?.data?.chartData;
      let FinalMData = metricData?.data?.chartData;
      setMetricTable(metricData?.data?.data);
      setSourceTableData(data?.data?.data);
      var sources = [];
      var source_fail = [];
      var curation_fail = [];
      var Msources = [];
      var Msource_fail = [];
      var Mcuration_fail = [];
      for (var i in FinalData) {
        sources.push(FinalData[i]["SOURCE"]);
        source_fail.push(FinalData[i]["srcFailCnt"]);
        curation_fail.push(FinalData[i]["curFailCnt"]);
      }
      for (var i in FinalMData) {
        Msources.push(FinalMData[i]["SOURCE"]);
        Msource_fail.push(FinalMData[i]["srcFailCnt"]);
        Mcuration_fail.push(FinalMData[i]["curFailCnt"]);
      }
      const updatedChartData = {
        ...chartData,
        data: {
          ...chartData.data,
          labels: sources,
          datasets: [
            {
              ...chartData.data.datasets[0],
              data: source_fail,
            },
          ],
        },
      };
      const updatedMetric = {
        ...metricChart,
        data: {
          ...chartData.data,
          labels: Msources,
          datasets: [
            {
              ...metricChart.data.datasets[0],
              data: Msource_fail,
            },
          ],
        },
      };
      setChartData(updatedChartData);
      setMetricChart(updatedMetric);
      setGraphLoader(false);
      setSourceSummaryLoader(false);
      setColor("white");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      chartDataCall("1");
      getSourceSummaryData();
      getVintageData();
      setLookbackDate({ startDate: "", endDate: "" });
      setSelectedOpt("Raw Data Rule");
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    changeHeadersData(lookback);
    // console.log(lookback)
  }, [selectedOpt, lookBackInput]);

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      if (lookBackInput != "") {
        setLookbackDate({ startDate: "", endDate: "" });
        setLookback("");
        getSourceSummaryData(lookBackInput);
        chartDataCall("5");
        changeHeadersData("5");
      }
    }
  }

  function handleChangeLook(event) {
    function isDecimalString(str) {
      return /^\d*\.\d+$/.test(str);
    }
    if (!isNaN(event.target.value)) {
      const firstValue = event.target.value.trim();
      const trimmedValue = firstValue.replace(/\./g, "");
      if (trimmedValue <= 100) {
        if (trimmedValue == "0") {
          setLookBackInput("");
        } else {
          setLookBackInput(trimmedValue);
        }
      } else {
        setLookBackInput("");
      }
    }
  }
  const changeHeadersData = async (tab) => {
    // console.log(e.target.value)
    // console.log(lookback)
    // console.log(evt);
    // try {
    //     var data = await axios.get(`/dashboard_header/`, { params: { 'startDate': lookbackDate.startDate, 'endDate': lookbackDate.endDate, } }, {
    //         withCredentials: true
    //     });
    // } catch (error) {
    //     console.log(error)
    // }
    console.log(tab);
    // console.log(selectedOpt)
    // console.log(lookBackInput)

    try {
      if (tab == "4") {
        var data = await axios.get(
          `/dashboard_header/`,
          {
            params: {
              startDate: lookbackDate.startDate,
              endDate: lookbackDate.endDate,
              rule_name: selectedOpt,
            },
          },
          {
            withCredentials: true,
          }
        );
        console.log(data);
        setHeadersData(data?.data?.headersData);
      } else if (tab == "") {
        // console.log('ji')
        var result = await axios.get(
          `/dashboard_header/`,
          { params: { daysToFilter: lookBackInput, rule_name: selectedOpt } },
          {
            withCredentials: true,
          }
        );
        console.log(result);
        setHeadersData(result?.data?.headersData);
      } else {
        console.log(lookBackInput);
        var result = await axios.get(
          `/dashboard_header/`,
          {
            params: { daysToFilter: findLookback(tab), rule_name: selectedOpt },
          },
          {
            withCredentials: true,
          }
        );
        console.log(result);
        setHeadersData(result?.data?.headersData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="p-4 mt-5">
      {FullPageLoader ? (
        <div className="position-relative">
          <div className="backdrop"></div>
          <div className="spinner-container">
            <Spinner />
          </div>
        </div>
      ) : null}

      <div className="row mb-3 ">
        <div className="col-3 adq-source d-flex justify-content-between h-25">
          <p className="mb-0" style={{ fontSize: "14px" }}>
            Summary Stats :{" "}
          </p>
          <select
            className="form-control "
            style={{ width: "200px", marginTop: "-10px" }}
            onChange={(e) => setSelectedOpt(e.target.value)}
          >
            <option value="All" selected>
              All
            </option>
            <option value="Raw Data Rule" selected>
              Raw Data Rules
            </option>
            <option value="Metric Rule">Metric Data Rules</option>
          </select>
        </div>
        <div className="col-9  adq-source d-flex justify-content-end">
          <div>
            <div className="d-flex  align-items-center justify-content-end">
              <p className="mb-0" style={{ fontSize: "14px" }}>
                Lookback Period :{" "}
              </p>
              <button
                type="button"
                value="7"
                name="week"
                className={`adq-button ${lookback === "1" ? "active" : ""}`}
                onClick={() => {
                  toggleLookback("1");
                }}
              >
                1 Week
              </button>
              <button
                type="button"
                value="30"
                name="1month"
                className={`adq-button ${lookback === "2" ? "active" : ""}`}
                onClick={() => {
                  toggleLookback("2");
                }}
              >
                1 Month
              </button>
              <button
                type="button"
                value="90"
                name="3month"
                className={`adq-button ${lookback === "3" ? "active" : ""}`}
                onClick={() => {
                  toggleLookback("3");
                }}
              >
                3 Months
              </button>
              <button
                type="button"
                name="custom"
                className={`adq-button ${lookback === "4" ? "active" : ""}`}
                onClick={() => {
                  toggleLookback("4");
                }}
              >
                Custom
              </button>
              <input
                type="text"
                name="inputVal"
                placeholder="Enter"
                min="0"
                max="100"
                className="form-control ms-1"
                style={{ width: "13%", height: "26px" }}
                value={lookBackInput}
                onInput={handleChangeLook}
                onKeyPress={handleKeyPress}
              ></input>
            </div>
            {lookback === "4" ? (
              <div className="d-flex justify-content-end mt-2">
                <div className="row w-75 ">
                  <div className="col-6 ">
                    <label className="fs-6">Start Date</label>
                    <input
                      type="date"
                      value={lookbackDate?.startDate}
                      name="startDate"
                      onInput={handleDateLookBack}
                      max={moment(new Date()).format("YYYY-MM-DD")}
                      className="form-control  w-100"
                    ></input>
                  </div>
                  <div className=" col-6 ">
                    <label className="fs-6">End Date</label>
                    <input
                      type="date"
                      min={lookbackDate?.startDate}
                      value={lookbackDate?.endDate}
                      name="endDate"
                      max={moment(new Date()).format("YYYY-MM-DD")}
                      onInput={handleDateLookBack}
                      className="form-control w-100"
                    ></input>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <div className="col-2">
          <div className="card p-3 adq-card">
            <div className="row">
              <div className="col-8">
                <span className="counter">{headersData?.total_check || 0}</span>
                <p className="mb-0">Checks</p>
              </div>
              <div className="col-4">
                <div className="text-center pt-2 d-flex justify-content-center h-100 align-items-center">
                  <i
                    class="fa-solid fa-circle-check adq-icon"
                    style={{ color: "#176b87" }}
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-2">
          <div className="card p-3 adq-card">
            <div className="row">
              <div className="col-8">
                <span className="counter">{headersData?.total_pass || 0}</span>
                <p className="mb-0">Passed</p>
              </div>
              <div className="col-4">
                <div className="text-center pt-2 d-flex justify-content-center h-100 align-items-center">
                  <i
                    class="fa-solid fa-thumbs-up adq-icon"
                    style={{ color: "mediumseagreen" }}
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-2">
          <div className="card p-3 adq-card">
            <div className="row">
              <div className="col-8">
                <span className="counter">{headersData?.total_fail || 0}</span>
                <p className="mb-0">Fails</p>
              </div>
              <div className="col-4">
                <div className="text-center pt-2 d-flex justify-content-center h-100 align-items-center">
                  <i
                    class="fa-solid fa-thumbs-down adq-icon"
                    style={{ color: "red" }}
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-2">
          <div className="card p-3 adq-card">
            <div className="row">
              <div className="col-8">
                <span className="counter">{headersData?.total_warn || 0}</span>
                <p className="mb-0">Warnings</p>
              </div>
              <div className="col-4">
                <div className="text-center pt-2 d-flex justify-content-center h-100 align-items-center">
                  <i
                    class="fa-solid fa-triangle-exclamation adq-icon"
                    style={{ color: "#dcdc22" }}
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-2">
          <div className="card p-3 adq-card">
            <div className="row">
              <div className="col-8">
                <span className="counter">
                  {headersData?.total_invalid || 0}
                </span>
                <p className="mb-0">Invalid</p>
              </div>
              <div className="col-4">
                <div className="text-center pt-2 d-flex justify-content-center h-100 align-items-center">
                  <i
                    class="fa-solid fa-circle-xmark adq-icon"
                    // onClick={loaderCall}
                    style={{ color: "orange" }}
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card mt-3 pb-3 adq-card">
        {/* <h2 className='adq-source ms-2 mt-2'>Source Summary</h2> */}
        <Tabs
          selectedIndex={selectedTabIndex}
          onSelect={handleTabSelect}
          className="ms-2"
        >
          <TabList className="horizontal-tabs">
            <Tab
              className={`tab-item  ${
                selectedTabIndex === 0 ? "active-tab" : ""
              }`}
              style={{ fontSize: "20px", fontWeight: "700" }}
            >
              Raw Data Source Summary
            </Tab>
            <Tab
              className={`tab-item ${
                selectedTabIndex === 1 ? "active-tab" : ""
              }`}
              style={{ fontSize: "20px", fontWeight: "700" }}
            >
              Metric Data Source Summary
            </Tab>
            <hr />
          </TabList>
          <TabPanel>
            {" "}
            <div className="row">
              <div
                className="col-4 mt-4 d-flex justify-content-center align-items-center "
                style={{ borderRight: `1px solid ${color}` }}
              >
                {graphLoader ? (
                  <div>
                    <Bars
                      type="Puff"
                      color="#176b87"
                      height={100}
                      width={100}
                    />
                    <h6>Loading . . .</h6>
                  </div>
                ) : chartData?.data?.labels.length <= 0 ? (
                  <div class="container d-flex justify-content-center align-items-center vh-100 border border-secondary">
                    <div>
                      <p class="text-center">No Failed Record(s) Found</p>
                    </div>
                  </div>
                ) : (
                  <div className="mt-5 w-100">
                    <Bar
                      data={chartData.data}
                      options={chartData.options}
                      width={100}
                      height={100}
                    />
                  </div>
                )}
              </div>
              <div className="col-8">
                {sourceSummaryLoader ? (
                  <div className=" d-flex justify-content-center align-items-center text-center h-100">
                    <Bars
                      type="Puff"
                      color="#176b87"
                      height={100}
                      width={100}
                    />
                    <h6>Loading . . .</h6>
                  </div>
                ) : sourceTableData?.length <= 0 ? (
                  <div class="container d-flex justify-content-center align-items-center vh-100 ">
                    <div>
                      <p class="text-center">No Data Found</p>
                    </div>
                  </div>
                ) : (
                  <SourceSummary
                    type="RawData"
                    sourceTableData={sourceTableData}
                    sourceList={sourceForSummary}
                    lookback={lookback}
                    lookbackDate={lookbackDate}
                    numberOfDays={lookBackInput}
                    filter={parsedData}
                  ></SourceSummary>
                )}
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="row">
              <div
                className="col-4 mt-4 d-flex justify-content-center align-items-center "
                style={{ borderRight: `1px solid ${color}` }}
              >
                {graphLoader ? (
                  <div>
                    <Bars
                      type="Puff"
                      color="#176b87"
                      height={100}
                      width={100}
                    />
                    <h6>Loading . . .</h6>
                  </div>
                ) : metricChart?.data?.labels.length <= 0 ? (
                  <div class="container d-flex justify-content-center align-items-center vh-100 border border-secondary">
                    <div>
                      <p class="text-center">No Failed Record(s) Found</p>
                    </div>
                  </div>
                ) : (
                  <div className="mt-5 w-100">
                    <Bar
                      data={metricChart.data}
                      options={metricChart.options}
                      width={100}
                      height={100}
                    />
                  </div>
                )}
              </div>
              <div className="col-8">
                {sourceSummaryLoader ? (
                  <div className=" d-flex justify-content-center align-items-center text-center h-100">
                    <Bars
                      type="Puff"
                      color="#176b87"
                      height={100}
                      width={100}
                    />
                    <h6>Loading . . .</h6>
                  </div>
                ) : metricTable?.length <= 0 ? (
                  <div class="container d-flex justify-content-center align-items-center vh-100 ">
                    <div>
                      <p class="text-center">No Data Found</p>
                    </div>
                  </div>
                ) : (
                  <SourceSummary
                    type="Metric"
                    sourceTableData={metricTable}
                    sourceList={sourceForSummary}
                    lookback={lookback}
                    lookbackDate={lookbackDate}
                    numberOfDays={lookBackInput}
                  ></SourceSummary>
                )}
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>

      {/* <div className="card mt-3 pb-3 adq-card  ">
        {vintageTblLoader ? (
          <div className=" d-flex justify-content-center align-items-center text-center">
            <Bars type="Puff" color="#176b87" height={100} width={100} />
            <h6>Loading . . .</h6>
          </div>
        ) : (
          <DataVintage vintageTable={vintageData} />
        )}
      </div> */}
    </div>
  );
}

export default Home;
